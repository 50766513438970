<template>
  <div id="page-content-wrapper" class="admin-content-area customer-edit-form">
    <template v-if="permissionNameArray.includes('can_add_customer_master')">
      <div class="container-fluid xyz">
        <common-loader
          refId="commonLoaderAddCustomerFormFields"
          ref="commonLoaderAddCustomerFormFields"
        >
        </common-loader>
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>

        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>

          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <!-- Customer Id -->
            <div class="customer-id">
              <div class="row">
                <div class="col-6 forms-block"></div>
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.customer_id_label }}
                    </div>
                  </div>
                  <div class="col-7 paddingleft0">
                    <input type="text" class="form-control" v-model="cus_id" :id="pre_id + 'CusID'" disabled />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ cus_id_error }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--START: Customer Details Card -->

            <h5 class="border-bottom pb-2 mt-3 mb-3">Customer Details</h5>
            <div class="row no-margin">
              <!-- isIndBuyer Checkbox -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    <label :for="pre_id + 'is_ind_buyer'">{{
                      staticInputLabelMessages.is_ind_buyer_label
                    }}</label>
                  </div>
                </div>
                <div class="col-9 paddingleft0 pt-2 custom-checkbox-v">
                  <label class="container">
                    <input
                      :id="pre_id + 'is_ind_buyer'"
                      v-model="is_ind_buyer"
                      value="true"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <!-- ABN Number -->
              <div v-if="production_country == 'AU'" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_abn_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0 input-with-icon">
                  <input
                    :id="pre_id + 'abn_no'"
                    @input="anbNoError = ''"
                    v-model="abnNo"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ anbNoError }}</span>
                </div>
              </div>

              <!-- ContactName -->
              <div class="col-6 forms-block" v-show="!is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="nameError = ''"
                    :id="pre_id + 'name'"
                    v-model="contactName"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ nameError }}</span>
                </div>
              </div>

              <!-- FirstName -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.first_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="firstNameError = ''"
                    :id="pre_id + 'firstName'"
                    v-model="firstName"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ firstNameError }}</span>
                </div>
              </div>

              <!-- Middle Name -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.middle_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    :id="pre_id + 'middleName'"
                    v-model="middleName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- LastName -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.last_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    :id="pre_id + 'lastName'"
                    v-model="lastName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Primary Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.primary_phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="primaryPhoneError = ''"
                    :id="pre_id + 'primaryPhone'"
                    v-model="primaryPhone"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ primaryPhoneError }}</span>
                </div>
              </div>

              <!--Email -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.email_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="emailError = ''"
                    :id="pre_id + 'email'"
                    v-model="email"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ emailError }}</span>
                </div>
              </div>

              <!--Manage By-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.managed_by_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="manageBy"
                    @change="changeManageBy"
                    @input="manageByError = ''"
                    name="manageBy"
                    :id="pre_id + 'manageBy'"
                    label="text"
                    :clearable="false"
                    @search="onSearchManageBy"
                    :filterable="false"
                    :options="manageByArr"
                    :placeholder="staticMessages.managed_by_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isManageByRecordNotFound">
                        {{ staticMessages.managed_by_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ manageByError }}</span>
                </div>
              </div>

              <!--Tag-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                    <div class="form-label">{{staticInputLabelMessages.tag_lable}}</div>
                </div>
                <div class="col-9 paddingleft0">
                        <v-select
                        v-model="tagId"
                        multiple
                        label="text"
                        id="tagId"
                        :clearable="true"
                        :searchable="true"
                        name="tagId"
                        :filterable="true"
                        :options=tagArr
                        :placeholder="staticMessages.select_tags">
                        </v-select>
                </div> 
              </div>
            </div>
            <!--END: Customer Details Card -->

            <!--START: Dlr/Spec Details -->
            <template v-if="dealAppSlug == 'amp-dealer-manager'">
              <h5 class="border-bottom pb-2 mt-3 mb-3">Dlr/Spec Details</h5>
              <div class="row no-margin">
                <!--Distributor Type-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.distributor_type_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'distributor_type'"
                      v-model="distributorType"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Funk - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.funk_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'funk_dlr_spec'"
                      v-model="funkDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Barko - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.barko_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'barko_dlr_spec'"
                      v-model="barkoDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Gearmatic Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.gearmatic_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'gearmatic_spec'"
                      v-model="gearmaticSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Cat/Weiler - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.cat_weiler_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'cat_weiler_dlr_spec'"
                      v-model="catWeilerDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Prentice - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.prentice_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'prentice_dlr_spec'"
                      v-model="prenticeDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Deere - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.deere_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'deere_dlr_spec'"
                      v-model="deereDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Tigercat - Dlr/Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.tigercat_dlr_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'tigercat_dlr_spec'"
                      v-model="tigercatDlrSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Esco Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.esco_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'esco_spec'"
                      v-model="escoSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Timberjack Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.timberjack_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'timberjack_spec'"
                      v-model="timberjackSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Franklin/TRE Spec-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.franklin_tre_spec_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'franklin_tre_spec'"
                      v-model="franklinTreSpec"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>

                <!--Other Dlr - write in-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{staticInputLabelMessages.other_dlr_write_in_lbl}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      :id="pre_id + 'other_dlr_write_in'"
                      v-model="otherDlrWriteIn"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                  </div> 
                </div>
              </div>
            </template>
            <!--END: Dlr/Spec Details -->

            <!--START: Customer Address -->
            <h5 class="border-bottom pb-2 mt-3 mb-3">Customer Address</h5>
            <div class="row no-margin">
              <!-- Country -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.country_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="countryId"
                    @change="changeConutry"
                    @input="countryError = ''"
                    :name="pre_id + 'countryId'"
                    :id="pre_id + 'countryId'"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="countryArr"
                    :disabled="true"
                    :placeholder="staticMessages.country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ countryError }}</span>
                </div>
              </div>

              <!-- State -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.state_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="stateId"
                    name="state"
                    :id="pre_id + 'stateId'"
                    label="text"
                    :filterable="true"
                    :options="stateArr"
                    @input="stateError = ''"
                    @change="onSearchState"
                    :placeholder="staticMessages.state_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ stateError }}</span>
                </div>
              </div>

              <!-- City -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.city_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="cityId"
                    name="city"
                    :id="pre_id + 'cityId'"
                    label="text"
                    :filterable="true"
                    :options="cityArr"
                    @input="cityError = ''"
                    @search="onSearchCity"
                    :clearSearchOnSelect="true"
                    @change="updateCity"
                    :placeholder="staticMessages.city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ cityError }}</span>
                </div>
              </div>

              <!--Address-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.address_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="addressError = ''"
                    @change="changeAddress"
                    :id="pre_id + 'address'"
                    v-model="address"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ addressError }}</span>
                </div>
              </div>

              <!--Zipcode -->
              <div class="clear"></div>
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.zip_code_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="zipcodeError = ''"
                    @change="changeZipCode"
                    :id="pre_id + 'zipcode'"
                    v-model="zipcode"
                    class="form-control"
                    type="text"
                    maxlength="10"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ zipcodeError }}</span>
                </div>
              </div>

              <!--Address2-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.cus_address2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="address2Error = ''"
                    @change="changeAddress2"
                    :id="pre_id + 'address2'"
                    v-model="address2"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <span class="help is-danger">{{ address2Error }}</span>
                </div>
              </div>
              <div class="clearfix"></div>

              <!-- billAddressSameAsAddress -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    <label :for="pre_id + 'billAddressSameAsAddress'">{{
                      staticInputLabelMessages.is_bill_address_same_label
                    }}</label>
                  </div>
                </div>
                <div class="col-6 paddingleft0 pt-2 custom-checkbox-v">
                  <label class="container">
                    <input
                      :id="pre_id + 'billAddressSameAsAddress'"
                      v-model="billAddressSameAsAddress"
                      value="true"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <!--END: Customer Address -->

            <!--START: Customer Billing Address -->
            <div class="" v-show="!billAddressSameAsAddress">
              <div class="">
                <h5 class="border-bottom pb-2 mt-3 mb-3" :for="pre_id + 'AddBilling'">Billing Address</h5>
              </div>
              <div class="row no-margin">
                <!--Bill Country -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.bill_country_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      @input="billCountryError = ''"
                      v-model="billCountry"
                      @change="changeBillCountry"
                      name="billCountry"
                      :id="billCountry"
                      label="text"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :options="billCountryArr"
                      :disabled="true"
                      :placeholder="staticMessages.country_place_holder"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isBillCountryRecordNotFound">
                          {{ staticMessages.country_search }}
                        </template>
                        <template v-else>
                          {{ staticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ billCountryError }}</span>
                  </div>
                </div>

                <!--Bill State -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.bill_state_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      @input="billStateError = ''"
                      v-model="billState"
                      name="billState"
                      :id="pre_id + 'AddBilling-billState'"
                      label="text"
                      :filterable="true"
                      :options="billStateArr"
                      @search="onSearchBillState"
                      :placeholder="staticMessages.state_place_holder"
                      class="custom-ci-auto-search"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isBillStateRecordNotFound">
                          {{ staticMessages.state_search }}
                        </template>
                        <template v-else>
                          {{ staticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ billStateError }}</span>
                  </div>
                </div>

                <!--Bill City -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.bill_city_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      @input="billCityError = ''"
                      v-model="billCity"
                      name="billCity"
                      :id="pre_id + 'AddBilling-billCity'"
                      label="text"
                      :filterable="false"
                      :options="billCityArr"
                      @search="onSearchBillCity"
                      :clearSearchOnSelect="true"
                      @change="updateBillCity"
                      :placeholder="staticMessages.city_place_holder"
                      class="custom-ci-auto-search"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isBillCityRecordNotFound">
                          {{ staticMessages.city_search }}
                        </template>
                        <template v-else>
                          {{ staticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ billCityError }}</span>
                  </div>
                </div>

                <!--Bill Address1-->
                <div class="clear"></div>
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.bill_address1_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      @input="billAddress1Error = ''"
                      :id="pre_id + 'AddBilling-billAddress1'"
                      v-model="billAddress1"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ billAddress1Error }}</span>
                  </div>
                </div>

                <!--Bill Post -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                        <!-- Just changed label to keep it same as customer address zipcode. -->
                        {{ staticInputLabelMessages.bill_zip_code_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      @input="billPostError = ''"
                      :id="pre_id + 'AddBilling-billPost'"
                      v-model="billPost"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ billPostError }}</span>
                  </div>
                </div>

                <!--Bill Address2-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">
                      {{ staticInputLabelMessages.bill_address2_label }}
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      @input="billAddress2Error = ''"
                      id="billAddress2"
                      v-model="billAddress2"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <span class="help is-danger">{{
                      billAddress2Error
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
                <h5 class="border-bottom pb-2 mt-3 mb-3" >Customer Type</h5>
                  <template v-for="(customerType, index) in customerTypeArray">
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          <label :for="pre_id + customerType.name">{{
                            customerType.name
                          }}</label>
                        </div>
                      </div>
                      <div class="col-6 paddingleft0 pt-2 custom-checkbox-v">
                        <label class="container">
                          <input
                            :id="pre_id + customerType.name"
                            v-model="selectedCustomerType"
                            :value='customerType.id'
                            class="form-control"
                            type="checkbox"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </template>
            </div>


            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger" id="alertError">
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input
                id="addCustomerBtn"
                :disabled="disableButtons.update"
                type="submit"
                :value="buttonNames.update"
                class="btn btn-primary"
                v-on:click.prevent="addCustomer()"
              />
              <input
                id="cancelCustomerBtn"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>
          </div>
          <!-- Forms -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import { HTTP } from "../../http-common";
import EventBus from ".././event-bus";
import { commonMixins } from "@/mixins/commonMixins";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "AddCustomerFormFields",
  mixins: [commonMixins],
  components: {
    "common-loader": CommonLoader,
  },

  data() {
    return {
      production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
      forceSaveContact:false,
      forceSaveContactFor:[],
      pre_id: "customer_form_fields_",
      staticInputLabelMessages: "",
      staticMessages: "",
      abnNo: "",
      anbNoError: "",
      firstName: "",
      firstNameError: "",
      middleName: "",
      lastName: "",
      contactName: "",
      nameError: "",
      primaryPhone: "",
      primaryPhoneError: "",
      email: "",
      emailError: "",
      manageBy: "",
      manageByArr: [],
      isManageByRecordNotFound: false,
      manageByError: "",
      countryId: "",
      countryArr: [],
      isCountryRecordNotFound: false,
      countryError: "",
      stateId: "",
      stateArr: [],
      isStateRecordNotFound: false,
      stateError: "",
      cityId: "",
      cityArr: [],
      isCityRecordNotFound: false,
      cityError: "",
      address: "",
      addressError: "",
      zipcode: "",
      zipcodeError: "",
      address2: "",
      address2Error: "",
      billAddressSameAsAddress: false,
      billAddress1: "",
      billAddress2: "",
      billCountry: "",
      billCountryArr: [],
      isBillCountryRecordNotFound: false,
      billState: "",
      billStateArr: [],
      isBillStateRecordNotFound: false,
      billCity: "",
      billCityArr: [],
      isBillCityRecordNotFound: false,
      billPost: "",
      buttonNames: {
        update: "Update",
        cancel: "Cancel",
      },
      disableButtons: {
        update: false,
        cancel: false,
      },
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Customer",
      permissionNameArray: ["can_add_customer_master"],
      is_ind_buyer: false,
      billCountryError: "",
      billStateError: "",
      billCityError: "",
      billPostError: "",
      billAddress1Error: "",
      billAddress2Error: "",
      customerTypeArray : [],
      selectedCustomerType : [],
      cus_id: "",
      cus_id_error: "",
      tagId: [],
      tagArr: [],
      distributorType: "",
      funkDlrSpec: "",
      barkoDlrSpec: "",
      gearmaticSpec: "",
      catWeilerDlrSpec: "",
      prenticeDlrSpec: "",
      deereDlrSpec: "",
      tigercatDlrSpec: "",
      escoSpec: "",
      timberjackSpec: "",
      franklinTreSpec: "",
      otherDlrWriteIn: "",
      dealAppSlug: ""
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
  },
  mounted: function () {
    self.dealAppSlug = process.env.VUE_APP_APP_SLUG;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.setActiveMenu("contact-list");
    self.getAllCustomerType();

    $("#alertError").hide();
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.id = self.$route.params.id;
      self.getCustomerDetailsById(self.$route.params.id);
      self.buttonNames.update = "Update";
      self.pageTitle = "Edit Customer";
    } else {
      self.countryId = self.prodCountryObj();
    }

    self.loadAllData(
      ["Countries"],
      false,
      self.loadCountryCallBack,
      "undefined",
      ""
    );
    self.loadAllData(["Tag"], false, self.loadTagsDataCallback, "undefined", "", "", "", "", "", "", "", "ContactMaster");
  },
  watch: {
    countryId: function (countryId) {
      self.loadAllData(
        ["States"],
        true,
        self.loadStateDataCallback,
        "undefined",
        "",
        self.countryId.value
      );
    },
    stateId: function (stateId) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
    billCountry: function (billCountry) {
      self.loadAllData(
        ["States"],
        true,
        self.loadBillStateDataCallback,
        "undefined",
        "",
        self.billCountry.value
      );
    },
    billState: function (billState) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    },
    forceSaveContact: function (forceSaveContact) {
      if(self.forceSaveContact){
        console.log("calling again addCustomer");
        self.addCustomer();
      }
    },
  },
  methods: {
    getCustIdUniqueCode() {
      self.cus_id_error = "";
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
      };
      HTTP.get("getCustIdUniqueCode", config).then((response) => {
        self.cus_id = response.data.content;
      });
    },
    loadTagsDataCallback(callbackData) {
      self.tagArr = callbackData.Tag;
    },
    loadCountryCallBack: function (callbackData) {
      self.isCountryRecordNotFound = self.isBillCountryRecordNotFound = false;
      self.countryArr = self.billCountryArr = callbackData.Countries;
      if (self.countryArr.length == 0) {
        self.isCountryRecordNotFound = true;
      }
      if (self.billCountryArr.length == 0) {
        self.isBillCountryRecordNotFound = true;
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr) {
        self.isCityRecordNotFound = true;
      }
    },
    onSearchManageBy(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadManageByCallBack,
          loading,
          search
        );
      }
    },
    loadManageByCallBack: function (callbackData) {
      self.isManageByRecordNotFound = false;
      self.manageByArr = callbackData.User;
      if (self.manageByArr) {
        self.isManageByRecordNotFound = true;
      }
    },
    changeManageBy: function (value) {},
    changeConutry: function (value) {
      self.forceSaveContactFor = [];
    },
    //need to clear forceSaveContactFor for change in customer address 
    // as on basis os this we are calling USPS api for customer address validtion
    changeAddress: function (value) {
      self.forceSaveContactFor = [];
    },
    changeAddress2: function (value) {
      self.forceSaveContactFor = [];
    },
    changeZipCode: function (value) {
      self.forceSaveContactFor = [];
    },
    changeStatus: function (value) {},
    changeBillCountry: function (value) {},
    onSearchCity(search, loading) {
      if (self.stateId == null || !self.stateId.value) {
        self.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadCityDataCallback,
            loading,
            search,
            self.stateId.value
          );
        }
      }
    },
    onSearchState(search, loading) {
      self.forceSaveContactFor = [];
      if (search.length > 2) {
        if (self.countryId == null || !self.countryId.value) {
          self.showSAlert("Please Select Country First!", "info", false);
        } else {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadStateDataCallback,
            loading,
            search,
            self.countryId.value
          );
        }
      }
    },
    loadStateDataCallback: function (callbackData) {
      self.isStateRecordNotFound = false;
      self.stateArr = callbackData.States;
      if (self.stateArr.length == 0) {
        self.isStateRecordNotFound = true;
      }
    },
    onSearchBillCity(search, loading) {
      if (self.billState == null || !self.billState.value) {
        self.showSAlert("Please Select Bill State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadBillCityDataCallback,
            loading,
            search,
            self.billState.value
          );
        }
      }
    },
    loadBillCityDataCallback: function (callbackData) {
      self.isBillCityRecordNotFound = false;
      self.billCityArr = callbackData.Cities;
      if (self.billCityArr) {
        self.isBillCityRecordNotFound = true;
      }
    },
    onSearchBillState(search, loading) {
      if (self.billCountry == null || !self.billCountry.value) {
        self.showSAlert("Please Select Bill Country First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadBillStateDataCallback,
            loading,
            search,
            self.billCountry.value
          );
        }
      }
    },
    loadBillStateDataCallback: function (callbackData) {
      self.isBillStateRecordNotFound = false;
      self.billStateArr = callbackData.States;
      if (self.billStateArr.length == 0) {
        self.isBillStateRecordNotFound = true;
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr) {
        self.isCityRecordNotFound = true;
      }
    },
    updateCity() {
      self.forceSaveContactFor = [];
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
    updateBillCity() {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    },
    showAlertOnSave: function (alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function () {
          $("#success-alert").slideUp(500);
        });
    },
    getCustomerDetailsById: function (id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
        params: {
          id: id,
        },
      };
      $("#commonLoaderAddCustomerFormFields")
        .find(".loading-container.lodingTable")
        .show();
      HTTP.get("/get/contact", config)
        .then((response) => {
          self.contactName =
            response.data.content.contact_name &&
            response.data.content.contact_name != ""
              ? response.data.content.contact_name
              : self.full_name;
          self.abnNo = response.data.content.abn_no;
          self.fullName = response.data.content.full_name;
          self.firstName = response.data.content.first_name;
          self.lastName = response.data.content.last_name;
          self.primaryPhone = response.data.content.primary_phone;
          self.email = response.data.content.email;
          if (response.data.content.manage_by_id == 0) {
            self.manageBy = "";
          } else if (
            !self.numberFormateValidation(response.data.content.manage_by_id)
          ) {
            self.manageBy = "";
          } else {
            self.manageBy = {
              value: response.data.content.manage_by_id,
              text: response.data.content.manage_by_name,
            };
          }
          self.countryId = {
            value: response.data.content.country_id,
            text: response.data.content.countryName,
          };
          self.stateId = {
            value: response.data.content.state_id,
            text: response.data.content.stateName,
            state_code: response.data.content.state_code,
          };
          self.cityId = {
            value: response.data.content.city_id,
            text: response.data.content.cityName,
          };
          self.address = response.data.content.address;
          self.zipcode = response.data.content.zip_code;
          if (
            response.data.content.address2 !== null &&
            response.data.content.address2 != ""
          ) {
            self.address2 = response.data.content.address2;
          }
          if (
            response.data.content.middle_name !== null &&
            response.data.content.middle_name != ""
          ) {
            self.middleName = response.data.content.middle_name;
          }
          if (
            response.data.content.bill_addr1 != null &&
            response.data.content.bill_addr1 != ""
          ) {
            self.billAddress1 = response.data.content.bill_addr1;
          }
          if (
            response.data.content.bill_addr2 != null &&
            response.data.content.bill_addr2 != ""
          ) {
            self.billAddress2 = response.data.content.bill_addr2;
          }
          if (
            response.data.content.bill_country_id != null &&
            response.data.content.bill_country_id != ""
          ) {
            self.billCountry = {
              value: response.data.content.bill_country_id,
              text: response.data.content.countryName,
            };
          }
          if (
            response.data.content.bill_state_id != null &&
            response.data.content.bill_state_id != ""
          ) {
            self.billState = {
              value: response.data.content.bill_state_id,
              text: response.data.content.billStateName,
              state_code: response.data.content.billState_code,
            };
          }
          if (
            response.data.content.bill_city_id != null &&
            response.data.content.bill_city_id != ""
          ) {
            self.billCity = {
              value: response.data.content.bill_city_id,
              text: response.data.content.billCityName,
            };
          }
          if (
            response.data.content.bill_post != null &&
            response.data.content.bill_post != ""
          ) {
            self.billPost = response.data.content.bill_post;
          }

          self.selectedCustomerType = response.data.content.customer_types;

          if (response.data.content.customer_id != null) {
            self.cus_id = response.data.content.customer_id;
          } else {
            self.getCustIdUniqueCode();
          }

          if (response.data.content.tags.length > 0) {
            response.data.content.tags.forEach(tag => {
              self.tagId.push({text :tag.tag.tag_label, value: tag.tag.id});
            });
          }

          if (response.data.content.cus_misc1 != null) {
            self.distributorType = response.data.content.cus_misc1;
          }
          if (response.data.content.cus_misc2 != null) {
            self.funkDlrSpec = response.data.content.cus_misc2;
          }
          if (response.data.content.cus_misc3 != null) {
            self.barkoDlrSpec = response.data.content.cus_misc3;
          }
          if (response.data.content.cus_misc4 != null) {
            self.gearmaticSpec = response.data.content.cus_misc4;
          }
          if (response.data.content.cus_misc5 != null) {
            self.catWeilerDlrSpec = response.data.content.cus_misc5;
          }
          if (response.data.content.cus_misc6 != null) {
            self.prenticeDlrSpec = response.data.content.cus_misc6;
          }
          if (response.data.content.cus_misc7 != null) {
            self.deereDlrSpec = response.data.content.cus_misc7;
          }
          if (response.data.content.cus_misc8 != null) {
            self.tigercatDlrSpec = response.data.content.cus_misc8;
          }
          if (response.data.content.cus_misc9 != null) {
            self.escoSpec = response.data.content.cus_misc9;
          }
          if (response.data.content.cus_misc10 != null) {
            self.timberjackSpec = response.data.content.cus_misc10;
          }
          if (response.data.content.cus_misc11 != null) {
            self.franklinTreSpec = response.data.content.cus_misc11;
          }
          if (response.data.content.cus_misc12 != null) {
            self.otherDlrWriteIn = response.data.content.cus_misc12;
          }

          $("#commonLoaderAddCustomerFormFields")
            .find(".loading-container.lodingTable")
            .hide();
        })
        .catch(function (err) {
          self.catchHandler(err, function () {});
          $("#commonLoaderAddCustomerFormFields")
            .find(".loading-container.lodingTable")
            .hide();
        });
    },
    checkForm: function () {
      var checked = true;
      let scrollTo = "";

      //Customer Details Validations
      const abnRegex = /^\d{11}$/;
      if (self.production_country === "AU") {
        if (!self.abnNo) {
          self.anbNoError = "Please enter ABN";
          if (checked) {
            scrollTo = self.pre_id + "abn_no";
            checked = false;
          }
        } else if (!abnRegex.test(self.abnNo)) {
          self.anbNoError = "ABN must be a 11-digit number";
          if (checked) {
            checked = false;
          }
        }
      }
      if (!self.contactName && !self.is_ind_buyer) {
        self.nameError = "Please enter name";
        if (checked) {
          scrollTo = self.pre_id + "name";
          checked = false;
        }
      }else if(self.is_ind_buyer && !self.firstName){
        self.firstNameError = "Please enter first name";
        if (checked) {
          scrollTo = self.pre_id + "firstName";
          checked = false;
        }
      }
      let phoneRegex = /^(\+\d{10,15}|\d{10,15})$/;
      if (!self.primaryPhone) {
        self.primaryPhoneError = "Please enter primary phone";
        if (checked) {
          scrollTo = self.pre_id + "primaryPhone";
          checked = false;
        }
      } else if (!phoneRegex.test(self.primaryPhone)) {
        self.primaryPhoneError =
          "Invalid phone number. Please enter a valid number.";
        scrollTo = self.pre_id + "primaryPhone";
        checked = false;
      }
      if (!self.email) {
        self.emailError = "Please enter email";
        if (checked) {
          scrollTo = self.pre_id + "email";
          checked = false;
        }
      } else if (self.email && !self.validEmail(self.email)) {
        self.emailError = "Please enter valid email";
        if (checked) {
          scrollTo = self.pre_id + "email";
          checked = false;
        }
      }
      if (self.manageBy == null || !self.manageBy.value) {
        self.manageByError = "Please enter managed by";
        if (checked) {
          scrollTo = self.pre_id + "manageBy";
          checked = false;
        }
      }

      //Customer Address Validations
      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }
      if (self.stateId == null || !self.stateId.value) {
        self.stateError = "Please select state";
        if (checked) {
          scrollTo = self.pre_id + "stateId";
          checked = false;
        }
      }
      if (self.cityId == null || !self.cityId.value) {
        self.cityError = "Please select city";
        if (checked) {
          scrollTo = self.pre_id + "cityId";
          checked = false;
        }
      }
      if (self.address == "" || self.address == null) {
        self.addressError = "Please enter address";
        if (checked) {
          scrollTo = self.pre_id + "address";
          checked = false;
        }
      }
      if (self.zipcode == "" || self.zipcode == null) {
        self.zipcodeError = "Please enter zip code";
        if (checked) {
          scrollTo = self.pre_id + "zipcode";
          checked = false;
        }
      }

      // Billing Address Validations
      if (!self.billAddressSameAsAddress) {
        if (self.billCountry == null || !self.billCountry.value) {
          self.billCountryError = "Please select bill country";
          if (checked) {
            checked = false;
          }
        }

        if (self.billState == null || !self.billState.value) {
          self.billStateError = "Please select bill state";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billState";
            checked = false;
          }
        }

        if (self.billCity == null || !self.billCity.value) {
          self.billCityError = "Please select bill city";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billCity";
            checked = false;
          }
        }

        if (self.billPost == "" || self.billPost == null) {
          self.billPostError = "Please enter bill zip code";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billPost";
            checked = false;
          }
        }

        if (self.billAddress1 == "" || self.billAddress1 == null) {
          self.billAddress1Error = "Please enter bill address 1";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billAddress1";
            checked = false;
          }
        }

        if (!self.cus_id) {
          self.cus_id_error = "CusID is required";
          if (checked) {
            scrollTo = self.pre_id + "CusID";
            checked = false;
          }
        }
      }
      if (scrollTo && !checked) {
        document
          .getElementById(scrollTo)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }
      return checked;
    },
    handleUspsValidationErrors: function(receivedData,address_type){
      let scrollTo = '';
      if(address_type == 'customerAddress'){
        if(receivedData.address){
          self.addressError = receivedData.address;
          scrollTo = self.pre_id + "address";
        }
        self.address2Error = receivedData.address_2;
        if(receivedData.zip_code){
          self.zipcodeError = receivedData.zip_code;
          scrollTo = self.pre_id + "zipcode";
        }
        if(receivedData.city_name){
          self.cityError = receivedData.city_name;
          scrollTo = self.pre_id + "cityId";
        }
        if(receivedData.state_code){
          self.stateError = receivedData.state_code;
          scrollTo = self.pre_id + "stateId";
        }
      }else if(address_type == 'billAddress'){
        if(receivedData.address){
          self.billAddress1Error = receivedData.address;
          scrollTo = self.pre_id + "AddBilling-billAddress1";
        }
        self.billAddress2Error = receivedData.address_2;
        if(receivedData.city_name){
          self.billCityError = receivedData.city_name;
          scrollTo = self.pre_id + "AddBilling-billCity";
        }
        if(receivedData.state_code){
          self.billStateError = receivedData.state_code;
          scrollTo = self.pre_id + "AddBilling-billState";
        }
        if(receivedData.zip_code){
          self.billPostError = receivedData.zip_code;
          scrollTo = self.pre_id + "AddBilling-billPost";
        }
      }
      return scrollTo;
    },
    addCustomer() {
      setTimeout( () => {
        if (self.checkForm()) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token"),
            },
          };

          let data = {};
          data.id = self.id;
          data.abn_no = self.abnNo;
          data.contact_name = self.contactName;
          data.is_ind_buyer = self.is_ind_buyer ? 1 : 0;
          data.first_name = self.firstName;
          data.middle_name = self.middleName;
          data.last_name = self.lastName;
          data.primary_phone = self.primaryPhone;
          data.manage_by_id = self.manageBy.value;
          data.email = self.email;
          data.address = self.address;
          data.address_2 = self.address2;
          data.zip_code = self.zipcode;

          data.country_id =
            self.countryId && self.countryId.value ? self.countryId.value : "";
          data.state_id =
            self.stateId && self.stateId.value ? self.stateId.value : "";
          data.city_id =
            self.cityId && self.cityId.value ? self.cityId.value : "";
          data.state_code =
            self.stateId && self.stateId.state_code ? self.stateId.state_code : "";
          data.city_name =
            self.cityId && self.cityId.text ? self.cityId.text : "";
          data.forceSaveContactFor = self.forceSaveContactFor;
          data.billAddressSameAsAddress = self.billAddressSameAsAddress;
          data.selectedCustomerType = self.selectedCustomerType;

          if (self.billAddressSameAsAddress) {
            data.bill_address1 = self.address;
            data.bill_address2 = self.address2;
            data.bill_country =
              self.countryId && self.countryId.value
                ? self.countryId.value
                : "";
            data.bill_state =
              self.stateId && self.stateId.value ? self.stateId.value : "";
            data.bill_city =
              self.cityId && self.cityId.value ? self.cityId.value : "";
            data.bill_post = self.zipcode;
          } else {
            data.bill_address1 = self.billAddress1;
            data.bill_address2 = self.billAddress2;
            data.bill_country =
              self.billCountry && self.billCountry.value
                ? self.billCountry.value
                : "";
            data.bill_state =
              self.billState && self.billState.value
                ? self.billState.value
                : "";
            data.bill_city =
              self.billCity && self.billCity.value ? self.billCity.value : "";
            data.bill_post = self.billPost;
            data.bill_state_code =
              self.billState && self.billState.state_code ? self.billState.state_code : "";
            data.bill_city_name =
              self.billCity && self.billCity.text ? self.billCity.text : "";
          }
          data.cus_id = self.cus_id;
          if (Array.isArray(self.tagId)) {
            const serializedTagId = JSON.stringify(self.tagId);
            data.tag_id = serializedTagId;
          }

          data.dealAppSlug = self.dealAppSlug;
          const allDlrSpecFields = [
              'distributorType', 'funkDlrSpec', 'barkoDlrSpec', 'gearmaticSpec',
              'catWeilerDlrSpec', 'prenticeDlrSpec', 'deereDlrSpec', 'tigercatDlrSpec',
              'escoSpec', 'timberjackSpec', 'franklinTreSpec', 'otherDlrWriteIn'
          ];

          allDlrSpecFields.forEach(field => {
              data[field] = (self.dealAppSlug == 'amp-dealer-manager') ? self[field] : '';
          });

          $("#commonLoaderAddCustomerFormFields")
            .find(".loading-container.lodingTable")
            .show();
          self.forceSaveContact = false;
          HTTP.post("/customer/store", data, config)
            .then(function (response) {
              if (response.data.status == "success") {
                self.forceSaveContactFor = [];
                self.disableButtons.update = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false);
                setTimeout(function () {
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.buttonBack();
                }, 3000);
              } else {
                if(response.data && response.data.address_validation){
                  let scrollTo = '';
                  let errorMessage = '';
                  if(response.data.address_type == 'customerAddress'){
                    scrollTo = self.handleUspsValidationErrors(response.data.content,'customerAddress');
                    errorMessage = "Customer Address: " + response.data.message;
                  }else if(response.data.address_type == 'billAddress'){
                    scrollTo = self.handleUspsValidationErrors(response.data.content,'billAddress');
                    errorMessage = "Bill Address: " + response.data.message;
                  }

                  if(response.data.address_validation == "Fail"){
                    self.showSAlert(errorMessage, "error", false);

                    if(scrollTo){
                      setTimeout(function () {
                        document
                        .getElementById(scrollTo)
                        .scrollIntoView({ behavior: "smooth", block: "start" });
                      }, 2000);
                    }
                  }else if(response.data.address_validation == "Warning"){
                    self.$swal({
                        title: "",
                        text: errorMessage,
                        type: "info",
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Update",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                    })
                    .then((result) => {
                        if (result.value) {
                          self.forceSaveContact = true;
                          if(response.data.address_type){
                            self.forceSaveContactFor.push(response.data.address_type);
                          }
                        }else{
                          if(scrollTo){
                            setTimeout(function () {
                              document
                              .getElementById(scrollTo)
                              .scrollIntoView({ behavior: "smooth", block: "start" });
                            }, 2000);
                          }
                        }
                    });
                  }else{
                    // display received error
                    self.showSAlert(errorMessage, "error", false);
                  }
                }else{
                  if (response.data.content.length > 0) {
                    var err = "";
                    for (var i = 0; i < response.data.content.length; i++) {
                      err += response.data.content[i] + "<br/>";
                    }
                    self.showSAlert(err, "error", false);
                  }
                }
              }
              $("#commonLoaderAddCustomerFormFields")
                .find(".loading-container.lodingTable")
                .hide();
            })
            .catch(function (err) {
              if (self.$route.params.id != 0 && self.$route.params.id != "") {
                self.disableButtons.update = false;
                self.disableButtons.cancel = false;
              }
              self.catchHandler(err, function () {});
              $("#commonLoaderAddCustomerFormFields")
                .find(".loading-container.lodingTable")
                .hide();
            });
        }
      }, 200);
    },

    buttonBack() {
      self.$router.push({ name: "ListContact" });
    },

    getAllCustomerType: function () {
      var config = {
          headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
          },
      };

      HTTP.get('/customertype/get', config).
      then(response => {
        self.$nextTick(() => {
            self.customerTypeArray = response.data.content;
        });
      })
      .catch(function (err) {
        if (err.response.status == 0) {
            self.error = "Remote server can not be reachable";
        }
      });
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
<style lang="css" scoped>
.customer-id #regenerateCusIdCode {
  color: #fff;
}
</style>
