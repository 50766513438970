<template>
    <!-- <div class="spaced-container"> -->
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <div class="container-fluid xyz">
                <div class="page-header-top-container">
                    <div class="page-name">{{pageTitle}}</div>
                    <button type="submit" v-on:click.prevent="buttonBack()" class="btn btn-primary waves-effect pull-right">Back</button>
                </div>
                <!--page content-->
                <div class="admin-page-content">
                    <!--Forms-->

                    <div :class="alertClass" id="success-alert">
                        <strong> {{alertMessage}} </strong>
                    </div>
                    <div class="forms-container add_user_forms_container vtc-dealer-forms">
                        <div class="row no-margin">

                            <!--Slug-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.slug_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="slugError = ''" id="name" v-model="slug" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{slugError}}</span>
                                </div>
                            </div>

                            <!-- Company -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="companyId" name="state"
                                              label="text" :filterable="false" :options="companyArr"
                                              @search="onSearchCompany" :clearable="false" @input="companyError = ''"
                                              :placeholder="staticMessages.company_custom_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCompanyRecordNotFound">
                                                {{staticMessages.company_custom_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyError}}</span>
                                </div>
                            </div>

                            <!--Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="location"
                                              @change="changedValueLocation"
                                              @input="locationError = ''"
                                              name="location"
                                              id="location"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="locationArr"
                                              :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{locationError}}</span>
                                </div>
                            </div>

                            <!--Lot-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lot_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lotError = ''" id="lot" v-model="lot" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lotError}}</span>
                                </div>
                            </div>

                            <!--Lot Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lot_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lotLocationError = ''" id="lotLocation" v-model="lotLocation" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lotLocationError}}</span>
                                </div>
                            </div>

                            <!--Lot Address-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lot_address_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lotAddressError = ''" id="lotAddress" v-model="lotAddress" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lotAddressError}}</span>
                                </div>
                            </div>

                            <!--Lot Phone-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lot_phone_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lotPhoneError = ''" id="lotPhone" v-model="lotPhone" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lotPhoneError}}</span>
                                </div>
                            </div>

                            <!--SCSEO-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.scseo_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="scseoError = ''" id="scseo" v-model="scseo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{scseoError}}</span>
                                </div>
                            </div>

                            <!-- State -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="state" name="state"
                                              label="text" :filterable="false" :options="stateArr"
                                              @search="onSearchState" :clearable="false" @input="stateError = ''"
                                              :placeholder="staticMessages.state_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isStateRecordNotFound">
                                                {{staticMessages.state_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{stateError}}</span>
                                </div>
                            </div>

                            <!-- City -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select v-model="cityId" name="state"
                                              label="text" :filterable="false" :options="cityArr"
                                              @input="cityError = ''"
                                              @search="onSearchCity"
                                              :placeholder="staticMessages.city_place_holder" class="custom-ci-auto-search">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isCityRecordNotFound">
                                                {{staticMessages.city_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cityError}}</span>
                                </div>
                            </div>

                            <!--Vehical Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vehical_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vehicalTypeError = ''" id="vehicalType" v-model="vehicalType" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vehicalTypeError}}</span>
                                </div>
                            </div>

                            <!--Class-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.class_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="classError = ''" id="classvehical" v-model="classvehical" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{classError}}</span>
                                </div>
                            </div>

                            <!--Is Truck -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_truck_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="isTruckId"
                                              @change="changeIsTruck"
                                              @input="isTruckError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="isTruckArr"
                                              :placeholder="staticMessages.is_truck_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isTruckError}}</span>
                                </div>
                            </div>

                            <!--Order No.-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.order_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="orderNoError = ''" id="orderNo" v-model="orderNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{orderNoError}}</span>
                                </div>
                            </div>

                            <!-- Order Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.order_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="orderDate"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.order_date_place_holder"
                                        name="issueDate"
                                        @input="chageOrderDate()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{orderDateError}}</span>
                                </div>
                            </div>

                            <!--Price Paid-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.price_paid_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="pricePaidError = ''" id="pricePaid" v-model="pricePaid" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{pricePaidError}}</span>
                                </div>
                            </div>

                            <!-- Acquire on Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.acquire_on_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="acquireOnDate"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.acquire_on_date_place_holder"
                                        name="issueDate"
                                        @input="changeAcuireDate()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{acquireOnDateError}}</span>
                                </div>
                            </div>

                            <!--Title In -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.title_in_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="titleInId"
                                              @change="changeTitleIn"
                                              @input="titleInError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="titleInArr"
                                              :placeholder="staticMessages.is_title_in_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isTruckError}}</span>
                                </div>
                            </div>

                            <!-- Title Out Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.title_out_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="titleOutDate"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.title_out_date_place_holder"
                                        name="issueDate"
                                        @input="changeTitleOutDate()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{titleOutDateError}}</span>
                                </div>
                            </div>

                            <!-- Available Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.available_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="availableDate"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.available_date_place_holder"
                                        name="issueDate"
                                        @input="changeAvailableDate()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{availableDateError}}</span>
                                </div>
                            </div>

                            <!--Year-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.year_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="yearError = ''" id="year" v-model="year" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{yearError}}</span>
                                </div>
                            </div>

                            <!--Make-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="makeId"
                                              @change="changedValue"
                                              @input="makeError = ''"
                                              name="make"
                                              id="make"
                                              label="text"
                                              :clearable="false"
                                              @search="onSearchMake"
                                              :filterable="false"
                                              :options="makeArr"
                                              :placeholder="staticMessages.make_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isMakeRecordNotFound">
                                                {{staticMessages.make_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{makeError}}</span>
                                </div>
                            </div>

                            <!--Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="modelId"
                                              @change="changedValue"
                                              @input="modelError = ''"
                                              name="modelId"
                                              id="modelId"
                                              label="text"
                                              :clearable="false"
                                              @search="onSearchModel"
                                              :filterable="false"
                                              :options="modelArr"
                                              :placeholder="staticMessages.model_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isModelRecordNotFound">
                                                {{staticMessages.model_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{modelError}}</span>
                                </div>
                            </div>

                            <!--VIN No.-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.vin_no_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="vinNoError = ''" id="vinNo" v-model="vinNo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{vinNoError}}</span>
                                </div>
                            </div>

                            <!--Unit-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.unit_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="unitError = ''" id="unit" v-model="unit" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{unitError}}</span>
                                </div>
                            </div>

                            <!--Classification-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.classification_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="classificationError = ''" id="classification" v-model="classification" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{classificationError}}</span>
                                </div>
                            </div>

                            <!--Condition-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.condition_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="conditionId"
                                              @change="changedValue"
                                              @input="conditionError = ''"
                                              name="modelId"
                                              id="modelId"
                                              label="text"
                                              :clearable="false"
                                              @search="onSearchCondition"
                                              :filterable="false"
                                              :options="conditionArr"
                                              :placeholder="staticMessages.condition_place_holder">
                                        <span slot="no-options" @click="$refs.select.open = false">
                                            <template v-if="!isConditionRecordNotFound">
                                                {{staticMessages.condition_search}}
                                            </template>
                                            <template v-else>
                                                {{staticMessages.search_record_not_found}}
                                            </template>
                                        </span>
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{conditionError}}</span>
                                </div>
                            </div>

                            <!--Asking Retail USD-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.asking_retail_usd_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="askingRetailUsdError = ''" id="askingRetailUsd" v-model="askingRetailUsd" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{askingRetailUsdError}}</span>
                                </div>
                            </div>

                            <!--Min Retail USD-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.min_retail_usd_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="minRetailUsdError = ''" id="minRetailUsd" v-model="minRetailUsd" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{minRetailUsdError}}</span>
                                </div>
                            </div>

                            <!--High Retail USD-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.high_retail_usd_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="highRetailUsdError = ''" id="highRetailUsd" v-model="highRetailUsd" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{highRetailUsdError}}</span>
                                </div>
                            </div>

                            <!--Show Price Online -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.show_price_online_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="showPriceOnlineId"
                                              @change="changedValue"
                                              @input="showPriceOnlineError = ''"
                                              name="showPriceOnlineId"
                                              id="showPriceOnlineId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="showPriceOnlineArr"
                                              :placeholder="staticMessages.show_price_online_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{showPriceOnlineError}}</span>
                                </div>
                            </div>

                            <!--Whole Shale USD-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.whole_sale_usd_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="wholeShaleUsdError = ''" id="wholeShaleUsd" v-model="wholeShaleUsd" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{wholeShaleUsdError}}</span>
                                </div>
                            </div>

                            <!--Quantity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.quantity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="quantityError = ''" id="quantity" v-model="quantity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{quantityError}}</span>
                                </div>
                            </div>

                            <!-- Status -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.status_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="statusId"
                                              @change="changedValue"
                                              @input="statusError = ''"
                                              name="statusId"
                                              id="statusId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="statusArr"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span>
                                </div>
                            </div>

                            <!--Visibility Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visibility_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="visibilityTypeId"
                                              @change="changedValue"
                                              @input="visibilityTypeError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="visibilityTypeArr"
                                              :placeholder="staticMessages.visibility_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{visibilityTypeError}}</span>
                                </div>
                            </div>

                            <!--Sale Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sale_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="saleTypeId"
                                              @change="changedValue"
                                              @input="saleTypeError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="saleTypeArr"
                                              :placeholder="staticMessages.sale_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{saleTypeError}}</span>
                                </div>
                            </div>

                            <!-- Is Featured -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_featured_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select
                                              v-model="isFeaturedId"
                                              @change="changedValue"
                                              @input="isFeaturedError = ''"
                                              name="isFeaturedId"
                                              id="isFeaturedId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="isFeaturedArr"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isFeaturedError}}</span>
                                </div>
                            </div>

                            <!--Internal Location-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.internal_location_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="internalLocationError = ''" id="internalLocation" v-model="internalLocation" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{internalLocationError}}</span>
                                </div>
                            </div>

                            <!--Description-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.description_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="descriptionError = ''" id="descrition" v-model="descrition" class="form-control"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span>
                                </div>
                            </div>

                            <!--Website-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.website_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="websiteError = ''" id="website" v-model="website" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{websiteError}}</span>
                                </div>
                            </div>

                            <!--Private Comment-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.private_comment_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="privateCommentError = ''" id="privateComment" v-model="privateComment" class="form-control" ></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{privateCommentError}}</span>
                                </div>
                            </div>

                            <!--Featured Text-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.featured_text_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="featuredTextError = ''" id="featuredText" v-model="featuredText" class="form-control" ></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{featuredTextError}}</span>
                                </div>
                            </div>

                            <!--Special Feature-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.special_feature_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="specialFeatureError = ''" id="specialFeature" v-model="specialFeature" class="form-control"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{specialFeatureError}}</span>
                                </div>
                            </div>

                            <!-- Special Expiration Date -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.special_expiration_date_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                        v-model="specialExpirationDate"
                                        :config="options"
                                        class="form-control"
                                        autocomplete="off"
                                        :placeholder="staticMessages.special_expiration_date_place_holder"
                                        name="issueDate"
                                        @input="changeSpecialExpirationDate()"
                                        >
                                    </date-picker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{specialExpirationDateError}}</span>
                                </div>
                            </div>


                            <!--YouTube Video URL-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.youtube_video_url_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="yupuTubeVideoUrlError = ''" id="yupuTubeVideoUrl" v-model="yupuTubeVideoUrl" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{yupuTubeVideoUrlError}}</span>
                                </div>
                            </div>

                            <!--Warranty Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.warranty_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="warrantyTypeId"
                                              @change="changedValue"
                                              @input="warrantyTypeError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="warrantyTypeArr"
                                              :placeholder="staticMessages.warranty_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{warrantyTypeError}}</span>
                                </div>
                            </div>

                            <!--Odometer-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.odometer_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="odometerError = ''" id="odometer" v-model="odometer" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{odometerError}}</span>
                                </div>
                            </div>

                            <!--Odomileto-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.odomileto_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="odomiletoError = ''" id="odomileto" v-model="odomileto" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{odomiletoError}}</span>
                                </div>
                            </div>

                            <!--Ecmmiles-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.ecmmiles_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="ecmmilesError = ''" id="ecmmiles" v-model="ecmmiles" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{ecmmilesError}}</span>
                                </div>
                            </div>

                            <!--GVW-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gvw_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gvwError = ''" id="gvw" v-model="gvw" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gvwError}}</span>
                                </div>
                            </div>

                            <!--GVWT-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.gvwt_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="gvwtError = ''" id="gvwt" v-model="gvwt" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{gvwtError}}</span>
                                </div>
                            </div>

                            <!--ECM-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.ecm_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="ecmError = ''" id="ecm" v-model="ecm" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{ecmError}}</span>
                                </div>
                            </div>

                            <!--Empty Weight-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.empty_weight_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="emptyWeightError = ''" id="emptyWeight" v-model="emptyWeight" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{emptyWeightError}}</span>
                                </div>
                            </div>

                            <!--Engine Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="engineMakeId"
                                              @change="changedValue"
                                              @input="engineMakeError = ''"
                                              name="engineMakeId"
                                              id="engineMakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="engineMakeArr"
                                              :placeholder="staticMessages.engine_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineMakeError}}</span>
                                </div>
                            </div>

                            <!--Engine Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="engineModelError = ''" id="engineModel" v-model="engineModel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineModelError}}</span>
                                </div>
                            </div>

                            <!--ENGCPL-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engcpl_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="engcplError = ''" id="engcpl" v-model="engcpl" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engcplError}}</span>
                                </div>
                            </div>

                            <!--Engine Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="engineSizeError = ''" id="engineSize" v-model="engineSize" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineSizeError}}</span>
                                </div>
                            </div>

                            <!--Engine HP-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_hp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="engineHpError = ''" id="engineHp" v-model="engineHp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineHpError}}</span>
                                </div>
                            </div>

                            <!-- Engine Brake -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.engine_brake_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="engineBrakeId"
                                              @change="changedValue"
                                              @input="engineBrakeError = ''"
                                              name="engineBrakeId"
                                              id="engineBrakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="engineBrakeArr"
                                              :placeholder="staticMessages.engine_brake_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{engineBrakeError}}</span>
                                </div>
                            </div>

                            <!-- APU Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="apuMakeId"
                                              @change="changedValue"
                                              @input="apuMakeError = ''"
                                              name="apuMakeId"
                                              id="apuMakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="apuMakeArr"
                                              :placeholder="staticMessages.apu_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{apuMakeError}}</span>
                                </div>
                            </div>

                            <!--APU Hours-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_hours_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="apuHoursError = ''" id="apuHours" v-model="apuHours" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{apuHoursError}}</span>
                                </div>
                            </div>

                            <!--APU Serials-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.apu_serials_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="apuSerialsError = ''" id="apuSerials" v-model="apuSerials" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{apuSerialsError}}</span>
                                </div>
                            </div>

                            <!--Trans Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="transMakeId"
                                              @change="changedValue"
                                              @input="transMakeError = ''"
                                              name="transMakeId"
                                              id="transMakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="transMakeArr"
                                              :placeholder="staticMessages.trans_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{transMakeError}}</span>
                                </div>
                            </div>

                            <!--Trans Model-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_model_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="transModelError = ''" id="transModel" v-model="transModel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{transModelError}}</span>
                                </div>
                            </div>

                            <!--Trans Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_speed_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="transSpeedId"
                                              @change="changedValue"
                                              @input="transSpeedError = ''"
                                              name="transSpeedId"
                                              id="transSpeedId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="transSpeedArr"
                                              :placeholder="staticMessages.trans_speed_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{transSpeedError}}</span>
                                </div>
                            </div>

                            <!--Trans Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trans_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="transTypeId"
                                              @change="changedValue"
                                              @input="transTypeError = ''"
                                              name="transTypeId"
                                              id="transTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="transTypeArr"
                                              :placeholder="staticMessages.trans_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{transTypeError}}</span>
                                </div>
                            </div>

                            <!--Fuel Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="fuelTypeId"
                                              @change="changedValue"
                                              @input="fuelTypeError = ''"
                                              name="fuelTypeId"
                                              id="fuelTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="fuelTypeArr"
                                              :placeholder="staticMessages.fuel_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelTypeError}}</span>
                                </div>
                            </div>

                            <!--Fuel Tanks-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_tanks_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fuelTanksError = ''" id="fuelTanks" v-model="fuelTanks" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelTanksError}}</span>
                                </div>
                            </div>

                            <!--Fuel Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fuelCapacityError = ''" id="fuelCapacity" v-model="fuelCapacity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelCapacityError}}</span>
                                </div>
                            </div>

                            <!--Fuel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fuel_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="fuelError = ''" id="fuel" v-model="fuel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fuelError}}</span>
                                </div>
                            </div>

                            <!--Exhaust -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exhaust_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="exhaustId"
                                              @change="changedValue"
                                              @input="exhaustError = ''"
                                              name="exhaustId"
                                              id="exhaustId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="exhaustArr"
                                              :placeholder="staticMessages.exhaust_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{exhaustError}}</span>
                                </div>
                            </div>

                            <!--Breathers -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.breathers_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="breathersId"
                                              @change="changedValue"
                                              @input="breathersError = ''"
                                              name="breathersId"
                                              id="breathersId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="breathersArr"
                                              :placeholder="staticMessages.breathers_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{breathersError}}</span>
                                </div>
                            </div>

                            <!--Rear Ratio-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_ratio_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rearRatioError = ''" id="rearRatio" v-model="rearRatio" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearRatioError}}</span>
                                </div>
                            </div>

                            <!--Rear-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rearError = ''" id="rear" v-model="rear" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearError}}</span>
                                </div>
                            </div>

                            <!--Locking Rears-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.locking_rears_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lockingRearsError = ''" id="lockingRears" v-model="lockingRears" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lockingRearsError}}</span>
                                </div>
                            </div>

                            <!--Axle Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.axle_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="axleTypeId"
                                              @change="changedValue"
                                              @input="axleTypeError = ''"
                                              name="axleTypeId"
                                              id="axleTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="axleTypeArr"
                                              :placeholder="staticMessages.axle_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{axleTypeError}}</span>
                                </div>
                            </div>

                            <!--Steer Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.steer_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="steerAxleCapacityError = ''" id="steerAxleCapacity" v-model="steerAxleCapacity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{steerAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Driver Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.driver_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="driverAxleCapacityError = ''" id="driverAxleCapacity" v-model="driverAxleCapacity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{driverAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Drop Axle Config -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_axle_config_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="dropAxleConfigId"
                                              @change="changedValue"
                                              @input="dropAxleConfigError = ''"
                                              name="dropAxleConfigId"
                                              id="dropAxleConfigId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="dropAxleConfigArr"
                                              :placeholder="staticMessages.drop_axle_config_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dropAxleConfigError}}</span>
                                </div>
                            </div>

                            <!--Drop Axle Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.drop_axle_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dropAxleCapacityError = ''" id="dropAxleCapacity" v-model="dropAxleCapacity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dropAxleCapacityError}}</span>
                                </div>
                            </div>

                            <!--Suspension -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.suspension_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="suspensionId"
                                              @change="changedValue"
                                              @input="suspensionError = ''"
                                              name="suspensionId"
                                              id="suspensionId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="suspensionArr"
                                              :placeholder="staticMessages.suspension_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{suspensionError}}</span>
                                </div>
                            </div>

                            <!--Wheelbase-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wheelbase_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="wheelbaseError = ''" id="wheelbase" v-model="wheelbase" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{wheelbaseError}}</span>
                                </div>
                            </div>

                            <!--Brake Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.brake_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="brakeTypeId"
                                              @change="changedValue"
                                              @input="brakeTypeError = ''"
                                              name="brakeTypeId"
                                              id="brakeTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="brakeTypeArr"
                                              :placeholder="staticMessages.brake_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{brakeTypeError}}</span>
                                </div>
                            </div>

                            <!--Tire Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tiresizeError = ''" id="tiresize" v-model="tiresize" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tiresizeError}}</span>
                                </div>
                            </div>

                            <!--Tire Trade-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_trade_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tireTradeError = ''" id="tireTrade" v-model="tireTrade" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tireTradeError}}</span>
                                </div>
                            </div>

                            <!--Tire Config-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tire_config_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="tireConfigError = ''" id="tireConfig" v-model="tireConfig" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tireConfigError}}</span>
                                </div>
                            </div>

                            <!--Front Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.front_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="frontWheelError = ''" id="frontWheel" v-model="frontWheel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{frontWheelError}}</span>
                                </div>
                            </div>

                            <!--Rear Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rearWheelError = ''" id="rearWheel" v-model="rearWheel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearWheelError}}</span>
                                </div>
                            </div>

                            <!--Is Piloted Wheels -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_piloted_wheels_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="isPilotedWheelsId"
                                              @change="changedValue"
                                              @input="isPilotedWheelsError = ''"
                                              name="isPilotedWheelsId"
                                              id="isPilotedWheelsId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="isPilotedWheelsArr"
                                              :placeholder="staticMessages.is_piloted_wheels_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isPilotedWheelsError}}</span>
                                </div>
                            </div>

                            <!--Is Fifth Wheel-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_fifth_wheel}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="isFifthWheelError = ''" id="isFifthWheel" v-model="isFifthWheel" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isFifthWheelError}}</span>
                                </div>
                            </div>

                            <!--Cab to Axle-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_to_axle_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="cabToAxleError = ''" id="cabToAxle" v-model="cabToAxle" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cabToAxleError}}</span>
                                </div>
                            </div>

                            <!--King Pin-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.king_pin_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="kingPinError = ''" id="kingPin" v-model="kingPin" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{kingPinError}}</span>
                                </div>
                            </div>

                            <!--Wet Line-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.wet_line_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="wetLineError = ''" id="wetLine" v-model="wetLine" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{wetLineError}}</span>
                                </div>
                            </div>

                            <!--Sleeper Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sleeper_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="sleeperSizeError = ''" id="sleeperSize" v-model="sleeperSize" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{sleeperSizeError}}</span>
                                </div>
                            </div>

                            <!--Bunk Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.bunk_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="bunkTypeError = ''" id="bunkType" v-model="bunkType" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{bunkTypeError}}</span>
                                </div>
                            </div>

                            <!--Bunk Heater-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.bunk_heater_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="bunkHeaterError = ''" id="bunkHeater" v-model="bunkHeater" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <div class="form-label">{{staticInputLabelMessages.name_label}}</div>
                                </div>
                            </div>

                            <!--Cab Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="cabTypeId"
                                              @change="changedValue"
                                              @input="cabTypeError = ''"
                                              name="cabTypeId"
                                              id="cabTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="cabTypeArr"
                                              :placeholder="staticMessages.cab_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cabTypeError}}</span>
                                </div>
                            </div>

                            <!--Cab Style -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_style_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="cabStyleId"
                                              @change="changedValue"
                                              @input="cabStyleError = ''"
                                              name="cabStyleId"
                                              id="cabStyleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="cabStyleArr"
                                              :placeholder="staticMessages.cab_style_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cabStyleError}}</span>
                                </div>
                            </div>

                            <!--Is Air Conditioning -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.is_air_conditioning_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="isAirConditioningId"
                                              @change="changedValue"
                                              @input="isAirConditioningError = ''"
                                              name="isAirConditioningId"
                                              id="isAirConditioningId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="isAirConditioningArr"
                                              :placeholder="staticMessages.is_air_conditioning_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{isAirConditioningError}}</span>
                                </div>
                            </div>

                            <!--Interior Color-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interior_color_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="interiorColorError = ''" id="interiorColor" v-model="interiorColor" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{interiorColorError}}</span>
                                </div>
                            </div>

                            <!--Exterior Color-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_color_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="exteriorColorError = ''" id="exteriorColor" v-model="exteriorColor" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{exteriorColorError}}</span>
                                </div>
                            </div>

                            <!--Heated Mirrors -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.heated_mirrors_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="heatedMirriorsId"
                                              @change="changedValue"
                                              @input="heatedMirriorsError = ''"
                                              name="heatedMirriorsId"
                                              id="heatedMirriorsId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="heatedMirriorsArr"
                                              :placeholder="staticMessages.is_heated_mirrors_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{heatedMirriorsError}}</span>
                                </div>
                            </div>

                            <!--Fairings -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.fairings_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="fairingId"
                                              @change="changedValue"
                                              @input="fairingError = ''"
                                              name="fairingId"
                                              id="fairingId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="fairingArr"
                                              :placeholder="staticMessages.is_fairings_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{fairingError}}</span>
                                </div>
                            </div>

                            <!--Visor -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.visor_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="visorId"
                                              @change="changedValue"
                                              @input="visorError = ''"
                                              name="visorId"
                                              id="visorId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="visorArr"
                                              :placeholder="staticMessages.visor_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{visorError}}</span>
                                </div>
                            </div>

                            <!--Pull From-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.pull_from_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="pullFromError = ''" id="pullFrom" v-model="pullFrom" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{pullFromError}}</span>
                                </div>
                            </div>

                            <!--CDL-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cdl_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="cdlError = ''" id="cdl" v-model="cdl" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cdlError}}</span>
                                </div>
                            </div>

                            <!--Jakebrake-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.jakebrake_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="jakebrakeError = ''" id="jakebrake" v-model="jakebrake" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{jakebrakeError}}</span>
                                </div>
                            </div>

                            <!--Top SP-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.top_sp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="topSpError = ''" id="topSp" v-model="topSp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{topSpError}}</span>
                                </div>
                            </div>

                            <!--Override-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.override_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="overrideError = ''" id="override" v-model="override" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{overrideError}}</span>
                                </div>
                            </div>

                            <!--Power Steering -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.power_steering_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="powerSteeringId"
                                              @change="changedValue"
                                              @input="powerSteeringError = ''"
                                              name="powerSteeringId"
                                              id="powerSteeringId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="powerSteeringArr"
                                              :placeholder="staticMessages.power_steering_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{powerSteeringError}}</span>
                                </div>
                            </div>

                            <!--Spread Axle -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.spread_axle_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="spreadAxleId"
                                              @change="changedValue"
                                              @input="spreadAxleError = ''"
                                              name="spreadAxleId"
                                              id="spreadAxleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="spreadAxleArr"
                                              :placeholder="staticMessages.spread_axle_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{spreadAxleError}}</span>
                                </div>
                            </div>

                            <!--Trail Axle-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.trail_axle_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="trailAxleError = ''" id="trailAxle" v-model="trailAxle" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{trailAxleError}}</span>
                                </div>
                            </div>

                            <!--AXT Capacity-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.axt_capacity_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="axtCapacityError = ''" id="axtCapacity" v-model="axtCapacity" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{axtCapacityError}}</span>
                                </div>
                            </div>

                            <!--Legal Weight Truck-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.legal_weight_truck_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="legalWeightTruckError = ''" id="legalWeightTruck" v-model="legalWeightTruck" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{legalWeightTruckError}}</span>
                                </div>
                            </div>

                            <!--Payload-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.payload_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="payloadError = ''" id="payload" v-model="payload" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{payloadError}}</span>
                                </div>
                            </div>

                            <!--Frame -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.frame_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="frameId"
                                              @change="changedValue"
                                              @input="frameError = ''"
                                              name="frameId"
                                              id="frameId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="frameArr"
                                              :placeholder="staticMessages.frame_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{frameError}}</span>
                                </div>
                            </div>

                            <!--Sllider Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.sllider_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="sliderTypeError = ''" id="sliderType" v-model="sliderType" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{sliderTypeError}}</span>
                                </div>
                            </div>

                            <!--Reefer Type-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="reeferTypeError = ''" id="reeferType" v-model="reeferType" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{reeferTypeError}}</span>
                                </div>
                            </div>

                            <!--Reefer Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="reeferMakeId"
                                              @change="changedValue"
                                              @input="reeferMakeError = ''"
                                              name="reeferMakeId"
                                              id="reeferMakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="reeferMakeArr"
                                              :placeholder="staticMessages.reefer_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{reeferMakeError}}</span>
                                </div>
                            </div>

                            <!--Reefer Details-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_details_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="reeferDetailsError = ''" id="reeferDetails" v-model="reeferDetails" class="form-control"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{reeferDetailsError}}</span>
                                </div>
                            </div>

                            <!--Reefer Hours-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_hours_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="reeferHoursError = ''" id="reeferHours" v-model="reeferHours" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{reeferHoursError}}</span>
                                </div>
                            </div>

                            <!--Reefer Serial-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.reefer_serial_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="reeferSerialError = ''" id="reeferSerial" v-model="reeferSerial" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{reeferSerialError}}</span>
                                </div>
                            </div>

                            <!--Standby-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.standby_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="standbyError = ''" id="standby" v-model="standby" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{standbyError}}</span>
                                </div>
                            </div>

                            <!--Rear Door Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_door_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="rearDoorTypeId"
                                              @change="changedValue"
                                              @input="rearDoorTypeError = ''"
                                              name="rearDoorTypeId"
                                              id="rearDoorTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="rearDoorTypeArr"
                                              :placeholder="staticMessages.rear_door_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearDoorTypeError}}</span>
                                </div>
                            </div>

                            <!--Rear Door Open-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.rear_door_open_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rearDoorOpenError = ''" id="rearDoorOpen" v-model="rearDoorOpen" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearDoorOpenError}}</span>
                                </div>
                            </div>

                            <!--Side Door Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.side_door_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="sideDoorTypeId"
                                              @change="changedValue"
                                              @input="sideDoorTypeError = ''"
                                              name="sideDoorTypeId"
                                              id="sideDoorTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="sideDoorTypeArr"
                                              :placeholder="staticMessages.rear_side_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rearDoorTypeError}}</span>
                                </div>
                            </div>

                            <!--Side Door Open-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.side_door_open_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="sideDoorOpenError = ''" id="sideDoorOpen" v-model="sideDoorOpen" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{sideDoorOpenError}}</span>
                                </div>
                            </div>

                            <!--Ramp -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.ramp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="rampId"
                                              @change="changedValue"
                                              @input="rampError = ''"
                                              name="rampId"
                                              id="rampId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="rampArr"
                                              :placeholder="staticMessages.ramp_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rampError}}</span>
                                </div>
                            </div>

                            <!--Lift Gate-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lift_gate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="liftGateError = ''" id="liftGate" v-model="liftGate" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{liftGateError}}</span>
                                </div>
                            </div>

                            <!--Lift Gate Details-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.lift_gate_details_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="liftGateDetailsError = ''" id="liftGateDetails" v-model="liftGateDetails" class="form-control"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{liftGateDetailsError}}</span>
                                </div>
                            </div>

                            <!--Interiior Height-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interior_height_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="interiorHeightError = ''" id="interiorheight" v-model="interiorHeight" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{interiorHeightError}}</span>
                                </div>
                            </div>

                            <!--Exterior Height-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_height_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="exteriorHeightError = ''" id="exteriorHeight" v-model="exteriorHeight" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{exteriorHeightError}}</span>
                                </div>
                            </div>

                            <!--Exterior Width-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_width_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="exteriorWidthError = ''" id="exteriorWidth" v-model="exteriorWidth" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{exteriorWidthError}}</span>
                                </div>
                            </div>

                            <!--Exterior Lenght-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.exterior_lenght_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="exteriorLengthError = ''" id="exteriorLength" v-model="exteriorLength" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{exteriorLengthError}}</span>
                                </div>
                            </div>

                            <!--Body Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.body_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="bodyTypeId"
                                              @change="changedValue"
                                              @input="bodyTypeError = ''"
                                              name="bodyTypeId"
                                              id="bodyTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="bodyTypeArr"
                                              :placeholder="staticMessages.body_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{bodyTypeError}}</span>
                                </div>
                            </div>

                            <!--Roof Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.roof_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="roofTypeId"
                                              @change="changedValue"
                                              @input="roofTypeError = ''"
                                              name="roofTypeId"
                                              id="roofTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="roofTypeArr"
                                              :placeholder="staticMessages.roof_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{roofTypeError}}</span>
                                </div>
                            </div>

                            <!--Floor Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.floor_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="floorTypeId"
                                              @change="changedValue"
                                              @input="floorTypeError = ''"
                                              name="floorTypeId"
                                              id="floorTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="floorTypeArr"
                                              :placeholder="staticMessages.floor_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{floorTypeError}}</span>
                                </div>
                            </div>

                            <!--Cab Details-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.cab_details_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <textarea @input="cabeDetailsError = ''" id="cabeDetails" v-model="cabeDetails" class="form-control"></textarea>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{cabeDetailsError}}</span>
                                </div>
                            </div>

                            <!--Interior Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.interior_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="interiorTypeId"
                                              @change="changedValue"
                                              @input="interiorTypeError = ''"
                                              name="interiorTypeId"
                                              id="interiorTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="interiorTypeArr"
                                              :placeholder="staticMessages.interior_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{interiorTypeError}}</span>
                                </div>
                            </div>

                            <!--Skirt-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.skirt_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="skirtError = ''" id="skirt" v-model="skirt" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{skirtError}}</span>
                                </div>
                            </div>

                            <!--Tailer Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.tailer_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="tailorTypeId"
                                              @change="changedValue"
                                              @input="tailorTypeError = ''"
                                              name="tailorTypeId"
                                              id="tailorTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="tailorTypeArr"
                                              :placeholder="staticMessages.tailer_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{tailorTypeError}}</span>
                                </div>
                            </div>

                            <!--Dump Type -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dump_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="dumpTypeId"
                                              @change="changedValue"
                                              @input="dumpTypeError = ''"
                                              name="dumpTypeId"
                                              id="dumpTypeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="dumpTypeArr"
                                              :placeholder="staticMessages.dump_type_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dumpTypeError}}</span>
                                </div>
                            </div>


                            <!--Dump Make -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dump_make_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="dumpMakeId"
                                              @change="changedValue"
                                              @input="dumpMakeError = ''"
                                              name="dumpMakeId"
                                              id="dumpMakeId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="dumpMakeArr"
                                              :placeholder="staticMessages.dump_make_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dumpMakeError}}</span>
                                </div>
                            </div>

                            <!--Dump Size-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dump_size_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dumpSizeError = ''" id="dumpSize" v-model="dumpSize" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dumpSizeError}}</span>
                                </div>
                            </div>

                            <!--Dump Gate-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dump_gate_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dumpGateError = ''" id="dumpGate" v-model="dumpGate" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dumpGateError}}</span>
                                </div>
                            </div>

                            <!--Dump Tarp-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.dump_tarp_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="dumpTarpError = ''" id="dumpTarp" v-model="dumpTarp" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{dumpTarpError}}</span>
                                </div>
                            </div>

                            <!--Hours-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.hours_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="hoursError = ''" id="hours" v-model="hours" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{hoursError}}</span>
                                </div>
                            </div>

                            <!--Chassis-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.chassis_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="chassisError = ''" id="chassis" v-model="chassis" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{chassisError}}</span>
                                </div>
                            </div>

                            <!--Marquee -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.marquee_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="marqueeError = ''" id="marquee" v-model="marquee" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{marqueeError}}</span>
                                </div>
                            </div>

                            <!--Body Style -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.body_style_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <v-select 
                                              v-model="bodyStyleId"
                                              @change="changedValue"
                                              @input="bodyStyleError = ''"
                                              name="bodyStyleId"
                                              id="bodyStyleId"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="bodyStyleArr"
                                              :placeholder="staticMessages.body_style_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{bodyStyleError}}</span>
                                </div>
                            </div>

                            <!--Over R -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.over_r_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="overRError = ''" id="overR" v-model="overR" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{overRError}}</span>
                                </div>
                            </div>

                            <!--Height C -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.height_c_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="heightCError = ''" id="heightC" v-model="heightC" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{heightCError}}</span>
                                </div>
                            </div>

                            <!--Height S -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.height_s_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="heightSError = ''" id="heightS" v-model="heightS" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{heightSError}}</span>
                                </div>
                            </div>

                            <!--Low F -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.low_f_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="lowFError = ''" id="lowF" v-model="heightS" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{lowFError}}</span>
                                </div>
                            </div>

                            <!--Pass To -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.pass_to_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="passToError = ''" id="passTo" v-model="passTo" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{passToError}}</span>
                                </div>
                            </div>

                            <!--Chair L -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.chair_l_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="chairLError = ''" id="chairL" v-model="chairL" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{chairLError}}</span>
                                </div>
                            </div>

                            <!--Chair C -->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.chair_c_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="chairCError = ''" id="chairC" v-model="chairC" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{chairCError}}</span>
                                </div>
                            </div>

                            <!--Range-->
                            <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.Range_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="rangeError = ''" id="range" v-model="range" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{rangeError}}</span>
                                </div>
                            </div>
                        </div>

                        <!--Error Alert-->
                        <div class="row clearfix" >
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="alert alert-danger" id="alertError">
                                    <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                                    <strong id="errorMsg" class="error"></strong>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <!--Buttons-->
                        <div class="row clearfix"></div>
                        <div class="button-demo">
                            <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                            <input id="addAndContinueAssetBtn" :disabled="disableButtons.saveAndContinue" type="submit" :value="buttonNames.saveAndContinue" class="btn btn-primary" v-on:click.prevent="addAsset(flag='Save and Continue')">
                            <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack">
                        </div>
                    </div>
                    <!-- Forms -->
                </div>
                <!--page content-->
            </div>
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
        <app-footer></app-footer>
    </div>
    <!-- Page Content Wrapper -->
    <!-- </div> -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../mixins/commonMixins';
    import EventBus from ".././event-bus";
    import {HTTP} from '../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticInputLabelMessages: "",
                staticMessages: "",
                slug: "",
                slugError: "",
                companyArr: [],
                companyId: "",
                companyError: "",
                location: "",
                locationArr: [],
                locationError: "",
                lot: "",
                lotError: "",
                lotLocation: "",
                lotLocationError: "",
                lotAddress: "",
                lotAddressError: "",
                cityId: "",
                cityArr: [],
                cityError: "",
                state: "",
                stateArr: [],
                stateError: "",
                scseo: "",
                scseoError: "",
                vehicalType: "",
                vehicalTypeError: "",
                classvehical: "",
                classError: "",
                lotPhone: "",
                lotPhoneError: "",
                orderNo: "",
                orderNoError: "",
                isTruckId: "",
                isTruckArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isTruckError: "",
                orderDate: "",
                orderDateError: "",
                pricePaid: "",
                pricePaidError: "",
                acquireOnDate: "",
                acquireOnDateError: "",
                titleOutDate: "",
                titleOutDateError: "",
                year: "",
                yearError: "",
                makeId: "",
                makeArr: [],
                makeError: "",
                modelId: "",
                modelArr: [],
                modelError: "",
                vinNo: "",
                vinNoError: "",
                unit: "",
                unitError: "",
                classification: "",
                classificationError: "",
                conditionId: "",
                conditionArr: [],
                conditionError: '',
                askingRetailUsd: "",
                askingRetailUsdError: "",
                minRetailUsd: "",
                minRetailUsdError: "",
                highRetailUsd: "",
                highRetailUsdError: "",
                titleInId: "",
                titleInArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                titleInError: "",
                showPriceOnlineId: "",
                showPriceOnlineArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                showPriceOnlineError: "",
                availableDate: "",
                availableDateError: "",
                wholeShaleUsd: "",
                wholeShaleUsdError: "",
                quantity: "",
                quantityError: "",
                statusArr: [],
                statusId: "",
                statusError: "",
                visibilityTypeId: "",
                visibilityTypeArr: [{text: "Private", value: "private"}, {text: "Public", value: "public"}],
                visibilityTypeError: "",
                saleTypeId: "",
                saleTypeArr: [],
                saleTypeError: "",
                isFeaturedId: "",
                isFeaturedArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isFeaturedError: "",
                internalLocation: "",
                internalLocationError: "",
                descrition: "",
                descriptionError: "",
                website: "",
                websiteError: "",
                privateComment: "",
                privateCommentError: "",
                featuredText: "",
                featuredTextError: "",
                specialFeature: "",
                specialFeatureError: "",
                specialExpirationDate: "",
                specialExpirationDateError: "",
                yupuTubeVideoUrl: "",
                yupuTubeVideoUrlError: "",
                warrantyTypeId: "",
                warrantyTypeArr: [],
                warrantyTypeError: "",
                odometer: "",
                odometerError: "",
                odomileto: "",
                odomiletoError: "",
                ecmmiles: "",
                ecmmilesError: "",
                gvw: "",
                gvwError: "",
                gvwt: "",
                gvwtError: "",
                ecm: "",
                ecmError: "",
                emptyWeight: "",
                emptyWeightError: "",
                engineMakeId: "",
                engineMakeArr: [],
                engineMakeError: "",
                engineModel: "",
                engineModelError: "",
                engcpl: "",
                engcplError: "",
                engineSerial: "",
                engineSerialError: "",
                engineSize: "",
                engineSizeError: "",
                engineHp: "",
                engineHpError: "",
                engineBrakeArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                engineBrakeId: "",
                engineBrakeError: "",
                apuMakeId: "",
                apuMakeArr: [],
                apuMakeError: "",
                apuHours: "",
                apuHoursError: "",
                apuSerials: "",
                apuSerialsError: "",
                transMakeId: "",
                transMakeArr: [],
                transMakeError: "",
                transModel: "",
                transModelError: "",
                transSpeedId: "",
                transSpeedArr: [],
                transSpeedError: "",
                transTypeId: "",
                transTypeArr: [],
                transTypeError: "",
                fuelTypeId: "",
                fuelTypeArr: [],
                fuelTypeError: "",
                fuelTanks: "",
                fuelTanksError: "",
                fuelCapacity: "",
                fuelCapacityError: "",
                fuel: "",
                fuelError: "",
                exhaustId: "",
                exhaustArr: [],
                exhaustError: "",
                breathersId: "",
                breathersArr: [],
                breathersError: "",
                rearRatio: "",
                rearRatioError: "",
                rear: "",
                rearError: "",
                lockingRears: "",
                lockingRearsError: "",
                axleTypeId: "",
                axleTypeArr: [],
                axleTypeError: "",
                steerAxleCapacity: "",
                steerAxleCapacityError: "",
                driverAxleCapacity: "",
                driverAxleCapacityError: "",
                dropAxleConfigId: "",
                dropAxleConfigArr: [],
                dropAxleConfigError: "",
                dropAxleCapacity: "",
                dropAxleCapacityError: "",
                suspensionId: "",
                suspensionArr: [],
                suspensionError: "",
                wheelbase: "",
                wheelbaseError: "",
                brakeTypeId: [],
                brakeTypeArr: [],
                brakeTypeError: "",
                tireTrade: "",
                tireTradeError: "",
                tiresize: "",
                tiresizeError: "",
                tireConfig: "",
                tireConfigError: "",
                frontWheel: "",
                frontWheelError: "",
                isPilotedWheelsArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isPilotedWheelsId: "",
                isPilotedWheelsError: "",
                isFifthWheel: "",
                isFifthWheelError: "",
                cabToAxle: "",
                cabToAxleError: "",
                kingPin: "",
                kingPinError: "",
                wetLine: "",
                wetLineError: "",
                sleeperSize: "",
                sleeperSizeError: "",
                bunkType: "",
                bunkTypeError: "",
                bunkHeater: "",
                bunkHeaterError: "",
                cabTypeId: "",
                cabTypeArr: [],
                cabTypeError: "",
                cabStyleId: "",
                cabStyleArr: [],
                cabStyleError: "",
                rearWheel: "",
                rearWheelError: "",
                isAirConditioningArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isAirConditioningId: "",
                isAirConditioningError: "",
                interiorColor: "",
                interiorColorError: "",
                exteriorColor: "",
                exteriorColorError: "",
                heatedMirriorsId: "",
                heatedMirriorsArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                heatedMirriorsError: "",
                fairingId: "",
                fairingArr: [],
                fairingError: "",
                visorId: "",
                visorArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                visorError: "",
                pullFrom: "",
                pullFromError: "",
                cdl: "",
                cdlError: "",
                jakebrake: '',
                jakebrakeError: '',
                topSp: "",
                topSpError: "",
                override: "",
                overrideError: "",
                powerSteeringId: "",
                powerSteeringArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                powerSteeringError: "",
                spreadAxleId: "",
                spreadAxleArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                spreadAxleError: "",

                trailAxle: "",
                trailAxleError: "",
                axtCapacity: "",
                axtCapacityError: "",
                legalWeightTruck: "",
                legalWeightTruckError: "",
                payload: "",
                payloadError: "",
                frameId: "",
                frameArr: [],
                frameError: "",
                sliderType: "",
                sliderTypeError: "",
                reeferType: "",
                reeferTypeError: "",
                reeferMakeId: "",
                reeferMakeArr: [],
                reeferMakeError: "",
                reeferDetails: "",
                reeferDetailsError: "",
                reeferHours: "",
                reeferHoursError: "",
                reeferSerial: "",
                reeferSerialError: "",
                standby: "",
                standbyError: "",
                rearDoorTypeId: "",
                rearDoorTypeArr: [],
                rearDoorTypeError: "",
                rearDoorOpen: "",
                rearDoorOpenError: "",
                sideDoorTypeId: "",
                sideDoorTypeArr: [],
                sideDoorTypeError: "",
                sideDoorOpen: "",
                sideDoorOpenError: "",
                rampId: "",
                rampArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                rampError: "",
                liftGate: "",
                liftGateError: "",
                liftGateDetails: "",
                liftGateDetailsError: "",
                interiorHeight: "",
                interiorHeightError: "",
                exteriorHeight: "",
                exteriorHeightError: "",
                exteriorWidth: "",
                exteriorWidthError: "",
                exteriorLength: "",
                exteriorLengthError: "",
                bodyTypeId: "",
                bodyTypeArr: [],
                bodyTypeError: "",
                roofTypeId: "",
                roofTypeArr: [],
                roofTypeError: "",
                floorTypeId: '',
                floorTypeArr: [],
                floorTypeError: '',
                cabeDetails: "",
                cabeDetailsError: "",
                interiorTypeId: "",
                interiorTypeArr: [],
                interiorTypeError: "",
                skirt: "",
                skirtError: "",
                tailorTypeId: "",
                tailorTypeArr: [],
                tailorTypeError: "",
                dumpTypeId: "",
                dumpTypeArr: [],
                dumpTypeError: "",
                dumpMakeId: "",
                dumpMakeArr: [],
                dumpMakeError: "",
                dumpSize: "",
                dumpSizeError: "",
                dumpGate: "",
                dumpGateError: "",
                dumpTarp: "",
                dumpTarpError: "",
                hours: "",
                hoursError: "",
                chassis: "",
                chassisError: "",
                marquee: "",
                marqueeError: "",
                bodyStyleId: "",
                bodyStyleArr: [],
                bodyStyleError: "",
                overR: "",
                overRError: "",
                heightC: "",
                heightCError: "",
                heightS: "",
                heightSError: "",
                lowF: "",
                lowFError: "",
                passTo: "",
                passToError: "",
                chairL: "",
                chairLError: "",
                chairC: "",
                chairCError: "",
                range: "",
                rangeError: "",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    saveAndContinue: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",
                permissionNameArray: ['can_add_inventory_master'],

                isCompanyRecordNotFound: false,
                isMakeRecordNotFound: false,
                isModelRecordNotFound: false,
                isConditionRecordNotFound: false,
                isStateRecordNotFound: false,
                isCityRecordNotFound: false,
            }
        },
        beforeMount() {
            self = this;
        },
        watch: {
            companyId: function (companyId) {
                self.loadAllData(['Location'], false, self.loadLocationDataCallback, 'undefined', '', companyId.value);
                self.location = '';
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticInputLabelMessages = self.allLabelMessages;
            self.staticMessages = self.allStaticMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");

            $("#alertError").hide();
            if (typeof (self.$route.params.id) != "undefined" && self.$route.params.id != "" && self.$route.params.id != 0) {
                self.getAssetDetailsById(self.$route.params.id);
                self.buttonNames.save = "Update";
                $("#addAndContinueAssetBtn").hide();
                self.pageTitle = "Edit Inventory";
            }
            /* self.loadAllData('Company', true, self.loadCompanyDataCallback, "undefined", '');
            self.loadAllData('States', true, self.loadStateDataCallback, "undefined", '');
            self.loadAllData('Make', false, self.loadMakeDataCallback, "undefined", '');
            self.loadAllData('Model', false, self.loadModelDataCallback, "undefined", '');
            self.loadAllData('Condition', false, self.loadConditionCallback, "undefined", ''); */
            self.loadAllData(['Location', 'Status', 'SaleType', 'WarrantyType', 'EngineMake', 'ApuMake', 'TransMake', 'TransSpeed', 'TransType', 'FuelType', 'Exhaust'
                , 'Breathers', 'AxleType', 'DropAxleConfig', 'Suspension', 'BrakeType', 'CabType', 'CabStyle', 'Fairings', 'Frame', 'ReeferMake', 'RearDoorType', 'SideDoorType'
                , 'BodyType', 'RoofType', 'FloorType', 'Interior', 'DumpType', 'DumpMake', 'BodyStyle'
            ], false, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Location', false, self.loadLocationDataCallback, "undefined", '');
            self.loadAllData('Status', false, self.loadStatusCallback, "undefined", '');
            self.loadAllData('SaleType', false, self.loadSaleTypeCallback, "undefined", '');
            self.loadAllData('WarrantyType', false, self.loadWarrantyTypeCallback, "undefined", '');
            self.loadAllData('EngineMake', false, self.loadEngineMakeCallback, "undefined", '');
            self.loadAllData('ApuMake', false, self.laodApuMakeCallback, "undefined", '');
            self.loadAllData('TransMake', false, self.loadTransMakeCallback, "undefined", '');
            self.loadAllData('TransSpeed', false, self.loadTransSpeedCallback, "undefined", '');
            self.loadAllData('TransType', false, self.loadTransTypeCallback, "undefined", '');
            self.loadAllData('FuelType', false, self.loadFuelTypeCallback, "undefined", '');
            self.loadAllData('Exhaust', false, self.loadExhaustCallback, "undefined", '');
            self.loadAllData('Breathers', false, self.loadBreathersCallback, "undefined", '');
            self.loadAllData('AxleType', false, self.loadAxleTypeCallback, "undefined", '');
            self.loadAllData('DropAxleConfig', false, self.loadDropAxleConfigCallback, "undefined", '');
            self.loadAllData('Suspension', false, self.loadSuspensionCallback, "undefined", '');
            self.loadAllData('BrakeType', false, self.loadBrakeTypeCallback, "undefined", '');
            self.loadAllData('CabType', false, self.loadCabTypeCallback, "undefined", '');
            self.loadAllData('CabStyle', false, self.loadCabStyleCallback, "undefined", '');
            self.loadAllData('Fairings', false, self.loadFairingCallback, "undefined", '');
            self.loadAllData('Frame', false, self.loadFrameCallback, "undefined", '');
            self.loadAllData('ReeferMake', false, self.loadReeferMakeCallback, "undefined", '');
            self.loadAllData('RearDoorType', false, self.loadRearDoorTypeCallback, "undefined", '');
            self.loadAllData('SideDoorType', false, self.loadSideDoorTypeCallback, "undefined", '');
            self.loadAllData('BodyType', false, self.loadBodyTypeCallback, "undefined", '');
            self.loadAllData('RoofType', false, self.loadRoofTypeCallback, "undefined", '');
            self.loadAllData('FloorType', false, self.loadFloorTypeCallback, "undefined", '');
            self.loadAllData('Interior', false, self.loadInteriorTypeCallback, "undefined", '');
            self.loadAllData('DumpType', false, self.loadDumpTypeCallback, "undefined", '');
            self.loadAllData('DumpMake', false, self.loadDumpMakeCallback, "undefined", '');
            self.loadAllData('BodyStyle', false, self.loadBodyStyleCallback, "undefined", ''); */
        },
        methods: {

            returnData: function () {
                let orderDate = self.apiRequestDateFormat(self.orderDate);
                let acquireOnDate = self.apiRequestDateFormat(self.acquireOnDate);
                let titleOutDate = self.apiRequestDateFormat(self.titleOutDate);
                let availableDate = self.apiRequestDateFormat(self.availableDate);


                let data = {
                    slug: self.slug,
                    company_id: self.companyId.value,
                    location_id: self.location.value,
                    lot_location: self.lotLocation,
                    lot_address: self.lotAddress,
                    city_id: self.cityId.value,
                    state_id: self.state.value,
                    scseo: self.scseo,
                    vahical_type: self.vehicalType,
                    class_vahical: self.classvehical,
                    lot_phone: self.lotPhone,
                    order_no: self.orderNo,
                    is_truck_if: self.isTruckId.value,
                    order_date: orderDate,
                    price_paid: self.pricePaid,
                    acquired_on_date: acquireOnDate,
                    title_out_date: titleOutDate,
                    year: self.year,
                    make_id: self.makeId.value,
                    model_id: self.modelId.value,
                    vinNo: self.vinNo,
                    unit: self.unit,
                    classification: self.classification,
                    condition_id: self.conditionId.value,
                    asking_ratail_usd: self.askingRetailUsd,
                    min_retail_usd: self.minRetailUsd,
                    high_retail_usd: self.highRetailUsd,
                    title_in_id: self.titleInId.value,
                    show_price_online_id: self.showPriceOnlineId.value,
                    available_date: availableDate,
                    wholeshale_usd: self.wholeShaleUsd,
                    quantity: self.quantity,
                    status_id: self.statusId.value,
                    visibility_type_id: self.visibilityTypeId.value,
                    sale_type_id: self.saleTypeId.value,
                    is_featured_id: self.isFeaturedId.value,
                    internal_location: self.internalLocation,
                    description: self.description,
                    website: self.websiteName,
                    private_comment: self.privateComment,
                    featured_text: self.featuredText,
                    special_feature: self.specialFeature,
                    youtube_video_url: self.yupuTubeVideoUrl,
                    warranty_type_id: self.warrantyTypeId.value,
                    odometer: self.odometer,
                    odomileto: self.odomileto,
                    ecmmiles: self.ecmmiles,
                    gvw: self.gvw,
                    gvwt: self.gvwt,
                    ecm: self.ecm,
                    empty_weight: self.emptyWeight,
                    engine_make_id: self.engineMakeId.value,
                    engine_make_model: self.engineModel,
                    engcpl: self.engcpl,
                    engine_serial: self.engineSerial,
                    engine_hp: self.engineHp,
                    engine_brake_id: self.engineBrakeId.value,
                    apu_make_id: self.apuMakeId.value,
                    apu_hours: self.apuHours,
                    apu_serials: self.apuSerials,
                    trans_make_id: self.transMakeId.value,
                    trans_speed_id: self.transSpeedId.value,
                    trans_type_id: self.transTypeId.value,
                    fuel_type_id: self.fuelTypeId.value,
                    fuel_tanks: self.fuelTanks,
                    fuel_capacity: self.fuelCapacity,
                    fuel: self.fuel,
                    exhaust_id: self.exhaustId.value,
                    breathers_id: self.breathersId.value,
                    rear_ratio: self.rearRatio,
                    rear: self.rear,
                    locking_rears: self.lockingRears,
                    axle_type_id: self.axleTypeId.value,
                    steer_axle_capacity: self.steerAxleCapacity,
                    driver_axle_capacity: self.driverAxleCapacity,
                    suspension_id: self.suspensionId.value,
                    wheel_base: self.wheelbase,
                    brake_type_id: self.brakeTypeId.value,
                    tire_trade: self.tireTrade,
                    tire_size: self.tiresize,
                    tire_config: self.tireConfig,
                    frontwheel: self.frontWheel,
                    is_piloted_wheel_id: self.isPilotedWheelsId.value,
                    is_fifth_wheel: self.isFifthWheel,
                    cab_to_axle: self.cabToAxle,
                    kingpin: self.kingPin,
                    wet_line: self.wetLine,
                    sleeper_size: self.sleeperSize,
                    bunk_type: self.bunkType,
                    bunk_heater: self.bunkHeater,
                    cab_type_id: self.cabTypeId.value,
                    cab_style_id: self.cabStyleId.value,
                    rear_wheel: self.rearWheel,
                    is_airconditioning_id: self.isAirConditioningId.value,
                    interior_color: self.interiorColor,
                    exterior_color: self.exteriorColor,
                    heated_mirriors_id: self.heatedMirriorsId.value,
                    fairing_id: self.fairingId.value,
                    visor_id: self.visorId.value,
                    pull_from: self.pullFrom,
                    cdl: self.cdl,
                    jake_brake: self.jakebrake,
                    topsp: self.topSp,
                    override: self.override,
                    power_steering_id: self.powerSteeringId.value,
                    spread_axle_id: self.spreadAxleId.value,
                    trail_axle: self.trailAxle,
                    axt_capacity: self.axtCapacity,
                    legal_weight_truck: self.legalWeightTruck,
                    payload: self.payload,
                    frame_id: self.frameId.value,
                    slider_type: self.sliderType,
                    reefer_type: self.reeferType,
                    reefer_make_id: self.reeferMakeId.value,
                    reefer_details: self.reeferDetails,
                    reefer_hours: self.reeferHours,
                    reefer_serials: self.reeferSerial,
                    stand_by: self.standby,
                    rear_door_type_id: self.rearDoorTypeId.value,
                    rear_door_open: self.rearDoorOpen,
                    side_door_open_id: self.sideDoorTypeId.value,
                    side_door_open: self.sideDoorOpen,
                    ramp_id: self.rampId.value,
                    lift_gate: self.liftGate,
                    lift_gate_details: self.liftGateDetails,
                    interior_height: self.interiorHeight,
                    exterior_height: self.exteriorHeight,
                    exterior_width: self.exteriorWidth,
                    exterior_lenght: self.exteriorLength,
                    body_type_id: self.bodyTypeId.value,
                    roof_type_id: self.roofTypeId.value,
                    floor_type_id: self.floorTypeId.value,
                    cab_details: self.cabeDetails,
                    interior_type_id: self.interiorTypeId.value,
                    skirt: self.skirt,
                    dump_type_id: self.dumpTypeId.value,
                    dump_make_id: self.dumpMakeId.value,
                    dump_size: self.dumpSize,
                    dump_trap: self.dumpTarp,
                    hours: self.hours,
                    chassis: self.chassis,
                    marquee: self.marquee,
                    body_style_id: self.bodyStyleId.value,
                    over_r: self.overR,
                    height_c: self.heightC,
                    height_s: self.heightS,
                    low_f: self.lowF,
                    pass_to: self.passTo,
                    chair_l: self.chairL,
                    chair_c: self.chairC,
                    range: self.range,
                }

                return data;
            },
            changeSpecialExpirationDate: function () {
                self.specialExpirationError = "";
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadMasterDataCallback: function(callbackData) {
                self.locationArr = callbackData.Location;
                self.statusArr = callbackData.Status;
                self.saleTypeArr = callbackData.SaleType;
                self.warrantyTypeArr = callbackData.WarrantyType;
                self.engineMakeArr = callbackData.EngineMake;
                self.apuMakeArr = callbackData.ApuMake;
                self.transMakeArr = callbackData.TransMake;
                self.transSpeedArr = callbackData.TransSpeed;
                self.transTypeArr = callbackData.TransType;
                self.fuelTypeArr = callbackData.FuelType;
                self.exhaustArr = callbackData.Exhaust;
                self.breathersArr = callbackData.Breathers;
                self.axleTypeArr = callbackData.AxleType;
                self.dropAxleConfigArr = callbackData.DropAxleConfig;
                self.suspensionArr = callbackData.Suspension;
                self.brakeTypeArr = callbackData.BrakeType;
                self.cabTypeArr = callbackData.CabType;
                self.cabStyleArr = callbackData.CabStyle;
                self.fairingArr = callbackData.Fairings;
                self.frameArr = callbackData.Frame;
                self.reeferMakeArr = callbackData.ReeferMake;
                self.rearDoorTypeArr = callbackData.RearDoorType;
                self.sideDoorTypeArr = callbackData.SideDoorType;
                self.bodyTypeArr = callbackData.BodyType;
                self.roofTypeArr = callbackData.RoofType;
                self.floorTypeArr = callbackData.FloorType;
                self.interiorTypeArr = callbackData.Interior;
                self.dumpTypeArr = callbackData.DumpType;
                self.dumpMakeArr = callbackData.DumpMake;
                self.bodyStyleArr = callbackData.BodyStyle;
            },
            onSearchMake(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Make'], true, self.loadMakeDataCallback, loading, search);
                }
            },
            loadMakeDataCallback: function (callbackData) {
                self.isMakeRecordNotFound = false;
                self.makeArr = callbackData.Make;
                if(self.makeArr.length == 0) {
                    self.isMakeRecordNotFound = true;
                }
            },
            onSearchModel(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Model'], true, self.loadModelDataCallback, loading, search);
                }
            },
            loadModelDataCallback: function (callbackData) {
                self.isModelRecordNotFound = false;
                self.modelArr = callbackData.Model;
                if(self.modelArr.length == 0) {
                    self.isModelRecordNotFound = true;
                }
            },
            onSearchCondition(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Condition'], true, self.loadConditionCallback, loading, search);
                }
            },
            loadConditionCallback: function (callbackData) {
                self.isConditionRecordNotFound = false;
                self.conditionArr = callbackData.Condition;
                if(self.conditionArr.length == 0) {
                    self.isConditionRecordNotFound = true;
                }
            },
            /* loadStatusCallback: function (callbackData) {
                self.statusArr = callbackData.Status;
            },
            loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArr = callbackData.SaleType;
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArr = callbackData.WarrantyType;
            },
            loadEngineMakeCallback: function (callbackData) {
                self.engineMakeArr = callbackData.EngineMake;
            },
            laodApuMakeCallback: function (callbackData) {
                self.apuMakeArr = callbackData.ApuMake;
            },
            loadTransMakeCallback: function (callbackData) {
                self.transMakeArr = callbackData.TransMake;
            },
            loadTransSpeedCallback: function (callbackData) {
                self.transSpeedArr = callbackData.TransSpeed;
            },
            loadTransTypeCallback: function (callbackData) {
                self.transTypeArr = callbackData.TransType;
            },
            loadFuelTypeCallback: function (callbackData) {
                self.fuelTypeArr = callbackData.FuelType;
            },
            loadExhaustCallback: function (callbackData) {
                self.exhaustArr = callbackData.Exhaust;
            },
            loadBreathersCallback: function (callbackData) {
                self.breathersArr = callbackData.Breathers;
            },
            loadAxleTypeCallback: function (callbackData) {
                self.axleTypeArr = callbackData.AxleType;
            },
            loadDropAxleConfigCallback: function (callbackData) {
                self.dropAxleConfigArr = callbackData.DropAxleConfig;
            },
            loadSuspensionCallback: function (callbackData) {
                self.suspensionArr = callbackData.Suspension;
            },
            loadBrakeTypeCallback: function (callbackData) {
                self.brakeTypeArr = callbackData.BrakeType;
            },
            loadCabTypeCallback: function (callbackData) {
                self.cabTypeArr = callbackData.CabType;
            },
            loadCabStyleCallback: function (callbackData) {
                self.cabStyleArr = callbackData.CabStyle;
            },
            loadFairingCallback: function (callbackData) {
                self.fairingArr = callbackData.Fairings;
            },
            loadFrameCallback: function (callbackData) {
                self.frameArr = callbackData.Frame;
            },
            loadReeferMakeCallback: function (callbackData) {
                self.reeferMakeArr = callbackData.ReeferMake;
            },
            loadRearDoorTypeCallback: function (callbackData) {
                self.rearDoorTypeArr = callbackData.RearDoorType;
            },
            loadSideDoorTypeCallback: function (callbackData) {
                self.sideDoorTypeArr = callbackData.SideDoorType;
            },
            loadBodyTypeCallback: function (callbackData) {
                self.bodyTypeArr = callbackData.BodyType;
            },
            loadRoofTypeCallback: function (callbackData) {
                self.roofTypeArr = callbackData.RoofType;
            },
            loadFloorTypeCallback: function (callbackData) {
                self.floorTypeArr = callbackData.FloorType;
            },
            loadInteriorTypeCallback: function (callbackData) {
                self.interiorTypeArr = callbackData.Interior;
            },
            loadDumpTypeCallback: function (callbackData) {
                self.dumpTypeArr = callbackData.DumpType;
            },
            loadDumpMakeCallback: function (callbackData) {
                self.dumpMakeArr = callbackData.DumpMake;
            },
            loadBodyStyleCallback: function (callbackData) {
                self.bodyStyleArr = callbackData.BodyStyle;
            }, */
            changeAvailableDate: function () {
                self.availableDateError = "";
            },
            changeTitleOutDate: function () {
                self.titleOutDateError = "";
            },
            changeTitleIn: function () {

            },
            changeAcuireDate: function () {
                self.acquireOnDateError = "";
            },
            chageOrderDate: function () {
                self.orderDateError = "";
            },
            changedValueLocation: function () {

            },
            changeIsTruck: function () {

            },
            onSearchState(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            loadStateDataCallback: function (callbackData) {
                self.isStateRecordNotFound = false;
                self.stateArr = callbackData.States;
                if(self.stateArr.length == 0) {
                    self.isStateRecordNotFound = true;
                }
            },
            loadCityDataCallback: function (callbackData) {
                self.isCityRecordNotFound = false;
                self.cityArr = callbackData.Cities;
                if(self.cityArr.length == 0) {
                    self.isCityRecordNotFound = true;
                }
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.isCompanyRecordNotFound = false;
                self.companyArr = callbackData.Company;
                if(self.companyArr.length == 0) {
                    self.isCompanyRecordNotFound = true;
                }
            },
            loadLocationDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },
            getAssetDetailsById: function (id) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: id
                    }
                };
                HTTP.get('/asset/get', config)
                        .then(response => {
                            self.name = response.data.content.name;
                        })
                        .catch(function (err) {
                            self.catchHandler(err, function () {});
                        });
            },
            checkForm: function () {
                var checked = true;
                if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            addAsset(flag) {
                // setTimeout(() => {
                //     if (self.checkForm()) {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };
                var data = self.returnData();
                if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                    data.append('id', self.$route.params.id);
                    self.buttonNames.save = "Updating ...";
                    self.disableButtons.save = true;
                    self.disableButtons.cancel = true;
                } else {
                    if (flag == "Save and Continue") {
                        self.buttonNames.saveAndContinue = "Saving ...";
                    } else {
                        self.buttonNames.save = "Saving ...";
                    }
                    self.disableButtons.save = true;
                    self.disableButtons.saveAndContinue = true;
                    self.disableButtons.cancel = true;
                }
                HTTP.post("/asset/add/update", data, config)
                        .then(function (response) {
                            if (typeof (self.$route.params.id) != 'undefined' && self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                    self.buttonNames.save = "Update";
                                } else {
                                    if (flag == "Save and Continue") {
                                        self.buttonNames.saveAndContinue = "Save and Continue";
                                    } else {
                                        self.buttonNames.save = "Save";
                                    }
                                }
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                } else {
                                    self.buttonNames.save = "Save";
                                }
                            }
                            if (response.data.status == "success") {
                                self.disableButtons.save = true;
                                self.disableButtons.saveAndContinue = true;
                                self.disableButtons.cancel = true;
                                self.showSAlert(response.data.message, 'success', false,3);
                                if (flag == "Save and Continue") {
                                    self.clearDataOnSaveAndContinue();
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                } else {
                                    setTimeout(function () {
                                        self.disableButtons.save = false;
                                        self.disableButtons.saveAndContinue = false;
                                        self.disableButtons.cancel = false;
                                        self.buttonBack();
                                    }, 3000);
                                }
                            } else {
                                self.disableButtons.save = false;
                                self.disableButtons.saveAndContinue = false;
                                self.disableButtons.cancel = false;
                                if (response.data.content.length > 0) {
                                    var err = "";
                                    for (var i = 0; i < response.data.content.length; i++) {
                                        err += response.data.content[i] + "<br/>";
                                    }
                                    self.showSAlert(err, 'error', false);
                                }
                            }
                        })
                        .catch(function (err) {
                            if (self.$route.params.id != 0 && self.$route.params.id != '') {
                                self.buttonNames.save = "Update";
                                self.disableButtons.save = false;
                                self.disableButtons.cancel = false;
                            } else {
                                if (flag == "Save and Continue") {
                                    self.buttonNames.saveAndContinue = "Save and Continue";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                    self.clearDataOnSaveAndContinue();
                                } else {
                                    self.buttonNames.save = "Save";
                                    self.disableButtons.save = false;
                                    self.disableButtons.saveAndContinue = false;
                                    self.disableButtons.cancel = false;
                                }
                            }
                            self.catchHandler(err, function () {});
                        });
                //     }
                // }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function(permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>