<template>
  <div id="page-content-wrapper" class="admin-content-area transaction-sheet">
    <template v-if="isShowVehicleWorkSheet">
      <vehicle-work-sheet
        :selectedOpportunityId="selectedOpportunityId"
        :editAssetId="editAssetId"
        :dealId="dealId"
        :vehicleData="vehicleData"
        :isVehicleEdit="isVehicleEdit"
        :customerCountyId="customerCountyId"
        :cusStateTaxRate="cusStateTaxRate"
        :warrantyTax="warrantyTax"
        :cusTaxRate="cusTaxRate"
        :locationId="locationId"
        :cusSalesTax="cusSalesTax"
        :cusStateCode="cusStateCode"
        :salesTaxCounty="county"
      ></vehicle-work-sheet>
      <div class="page-loader-wrapper loading-container" style="display: none;">
        <div class="loading">
          <img src="/static/images/loading.gif">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span
              >{{
                  allLabelMessages.transaction_sheet_title
                }}<!-- {{dealStatusId.value}} {{dealStatusArr}}--></span
              >
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="exportToPdf()"
                  class="btn btn-primary waves-effect pull-right"
                  id="printQuotBtn"
                >
                  Print Documents
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal(0, 2)"
                  class="btn btn-primary waves-effect pull-right"
                  id="documentRequest"
                >
                  Doc Request
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal(0, 3)"
                  class="btn btn-primary waves-effect pull-right"
                  id="documentRequest"
                  disabled
                >
                  Lost
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal(0, 5)"
                  class="btn btn-primary waves-effect pull-right"
                  id="documentRequest"
                >
                  Request Ro/PO
                </button>
              </div>
              <div class="col-12 py-2">
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="closeDeal()"
                  class="btn btn-primary waves-effect pull-right"
                >
                  Close
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveAndCloseDeal()"
                  class="btn btn-primary waves-effect pull-right"
                >
                  {{ dealMapperId > 0 ? 'Update' : 'Save' }}
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal()"
                  class="btn btn-primary waves-effect pull-right"
                  disabled
                >
                  Copy Transaction
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal()"
                  class="btn btn-primary waves-effect pull-right"
                  disabled
                >
                  Delete Transaction
                </button>
                <button
                  style="margin-right: 5px;"
                  type="submit"
                  v-on:click.prevent="saveBookDeal()"
                  class="btn btn-primary waves-effect pull-right"
                >
                  Mail History
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="admin-page-content">
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <div class="row no-margin">
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-1 col-sm-12 paddingleft0 custom-checkbox-v">
                  <label class="container" style="padding-top: 5px !important;">
                    <input
                      id="yes"
                      value="true"
                      v-model="add_to_forecast"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!--<div class="requiredstar">*</div>-->
                  <!--<span class="help is-danger">{{descriptionError}}</span> -->
                </div>
                <div class="col-md-7 col-sm-12 paddingleft0">
                  <div class="form-label" style="padding-top: 5px !important;">Add to forecast</div>
                </div>
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="col-md-3 col-sm-12 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.deal_no_label }}
                    </div>
                  </div>
                  <div class="col-md-9 col-sm-12 paddingleft0">
                    <div class="row">
                      <div class="col-md-12">
                        <!-- <input class="form-control" type="text"/> -->
                        <span class="form-control">{{ dealNo }}</span>
                        <!-- <input @input="dealNoError = ''" id="dealNo" v-model="dealNo" class="form-control" type="text" maxlength="17"/> -->
                        <!--<div class="requiredstar">*</div>-->
                        <span class="help is-danger">{{ dealNoError }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label pt-0">
                    {{ allLabelMessages.opportunity_number_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 mb-1 paddingleft0">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="form-control">{{ oppNo }}</span>
                      <span class="help is-danger">{{ oppNoError }}</span>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <div class="row no-margin">
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-2 col-sm-12 paddingleft0">
                  <div class="form-label">
                    Branch
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 paddingleft0">
                  <v-select
                    v-model="locationId"
                    name="locationId"
                    label="text"
                    :filterable="false"
                    :options="locationArr"
                    @search="onSearchLocation"
                    :clearable="true"
                    @input="companyError = ''"
                    :placeholder="allStaticMessages.company_custom_place_holder"
                    @change="addWarrantyTax()"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCompanyRecordNotFound">
                        {{ allStaticMessages.company_custom_search }}
                      </template>
                      <template v-else>
                        {{ allStaticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ companyError }}</span>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 mb-1 forms-block"></div>
              <!-- <div class="col-md-4 col-sm-12 forms-block">
              </div> -->
            </div>
            <!-- <div class="row no-margin">
              <div class="col-md-2 forms-block">
                <div class="form-label">
                  Department:
                </div>
              </div>
            </div> -->
            <div class="row no-margin">
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-2 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.excede_id_hash_label }}
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 paddingleft0">
                  <input
                    @input="contractNumberError = ''"
                    id="contractNumber"
                    v-model="contractNumber"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{ contractNumberError }}</span>
                </div>
                <!-- Contract Date -->
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="col-md-3 col-sm-12 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.invoice_date_label }}
                    </div>
                  </div>
                  <div class="col-md-9 col-sm-12 paddingleft0">
                    <date-picker
                      @input="contractDateError = ''"
                      v-model="contractDate"
                      :config="options"
                      class="form-control"
                      autocomplete="off"
                      :placeholder="allStaticMessages.contract_date_place_holder"
                      name="contractDate"
                    >
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{ contractDateError }}</span>
                    </date-picker>
                  </div>
                </div>
              </div>

              <!-- Invoice Number -->
              <div class="col-md-4 col-sm-12 mb-1 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.invoice_hash_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 paddingleft0">
                  <span class="form-control">{{ invoiceNo }}</span>
                  <!-- <input @input="invoiceNoError = ''" id="invoiceNo" v-model="invoiceNo" class="form-control" type="text" maxlength="17"/> -->
                  <span class="help is-danger">{{ invoiceNoError }}</span>
                </div>
              </div>
            </div>
            <div class="row no-margin">
              <!-- Sales Person -->
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-2 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.seles_person }}
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 paddingleft0">
                  <v-select
                    v-model="salePersonId"
                    name="salePersonId"
                    label="text"
                    :filterable="false"
                    :options="salePersonArr"
                    @search="onSearchSalePerson"
                    :clearable="true"
                    @input="salePersonError = ''"
                    :placeholder="allStaticMessages.sales_person_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isSalePersonRecordNotFound">
                        {{ allStaticMessages.sales_person_search }}
                      </template>
                      <template v-else>
                        {{ allStaticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ salePersonError }}</span>
                </div>
              </div>

              <!-- <div class="col-md-4 col-sm-12 forms-block"></div> -->

              <!-- Cus. Phone Number -->
              <div class="col-md-4 col-sm-12 mb-1 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.cus_phone_number_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 paddingleft0">
                  <input
                    @input="cusPhoneNumberError = ''"
                    id="cusPhoneNumber"
                    v-model="cusPhoneNumber"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{ cusPhoneNumberError }}</span>
                </div>
              </div>
            </div>
            <div class="row no-margin">
              <!-- Customer -->
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-2 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.customer_name_label}}
                  </div>
                </div>
                <div class="col-md-7 col-sm-12 paddingleft0 customer-name-dropdown">
                  <v-select
                    v-model="customerId"
                    name="customerId"
                    label="text"
                    :filterable="false"
                    :options="customerArr"
                    @search="onSearchCustomer"
                    :clearable="true"
                    @change="populateCustomerData($event)"
                    id="customerId"
                    :placeholder="allStaticMessages.customer_place_holder"
                    class="custom-ci-auto-search"
                  >
                  <!-- @input="customerError = ''" -->
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCustomerRecordNotFound">
                        {{ allStaticMessages.customer_search }}
                      </template>
                      <template v-else>
                        {{ allStaticMessages.search_record_not_found }}
                      </template>
                    </span>
                    <template #option="{ text, address, customerId }">
                      {{ text }} - {{ customerId }}
                      <br />
                      <cite style="font-size: small;">{{ address }}</cite>
                    </template>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ customerError }}</span>
                </div>
                <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                  <input
                      id="cusId"
                      class="form-control"
                      type="text"
                      maxlength="190"
                      v-model="linkedCustomerId"
                      style="min-height:40px;"
                    />
                </div>
                <div class="col-md-1 col-sm-12 mb-1 pl-0 mt-1">
                  <i class="fa fa-plus-circle" aria-hidden="true" @click="openCustomerModal" title="Click To Create New Customer"></i>
                </div>
              </div>
              <!-- <div class="col-md-4 col-sm-12 mb-1 forms-block"></div> -->

              <!-- Cus. Fax -->
              <div class="col-md-4 col-sm-12 mb-1 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.cus_fax_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 paddingleft0">
                  <input
                    @input="cusFaxError = ''"
                    id="cusFax"
                    v-model="cusFax"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{ cusFaxError }}</span>
                </div>
              </div>
            </div>
            <div class="row no-margin">
              <!-- Cus. Address -->
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="col-md-2 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.address_label }}
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 paddingleft0">
                  <input
                    @input="cusAddressError = ''"
                    id="cusAddress"
                    v-model="cusAddress"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{ cusAddressError }}</span>
                </div>
              </div>

              <!-- Cus. Cell -->
              <div class="col-md-4 col-sm-12 mb-1 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.cus_cell_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 paddingleft0">
                  <input
                    @input="cusCellError = ''"
                    id="cusCell"
                    v-model="cusCell"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{ cusCellError }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="row no-margin"> -->
              <!-- Cus. Address 2 -->
              <!-- <div class="col-md-4 col-sm-12 mb-1 forms-block">
                <div class="col-md-4 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.cus_address2_label }}
                  </div>
                </div>
                <div class="col-md-8 col-sm-12 paddingleft0">
                  <input
                  @input="cusAddress2Error = ''"
                  id="cusAddress2"
                  v-model="cusAddress2"
                  class="form-control"
                  type="text"
                  maxlength="190"
                  />
                  <span class="help is-danger">{{ cusAddress2Error }}</span>
                </div>
              </div> -->
            <!-- </div> -->

            <div class="row no-margin">
              <div class="col-md-8 col-sm-12 mb-1 forms-block">
                <div class="row no-margin">
                  <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.city_label }}
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="customerCityError = ''"
                      id="cusCityName"
                      v-model="cusCityName"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <span class="help is-danger">{{
                        customerCityError
                      }}</span>
                  </div>
                  <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="cusStateCodeError = ''"
                      id="cusStateCode"
                      v-model="cusStateCode"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <span class="help is-danger">{{
                        cusStateCodeError
                      }}</span>
                  </div>

                  <div class="col-md-3 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="cusCityZipCodeError = ''"
                      id="cusCityZipCode"
                      v-model="cusCityZipCode"
                      class="form-control"
                      type="text"
                      maxlength="20"
                    />
                    <span class="help is-danger">{{
                        cusCityZipCodeError
                      }}</span>
                    <!-- <v-select
                      v-model="customerCityId"
                      name="customerCityId"
                      label="text"
                      :filterable="false"
                      :options="customerCityArr"
                      @search="onSearchCustomerCity"
                      :clearable="false"
                      @input="customerCityError = ''"
                      id="customerCityId"
                      :placeholder="allStaticMessages.city_place_holder"
                      class="custom-ci-auto-search"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isCustomerCityRecordNotFound">
                          {{ allStaticMessages.city_search }}
                        </template>
                        <template v-else>
                          {{ allStaticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <span class="help is-danger">{{ customerCityError }}</span> -->
                  </div>
                </div>
                <!-- <div class="row no-margin"> -->
                  <!-- Alt Cus. City Name -->
                  <!-- <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.alt_city_name_label }}
                    </div>
                  </div> -->
                  <!-- <div class="col-md-6 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="cusAltCityNameError = ''"
                      id="cusAltCityName"
                      v-model="cusAltCityName"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <span class="help is-danger">{{
                        cusAltCityNameError
                      }}</span>
                  </div> -->
                <!-- </div> -->
                <div class="row no-margin">
                  <!-- Customer County -->
                  <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.county_label }}
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 mb-1 paddingleft0">
                    <v-select
                      v-model="customerCountyId"
                      name="customerCountyId"
                      label="name"
                      :filterable="false"
                      :options="countyCodeArr"
                      @search="onSearchCountyCode"
                      :clearable="false"
                      @input="customerCountyError = ''"
                      id="customerCountyId"
                      :placeholder="allStaticMessages.county_place_holder"
                      class="custom-ci-auto-search"
                      v-on:change="preFillTaxInfo"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isCustomerCountyRecordNotFound">
                          {{ allStaticMessages.county_search }}
                        </template>
                        <template v-else>
                          {{ allStaticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{
                        customerCountyError
                      }}</span>
                  </div>
                </div>
                <!-- <div class="row no-margin"> -->
                  <!-- Alt Cus. County Name -->
                  <!-- <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.alt_county_name_label }}
                    </div>
                  </div> -->
                  <!-- <div class="col-md-3 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="cusAltCountyNameError = ''"
                      id="cusAltCountyName"
                      v-model="cusAltCountyName"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <span class="help is-danger">{{
                        cusAltCountyNameError
                      }}</span>
                  </div> -->
                <!-- </div> -->
                <!-- "Tax Exemption Category" field is not being used and need to be removed  -->
                <div class="row no-margin">
                  <!-- Customer Email -->
                  <div class="col-md-2 col-sm-12 mb-1 paddingleft0">
                    <div class="form-label">
                      {{ allLabelMessages.customer_email_label }}
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 mb-1 paddingleft0">
                    <input
                      @input="customerEmailError = ''"
                      id="customerEmail"
                      v-model="customerEmail"
                      class="form-control"
                      type="text"
                      maxlength="190"
                    />
                    <!--<div class="requiredstar">*</div>-->
                    <span class="help is-danger">{{ customerEmailError }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12 forms-block">
                <fieldset class="border border-secondary" style="position: relative;padding-top: 15px;margin-top: 15px;">
                  <legend style="width: auto;font-size: 16px;
    position: absolute;
    top: -18px;
    left: 20px;
    background-color: white;
    padding: 5px">Sales Tax</legend>
                  <div class="row no-margin mb-1">
                    <!-- Cus. State Tax Rate -->
                    <div class="col-md-5 col-sm-12">
                      <div class="form-label">
                        {{ allLabelMessages.district_county_label }}
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <input
                        id="cusStateTaxRate"
                        v-model="districtOrCounty"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                    </div>
                  </div>
                  <div class="row no-margin">
                    <!-- Cus. Tax Rate -->
                    <div class="col-md-5 col-sm-12">
                      <div class="form-label">
                        {{ allLabelMessages.tax_rate_label }}
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <money v-bind="rateMask"
                        @input="cusTaxRateError = ''"
                        id="cusTaxRate"
                        v-model="cusTaxRate"
                        class="form-control"
                        type="text"
                        maxlength="190"
                        disabled
                      />
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{ cusTaxRateError }}</span>
                      &nbsp;
                    </div>
                    <div class="col-md-5 col-sm-12"></div>
                    <div
                      class="col-md-7 col-sm-12 custom-checkbox-v"
                    >
                      <div class="row" style="padding-left: 15px">
                        <div class="col-2">
                          <label class="container">
                            <input
                              id="yes"
                              value="true"
                              v-model="cusSalesTax"
                              class="form-control"
                              type="checkbox"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-label col-10">
                          {{ allLabelMessages.applied_sales_tax_label }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row no-margin mb-1">
                    <!-- Cus. State Tax Rate -->
                    <div class="col-md-5 col-sm-12">
                      <div class="form-label">
                        {{ allLabelMessages.state_tax_rate_label }}
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <money v-bind="rateMask"
                        id="cusStateTaxRate"
                        v-model="cusStateTaxRate"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{
                          cusStateTaxRateError
                        }}</span>
                    </div>
                  </div>
                  <div class="row no-margin mb-1">
                    <!-- Cus. State Tax Rate -->
                    <div class="col-md-5 col-sm-12">
                      <div class="form-label">
                        {{ allLabelMessages.warranty_tax_label }}
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <money v-bind="warrantyRateMask"
                        id="warrantyTax"
                        v-model="warrantyTax"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <span class="help is-danger">{{
                        warrantyTaxError
                      }}</span>
                    </div>
                  </div>
                  <div class="row no-margin mb-1">
                    <!-- Cus. State Tax Rate -->
                    <div class="col-md-5 col-sm-12">
                      <div class="form-label">
                        {{ allLabelMessages.county }}
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <input
                        id="cusStateTaxRate"
                        v-model="county"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                    </div>
                  </div>

                  <!--                  <div class="row no-margin py-2">-->
<!--                    &lt;!&ndash; Cus. County Tax Rate &ndash;&gt;-->
<!--                    <div class="col-md-4 col-sm-12 paddingleft0">-->
<!--                      <div class="form-label">-->
<!--                        {{ allLabelMessages.county_tax_rate_label }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-8 col-sm-12 paddingleft0">-->
<!--                      <input-->
<!--                        @input="cusCountyTaxRateError = ''"-->
<!--                        id="cusCountyTaxRate"-->
<!--                        v-model="cusCountyTaxRate"-->
<!--                        class="form-control"-->
<!--                        type="text"-->
<!--                        maxlength="190"-->
<!--                      />-->
<!--                      &lt;!&ndash;<div class="requiredstar">*</div>&ndash;&gt;-->
<!--                      <span class="help is-danger">{{-->
<!--                          cusCountyTaxRateError-->
<!--                        }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
                </fieldset>
              </div>
            </div>
            <hr/>

            <div class="row no-margin"  style="padding-bottom:15px;">
              <!-- Internal Commnets -->
              <div class="col-md-12 col-sm-12 mb-1 forms-block">
                <div class="col-md-12 col-sm-12 paddingleft0">
                  <div class="form-label">
                    {{ allLabelMessages.internal_comments_label }}
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 paddingleft0">
                  <textarea
                    @input="internalCommentsError = ''"
                    id="internalComments"
                    v-model="internalComments"
                    class="form-control"
                  ></textarea>
                  <!--<div class="requiredstar">*</div>-->
                  <span class="help is-danger">{{
                      internalCommentsError
                    }}</span>
                </div>
              </div>
            </div>
            <hr/>
            <div class="row no-margin">
            <!-- <div class="col-md-12 forms-block"> -->
            <!-- <div class="border forms-block"> -->
              <div class="col-md-12 forms-block">
              <!-- <div class="col-md-12 forms-block" style="padding-top: 10px;padding-bottom: 10px;"> -->
                <!-- showOnBuyersOrder -->
                <div class="col-md-6 forms-block paddingleft0">
                  <div class="col-md-1 col-sm-12 paddingleft0 custom-checkbox-v">
                    <label class="container">
                      <input
                        id="yes"
                        value="true"
                        v-model="showOnBuyersOrder"
                        class="form-control"
                        type="checkbox"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-md-6 col-sm-12 paddingleft0">
                    <div class="form-label" style="padding-top: 5px !important;">
                      {{ allLabelMessages.show_on_buyers_order_label }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 forms-block">
                    <!-- Commnets -->
                <div class="col-md-6 col-sm-12 paddingleft0">
                  <div class="col-md-12 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ allLabelMessages.comments_label }}
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 paddingleft0" style="padding-bottom: 15px;">
                      <textarea
                        @input="commentsError = ''"
                        id="comments"
                        v-model="comments"
                        class="form-control"
                      ></textarea>
                      <span class="help is-danger">{{ commentsError }}</span>
                    </div>
                </div>
                    <!-- Commnets 2 -->
                    <div class="col-md-6 col-sm-12 paddingleft0">
                      <div class="col-md-12 col-sm-12 paddingleft0">
                        <div class="form-label">
                          {{ allLabelMessages.comments2_label }}
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12 paddingleft0" style="padding-bottom: 15px;">
                        <textarea
                          @input="comments2Error = ''"
                          id="comments2"
                          v-model="comments2"
                          class="form-control"
                        ></textarea>
                        <span class="help is-danger">{{ comments2Error }}</span>
                      </div>
                    </div>
              </div>
            <!-- </div> -->
            <!-- </div> -->
            </div>
            <br/>

            <div class="row no-margin border">
              <span class="m-2">Vehicle Information</span>
              <hr/>

              <div class="col-md-12 forms-block text-center">
                <input
                  id="addAssetBtn"
                  type="submit"
                  value="Add Vehicle"
                  class="btn btn-primary"
                  v-on:click="vehicleWorkSheet()"
                  :disabled="(locationId.value && customerCountyId.value) ? false : true"
                />
                <button
                  id="exportAsset"
                  title="Export"
                  class="btn btn-primary waves-effect ml-2"
                >
                  Load Template
                </button>
                <button
                  id="exportAsset"
                  title="Export"
                  class="btn btn-primary waves-effect ml-2"
                >
                  Copy Vehicle
                </button>
                <button
                  id="exportAsset"
                  title="Export"
                  class="btn btn-primary waves-effect ml-2"
                >
                  Search Stock Vehicle
                </button>
                <button
                  id="exportAsset"
                  title="Export"
                  class="btn btn-primary waves-effect ml-2"
                >
                  Update VIN# / Stock No#
                </button>
              </div>

              <div class="col-md-10 m-auto pb-3">
                <div
                  class="table-listing white-bg border-blue border-radius-top-right"
                >
                  <table class="table custom-table jsmartable">
                    <thead>
                    <tr>
                      <th class="">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="selectAllCheckBox"
                            name="example1"
                          />
                          <label
                            class="custom-control-label"
                            for="selectAllCheckBox"
                          ></label>
                        </div>
                      </th>
                      <th>NU</th>
                      <th>VIN</th>
                      <th>STOCK NO</th>
                      <th>MODEL</th>
                      <th>SALE PRICE</th>
                      <th>DELIVERY DATE</th>
                      <th>DELIVERED</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="assetArr && assetArr.length > 0">
                      <template  v-for="(vehicle, index) in assetArr">
                        <tr class="mainDetails">
                          <td class="">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label"></label>
                            </div>
                          </td>
                          <!--                      todo need to add vehicle data here and make it dynamic.-->
<!--                          <td>{{ asset.asset.vehicle_type }}</td>-->
<!--                          <td>{{ asset.asset.vin_no }}</td>-->
                          <td>{{vehicle.asset.vehicle_type}}</td>
                          <td>{{vehicle.asset.vin_no}}</td>
                          <td>{{vehicle.asset.unit}}</td>
                          <td v-if="vehicle.asset.model_type">{{vehicle.asset.model_type.name}}</td>
                          <td v-else></td>
                          <!-- <td>$ {{totalAmountFromVehicleWorkSheet}}</td> -->
                          <!-- <td>$ {{vehicle.asset.retail_price}}</td> -->
                          <td>$ {{vehicle.frontend_sales}}</td>
                          <td>1/1/1990</td>
                          <td></td>
                          <td>
                            <a v-on:click="vehicleWorkSheet('edit', vehicle.id)" title='Edit Vehicle' href="javascript:void(0)" class="mar-right-0">
                              <i aria-hidden="true" class="fa fa-edit"></i>
                            </a>
                            <a v-on:click="deleteVehicleWorksheet(vehicle.id)" title='Delete Vehicle' href="javascript:void(0)">
                              <i aria-hidden="true" class="fa fa-trash-o text-danger"></i>
                            </a>
                          </td>
                          <!-- <td><a v-on:click="vehicleWorkSheet()" title='Edit Vehicle' href="javascript:void(0)" class="mar-right-0">
                            <i aria-hidden="true" class="fa fa-edit-right"></i>
                        </a></td> -->
                        </tr>
                      </template>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr/>
            </div>
            <br/>

            <div class="row no-margin">
              <ul class="nav nav-tabs">
                <!-- <li class="nav-item">
                  <a class="nav-link" :class="tab == 'lease' ? 'active' : ''" @click="changeTab('lease')">Lease</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="tab == 'finance' ? 'active' : ''" @click="changeTab('finance')">Finance</a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link" :class="tab == 'cash' ? 'active' : ''" @click="changeTab('cash')">Cash</a>
                </li>
              </ul>

              <!-- <div class="tabcontent" id="customer-activity-logs1"> -->
              <div class="tabcontent" id="customer-activity-logs1">
                <div class="row no-margin mt-4">
                  <!-- Order Type -->
                  <div class="col-md-4 col-sm-12 mb-2 forms-block">
                    <div class="col-md-4 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ allLabelMessages.order_type_label }}
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-12 paddingleft0">
                      <v-select
                        v-model="orderType"
                        @input="orderTypeError = ''"
                        name="orderType"
                        id="orderType"
                        label="text"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        :options="orderTypeArr"
                        :placeholder="allStaticMessages.order_type_place_holder"
                      >
                      </v-select>
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{ orderTypeError }}</span>
                    </div>
                  </div>
                </div>

                <div class="row no-margin">
                  <!-- Downpayment On Order -->
                  <div class="col-md-9 col-sm-12 mb-1 forms-block">
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ allLabelMessages.downpayment_on_order_label }}
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask"
                        id="downpayment"
                        v-model="downPaymentOnOrder"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{ downpaymentAmtError }}</span>
                    </div>
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <v-select
                        v-model="downpaymentPaymentType"
                        name="selectDownPayment"
                        id="selectDownPayment"
                        label="text"
                        :clearable="true"
                        :searchable="false"
                        :filterable="false"
                        :options="paymentTypeArr"
                        :placeholder="
                          allStaticMessages.payment_type_place_holder
                        "
                      >
                      </v-select>
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <input
                        id=""
                        class="form-control"
                        type="text"
                        maxlength="190"
                        v-model="downPaymentInfo"
                      />
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                  </div>
                </div>
                <div class="row no-margin">
                  <!-- Cash Back -->
                  <div class="col-md-9 col-sm-12 mb-1 forms-block">
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ allLabelMessages.cash_back_label }}
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask"
                        id="cashBack"
                        v-model="cashBack"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{ cashBackError }}</span>
                    </div>
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <v-select
                        v-model="cashBackPaymentType"
                        name="selectCashBack"
                        id="selectCashBack"
                        label="text"
                        :clearable="true"
                        :searchable="false"
                        :filterable="false"
                        :options="paymentTypeArr"
                        :placeholder="
                          allStaticMessages.payment_type_place_holder
                        "
                      >
                      </v-select>
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <input
                        id=""
                        class="form-control"
                        type="text"
                        maxlength="190"
                        v-model="cashBackInfo"
                      />
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                  </div>
                </div>
                <div class="row no-margin">
                  <!-- Due On Delivery -->
                  <div class="col-md-9 col-sm-12 mb-1 forms-block">
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <div class="form-label">
                        {{ allLabelMessages.due_on_delivery_label }}
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <money v-bind="moneyMask"
                        id="dueOnDelivery"
                        v-model="dueOnDelivery"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <!--<div class="requiredstar">*</div>-->
                      <span class="help is-danger">{{
                          dueOnDeliveryError
                        }}</span>
                    </div>
                    <div class="col-md-3 col-sm-12 paddingleft0">
                      <v-select
                        v-model="dueOnDeliveryPaymentType"
                        name="selectDueOnDelivery"
                        id="selectDueOnDelivery"
                        label="text"
                        :clearable="true"
                        :searchable="false"
                        :filterable="false"
                        :options="paymentTypeArr"
                        :placeholder="
                          allStaticMessages.payment_type_place_holder
                        "
                      >
                      </v-select>
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                    <div class="col-md-2 col-sm-12 paddingleft0">
                      <input
                        id=""
                        class="form-control"
                        type="text"
                        maxlength="190"
                        v-model="duePaymentInfo"
                      />
                      <!--<div class="requiredstar">*</div>-->
                    </div>
                  </div>
                </div>
                <hr/>

                <div v-if="tab == 'lease'">
                  <KeepAlive>
                    <lease-tab
                      v-bind:financeCompanyStateArr="financeCompanyStateArr"
                      v-bind:selectFinanceCompanyArr="selectFinanceCompanyArr"
                      v-bind:leaseData="leaseData"
                      v-bind:isEdit="isEdit"
                      v-bind:key="tab"
                      @leaseData="addLeaseData($event)">
                    </lease-tab>
                  </KeepAlive>
                </div>


                <div v-else-if="tab == 'finance'">
                  <KeepAlive>
                    <finance-tab
                      v-bind:financeCompanyStateArr="financeCompanyStateArr"
                      v-bind:selectFinanceCompanyArr="selectFinanceCompanyArr"
                      v-bind:financeData="financeData"
                      v-bind:isEdit="isEdit"
                      v-bind:key="tab"
                      @financeData="addFinanceData($event)">
                    </finance-tab>
                  </KeepAlive>
                </div>

                <div v-else>
                  <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                      <div class="book-deal-customer-block text">
                        Cash Details
                      </div>
                    </div>
                  </div>
                  <KeepAlive>
                    <div>
                      <div class="row no-margin">
                        <!-- Sales Price -->
                        <div
                          class="col-md-6 col-sm-12 mb-0 forms-block min-margin"
                          v-if="dealStatusIdValue != 3 && dealType != 'finance'"
                        >
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              {{ allLabelMessages.sales_price_label }}
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              @input="salesPriceError = ''"
                              id="salesPrice"
                              v-model="salesPrice"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{ salesPriceError }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 mb-0">
                          <div class="col-md-4 mb-0 col-sm-12 paddingleft0">
                            <div class="form-label deallabel text-right">
                              Financial Company:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 mb-0 paddingleft0">
                            <v-select v-model="cashFinanceCompanyId" name="state"
                                  label="text"
                                  :filterable="false"
                                  :options="selectFinanceCompanyArr"
                                  @search="onSearchFinanceCompany"
                                  :clearable="true"
                                  @input="companyError = ''"
                                  :placeholder="allStaticMessages.company_custom_place_holder"
                                  class="custom-ci-auto-search"
                                  >
                              <span slot="no-options" @click="$refs.select.open = false">
                                  <template v-if="!isCompanyRecordNotFound">
                                      {{allStaticMessages.company_custom_search}}
                                  </template>
                                  <template v-else>
                                      {{allStaticMessages.search_record_not_found}}
                                  </template>
                              </span>
                            </v-select>
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{ salesPriceError }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Downpayment Amt -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              {{ allLabelMessages.down_payment_label }}
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              @blur.native="calculateQuot"
                              id="downpayment"
                              v-model="downpaymentAmt"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                                downpaymentAmtError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Trade Allowance -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              {{ allLabelMessages.trade_allowance_label }}
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              @blur.native="calculateQuot"
                              id="tradeAllownce"
                              v-model="tradeAllownce"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                                tradeAllownceError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Total Fees -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Total Fees:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              id="totalFees"
                              class="form-control dealinputs"
                              v-model="totalFees"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                              totalFeesError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Total Sales Tax -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Total Sales Tax:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              id="totalSalesTax"
                              v-model="totalSalesTax"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                              totalSalesTaxError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Downpayment Amt -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Total:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              id="total"
                              v-model="total"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                                totalError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Downpayment Amt -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Warranties:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              id="warranties"
                              v-model="warranties"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                              warrantiesError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Insurance -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Insurance:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              @blur.native="calculateQuot"
                              id="insurance"
                              v-model="insurance"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                              insuranceError
                              }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row no-margin">
                        <!-- Total + Warrenties -->
                        <div class="col-md-6 col-sm-12 mb-1 forms-block min-margin">
                          <div class="col-md-4 col-sm-12 paddingleft0">
                            <div class="form-label deallabel">
                              Total + Warrenties:
                            </div>
                          </div>
                          <div class="col-md-8 col-sm-12 paddingleft0">
                            <money v-bind="moneyMask"
                              id="ttlWarrenties"
                              v-model="totalPlusWarranties"
                              class="form-control dealinputs"
                              type="text"
                              maxlength="17"
                              disabled
                            />
                            <!--<div class="requiredstar">*</div>-->
                            <span class="help is-danger">{{
                              totalPlusWarrantiesError
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </KeepAlive>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <div class="ml-2"></div>
        </div>

        <!-- Add Customer -->
        <add-customer v-bind:isClickCustomerModal="isClickCustomerModal" v-bind:staticInputLabelMessages="allLabelMessages" v-bind:staticMessages="allStaticMessages"></add-customer>

      </div>
    </template>
    <div class="page-loader-wrapper loading-container" style="display: none;">
      <div class="loading">
        <img src="/static/images/loading.gif">
      </div>
    </div>
    <print-doc-option-modal></print-doc-option-modal>
  </div>
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import {commonMixins} from "../../../mixins/commonMixins";
import EventBus from "../../event-bus";
import {HTTP} from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../../NoAccessPage/NoAccessPage";
import VehicleWorksheetModal from "./VehicleWorksheetModal";
import PrintDocOptionModal from "./PrintDocOption"
import VehicleWorkSheet from "./VehicleWorksheet";
import tinymce from "vue-tinymce-editor";
import {BCollapse} from "bootstrap-vue";
import LeaseTab from "./LeaseTab.vue";
import FinanceTab from "./FinanceTab.vue";
import AddCustomerModal from "./AddCustomerModal.vue";
import moment from 'moment';
import {Money} from 'v-money';

export default {
  name: "TransactionSheet",
  components: {
    datePicker,
    "no-access": NoAccessPage,
    tinymce: tinymce,
    "app-footer": Footer,
    "b-collapse": BCollapse,
    "vehicle-worksheet-modal": VehicleWorksheetModal,
    "print-doc-option-modal": PrintDocOptionModal,
    "vehicle-work-sheet": VehicleWorkSheet,
    'lease-tab': LeaseTab,
    'finance-tab': FinanceTab,
    'add-customer': AddCustomerModal,
    Money
  },
  mixins: [commonMixins],
  data() {
    return {
      dealType: "cash",
      selectedRows: [],
      selectAll: false,
      add_to_forecast: true,
      dealNo: "",
      dealNoError: "",
      oppNo: "",
      oppNoError: "",
      invoiceNo: "",
      invoiceNoError: "",
      invoiceHash: "",
      invoiceHashError: "",
      excedeIdHash: "",
      excedeIdHashError: "",
      companyId: "",
      companyArr: [],
      companyError: "",
      isCompanyRecordNotFound: false,
      dealStatusId: {value: 8, text: "Quotation"},
      dealStatusIdValue: 8,
      dealStatusArr: [],
      statusError: "",
      isDealStatusRecordNotFound: false,
      contractNumber: "",
      contractNumberError: "",
      contractDate: "",
      contractDateError: "",
      invoiceDate: "",
      invoiceDateError: "",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom"
        }
      },
      rosNumber: "",
      rosNumberError: "",
      salePersonId: "",
      salePersonArr: [],
      salePersonError: "",
      isSalePersonRecordNotFound: false,
      cusPhoneNumber: "",
      cusPhoneNumberError: "",
      customerId: "",
      linkedCustomerId:"",
      customerArr: [],
      customerError: "",
      isCustomerRecordNotFound: false,
      individual: false,
      individualValue: "",
      cusFax: "",
      cusFaxError: "",
      cusAddress: "",
      cusAddressError: "",
      cusCell: "",
      cusCellError: "",
      cusAddress2: "",
      cusAddress2Error: "",
      cusSIN: "",
      cusSINError: "",
      customerCityId: "",
      customerCityArr: [],
      customerCityError: "",
      isCustomerCityRecordNotFound: false,
      cusCity2: "",
      cusCity3: "",
      cusCityName: "",
      cusStateCode: "",
      cusStateCodeError: "",
      cusCityZipCode: "",
      cusCityZipCodeError: "",
      cusTaxExemptionNumber: "",
      cusTaxExemptionNumberError: "",
      cusAltCityName: "",
      cusAltCityNameError: "",
      cusAddressType: "main address",
      cusAddressTypeOptions: [
        {text: "Custom", value: "custom address"},
        {text: "Delivery Address", value: "delivery address"},
        {text: "Main Address", value: "main address"}
      ],
      customerCountyId: "",
      customerCountyArr: [],
      customerCountyError: "",
      isCustomerCountyRecordNotFound: false,
      cusTaxRate: 0,
      cusTaxRateError: "",
      cusSalesTax: true,
      cusStateTaxRate: 0,
      cusStateTaxRateError: "",
      cusCountyTaxRate: "",
      cusCountyTaxRateError: "",
      cusAltCountyName: "",
      cusAltCountyNameError: "",
      cusCityTaxRate: "",
      cusCityTaxRateError: "",
      cusOtherTaxRate: "",
      cusOtherTaxRateError: "",
      cusOther2TaxRate: "",
      cusOther2TaxRateError: "",
      customerEmail: "",
      customerEmailError: "",
      cusCountySurtax: "",
      cusCountySurtaxError: "",
      cusCountyCode: "",
      cusCountyCodeError: "",
      // Buyer
      buyerPhoneNumber: "",
      buyerPhoneNumberError: "",
      buyerNumber: "",
      buyerNumberError: "",
      buyerId: "",
      buyerArr: [],
      buyerError: "",
      isBuyerRecordNotFound: false,
      buyerIndividual: false,
      buyerFax: "",
      buyerFaxError: "",
      buyerAddress: "",
      buyerAddressError: "",
      buyerCell: "",
      buyerCellError: "",
      buyerAddress2: "",
      buyerAddress2Error: "",
      buyerSIN: "",
      buyerSINError: "",
      buyerCityId: "",
      buyerCityArr: [],
      buyerCityError: "",
      isBuyerCityRecordNotFound: false,
      buyerCity2: "",
      buyerCity3: "",
      buyerTaxExemptionNumber: "",
      buyerTaxExemptionNumberError: "",
      buyerAltCityName: "",
      buyerAltCityNameError: "",
      buyerAddressType: "main address",
      buyerAddressTypeOptions: [
        {text: "Custom", value: "custom address"},
        {text: "Delivery Address", value: "delivery address"},
        {text: "Main Address", value: "main address"}
      ],
      buyerCountyId: "",
      buyerCountyArr: [],
      buyerCountyError: "",
      isBuyerCountyRecordNotFound: false,
      buyerTaxRate: "",
      buyerTaxRateError: "",
      buyerSalesTax: true,
      buyerStateTaxRate: "",
      buyerStateTaxRateError: "",
      buyerCountyTaxRate: "",
      buyerCountyTaxRateError: "",
      buyerAltCountyName: "",
      buyerAltCountyNameError: "",
      buyerCityTaxRate: "",
      buyerCityTaxRateError: "",
      buyerOtherTaxRate: "",
      buyerOtherTaxRateError: "",
      buyerOther2TaxRate: "",
      buyerOther2TaxRateError: "",
      buyerEmail: "",
      buyerEmailError: "",
      buyerCountySurtax: "",
      buyerCountySurtaxError: "",
      buyerCountyCode: "",
      buyerCountyCodeError: "",
      // Deliver To
      deliverToPhoneNumber: "",
      deliverToPhoneNumberError: "",
      deliverToId: "",
      deliverToArr: [],
      deliverToError: "",
      isDeliverToRecordNotFound: false,
      deliverToIndividual: false,
      deliverToFax: "",
      deliverToFaxError: "",
      deliverToAddress: "",
      deliverToAddressError: "",
      deliverToCell: "",
      deliverToCellError: "",
      deliverToAddress2: "",
      deliverToAddress2Error: "",
      deliverToSIN: "",
      deliverToSINError: "",
      deliverToCityId: "",
      deliverToCityArr: [],
      deliverToCityError: "",
      isDeliverToCityRecordNotFound: false,
      deliverToCity2: "",
      deliverToCity3: "",
      deliverToTaxExemptionNumber: "",
      deliverToTaxExemptionNumberError: "",
      deliverToAltCityName: "",
      deliverToAltCityNameError: "",
      deliverToAddressType: "main address",
      deliverToAddressTypeOptions: [
        {text: "Custom", value: "custom address"},
        {text: "Delivery Address", value: "delivery address"},
        {text: "Main Address", value: "main address"}
      ],
      deliverToCountyId: "",
      deliverToCountyArr: [],
      deliverToCountyError: "",
      isDeliverToCountyRecordNotFound: false,
      deliverToTaxRate: "",
      deliverToTaxRateError: "",
      deliverToSalesTax: true,
      deliverToStateTaxRate: "",
      deliverToStateTaxRateError: "",
      deliverToCountyTaxRate: "",
      deliverToCountyTaxRateError: "",
      deliverToAltCountyName: "",
      deliverToAltCountyNameError: "",
      deliverToCityTaxRate: "",
      deliverToCityTaxRateError: "",
      deliverToOtherTaxRate: "",
      deliverToOtherTaxRateError: "",
      deliverToOther2TaxRate: "",
      deliverToOther2TaxRateError: "",
      deliverToEmail: "",
      deliverToEmailError: "",
      deliverToCountySurtax: "",
      deliverToCountySurtaxError: "",
      deliverToCountyCode: "",
      deliverToCountyCodeError: "",

      internalComments: "",
      internalCommentsError: "",
      comments: "",
      commentsError: "",
      showOnBuyersOrder: false,
      comments2: "",
      comments2Error: "",
      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel",
        copyVehicle: "Copy Vehicle",
        searchStockVehicles: "Search Stock Vehicles",
        updateVinOrStock: "Update Vin#/Stock#",
        lease: "Lease",
        finance: "Finance",
        cash: "Cash"
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
        copyVehicle: false,
        searchStockVehicles: false,
        updateVinOrStock: false,
        lease: false,
        finance: false,
        cash: false
      },
      paymentType: "",
      paymentTypeArr: [],
      paymentTypeError: "",
      orderType: "",
      orderTypeArr: [],
      orderTypeError: "",
      downpayment: 0,
      downpaymentError: "",
      downpaymentPaymentType: "",
      downpaymentDesc: "",
      dueOnDelivery: 0,
      dueOnDeliveryError: "",
      dueOnDeliveryPaymentType: "",
      dueOnDeliveryDesc: "",
      cashBack: 0,
      cashBackError: "",
      cashBackPaymentType: "",
      cashBackDesc: "",
      // Cash Details
      salesPrice: 0.00,
      salesPriceError: "",
      annualePerRate: 0,
      annualePerRateError: "",
      financeTerm: 0,
      financeTermError: "",
      daysToFirstPayment: 0,
      daysToFirstPaymentError: "",
      daysToFirstPaymentDate: "",
      daysToFirstPaymentDateError: "",
      financeIncome: 0,
      financeIncomeError: "",
      financePayment: 0,
      financePaymentError: "",
      salesSubtotal: 0,
      salesSubtotalError: "",
      feesSubtotal: 0,
      feesSubtotalError: "",
      warrantyNotTaxable: 0,
      warrantyNotTaxableError: "",
      dealerRate: 0,
      dealerRateError: "",
      physicalDamagePrice: 0.0,
      physicalDamagePriceError: "",
      physicalDamageCost: 0.0,
      physicalDamageCostError: "",
      nonTruckingLiabilityPrice: 0.0,
      nonTruckingLiabilityPriceError: "",
      nonTruckingLiabilityCost: 0.0,
      nonTruckingLiabilityCostError: "",
      gapInsurancePrice: 0.0,
      gapInsurancePriceError: "",
      gapInsuranceCost: 0.0,
      gapInsuranceCostError: "",
      disabilityPrice: 0.0,
      disabilityPriceError: "",
      disabilityCost: 0.0,
      disabilityCostError: "",
      creditLifePrice: 0.0,
      creditLifePriceError: "",
      creditLifeCost: 0.0,
      creditLifeCostError: "",
      cvraPrice: 0.0,
      cvraPriceError: "",
      cvraCost: 0.0,
      cvraCostError: "",
      downpaymentAmt: 0.0,
      downpaymentAmtError: "",
      tradeAllownce: 0,
      tradeAllownceError: "",
      totalFees: 0.0,
      totalFeesError: "",
      totalSalesTax: 0.0,
      totalSalesTaxError: "",
      totalFinanced: 0.0,
      totalFinancedError: "",
      financeCharge: 0.0,
      financeChargeError: "",
      financeCompany: "",
      financeCompanyError: "",
      financeCompanyAddress: "",
      financeCompanyAddressError: "",
      financeCompanyCity: "",
      financeCompanyCityError: "",
      financeCompanyZip: "",
      financeCompanyZipError: "",
      financeCompanyComment: "",
      financeCompanyCommentError: "",
      financeCompanyAlt: "",
      financeCompanyAltError: "",
      financeCompanyAltAddress: "",
      financeCompanyAltAddressError: "",
      financeCompanyAltCity: "",
      financeCompanyAltCityError: "",
      financeCompanyAltZip: "",
      financeCompanyAltZipError: "",
      financeCompanyAltComment: "",
      financeCompanyAltCommentError: "",
      deferredPrice: 0.0,
      deferredPriceError: "",
      totalOfPayments: 0.0,
      totalOfPaymentsError: "",
      total: 0.0,
      totalError: "",
      warranties: 0,
      warrantiesError: "",
      insurance: 0,
      insuranceError: "",
      totalWarranties: 0.0,
      totalWarrantiesError: "",

      assetInfo: [],
      opportunityInfo: [],
      opportunityInventoryArr: [],
      opportunityId: this.$route.params.opportunityId,
      assetId: 0,
      selectedOpportunityId: 0,
      editAssetId: 0,
      isShowVehicleWorkSheet: false,
      dealId: 0,
      dealData: "",
      makeArr: [],
      makeId: 0,
      isMakeRecordNotFound: false,
      modelArr: [],
      modelId: 0,
      isModelRecordNotFound: false,
      conditionArr: [],
      dataAccessType: "used",
      conditionId: {text: "New", value: "1"},
      rrp: 0,
      gst: 0,
      list_price: 0,
      dealer_discount: 0,
      dealer_net: 0,
      conquest: 0,
      fituptotal: 0,
      pre_delivery: 0,
      bonus_amount: 0.0,
      bonus_amount_error: "",
      extra_conquest: 0.0,
      extra_conquest_error: "",
      total_cq_approved: 0.0,
      sub_total1: 0.0,
      overallowance: 0.0,
      gross_profit: 0.0,
      gross_profit_error: "",
      gross_profit_percentage: 0.0,
      cab_chassis: 0.0,
      build_up_total: 0.0,
      sub_total_ex_gst: 0.0,
      total_gst: 0.0,
      sub_total2: 0.0,
      stampDutyPercentage: 0.0,
      cylinders: "",
      stampDutyType: {text: "Exempt", value: "exempt"},
      stampDutyTypeArr: [
        {text: "New/Demo", value: "new/demo"},
        {text: "Exempt", value: "exempt"}
      ],
      selectFinanceCompany: "",
      selectFinanceCompanyArr: [],
      isFinanceCompanyRecordNotFound: false,
      financeCompanyState: "",
      financeCompanyStateArr: [],
      isFinanceCompanyStateRecordNotFound: false,
      financeCompanyAltState: "",
      financeCompanyAltStateArr: [],
      rego: 0.0,
      rego_error: "",
      ctp: 0.0,
      ctp_error: "",
      stamp_duty: 0.0,
      trade_detail: "",
      trade_value_inc_gst: 0.0,
      trade_value_inc_gst_error: "",
      over_allowance_inc_gst: 0.0,
      over_allowance_inc_gst_error: "",
      trade_payout: 0.0,
      trade_payout_error: "",
      trade_equity: 0.0,
      change_over: 0.0,
      isSupplierRecordNotFound: false,
      lineItemsArr: [],
      supplierArr: [],
      lineItemsObject: {
        supplierId: "",
        supplierIdError: "",
        buildUp: "",
        buildUpError: "",
        priceGST: "",
        priceGSTError: ""
      },
      plusCountSupplier: 49,
      firstAdd: false,
      servicePlan: "",
      vehicleWarranty: "",
      roadsideAssistance: "",
      specificationUrl: "",
      specificationUrlError: "",
      specPages: "",
      specPagesError: "",
      dealInventoryMapperId: "",
      assetArr: [],
      districtOrCounty: "",
      warrantyTax: 9.25,
      county: "",
      countyCodeArr: [],
      taxRateArr: [],
      isCountyCodeNotFound: false,
      totalAmountFromVehicleWorkSheet: '',
      totalPlusWarranties: 0,
      taxRate: '',
      vehicleData: '',
      isEdit: false,
      isEditVehicle: false,
      tab: 'cash',
      leaseData: {},
      financeData: {},
      downPaymentInfo: '',
      cashBackInfo: '',
      duePaymentInfo: '',
      isTabChange: false,
      dealMapperId: 0,
      cashId: 0,
      isCountyEdit: false,
      isVehicleEdit: false,
      oldTab: '',
      oldTabId: 0,
      totalFeesError: "",
      totalSalesTaxError: "",
      insuranceError: "",
      ttlWarrentiesError: "",
      warrantyTaxError: "",
      totalPlusWarrantiesError: "",
      cashFinanceCompanyId: 0,
      tempRetailPrice: 0,
      downPaymentOnOrder: 0,
      locationArr: [],
      locationId: "",
      moneyMask: {
        // decimal: ',',
        thousands: ',',
        prefix: '$ ',
        // suffix: ' #',
        // precision: 0,
      },
      rateMask: {
        suffix: ' %',
        precision: 4,
      },
      warrantyRateMask: {
        suffix: ' %',
        precision: 4,
      },
      print_document_flag:false,
      isClickCustomerModal: false,
      flagCustomerPopulate: 0,
      currentCustomerData: false,
      warrantyFromVehicleWorksheet: 0,
      isMount: true,
    };
  },
  watch: {
    dealStatusId: function (dealStatusId) {
      // if (typeof (dealStatusId) != 'undefined' && dealStatusId.value != "" && dealStatusId.value != 0) {
      self.dealStatusIdValue = dealStatusId.value;
      // }
      if (self.dealStatusIdValue != 8) {
        for (let i = self.lineItemsArr.length - 1; i >= 0; i--) {
          self.removeSupplier(i);
        }
        self.plusCountSupplier = 49;
        // if (self.lineItemsArr.length <= 0) {
          self.addNewRow();
        // }
      }
    },
    selectFinanceCompany: function (selectFinanceCompany) {
      self.loadAllData(
        ["FinanceGroup"],
        false,
        self.loadFinanceCompanyDataCallback,
        "undefined",
        ""
      );
    },
    financeCompanyState: function (financeCompanyState) {
      self.loadAllData(
        ["States"],
        false,
        self.loadFinanceCompanyStateDataCallback,
        "undefined",
        ""
      );
    },
    tab:function(value){
      self.tab = value;
      self.isTabChange = true;
      // if(self.isEdit) {
      //   self.editTransactionSheet(self.dealId);
      // }
    },
    // cusStateTaxRate:function() {
    //   self.calculateQuot();
    // },
    // warrantyTax:function() {
    //   self.calculateQuot();
    // },
    salesPrice:function() {
      self.calculateQuot;
    },
    totalFees:function() {
      self.calculateQuot;
    },
  },
  beforeMount() {
    self = this;
    // console.log('Id>>', this.$route.params.referenceNo);
    // console.log('Opportunity Id BeforeMount>>', self.opportunityId);
    if (self.opportunityId !== null && self.opportunityId !== 0 && typeof self.opportunityId !== 'undefined') {
      self.oppNo = this.$route.params.referenceNo;
      localStorage.setItem("opportunityId", self.opportunityId);
      localStorage.setItem("referenceNo", self.oppNo);
      self.createDealWithOpportunity();
    } else if(localStorage.getItem("opportunityId") !== null) {
      self.opportunityId = localStorage.getItem("opportunityId");
      self.dealId = localStorage.getItem('dealId');
      self.oppNo = localStorage.getItem('referenceNo');
    }
  },
  mounted() {
    var isEventBus = false;
    EventBus.$on("work-sheet-callback-after-saving", function (
      callbackDealInventoryMapperId,
      callbackAssetId,
      callbackTotalAmount,
      // callbackTotalFees
      ) {
        if (typeof self != "undefined") {
          self.dealInventoryMapperId = callbackDealInventoryMapperId;
          self.assetId = callbackAssetId;
          self.totalAmountFromVehicleWorkSheet = callbackTotalAmount;
          // self.totalFees = callbackTotalFees;
          isEventBus = true;

          self.currentCustomerData = true;

          // self.currentCustomerData = {
          //     customerCountyId: self.customerCountyId,
          //     cusAddress: self.cusAddress,
          //     cusAddress2: self.cusAddress2,
          //     // customerCityId: customerData.cityId,
          //     customerEmail: self.customerEmail,
          //     cusPhoneNumber: self.cusPhoneNumber,
          //     cusCell: self.cusCell,
          //     cusFax: self.cusFax,
          //     linkedCustomerId: self.linkedCustomerId,
          //     cusCityName: self.cusCityName,
          //     cusStateCode: self.cusStateCode,
          //     cusCityZipCode: self.cusCityZipCode,
          // }

          self.vehicleWorkSheet();
          
          self.getAssetDataFromId();
          self.isVehicleEdit = false;
        }
    });
    var localDealInventoryMapperId = localStorage.getItem('dealInventoryMapperId');
    var localVehicleId = localStorage.getItem('vehicleId');
    var localTotalAmountFromVehicleWorkSheet = localStorage.getItem('totalAmountFromVehicleWorkSheet');
    // var locatlTotalFees = localStorage.getItem('totalFees');
    if(!isEventBus && localDealInventoryMapperId !== null && !self.$route.query.deal_id) {
      self.dealInventoryMapperId = localDealInventoryMapperId;
      self.assetId = localVehicleId;
      self.totalAmountFromVehicleWorkSheet = localTotalAmountFromVehicleWorkSheet;
      // self.totalFees = locatlTotalFees;

      self.getAssetDataFromId();
      self.isVehicleEdit = false;
    }

    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    } else {
      var userJson = JSON.parse(userObj);
      self.salePersonId = {
        text: userJson["first_name"] + " " + userJson["last_name"],
        value: userJson["id"]
      };
      self.dataAccessType = userJson["access_inventory"];
      if (userJson["access_inventory"] == "used") {
        self.conditionId = {text: "Used", value: "2"};
      } else {
        self.conditionId = {text: "New", value: "1"};
      }
    }
    
    self.setActiveMenu('transaction-sheet');
    EventBus.$emit("menu-selection-changed", "transaction-sheet");
    self.firstAdd = true;

    if (
      typeof self.$route.query.deal_id != "undefined" &&
      self.$route.query.deal_id != "" &&
      self.$route.query.deal_id != 0
    ) {
      self.dealId = self.$route.query.deal_id;
      // self.getAvailableDeals(0);
    }

    if(self.$route.query.deal_id > 0) {
      self.editTransactionSheet(self.$route.query.deal_id);
    }

    if(self.tab == 'cash') {
      self.calculateQuot;
    }

    if (process.env.IS_REDIRECTION) {
      self.countryId = {text: self.allLabelMessages.AU_country_label, value: 13};
    } else {
      self.countryId = {text: self.allLabelMessages.US_country_label, value: 231};
    }

    self.loadAllData(
      [
        "Company",
        "DealStatus",
        "User",
        "Contact",
        "Cities",
        "Countries",
        "FinanceGroup",
        "PaymentType",
        "States",
        "Location",
      ],
      false,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    // self.$nextTick(() => {
    //   self.loadAllData(
    //     ["Make", "Model", "Condition"],
    //     false,
    //     self.loadTypeMasterDataCallback,
    //     "undefined",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     self.dataAccessType
    //   );
    // });

    setTimeout(function() {
      if(self.isEdit === false) {
        self.onSearchDefaultCustomer();
      }
    }, 1000);
  },
  methods: {
    autoPopulateCustomerAddress(customerData){
      if(customerData != '') {
        self.customerError = '';
        self.cusAddress = customerData.address;
        self.cusAddress2 = '';
        // self.customerCityId = customerData.cityId;
        self.customerEmail = customerData.email;
        self.cusPhoneNumber = customerData.primaryPhone;
        self.cusCell = customerData.alternate_phone;
        self.cusFax = customerData.fax;
        self.linkedCustomerId = customerData.customerId;
        self.cusCityName = customerData.cityName;
        self.cusStateCode = customerData.stateCode;
        self.cusCityZipCode = customerData.zipCode;
      }
    },

    exportToPdf(){
      //as per discussion need to call save(update) before print documents.
      self.print_document_flag = true;
      self.saveAndCloseDeal();

      // $("#printQuotBtn").attr("disabled", true);

      // // var data = self.returnFormDataForSave();

      // var config = {
      //     headers: {
      //         Authorization:"Bearer " + localStorage.getItem("api_token")
      //     },
      //     params : {
      //         // data:data,
      //         deal_id: self.dealId,
      //         // saleperson_id: self.salePersonId.value,
      //         // customer_id: self.customerId.value,
      //     }
      // };
      // HTTP.get("/export_pdf", config)
      //     .then(function(response) {
      //         if (response.status == "200") {
      //             if(response.data.code == "200"){
      //                 // window.open(process.env.ROOT_API + '/s3/' + response.data.content);
      //                 window.open(response.data.content);
      //             }else{
      //                 console.log('err', response.data.message);
      //             }
      //         }
      //         $("#printQuotBtn").attr("disabled", false);
      //     })
      //     .catch(function(err) {
      //         console.log('err', err)
      //         if (err.response.status == 0) {
      //             self.error = "Remote server can not be reachable";
      //         } else {
      //             self.error = err.response.data.message;
      //         }
      //         $("#printQuotBtn").attr("disabled", false);
      //     });
      },

    changeDealType: function (type) {
      self.dealType = type;
    },
    loadImportModal: function () {
      $("#defaultModal").modal("show");
    },
    loadTypeMasterDataCallback: function (callbackData) {
      if (callbackData && callbackData.length <= 0) {
        return false;
      }
      self.makeArr = callbackData.Make;
      self.modelArr = callbackData.Model;
      self.conditionArr = callbackData.Condition;
    },
    addNewRow: function () {
      if (self.addNewSupplier()) {
        if (self.firstAdd) {
          self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
          self.firstAdd = false;
        } else {
          self.plusCountSupplier = self.plusCountSupplier - 1;
          self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
        }
      }
    },
    addNewSupplier: function () {
      var validationError = true;
      for (let index = 0; index < self.lineItemsArr.length; index++) {
        var lineItemObject = self.lineItemsArr[index];
        lineItemObject.supplierIdError = "";
        lineItemObject.buildUpError = "";
        lineItemObject.priceGSTError = "";
        // if(self.dealStatusIdValue == 8){
        if (
          lineItemObject.supplierId == null ||
          !lineItemObject.supplierId.value
        ) {
          lineItemObject.supplierIdError = "Please select supplier";
          if (validationError) {
            validationError = false;
          }
        }
        if (!lineItemObject.buildUp) {
          lineItemObject.buildUpError = "Please select build up";
          if (validationError) {
            validationError = false;
          }
        }
        if (!lineItemObject.priceGST) {
          lineItemObject.priceGSTError = "Please select price ex. GST";
          if (validationError) {
            validationError = false;
          }
        } else if (
          lineItemObject.priceGST &&
          !self.numberFormateValidation(lineItemObject.priceGST)
        ) {
          lineItemObject.priceGSTError = "Only Numeric Value Allow";
          if (validationError) {
            validationError = false;
          }
        }
        // }
      }
      return validationError;
    },
    removeSupplier: function (index) {
      if (self.plusCountSupplier <= 49) {
        self.plusCountSupplier = self.plusCountSupplier + 1;
        self.lineItemsArr.splice(index, 1);
      }
      self.calculateQuot;
    },
    onSearchSupplier(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Vendor"],
          true,
          self.loadSupplierDataCallback,
          loading,
          search
        );
      }
    },
    loadSupplierDataCallback: function (callbackData) {
      self.isSupplierRecordNotFound = false;
      self.supplierArr = callbackData.Vendor;
      if (self.supplierArr.length == 0) {
        self.isSupplierRecordNotFound = true;
      }
    },
    vehicleWorkSheet: function (type = null,id =null) {
      self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
      self.editAssetId = id;
      if(type == 'edit') {
        self.isVehicleEdit = true;
      }
    },
    editOpportunityInventory: function (editOptionAssetId) {
      self.editAssetId = editOptionAssetId;
      self.isShowVehicleWorkSheet = !self.isShowVehicleWorkSheet;
    },
    deleteOpportunityInventory: function (deleteAssetId) {
      /* console.log(deleteAssetId);
                console.log(self.selectedOpportunityId);return false; */
      self
        .$swal({
          title: "",
          text:
            "Are you sure that you want to delete this Opportunity Inventory!",
          type: "info",
          confirmButtonText: "OK",
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true
        })
        .then(result => {
          if (result.value) {
            var config = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("api_token")
              },
              data: {
                asset_id: deleteAssetId,
                opportunity_id: self.selectedOpportunityId
              }
            };
            HTTP.delete("/opportunity/inventory/delete", config)
              .then(function (response) {
                if (response.data.code == "200") {
                  self.getOpportunityInventory(self.selectedOpportunityId);
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {
                });
              });
          }
        });
    },
    addOpportunity: function (params) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        }
      };
      var data = new FormData();
      data.append("asset_id", self.assetId);
      data.append("deal_id", self.dealId);
      HTTP.post("/deal/opportunity/add", data, config)
        .then(function (response) {
          // $('#opportunityModal').modal('hide');
          // Display Opportunity Inventory Data Function
          self.getOpportunityInventory(response.data.content.id);
          // self.getDealData();
        })
        .catch(function (err) {
          self.catchHandler(err, function () {
          });
        });
    },
    getOpportunityInventory: function (opportunityId) {
      self.selectedOpportunityId = opportunityId;
      $("#opportunityModal").modal("hide");
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: opportunityId
        }
      };
      self.showLoader();
      HTTP.get("/opportunity/get", config)
        .then(function (data) {
          self.hideLoader();
          /* console.log(JSON.stringify(data.data.content));
                        self.opportunityInfo = [];
                        self.opportunityInfo = data.data.content.inventory_data; */
          self.opportunityInventoryArr = data.data.content.inventory_data;
        })
        .catch(function (error) {
          self.hideLoader();
          self.catchHandler(error, function () {
          });
        });
    },
    getdeal: function (dealId) {
      self.dealId = dealId;
      self.assetInfo = [];
      /* console.log(self.dealId); */
      $("#dealModal").modal("hide");
      /* return false; */
      self.$nextTick(() => {
        self.getDealData();
      });
    },
    getAvailableDeals: function (id) {
      // var idArr = [];
      // idArr.push(id);
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id,
          deal_id: self.dealId
        }
      };
      self.showLoader();
      HTTP.get("/asset/get_asset_deals_by_ids", config)
        .then(function (data) {
          self.hideLoader();
          if (data.status == 200) {
            self.assetInfo = data.data.content;
            if (self.assetInfo && self.assetInfo.length > 0) {
              self.dealId = self.assetInfo[0].id;
              self.dealNo = self.assetInfo[0].deal_no;
              self.getAvailableOpportunity(self.dealId);
              self.$nextTick(() => {
                self.getDealData();
              });
              // Display Opportunity Inventory Data Function
              // self.getOpportunityInventory(self.assetInfo[0].id);
              // Check
            } else {
              self.saveBookDeal();
              self.$nextTick(() => {
                // self.addOpportunity();
              });
            }
          }
        })
        .catch(function (error) {
          self.hideLoader();
          self.catchHandler(error, function () {
          });
        });
    },
    /**
     * get Deal data
     */
    getDealData: function () {
      self.addNewRow();
      /* console.log(self.dealId);
                return false; */
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: self.dealId
        }
      };
      self.showLoader();
      HTTP.get("/deal/getbyid", config)
        .then(function (data) {
          self.hideLoader();
          if (data.status == 200) {
            /* self.assetData = data.data;
                            self.asset = self.assetData; */
            self.dealData = data.data.content;
            self.opportunityInventoryArr =
              data.data.content.deal_inventory_data;
            self.loadDealData();
            if (data.data.content.line_items.length > 0) {
              self.lineItemsArr = [];
              self.lineItemsArr = data.data.content.line_items;
              self.plusCountSupplier -= data.data.content.line_items.length;
              // self.lineItemsArr.push(Vue.util.extend({}, self.lineItemsObject));
            }
            self.selectedOpportunityId = data.data.content.opportunity_id;
            self.getOpportunityInventory(self.selectedOpportunityId);
            /* if(data.data.content.opportunity_id != null && data.data.content.opportunity_id != 'undefined' && data.data.content.opportunity_id != 0){
                                self.opportunityInfo.push({
                                    id: data.data.content.opportunity_id,
                                    reference_no:  data.data.content.opportunity_reference_no
                                });
                                self.$nextTick(() => {
                                    $('#opportunityModal').modal('show');
                                });
                            } else {
                                self.addOpportunity();
                            } */
          }
        })
        .catch(function (error) {
          self.hideLoader();
          /* console.log(error); */
          self.catchHandler(error, function () {
          });
        });
    },
    /**
     * Checck Opportunity available for tthe deal
     * @param string $id comma separated deal ids
     */
    getAvailableOpportunity: function (id) {
      /* console.log(id);
                return false; */
      // var idArr = [];
      // idArr.push(id);
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id
        }
      };
      self.showLoader();
      HTTP.get("/asset/get_deal_opportunity_by_id", config)
        .then(function (data) {
          self.hideLoader();
          /* console.log(JSON.stringify(data.data));
                        return false; */
          if (data.status == 200) {
            /* self.assetData = data.data;
                            self.asset = self.assetData; */
            self.opportunityInfo = data.data.content;
            if (self.opportunityInfo && self.opportunityInfo.length > 0) {
              if (self.opportunityInfo.length >= 1) {
                $("#opportunityModal").modal("show");
              } else {
                // Display Opportunity Inventory Data Function
                self.getOpportunityInventory(
                  self.opportunityInfo[0].opportunity_id
                );
              }
            } else {
              // Create Opportunity
              self.addOpportunity('getAvailableOpportunity');
              // self.saveBookDeal();
              self.$nextTick(() => {
              });
            }
          }
        })
        .catch(function (error) {
          self.hideLoader();
          self.catchHandler(error, function () {
          });
        });
    },
    loadMasterDataCallback: function (callbackData) {

      if (callbackData && callbackData.length <= 0) {
        return false;
      }

      self.isCompanyRecordNotFound = self.isDealStatusRecordNotFound = self.isFinanceCompanyRecordNotFound = self.isFinanceCompanyStateRecordNotFound = false;
      self.isPaymentTypeRecordNotFound = self.isDownPaymentRecordNotFound = self.isCashBackRecordNotFound = self.isDueOnDeliveryRecordNotFound = false;
      self.companyArr = callbackData.Company;
      if (self.companyArr.length == 0) {
        self.isCompanyRecordNotFound = true;
      }

      self.locationArr = callbackData.Location;
      if (self.locationArr.length == 0) {
        self.isLocationRecordNotFound = true;
      } else if(self.isEdit == false){
        /* This else condition is applied to select the default branch "Los Angeles Freightliner - Whittier" on mount */ 
        self.locationArr.filter(location => {
          if(location.text === "WHITTIER 210") { // This condition is to be applied using locid instead of name but locid is not passed from backend, todo locid pass from backend
            self.locationId = {
              text: location.text,
              value: location.value
            }
          }
        });
      }

      self.dealStatusArr = callbackData.DealStatus;
      if (self.dealStatusArr.length == 0) {
        self.isDealStatusRecordNotFound = true;
      }

      self.isSalePersonRecordNotFound = self.isCustomerRecordNotFound = self.isCustomerCityRecordNotFound = self.isCustomerCountyRecordNotFound = false;
      self.isBuyerRecordNotFound = self.isBuyerCityRecordNotFound = self.isBuyerCountyRecordNotFound = false;
      self.isDeliverToRecordNotFound = self.isDeliverToCityRecordNotFound = self.isDeliverToCountyRecordNotFound = false;

      self.salePersonArr = callbackData.User;
      if (self.salePersonArr.length == 0) {
        self.isSalePersonRecordNotFound = true;
      }

      self.customerArr = callbackData.Contact;
      if (self.customerArr.length == 0) {
        self.isCustomerRecordNotFound = true;
      }
      self.customerCityArr = callbackData.Cities;
      if (self.customerCityArr.length == 0) {
        self.isCustomerCityRecordNotFound = true;
      }
      self.customerCountyArr = callbackData.Countries;
      if (self.customerCountyArr.length == 0) {
        self.isCustomerCountyRecordNotFound = true;
      }
      // Buyer
      self.buyerArr = callbackData.Contact;
      if (self.buyerArr.length == 0) {
        self.isBuyerRecordNotFound = true;
      }
      self.buyerCityArr = callbackData.Cities;
      if (self.buyerCityArr.length == 0) {
        self.isBuyerCityRecordNotFound = true;
      }
      self.buyerCountyArr = callbackData.Countries;
      if (self.buyerCountyArr.length == 0) {
        self.isBuyerCountyRecordNotFound = true;
      }
      // Deliver To
      self.deliverToArr = callbackData.Contact;
      if (self.deliverToArr.length == 0) {
        self.isDeliverToRecordNotFound = true;
      }
      self.deliverToCityArr = callbackData.Cities;
      if (self.deliverToCityArr.length == 0) {
        self.isDeliverToCityRecordNotFound = true;
      }
      self.deliverToCountyArr = callbackData.Countries;
      if (self.deliverToCountyArr.length == 0) {
        self.isDeliverToCountyRecordNotFound = true;
      }
      // Finance Company
      self.selectFinanceCompanyArr = callbackData.FinanceGroup;
      if (self.selectFinanceCompanyArr.length == 0) {
        self.isFinanceCompanyRecordNotFound = true;
      }
      self.financeCompanyStateArr = callbackData.States;

      if (self.financeCompanyStateArr.length == 0) {
        self.isFinanceCompanyStateRecordNotFound = true;
      }
      // Payment Type
      self.paymentTypeArr = callbackData.PaymentType;

      if(self.isEdit) {
         self.salePersonArr.filter(sale => {
          if(sale.value === self.salePersonId) {
            self.salePersonId = {
              text: sale.text,
              value: sale.value
            }
            self.salePersonError = '';
          }
          // return sale.value === self.salePersonId;
        });

        self.paymentTypeArr.filter(type => {
          if(type.value === self.downpaymentPaymentType) {
            self.downpaymentPaymentType = {
              text: type.text,
              value: type.value
            }
          }
          if(type.value === self.cashBackPaymentType) {
            self.cashBackPaymentType = {
              text: type.text,
              value: type.value
            }
          }
          if(type.value === self.dueOnDeliveryPaymentType) {
            self.dueOnDeliveryPaymentType = {
              text: type.text,
              value: type.value
            }
          }
        });

        self.locationArr.filter(location => {
          if(location.value === self.locationId) {
            self.locationId = {
              text: location.text,
              value: location.value
            }
          }
        });

        self.customerCityArr.filter(city => {
          if(city.value === self.customerCityId) {
            self.customerCityId = {
              text: city.text,
              value: city.value
            }
          }
        });

        self.customerArr.filter(customer => {
          if(customer.value === self.customerId) {
            self.customerId = {
              text: customer.text,
              value: customer.value
            }
            self.linkedCustomerId = customer.customerId;
          }
          self.customerError = '';
        });

        self.selectFinanceCompanyArr.filter(financeCompany => {
        if(financeCompany.value === self.cashFinanceCompanyId) {
            self.cashFinanceCompanyId = {
                  text: financeCompany.text,
                  value: financeCompany.value
              }
          }
        });
      }
    },
    /* loadMasterDetailsCallback: function(callbackData) {
                self.isSalePersonRecordNotFound = self.isCustomerRecordNotFound = self.isCustomerCityRecordNotFound = self.isCustomerCountyRecordNotFound = false;
                self.isBuyerRecordNotFound = self.isBuyerCityRecordNotFound = self.isBuyerCountyRecordNotFound = false;
                self.isDeliverToRecordNotFound = self.isDeliverToCityRecordNotFound = self.isDeliverToCountyRecordNotFound = false;

                self.salePersonArr = callbackData.User;
                if(self.salePersonArr.length == 0) {
                    self.isSalePersonRecordNotFound = true;
                }
                self.customerArr = callbackData.Contact;
                if(self.customerArr.length == 0) {
                    self.isCustomerRecordNotFound = true;
                }
                self.customerCityArr = callbackData.Cities;
                if(self.customerCityArr.length == 0) {
                    self.isCustomerCityRecordNotFound = true;
                }
                self.customerCountyArr = callbackData.Countries;
                if(self.customerCountyArr.length == 0) {
                    self.isCustomerCountyRecordNotFound = true;
                }
                // Buyer
                self.buyerArr = callbackData.Contact;
                if(self.buyerArr.length == 0) {
                    self.isBuyerRecordNotFound = true;
                }
                self.buyerCityArr = callbackData.Cities;
                if(self.buyerCityArr.length == 0) {
                    self.isBuyerCityRecordNotFound = true;
                }
                self.buyerCountyArr = callbackData.Countries;
                if(self.buyerCountyArr.length == 0) {
                    self.isBuyerCountyRecordNotFound = true;
                }
                // Deliver To
                self.deliverToArr = callbackData.Contact;
                if(self.deliverToArr.length == 0) {
                    self.isDeliverToRecordNotFound = true;
                }
                self.deliverToCityArr = callbackData.Cities;
                if(self.deliverToCityArr.length == 0) {
                    self.isDeliverToCityRecordNotFound = true;
                }
                self.deliverToCountyArr = callbackData.Countries;
                if(self.deliverToCountyArr.length == 0) {
                    self.isDeliverToCountyRecordNotFound = true;
                }
            }, */
    onSearchCompany(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Company"],
          true,
          self.loadCompanyDataCallback,
          loading,
          search
        );
      }
    },
    loadCompanyDataCallback: function (callbackData) {
      self.isCompanyRecordNotFound = false;
      self.companyArr = callbackData.Company;
      if (self.companyArr.length == 0) {
        self.isCompanyRecordNotFound = true;
      }
    },
    onSearchLocation(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Location"],
          true,
          self.loadLocationDataCallback,
          loading,
          search
        );
      }
    },
    loadLocationDataCallback: function (callbackData) {
      self.isLocationRecordNotFound = false;
      self.locationArr = callbackData.Location;
      if (self.locationArr.length == 0) {
        self.isLocationRecordNotFound = true;
      }
    },
    onSearchMake(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Make"],
          true,
          self.loadMakeDataCallback,
          loading,
          search,
          "",
          "",
          "",
          "",
          "",
          "",
          self.conditionId.text.toLowerCase()
        );
      }
    },
    loadMakeDataCallback: function (callbackData) {
      self.isMakeRecordNotFound = false;
      self.makeArr = callbackData.Make;
      if (self.makeArr.length == 0) {
        self.isMakeRecordNotFound = true;
      }
    },
    changedMakeValue: function () {
      let make_id = "";
      if (
        typeof self.makeId != "undefined" &&
        self.makeId != null &&
        self.makeId != "" &&
        self.makeId != 0
      ) {
        make_id = self.makeId.value;
      }
      self.loadAllData(
        ["Model"],
        false,
        self.loadModelDataCallback,
        "undefined",
        "",
        make_id,
        "",
        "",
        "",
        "",
        "",
        self.conditionId.text.toLowerCase()
      );
    },
    onSearchModel(search, loading) {
      if (search.length > 2) {
        loading(true);
        let make_id = "";
        if (
          typeof self.makeId != "undefined" &&
          self.makeId != null &&
          self.makeId != "" &&
          self.makeId != 0
        ) {
          make_id = self.makeId.value;
        }
        self.loadAllData(
          ["Model"],
          true,
          self.loadModelDataCallback,
          loading,
          search,
          make_id
        );
      }
    },
    loadModelDataCallback: function (callbackData) {
      self.isModelRecordNotFound = false;
      self.modelArr = callbackData.Model;
      if (self.modelArr.length == 0) {
        self.isModelRecordNotFound = true;
      }
    },
    changedValue: function (data) {
      self.getModelPriceBookData(data.value);
    },
    getModelPriceBookData(modelid) {
      if (modelid) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          },
          params: {
            make_id: self.makeId.value,
            model_id: modelid
          }
        };

        HTTP.get("/price/book/makemodelget", config)
          .then(response => {
            // console.log(JSON.stringify(response.data));
            // console.log(response.data.content.list_price);
            // if(self.salesPrice < response.data.content.list_price){
            self.salesPrice = self.convertToNum(
              parseFloat(response.data.content.list_price),
              2
            );
            // }
            self.rrp = self.convertToNum(response.data.content.rrp, 2);
            self.gst = self.convertToNum(response.data.content.gst, 2);
            self.list_price = self.convertToNum(
              response.data.content.list_price,
              2
            );
            self.dealer_discount = self.convertToNum(
              response.data.content.dealer_discount,
              2
            );
            self.dealer_net = self.convertToNum(
              response.data.content.dealer_net,
              2
            );
            self.conquest = self.convertToNum(
              response.data.content.conquest,
              2
            );
            self.fituptotal = self.convertToNum(
              response.data.content.fituptotal,
              2
            );
            self.pre_delivery = self.convertToNum(
              response.data.content.pre_delivery,
              2
            );
            self.stampDutyPercentage = 3;
            self.cylinders = response.data.content.cylinder;
            if (response.data.content.cylinder == "6 Cylinder") {
              self.stampDutyPercentage = 3.5;
            }
            self.calculateQuot;
          })
          .catch(function (err) {
            console.log(err);
            // self.catchHandler(err, function () {});
          });
      }
    },
    onSearchDealStatus(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["DealStatus"],
          true,
          self.loadStatusDataCallback,
          loading,
          search,
          "",
          "",
          "",
          "",
          "",
          "",
          self.conditionId.text.toLowerCase()
        );
      }
    },
    loadStatusDataCallback: function (callbackData) {
      self.isDealStatusRecordNotFound = false;
      self.dealStatusArr = callbackData.DealStatus;
      if (self.dealStatusArr.length == 0) {
        self.isDealStatusRecordNotFound = true;
      }
    },
    onSearchSalePerson(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadSalePersonCallback,
          loading,
          search
        );
      }
    },
    loadSalePersonCallback: function (callbackData) {
      self.isSalePersonRecordNotFound = false;
      self.salePersonArr = callbackData.User;
      if (self.salePersonArr.length == 0) {
        self.isSalePersonRecordNotFound = true;
      }
    },
    onSearchCustomer(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Contact"],
          true,
          self.loadCustomerCallback,
          loading,
          search
        );
      }
    },
    loadCustomerCallback: function (callbackData) {
      self.isCustomerRecordNotFound = false;
      self.customerArr = callbackData.Contact;
      if (self.customerArr.length == 0) {
        self.isCustomerRecordNotFound = true;
      }
    },
    loadCustomerIdAndName: function (callbackData) {
      // self.isCustomerRecordNotFound = false;
      // self.customerArr = callbackData.Contact;
      // if (self.customerArr.length == 0) {
      //   self.isCustomerRecordNotFound = true;
      // }
      const customerInfo = callbackData.Contact;
      const selectedCustomer = customerInfo.filter(customer => {
        return customer.value == self.customerId;
      })
      self.linkedCustomerId = selectedCustomer[0].customerId;
      self.customerId = selectedCustomer[0];
      // self.populateCustomerData(customerInfo, true);
    },
    onSearchCustomerCity(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Cities"],
          true,
          self.loadCustomerCityCallback,
          loading,
          search
        );
      }
    },
    loadCustomerCityCallback: function (callbackData) {
      self.isCustomerCityRecordNotFound = false;
      self.customerCityArr = callbackData.Cities;
      if (self.customerCityArr.length == 0) {
        self.isCustomerCityRecordNotFound = true;
      }
    },
    onSearchCustomerCounty(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Countries"],
          true,
          self.loadCustomerCountyCallback,
          loading,
          search
        );
      }
    },
    loadCustomerCountyCallback: function (callbackData) {
      self.isCustomerCountyRecordNotFound = false;
      self.customerCountyArr = callbackData.Countries;
      if (self.customerCountyArr.length == 0) {
        self.isCustomerCountyRecordNotFound = true;
      }
    },
    //Search For county
    onSearchCountyCode(search, loading){
      if (search.length > 1){
        loading(true);
        self.loadAllData(
          ["TaxRate"],
          true,
          self.loadCountyCode,
          loading,
          search
        );
      }
    },
    loadCountyCode : function(callbackData){
      self.isCountyCodeNotFound = false;
      var taxRates = callbackData.TaxRate;
      self.taxRateArr = [];
      self.countyCodeArr = [];
      taxRates.forEach(function(taxRate){
        self.taxRateArr[taxRate.code] = taxRate.rate;
        self.countyCodeArr.push({
          name: taxRate.name,
          value: taxRate.id,
          text: taxRate.code,
        });
      });
      if  (self.countyCodeArr.length === 0){
        self.isCountyCodeNotFound = true;
      }
    },
    // Buyer
    onSearchBuyer(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Contact"],
          true,
          self.loadBuyerCallback,
          loading,
          search
        );
      }
    },
    loadBuyerCallback: function (callbackData) {
      self.isBuyerRecordNotFound = false;
      self.buyerArr = callbackData.Contact;
      if (self.buyerArr.length == 0) {
        self.isBuyerRecordNotFound = true;
      }
    },
    onSearchBuyerCity(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Cities"],
          true,
          self.loadBuyerCityCallback,
          loading,
          search
        );
      }
    },
    loadBuyerCityCallback: function (callbackData) {
      self.isBuyerCityRecordNotFound = false;
      self.buyerCityArr = callbackData.Cities;
      if (self.buyerCityArr.length == 0) {
        self.isBuyerCityRecordNotFound = true;
      }
    },
    onSearchBuyerCounty(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Countries"],
          true,
          self.loadBuyerCountyCallback,
          loading,
          search
        );
      }
    },
    loadBuyerCountyCallback: function (callbackData) {
      self.isBuyerCountyRecordNotFound = false;
      self.buyerCountyArr = callbackData.Countries;
      if (self.buyerCountyArr.length == 0) {
        self.isBuyerCountyRecordNotFound = true;
      }
    },
    // Deliver To
    onSearchDeliverTo(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Contact"],
          true,
          self.loadDeliverToCallback,
          loading,
          search
        );
      }
    },
    loadDeliverToCallback: function (callbackData) {
      self.isDeliverToRecordNotFound = false;
      self.deliverToArr = callbackData.Contact;
      if (self.deliverToArr.length == 0) {
        self.isDeliverToRecordNotFound = true;
      }
    },
    onSearchDeliverToCity(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Cities"],
          true,
          self.loadDeliverToCityCallback,
          loading,
          search
        );
      }
    },
    loadDeliverToCityCallback: function (callbackData) {
      self.isDeliverToCityRecordNotFound = false;
      self.deliverToCityArr = callbackData.Cities;
      if (self.deliverToCityArr.length == 0) {
        self.isDeliverToCityRecordNotFound = true;
      }
    },
    onSearchDeliverToCounty(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Countries"],
          true,
          self.loadDeliverToCountyCallback,
          loading,
          search
        );
      }
    },
    loadDeliverToCountyCallback: function (callbackData) {
      self.isDeliverToCountyRecordNotFound = false;
      self.deliverToCountyArr = callbackData.Countries;
      if (self.deliverToCountyArr.length == 0) {
        self.isDeliverToCountyRecordNotFound = true;
      }
    },
    onChangeFinanceCompany: function (value) {
      if (
        self.selectFinanceCompany != null &&
        self.selectFinanceCompany.value
      ) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          },
          params: {
            id: self.selectFinanceCompany.value
          }
        };
        HTTP.get("/financegroup/get", config)
          .then(function (response) {
            self.financeCompany = response.data.content.name;
            self.financeCompanyAddress = response.data.content.address;
            if (
              response.data.content.state_id != null &&
              response.data.content.state_id != ""
            ) {
              self.financeCompanyState = {
                value: response.data.content.state_id,
                text: response.data.content.state_name
              };
            }
            if (
              response.data.content.city_name != null &&
              response.data.content.city_name != ""
            ) {
              self.financeCompanyCity = response.data.content.city_name;
            }
            self.financeCompanyZip = response.data.content.zip_code;
            self.financeCompanyComment = response.data.content.comment;
          })
          .catch(function (err) {
            self.catchHandler(err, function () {
            });
          });
      } else {
        self.financeCompany = "";
        self.financeCompanyAddress = "";
        self.financeCompanyCity = "";
        self.financeCompanyState = "";
        self.financeCompanyZip = "";
        self.financeCompanyComment = "";
      }
    },
    onSearchFinanceCompany(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["FinanceGroup"],
          true,
          self.loadFinanceCompanyDataCallback,
          loading,
          search
        );
      }
    },
    loadFinanceCompanyDataCallback: function (callbackData) {
      self.isFinanceCompanyRecordNotFound = false;
      self.selectFinanceCompanyArr = callbackData.FinanceGroup;
      if (self.selectFinanceCompanyArr.length == 0) {
        self.isFinanceCompanyRecordNotFound = true;
      }
    },
    onSearchFinanceCompanyState(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["States"],
          true,
          self.loadFinanceCompanyStateDataCallback,
          loading,
          search
        );
      }
    },
    loadFinanceCompanyStateDataCallback: function (callbackData) {
      self.isFinanceCompanyStateRecordNotFound = false;
      self.financeCompanyStateArr = callbackData.States;
      if (self.financeCompanyStateArr.length == 0) {
        self.isFinanceCompanyStateRecordNotFound = true;
      }
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        if (self.selectAll) {
          for (let i in self.opportunityInfo) {
            self.selectedRows.push(self.opportunityInfo[i].id);
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectAll = false;
        if (self.selectedRows.length == self.opportunityInfo.length) {
          self.selectAll = true;
        }
      }, 100);
    },
    getAssetData(id) {
      var idArr = [];
      idArr.push(id);
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },

        params: {
          id: idArr
        }
      };
      self.showLoader();
      HTTP.get("/asset/getmulty", config)
        .then(function (data) {
          self.hideLoader();
          if (data.status == 200) {
            /* self.assetData = data.data;
                                self.asset = self.assetData; */
            self.assetInfo = data.data.content;
          }
        })
        .catch(function (error) {
          self.hideLoader();
          self.catchHandler(error, function () {
          });
        });
    },
    checkForm: function () {
      var checked = true;

      /* if (self.selectedOpportunityId == 0) {
                    // self.assetError = "Please select Opportunity";
                    self.showSAlert("Please Select Opportunity", 'info', false);
                    if (checked) {
                        checked = false;
                    }
                } */
      self.specificationUrlError = "";
      if (
        self.specificationUrl &&
        !self.websiteUrlValidation(self.specificationUrl)
      ) {
        self.specificationUrlError = "Please enter valid url";
        if (checked) {
          checked = false;
        }
      }
      self.specPagesError = "";
      /* console.log("self.specPages-"+self.specPages + "-Over") */
      if (self.specPages && self.specPages != "") {
        if (!self.validPageNumberInput(self.specPages)) {
          self.specPagesError = "Please enter valid pages string";
          if (checked) {
            checked = false;
          }
        }
        for (var i = 0; i < self.specPages.length; i++) {
          if (i % 2) {
            if (self.specPages[i] != "-" && self.specPages[i] != ",") {
              self.specPagesError =
                'Please use pattern like "1,3,5-7" for "1,3,5,6,7" and do not use space';
              /* console.log(self.specPages[i] + " != , or - "); */
              if (checked) {
                checked = false;
              }
            }
          } else {
            if (self.specPages[i] <= "0") {
              self.specPagesError = "Please enter valid page number";
              if (checked) {
                checked = false;
              }
            }
          }
        }
      }

      self.customerError = "";
      if (self.customerId == null || !self.customerId.value) {
        self.customerError = "Please select Customer";
        if (checked) {
          checked = false;
        }
      }
      self.salePersonError = "";
      if (self.salePersonId == null || !self.salePersonId.value) {
        self.salePersonError = "Please select Sales Person";
        if (checked) {
          checked = false;
        }
      }
      self.cusPhoneNumberError = "";
      if (
        self.cusPhoneNumber &&
        !self.validContactNumber(self.cusPhoneNumber)
      ) {
        self.cusPhoneNumberError = "Please enter valid phone";
        if (checked) {
          checked = false;
        }
      } else if (
        self.cusPhoneNumber &&
        !self.checkContactNoLength(self.cusPhoneNumber)
      ) {
        self.cusPhoneNumberError = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      self.cusTaxRateError = "";
      if (self.cusTaxRate && !self.numberFormateValidation(parseFloat(self.cusTaxRate))) {
        self.cusTaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.cusStateTaxRateError = "";
      if (
        self.cusStateTaxRate &&
        !self.numberFormateValidation(self.cusStateTaxRate)
      ) {
        self.cusStateTaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.cusCountyTaxRateError = "";
      if (
        self.cusCountyTaxRate &&
        !self.numberFormateValidation(self.cusCountyTaxRate)
      ) {
        self.cusCountyTaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.cusCityTaxRateError = "";
      if (
        self.cusCityTaxRate &&
        !self.numberFormateValidation(self.cusCityTaxRate)
      ) {
        self.cusCityTaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.cusOtherTaxRateError = "";
      if (
        self.cusOtherTaxRate &&
        !self.numberFormateValidation(self.cusOtherTaxRate)
      ) {
        self.cusOtherTaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.cusOther2TaxRateError = "";
      if (
        self.cusOther2TaxRate &&
        !self.numberFormateValidation(self.cusOther2TaxRate)
      ) {
        self.cusOther2TaxRateError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.customerEmailError = "";
      if (self.customerEmail && !self.validEmail(self.customerEmail)) {
        self.customerEmailError = "Please enter valid Email";
        if (checked) {
          checked = false;
        }
      }
      self.cusCountySurtaxError = "";
      if (
        self.cusCountySurtax &&
        !self.numberFormateValidation(self.cusCountySurtax)
      ) {
        self.cusCountySurtaxError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.deliverToPhoneNumberError = "";
      if (
        self.deliverToPhoneNumber &&
        !self.validContactNumber(self.deliverToPhoneNumber)
      ) {
        self.deliverToPhoneNumberError = "Please enter valid phone";
        if (checked) {
          checked = false;
        }
      } else if (
        self.deliverToPhoneNumber &&
        !self.checkContactNoLength(self.deliverToPhoneNumber)
      ) {
        self.deliverToPhoneNumberError = "Max Length is 13";
        if (checked) {
          checked = false;
        }
      }
      self.salesPriceError = "";
      if (self.salesPrice && !self.numberFormateValidation(self.salesPrice)) {
        self.salesPriceError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.downpaymentAmtError = "";
      if (
        self.downpaymentAmt &&
        !self.numberFormateValidation(self.downpaymentAmt)
      ) {
        self.downpaymentAmtError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.tradeAllownceError = "";
      if (
        self.tradeAllownce &&
        !self.numberFormateValidation(self.tradeAllownce)
      ) {
        self.tradeAllownceError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      /* self.totalFeesError = "";
                if(self.totalFees && !self.numberFormateValidation(self.totalFees)) {
                    self.totalFeesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                }
      /* self.totalSalesTaxError = "";
                if(self.totalSalesTax && !self.numberFormateValidation(self.totalSalesTax)) {
                    self.totalSalesTaxError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      self.totalError = "";
      if (self.total && !self.numberFormateValidation(self.total)) {
        self.totalError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      /* self.warrantiesError = "";
                if(self.warranties && !self.numberFormateValidation(self.warranties)) {
                    self.warrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* self.insuranceError = "";
                if(self.insurance && !self.numberFormateValidation(self.insurance)) {
                    self.insuranceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* self.totalWarrantiesError = "";
                if(self.totalWarranties && !self.numberFormateValidation(self.totalWarranties)) {
                    self.totalWarrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      self.bonus_amount_error = "";
      if (
        self.bonus_amount &&
        !self.numberFormateValidation(self.bonus_amount)
      ) {
        self.bonus_amount_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.extra_conquest_error = "";
      if (
        self.extra_conquest &&
        !self.numberFormateValidation(self.extra_conquest)
      ) {
        self.extra_conquest_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.gross_profit_error = "";
      if (
        self.gross_profit &&
        !self.numberFormateValidation(self.gross_profit)
      ) {
        self.gross_profit_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.rego_error = "";
      if (self.rego && !self.numberFormateValidation(self.rego)) {
        self.rego_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.ctp_error = "";
      if (self.ctp && !self.numberFormateValidation(self.ctp)) {
        self.ctp_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.trade_value_inc_gst_error = "";
      if (
        self.trade_value_inc_gst &&
        !self.numberFormateValidation(self.trade_value_inc_gst)
      ) {
        self.trade_value_inc_gst_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.over_allowance_inc_gst_error = "";
      if (
        self.over_allowance_inc_gst &&
        !self.numberFormateValidation(self.over_allowance_inc_gst)
      ) {
        self.over_allowance_inc_gst_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.trade_payout_error = "";
      if (
        self.trade_payout &&
        !self.numberFormateValidation(self.trade_payout)
      ) {
        self.trade_payout_error = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      // if (self.dealStatusId.value == 8) {
      //   if (self.lineItemsArr && self.lineItemsArr.length > 0) {
      //     for (var index = 0; index < self.lineItemsArr.length; index++) {
      //       var lineItemObject = self.lineItemsArr[index];
      //       lineItemObject.supplierIdError = "";
      //       lineItemObject.buildUpError = "";
      //       lineItemObject.priceGSTError = "";
      //       if (
      //         lineItemObject.supplierId == null ||
      //         !lineItemObject.supplierId.value
      //       ) {
      //         lineItemObject.supplierIdError = "Please select supplier";
      //         if (checked) {
      //           checked = false;
      //         }
      //       }
      //       if (!lineItemObject.buildUp) {
      //         lineItemObject.buildUpError = "Please select build up";
      //         if (checked) {
      //           checked = false;
      //         }
      //       }
      //       if (!lineItemObject.priceGST) {
      //         lineItemObject.priceGSTError = "Please select price ex. GST";
      //         if (checked) {
      //           checked = false;
      //         }
      //       } else if (
      //         lineItemObject.priceGST &&
      //         !self.numberFormateValidation(lineItemObject.priceGST)
      //       ) {
      //         lineItemObject.priceGSTError = "Only Numeric Value Allow";
      //         if (checked) {
      //           checked = false;
      //         }
      //       }
      //     }
      //   }
      // }

      self.dueOnDeliveryError = "";
      if (
        self.dueOnDelivery &&
        !self.numberFormateValidation(self.dueOnDelivery)
      ) {
        self.dueOnDeliveryError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }

      self.cashBackError = "";
      if (
        self.cashBack &&
        !self.numberFormateValidation(self.cashBack)
      ) {
        self.cashBackError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }

      self.cashBackError = "";
      if (
        self.cashBack &&
        !self.numberFormateValidation(self.cashBack)
      ) {
        self.cashBackError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }

      self.warrantyTaxError = "";
      if (
        self.warrantyTax &&
        !self.numberFormateValidation(self.warrantyTax)
      ) {
        self.warrantyTaxError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    openBookDealLink: function (flag) {
      self.$router.push(
        "/quotation/?id=" + self.$route.query.id + "&deal_id=" + self.dealId
      );
    },
    returnDealFormData: function () {
      var data = {
        inventory_id: self.assetId,
        make_id: self.makeId.value,
        model_id: self.modelId.value,
        opportunity_id: self.selectedOpportunityId,
        add_to_forecast: self.add_to_forecast,
        deal_no: self.dealNo,
        location_id: self.locationId.value,
        deal_status_id: self.dealStatusId.value,
        contract_number: self.contractNumber,
        contract_date: self.contractDate,
        ros_number: self.rosNumber,
        saleperson_id: self.salePersonId.value,
        cus_phone_number: self.cusPhoneNumber,
        customer_id: self.customerId.value,
        individual: self.individual,
        individual_value: self.individualValue,
        cus_fax: self.cusFax,
        cus_address: self.cusAddress,
        cus_cell: self.cusCell,
        cus_address_2: self.cusAddress2,
        cus_sin: self.cusSIN,
        cus_city_id: self.customerCityId.value,
        cus_city_2: self.cusCity2,
        cus_city_3: self.cusCity3,
        cus_tax_exemption_number: self.cusTaxExemptionNumber,
        cus_alt_city_name: self.cusAltCityName,
        cus_address_type: self.cusAddressType,
        customer_county_id: self.customerCountyId.value,
        cus_tax_rate: self.cusTaxRate,
        cus_sales_tax: self.cusSalesTax,
        cus_state_tax_rate: self.cusStateTaxRate,
        cus_alt_county_name: self.cusAltCountyName,
        cus_county_tax_rate: self.cusCountyTaxRate,
        cus_city_tax_rate: self.cusCityTaxRate,
        cus_other_tax_rate: self.cusOtherTaxRate,
        cus_other_2_tax_rate: self.cusOther2TaxRate,
        customer_email: self.customerEmail,
        cus_county_surtax: self.cusCountySurtax,
        cus_county_code: self.cusCountyCode,
        // CO-Buyer
        buyer_number: self.buyerNumber,
        buyer_id: self.buyerId.value,
        buyer_address: self.buyerAddress,
        buyer_address_2: self.buyerAddress2,
        buyer_city_id: self.buyerCityId.value,
        buyer_city_2: self.buyerCity2,
        buyer_city_3: self.buyerCity3,
        // Deliver-To
        deliver_to_phone_number: self.deliverToPhoneNumber,
        deliver_to_id: self.deliverToId.value,
        deliver_to_address: self.deliverToAddress,
        deliver_to_address_2: self.deliverToAddress2,
        deliver_to_city_id: self.deliverToCityId.value,
        deliver_to_city_2: self.deliverToCity2,
        deliver_to_city_3: self.deliverToCity3,
        internal_comments: self.internalComments,
        comments: self.comments,
        comments_2: self.comments2,
        comments_2: self.comments2,
        show_on_buyers_order: self.showOnBuyersOrder,
        // Cash Detail
        sales_price: self.salesPrice,
        downpaymentAmt: self.downpaymentAmt,
        trade_allownce: self.tradeAllownce,
        total_fees: self.totalFees,
        total_sales_tax: self.totalSalesTax,
        total: self.total,
        warranties: self.warranties,
        insurance: self.insurance,
        total_warranties: self.totalWarranties,
        line_items: self.lineItemsArr,
        bonus_amount: self.bonus_amount,
        extra_conquest: self.extra_conquest,
        gross_profit: self.gross_profit,
        stamp_duty_type: self.stampDutyType.value,
        rego: self.rego,
        ctp: self.ctp,
        trade_detail: self.trade_detail,
        trade_value_inc_gst: self.trade_value_inc_gst,
        over_allowance_inc_gst: self.over_allowance_inc_gst,
        trade_payout: self.trade_payout,
        service_plan: self.servicePlan,
        vehicle_warranty: self.vehicleWarranty,
        roadside_assistance: self.roadsideAssistance,
        specification_url: self.specificationUrl,
        spec_pages: self.specPages,
        //NEW FIELDS STARTED
        excede_id_hash: self.excedeIdHash,
        invoice_date: self.invoiceDate,
        invoice_hash: self.invoiceHash,
        payment_type: self.paymentType,
        order_type: self.orderType,
        downpayment_payment_type: self.downpaymentPaymentType,
        downpayment_desc: self.downpaymentDesc,
        cash_back: self.cashBack,
        cash_back_payment_type: self.cashBackPaymentType,
        cash_back_desc: self.cashBackDesc,
        due_on_delivery: self.dueOnDelivery,
        due_on_delivery_payment_type: self.dueOnDeliveryPaymentType,
        due_on_delivery_desc: self.dueOnDeliveryDesc,
        physical_damage_price: self.physicalDamagePrice,
        physical_damage_cost: self.physicalDamageCost,
        non_trucking_liability_price: self.nonTruckingLiabilityPrice,
        non_trucking_liability_cost: self.nonTruckingLiabilityCost,
        gap_insurance_price: self.gapInsurancePrice,
        gap_insurance_cost: self.gapInsuranceCost,
        disability_price: self.disabilityPrice,
        disability_cost: self.disabilityCost,
        credit_life_price: self.creditLifePrice,
        credit_life_cost: self.creditLifeCost,
        cvra_price: self.cvraPrice,
        cvra_cost: self.cvraCost,
        annuale_per_rate: self.annualePerRate,
        dealer_rate: self.dealerRate,
        finance_income: self.financeIncome,
        finance_term: self.financeTerm,
        days_to_first_payment: self.daysToFirstPayment,
        days_to_first_payment_date: self.daysToFirstPaymentDate,
        finance_payment: self.financePayment,
        sales_subtotal: self.salesSubtotal,
        total_financed: self.totalFinanced,
        finance_charge: self.financeCharge,
        deferred_price: self.deferredPrice,
        select_finance_company: self.selectFinanceCompany,
        finance_company: self.financeCompany,
        finance_company_address: self.financeCompanyAddress,
        finance_company_city: self.financeCompanyCity,
        finance_company_state: self.financeCompanyState,
        finance_company_zip: self.financeCompanyZip,
        finance_company_comment: self.financeCompanyComment,
        finance_ccompany_alt: self.financeCompanyAlt,
        finance_company_alt_address: self.financeCompanyAltAddress,
        finance_company_alt_city: self.financeCompanyAltCity,
        finance_company_alt_state: self.financeCompanyAltState,
        finance_company_alt_zip: self.financeCompanyAltZip
      };
      return data;
    },
    saveBookDeal: function (is_auto, status) {
      is_auto =
        is_auto != null && is_auto != "undefined" && is_auto != 0 ? 1 : 0;
      var stat = "";
      setTimeout(() => {
        if (self.checkForm()) {
          stat = self.dealStatusId.value =
            status != null && status != "undefined" && status != 0
              ? status
              : self.dealStatusId.value;
          // console.log("FORMCHECK DONE saveBookDeal");
          // return false;
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = self.returnDealFormData();
          HTTP.post("/deal/add/update", data, config)
            .then(function (response) {
              // console.log("status : " + stat);

              // if (stat != null && stat != "undefined" && stat != 0) {
              //   self.getdeal(self.dealId);
              // }
              if (is_auto == 0) {
                self.showSAlert(response.data.message, "success", false);
                if (self.dealId != response.data.content.id) {
                  self.dealId = response.data.content.id;
                  // self.openBookDealLink();
                }
                // self.getDealData();
                // $("#dealModal").modal("hide");
                // if (
                //   response.data.content.opportunity_id != null &&
                //   response.data.content.opportunity_id != "undefined" &&
                //   response.data.content.opportunity_id != 0
                // ) {
                // } else {
                //   self.addOpportunity('saveBookDeal');
                // }
              }
              // self.clearDataOnSaveAndContinue();
              // self.getAvailableDeals(self.$route.query.id);
            })
            .catch(function (err) {
              // console.log(err);
              self.catchHandler(err, function () {
              });
            });
        }
      }, 200);
    },
    printQuotation: function () {
      // console.log("printQuotation");
      setTimeout(() => {
        if (self.checkForm()) {
          $("#printQuotBtn").html("Preparing PDF...");
          $("#printQuotBtn").prop("disabled", true);
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = {
            inventory_id: self.assetId,
            make_id: self.makeId.value,
            model_id: self.modelId.value,
            opportunity_id: self.selectedOpportunityId,
            add_to_forecast: self.add_to_forecast,
            deal_no: self.dealNo,
            location_id: self.locationId.value,
            deal_status_id: self.dealStatusId.value,
            contract_number: self.contractNumber,
            contract_date: self.contractDate,
            ros_number: self.rosNumber,
            saleperson_id: self.salePersonId.value,
            cus_phone_number: self.cusPhoneNumber,
            customer_id: self.customerId.value,
            individual: self.individual,
            individual_value: self.individualValue,
            cus_fax: self.cusFax,
            cus_address: self.cusAddress,
            cus_cell: self.cusCell,
            cus_address_2: self.cusAddress2,
            cus_sin: self.cusSIN,
            cus_city_id: self.customerCityId.value,
            cus_city_2: self.cusCity2,
            cus_city_3: self.cusCity3,
            cus_tax_exemption_number: self.cusTaxExemptionNumber,
            cus_alt_city_name: self.cusAltCityName,
            cus_address_type: self.cusAddressType,
            customer_county_id: self.customerCountyId.value,
            cus_tax_rate: self.cusTaxRate,
            cus_sales_tax: self.cusSalesTax,
            cus_state_tax_rate: self.cusStateTaxRate,
            cus_alt_county_name: self.cusAltCountyName,
            cus_county_tax_rate: self.cusCountyTaxRate,
            cus_city_tax_rate: self.cusCityTaxRate,
            cus_other_tax_rate: self.cusOtherTaxRate,
            cus_other_2_tax_rate: self.cusOther2TaxRate,
            customer_email: self.customerEmail,
            cus_county_surtax: self.cusCountySurtax,
            cus_county_code: self.cusCountyCode,
            /* Cash Detail */
            sales_price: self.salesPrice,
            downpaymentAmt: self.downpaymentAmt,
            trade_allownce: self.tradeAllownce,
            total_fees: self.totalFees,
            total_sales_tax: self.totalSalesTax,
            total: self.total,
            warranties: self.warranties,
            insurance: self.insurance,
            total_warranties: self.totalWarranties,
            line_items: self.lineItemsArr,
            bonus_amount: self.bonus_amount,
            extra_conquest: self.extra_conquest,
            gross_profit: self.gross_profit,
            stamp_duty_type: self.stampDutyType.value,
            rego: self.rego,
            ctp: self.ctp,
            trade_detail: self.trade_detail,
            trade_value_inc_gst: self.trade_value_inc_gst,
            over_allowance_inc_gst: self.over_allowance_inc_gst,
            trade_payout: self.trade_payout,
            dealer_net: self.dealer_net,
            conquest: self.conquest,
            total_cq_approved: self.total_cq_approved,
            sub_total1: self.sub_total1,
            overallowance: self.overallowance,
            pre_delivery: self.pre_delivery,
            gross_profit_percentage: self.gross_profit_percentage,
            cab_chassis: self.cab_chassis,
            build_up_total: self.build_up_total,
            sub_total_ex_gst: self.sub_total_ex_gst,
            total_gst: self.total_gst,
            sub_total2: self.sub_total2,
            cylinders: self.cylinders,
            stamp_duty: self.stamp_duty,
            trade_equity: self.trade_equity,
            change_over: self.change_over,
            service_plan: self.servicePlan,
            vehicle_warranty: self.vehicleWarranty,
            roadside_assistance: self.roadsideAssistance,
            specification_url: self.specificationUrl,
            spec_pages: self.specPages
          };
          HTTP.post("/deal/print/quotation", data, config)
            .then(function (response) {
              if (response.status == 200) {
                $("#printQuotBtn").html("Print Quotation");
                $("#printQuotBtn").prop("disabled", false);
                // self.downloadFile(response.data.content);
                // window.location.href = response.data.content;
                window.open(response.data.content, "_blank").focus();
              }
              if (response.status == 201) {
                $("#printQuotBtn").html("Print Quotation");
                $("#printQuotBtn").prop("disabled", false);
                self.showSAlert(response.data.content[0], "error", false);
              }
            })
            .catch(function (err) {
              $("#printQuotBtn").html("Print Quotation");
              $("#printQuotBtn").prop("disabled", false);
              self.catchHandler(err, function () {
              });
            });
        }
      }, 200);
    },
    
    loadDealData: function () {
      /* console.log(JSON.stringify(self.dealData)); */
      self.conditionId = {text: "New", value: "1"};
      self.add_to_forecast = self.dealData.is_forecast == "yes" ? true : false;
      self.dealNo = self.dealData.deal_no;
      self.locationId =
        self.dealData.department_id != null &&
        self.dealData.department_id != "undefined" &&
        self.dealData.department_id != 0
          ? {
            text: self.dealData.department,
            value: self.dealData.department_id
          }
          : "";
      self.dealStatusId =
        self.dealData.deal_status_id != null &&
        self.dealData.deal_status_id != "undefined" &&
        self.dealData.deal_status_id != 0
          ? {
            text: self.dealData.deal_status_name,
            value: self.dealData.deal_status_id
          }
          : "";
      self.contractNumber = self.dealData.contract_no;
      self.contractDate = self.dealData.contract_date;
      self.rosNumber = self.dealData.ros_no;
      self.salePersonId =
        self.dealData.salesperson_id != null &&
        self.dealData.salesperson_id != "undefined" &&
        self.dealData.salesperson_id != 0
          ? {
            text: self.dealData.sales_person,
            value: self.dealData.salesperson_id
          }
          : "";
      self.cusPhoneNumber = self.dealData.cus_phone;
      self.customerId =
        self.dealData.contact_id != null &&
        self.dealData.contact_id != "undefined" &&
        self.dealData.contact_id != 0
          ? {
            text: self.dealData.customer_name,
            value: self.dealData.contact_id
          }
          : "";
      self.individual = self.dealData.is_individual == "yes" ? true : false;
      self.individualValue = self.dealData.individual;
      self.cusFax = self.dealData.cus_fax;
      self.cusAddress = self.dealData.cus_address;
      self.cusCell = self.dealData.cus_cell;
      self.cusAddress2 = self.dealData.cus_address2;
      self.cusSIN = self.dealData.cus_sin;
      self.customerCityId =
        self.dealData.customer_city_id != null &&
        self.dealData.customer_city_id != "undefined" &&
        self.dealData.customer_city_id != 0
          ? {
            text: self.dealData.customer_city_name,
            value: self.dealData.customer_city_id
          }
          : "";
      self.cusCity2 = self.dealData.city2;
      self.cusCity3 = self.dealData.city3;
      self.cusTaxExemptionNumber = self.dealData.cus_tax_exemption_number;
      self.cusAltCityName = self.dealData.alt_city;
      self.cusAddressType = self.dealData.address_type;
      self.customerCountyId =
        self.dealData.customer_country_id != null &&
        self.dealData.customer_country_id != "undefined" &&
        self.dealData.customer_country_id != 0
          ? {
            text: self.dealData.customer_country_name,
            value: self.dealData.customer_country_id,
          }
          : "";
      // self.cusTaxRate = self.dealData.tax_rate;
      self.cusSalesTax = self.dealData.apply_sales_tax == "yes" ? true : false;
      self.cusStateTaxRate = !(self.dealData.state_tax_rate == '' || self.dealData.state_tax_rate == undefined) ? (self.dealData.state_tax_rate) : (0);
      self.cusAltCountyName = self.dealData.alt_county;
      self.cusCountyTaxRate = self.dealData.county_tax_rate;
      self.cusCityTaxRate = self.dealData.city_tax_rate;
      self.cusOtherTaxRate = self.dealData.other_tax_rate;
      self.cusOther2TaxRate = self.dealData.other2_tax_rate;
      self.customerEmail = self.dealData.cus_email;
      self.cusCountySurtax = self.dealData.county_surtax;
      self.cusCountyCode = self.dealData.county_code;
      // CO-Buyer
      self.buyerNumber = self.dealData.co_buyer;
      self.buyerId =
        self.dealData.co_buyer_contact_id != null &&
        self.dealData.co_buyer_contact_id != "undefined" &&
        self.dealData.co_buyer_contact_id != 0
          ? {
            text: self.dealData.cobuyer_name,
            value: self.dealData.co_buyer_contact_id
          }
          : "";
      self.buyerAddress = self.dealData.co_buyer_cus_address;
      self.buyerAddress2 = self.dealData.co_buyer_cus_address2;
      self.buyerCityId =
        self.dealData.cobuyer_city_id != null &&
        self.dealData.cobuyer_city_id != "undefined" &&
        self.dealData.cobuyer_city_id != 0
          ? {
            text: self.dealData.cobuyer_city_name,
            value: self.dealData.cobuyer_city_id
          }
          : "";
      self.buyerCity2 = self.dealData.co_buyer_city2;
      self.buyerCity3 = self.dealData.co_buyer_city3;
      // Deliver-To
      self.deliverToPhoneNumber = self.dealData.del_to_phone;
      self.deliverToId =
        self.dealData.del_to_contact_id != null &&
        self.dealData.del_to_contact_id != "undefined" &&
        self.dealData.del_to_contact_id != 0
          ? {
            text: self.dealData.dealer_name,
            value: self.dealData.del_to_contact_id
          }
          : "";
      self.deliverToAddress = self.dealData.del_to_cus_address;
      self.deliverToAddress2 = self.dealData.del_to_cus_address2;
      self.deliverToCityId =
        self.dealData.dealer_city_id != null &&
        self.dealData.dealer_city_id != "undefined" &&
        self.dealData.dealer_city_id != 0
          ? {
            text: self.dealData.dealer_city_name,
            value: self.dealData.dealer_city_id
          }
          : "";
      self.deliverToCity2 = self.dealData.del_to_city2;
      self.deliverToCity3 = self.dealData.del_to_city3;
      self.internalComments = self.dealData.internal_comments;
      self.comments = self.dealData.comments;
      self.comments2 = self.dealData.comments2;
      self.showOnBuyersOrder =
        self.dealData.show_on_buyer_order == "yes" ? true : false;
      // Cash Detail
      // self.salesPrice = self.convertToNum(self.dealData.sales_price, 2);
      // self.downpaymentAmt = self.convertToNum(self.dealData.down_payment, 2);
      // self.tradeAllownce = self.convertToNum(self.dealData.trade_allowance, 2);
      // self.totalFees = self.convertToNum(self.dealData.total_fees, 2);
      // self.totalSalesTax = self.convertToNum(self.dealData.total_sales_tax, 2);
      // self.total = self.convertToNum(self.dealData.total, 2);
      // self.warranties = self.convertToNum(self.dealData.warranties, 2);
      // self.insurance = self.convertToNum(self.dealData.insurance, 2);
      // self.totalWarranties = self.convertToNum(
      //   self.dealData.total_plus_wattanties,
      //   2
      // );
      self.bonus_amount = self.convertToNum(self.dealData.bonus, 2);
      self.extra_conquest = self.convertToNum(self.dealData.extra_conquest, 2);
      self.gross_profit = self.convertToNum(self.dealData.gross_profit, 2);

      self.stampDutyType = {text: "New/Demo", value: "new/demo"};
      if (self.dealData.stamp_duty_type == "exempt") {
        self.stampDutyType = {text: "Exempt", value: "exempt"};
      }

      self.rego = self.convertToNum(self.dealData.rego, 2);
      self.ctp = self.convertToNum(self.dealData.ctp, 2);
      self.trade_detail = self.dealData.trade_details;
      self.trade_value_inc_gst = self.convertToNum(
        self.dealData.approx_trade_value,
        2
      );
      self.over_allowance_inc_gst = self.convertToNum(
        self.dealData.over_allowance,
        2
      );
      self.trade_payout = self.convertToNum(self.dealData.trade_payout, 2);
      /* console.log(JSON.stringify(self.dealData.deal_inventory_data)); */
      if (self.dealData.deal_inventory_data.length == 1) {
        self.makeId = {
          text: self.dealData.deal_inventory_data[0].make,
          value: self.dealData.deal_inventory_data[0].make_id
        };
        self.modelId = {
          text: self.dealData.deal_inventory_data[0].model_display,
          value: self.dealData.deal_inventory_data[0].model_id
        };
        self.servicePlan = self.dealData.deal_inventory_data[0].service_plan;
        self.vehicleWarranty =
          self.dealData.deal_inventory_data[0].vehicle_warranty;
        self.roadsideAssistance =
          self.dealData.deal_inventory_data[0].roadside_assistance;
        self.specificationUrl =
          self.dealData.deal_inventory_data[0].specification_pdf_url;
        self.specPages =
          self.dealData.deal_inventory_data[0].specification_pdf_pages;
      } else {
        self.makeId = 0;
        self.modelId = 0;
        self.servicePlan = "";
        self.vehicleWarranty = "";
        self.roadsideAssistance = "";
        self.specificationUrl = "";
        self.specPages = "";
      }
      self.excedeIdHash = self.dealData.excede_id;
      self.invoiceDate = self.dealData.invoice_date;
      self.invoiceHash = self.dealData.invoice_number;
      self.paymentType =
        self.dealData.payment_type_id != null &&
        self.dealData.payment_type_id != "undefined" &&
        self.dealData.payment_type_id != 0
          ? {
            text: self.dealData.payment_type_name,
            value: self.dealData.payment_type_id
          }
          : "";
      self.orderType =
        self.dealData.order_type_id != null &&
        self.dealData.order_type_id != "undefined" &&
        self.dealData.order_type_id != 0
          ? {
            text: self.dealData.order_type_name,
            value: self.dealData.order_type_id
          }
          : "";
      self.downpaymentPaymentType =
        self.dealData.down_payment_type_id != null &&
        self.dealData.down_payment_type_id != "undefined" &&
        self.dealData.down_payment_type_id != 0
          ? {
            text: self.dealData.down_payment_type_name,
            value: self.dealData.down_payment_type_id
          }
          : "";
      self.downpaymentDesc = self.dealData.downpayment_desc;
      self.cashBack = self.dealData.cash_back;
      self.cashBackPaymentType =
        self.dealData.cash_back_type_id != null &&
        self.dealData.cash_back_type_id != "undefined" &&
        self.dealData.cash_back_type_id != 0
          ? {
            text: self.dealData.cashback_payment_type_name,
            value: self.dealData.cash_back_type_id
          }
          : "";
      self.cashBackDesc = self.dealData.cash_back_desc;
      self.dueOnDelivery = self.dealData.due_payment;
      self.dueOnDeliveryPaymentType =
        self.dealData.due_payment_id != null &&
        self.dealData.due_payment_id != "undefined" &&
        self.dealData.due_payment_id != 0
          ? {
            text: self.dealData.due_payment_type_name,
            value: self.dealData.due_payment_id
          }
          : "";
      self.dueOnDeliveryDesc = self.dealData.due_on_delivery_desc;
      self.physicalDamagePrice = self.dealData.physical_damage_price;
      self.physicalDamageCost = self.dealData.physical_damage_cost;
      self.nonTruckingLiabilityPrice =
        self.dealData.non_trucking_liability_price;
      self.nonTruckingLiabilityCost = self.dealData.non_trucking_liability_cost;
      self.gapInsurancePrice = self.dealData.gap_insurance_price;
      self.gapInsuranceCost = self.dealData.gap_insurance_cost;
      self.disabilityPrice = self.dealData.disability_price;
      self.disabilityCost = self.dealData.disability_cost;
      self.creditLifePrice = self.dealData.credit_life_price;
      self.creditLifeCost = self.dealData.credit_life_cost;
      self.cvraPrice = self.dealData.cvra_price;
      self.cvraCost = self.dealData.cvra_cost;
      self.annualePerRate = self.dealData.finance_annuale_per_rate;
      self.dealerRate = self.dealData.dealer_rate;
      self.financeIncome = self.dealData.dealer_finance_income;
      self.financeTerm = self.dealData.dealer_term;
      self.daysToFirstPayment = self.dealData.finance_days_to_first_payment;
      self.daysToFirstPaymentDate = self.dealData.finance_first_payment_date;
      self.financePayment = self.dealData.finance_payment;
      self.salesSubtotal = self.dealData.finance_sales_subtotal;
      self.totalFinanced = self.dealData.finance_total;
      self.financeCharge = self.dealData.finance_charge;
      self.deferredPrice = self.dealData.finance_deferred_price;
      self.selectFinanceCompany = self.dealData.finance_company;
      self.financeCompany = self.dealData.finance_company_name;
      self.financeCompanyAddress = self.dealData.finance_company_address;
      self.financeCompanyCity = self.dealData.finance_company_city;
      self.financeCompanyState =
        self.dealData.finance_company_state != null &&
        self.dealData.finance_company_state != "undefined" &&
        self.dealData.finance_company_state != 0
          ? {
            text: self.dealData.finance_company_state_name,
            value: self.dealData.finance_company_state
          }
          : "";
      self.financeCompanyZip = self.dealData.finance_company_zip;
      self.financeCompanyComment = self.dealData.finance_company_comment;
      self.financeCompanyAlt = self.dealData.finance_company_alt_name;
      self.financeCompanyAltAddress = self.dealData.finance_company_alt_address;
      self.financeCompanyAltState =
        self.dealData.finance_company_alt_state != null &&
        self.dealData.finance_company_alt_state != "undefined" &&
        self.dealData.finance_company_alt_state != 0
          ? {
            text: self.dealData.finance_company_alt_state_name,
            value: self.dealData.finance_company_alt_state
          }
          : "";
      self.financeCompanyAltCity = self.dealData.finance_company_alt_city;
      self.financeCompanyAltZip = self.dealData.finance_company_alt_zip;
    },

    createDealWithOpportunity: function () {
      self.showLoader();
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        }
      };
      var data = new FormData();
      data.append("opportunity_id", self.opportunityId);
      HTTP.post("/deal-create-with-opportunity", data, config)
        .then(function (response) {
          var content = response.data.content;
          self.dealId = content.id;
          localStorage.setItem('dealId', self.dealId);
          self.hideLoader();
        })
        .catch(function (err) {
          self.hideLoader();
          self.catchHandler(err, function () {
          });
        });
    },
    getAssetDataFromId: function () {
      if (self.assetId !== null && self.assetId !== undefined && self.assetId !== 0) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token")
          }
        };
        var data = new FormData();
        // data.append('id', self.dealInventoryMapperId);
        data.append('id', self.dealId);
        data.append('edit', false);
        //getting data from the deal_inventory_mapper table
        HTTP.post("get/deal-inventory-mapper-data", data, config)
          .then(function (response) {
            self.salesPrice = response.data.totalSalesPrice;
            self.tempRetailPrice = response.data.totalSalesPrice;
            self.totalFees = response.data.calculation.total_fees;
            self.totalSalesTax = response.data.calculation.total_sales_tax;
            self.warranties = response.data.calculation.warranties;
            self.warrantyFromVehicleWorksheet = response.data.calculation.warranty_from_vehicle_worksheet;
            self.assetArr = response.data.content; // todo: make asset return only the data that is required?
            if (self.assetArr.length > 0) {
              self.assetArrHasValue = true;
            }
            self.calculateQuot();
          })
          .catch(function (err) {
            self.catchHandler(err, function () {
            });
            self.hideLoader();
          });
      }

    },
    preFillTaxInfo: function (search){
      if(!self.isCountyEdit && typeof self.customerCountyId === "object" && self.customerCountyId.text) {
        try {
          // console.log('self.taxRateArr=>',self.taxRateArr, self.customerCountyId, self.salesPrice);
          // console.log(self.taxRateArr[self.customerCountyId.text])
          // self.cusTaxRate = (parseFloat(self.cusTaxRate) > 0) ? (parseFloat(self.cusTaxRate)) : (self.taxRateArr[self.customerCountyId.text] * 100).toFixed(2);
          const taxRate = parseFloat(self.taxRateArr[self.customerCountyId.text])*100;
          const rate = taxRate.toString();
          // console.log('TaxRate>>', taxRate,self.taxRateArr[self.customerCountyId.text],parseFloat(self.taxRateArr[self.customerCountyId.text]), parseFloat(self.taxRateArr[self.customerCountyId.text])*100,self.taxRateArr[self.customerCountyId.text]*100);
          /*Not required as all the precision is to be taken 4
            if(rate.split(".")[1] !== undefined) {
              self.rateMask.precision = rate.split(".")[1].length;
            } else {
              self.rateMask.precision = 2;
            }
          */
          
          // console.log('Prcecision>>', typeof (self.taxRateArr[self.customerCountyId.text]), typeof taxRate,rate, rate.split("."), precision);
          self.cusTaxRate = taxRate;
          // self.cusTaxRate = (self.cusTaxRate == 'NaN' ? 0 : self.cusTaxRate);
          // console.log('taxRate=>',self.taxRateArr[self.customerCountyId.text]);
          self.taxRate = self.taxRate != 0 ? self.taxRate : self.taxRateArr[self.customerCountyId.text];
          self.cusStateTaxRate = self.taxRateArr[self.customerCountyId.text] ? taxRate : (0);
          // console.log('cusTaxRate>>>', self.cusTaxRate);
          // var salesTaxAmount = self.taxRateArr[self.customerCountyId.text] * self.totalAmountFromVehicleWorkSheet;
          // var salesPriceAmount = (parseInt(self.totalAmountFromVehicleWorkSheet) + salesTaxAmount).toFixed(2);
          // var salesTaxAmount = self.taxRateArr[self.customerCountyId.text] * self.tempRetailPrice;
          // var salesPriceAmount = (parseInt(self.tempRetailPrice) + salesTaxAmount).toFixed(2);
          // self.salesPrice = isNaN(salesPriceAmount) ? 0 : salesPriceAmount;
        } catch (error) {
          console.log('error=>',error);
        }
      } else {
        self.isCountyEdit = false;
        self.cusTaxRate = (self.cusTaxRate == null ? 0 : self.cusTaxRate);
        self.taxRate = self.cusTaxRate;
        // var salesTaxAmount = self.cusTaxRate * self.totalAmountFromVehicleWorkSheet;
        // var salesPriceAmount = (parseInt(self.totalAmountFromVehicleWorkSheet) + salesTaxAmount).toFixed(2);
        // var salesTaxAmount = parseFloat(self.cusTaxRate) * self.tempRetailPrice;
        // var salesPriceAmount = (parseInt(self.tempRetailPrice) + salesTaxAmount).toFixed(2);
        // self.salesPrice = isNaN(salesPriceAmount) ? 0 : salesPriceAmount;
      }
    },

    returnFormDataForSave: function (){
      try {
        var cashData = {};
        if(self.tab == 'cash') {
          cashData = {
            id: self.cashId == 0 ? 0 : self.cashId,
            sales_price:  parseInt(self.salesPrice).toFixed(2),
            down_payment: parseInt(self.downpaymentAmt).toFixed(2),
            trade_allowance_inc_gst: parseInt(self.tradeAllownce).toFixed(2),
            total_fees: parseInt(self.totalFees).toFixed(2),
            total_sales_tax: parseInt(self.totalSalesTax).toFixed(2),
            insurance: parseInt(self.insurance).toFixed(2),
            total_plus_warranties: parseInt(self.totalPlusWarranties).toFixed(2),
            financial_company_id: self.cashFinanceCompanyId !== 0 ? self.cashFinanceCompanyId.value : null
          }
        }

        var formData = {
          lease_data: self.tab == 'lease' ? self.leaseData : [],
          finance_data: self.tab == 'finance' ? self.financeData : [],
          cash_data: self.tab == 'cash' ? cashData : [],
          old_tab: self.oldTab,
          old_tab_id: self.oldTabId,
          dealsData: {
            location_id: (!!self.locationId ? self.locationId.value : null),
            salesperson_id: (!!self.salePersonId ? self.salePersonId.value : null),
            city_id: (!!self.customerCityId ? self.customerCityId.value : null),
            county_id: (!!self.customerCountyId ? self.customerCountyId.value : null),
            contact_id: (!!self.customerId ? self.customerId.value : null),

            opportunity_id: self.opportunityId,
            order_type_id: (!!self.orderType ? self.orderType.value : null),

            down_payment_on_order: parseFloat(self.downPaymentOnOrder).toFixed(2),
            down_payment_on_order_id: (!!self.downpaymentPaymentType ? self.downpaymentPaymentType.value : null),
            down_payment_info: self.downPaymentInfo,

            cash_back: parseInt(self.cashBack).toFixed(2),
            cash_back_type_id: (self.cashBackPaymentType ? self.cashBackPaymentType.value : null),
            cash_back_info: self.cashBackInfo,

            due_payment: parseInt(self.dueOnDelivery).toFixed(2),
            due_payment_id: (!!self.dueOnDeliveryPaymentType ? self.dueOnDeliveryPaymentType.value : null),
            due_payment_info: self.duePaymentInfo,

            tax_rate: parseFloat(self.cusTaxRate),
            state_tax_rate: parseFloat(self.cusStateTaxRate),
            warranty_tax_rate: parseFloat(self.warrantyTax),

            order_payment_method: self.tab,

          },

          dealsMapperData: {
            deal_no: self.dealNo,
            invoice_number:self.invoiceNo,
            id: self.dealMapperId,
            contract_number: self.contractNumber,
            contract_date: self.contractDate,
            cus_phone: self.cusPhoneNumber,
            cus_fax: self.cusFax,
            cus_cell: self.cusCell,
            cus_address: self.cusAddress,
            city_name:self.cusCityName,
            zip_code:self.cusCityZipCode,
            state_code:self.cusStateCode,
            // cus_address2: self.cusAddress2,
            // cus_city2: self.cusCity2,
            // alt_city: self.cusAltCityName,
            // alt_county: self.cusAltCountyName,
            customer_email: self.customerEmail,
            district_or_county: self.districtOrCounty,
            internal_comments: self.internalComments,
            comments: self.comments,
            comments2: self.comments2,
            is_forecast: self.add_to_forecast ? 'yes' : 'no',
            county: self.county,
            county_code: (!!self.customerCountyId ? self.customerCountyId.name : null),
            apply_sales_tax: self.cusSalesTax ? 'yes' : 'no',
            show_on_buyer_order: self.showOnBuyersOrder ? 'yes' : 'no',
          },
        }
        if (self.dealId !== null && self.dealId !== 0 && typeof self.dealId !== 'undefined') {
          formData.id = self.isEdit ? parseInt(self.dealId) : self.dealId;
        }
        return formData;
      } catch (error) {
        console.log('error=>',error);
      }
      // todo: figure out what to do with the fields that are not present in the deals table
    },

    saveAndCloseDeal: function (){
      // console.log("In save and close deal");
      // if (self.checkForm()) {
        self.showLoader();
        setTimeout(() => {
          // if (self.checkForm()) {
            var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = self.returnFormDataForSave();
          HTTP.post("/save/deal-data", data, config)
            .then(function (response) {
              // localStorage.removeItem('dealId');
              // localStorage.removeItem('opportunityId');
              // localStorage.removeItem('dealInventoryMapperId');
              // localStorage.removeItem('vehicleId');
              // localStorage.removeItem('totalAmountFromVehicleWorkSheet');
              if(response.data.code == 200) {
                self.showSAlert(self.dealMapperId == 0 ? 'Deal Created Successfully!' : 'Deal Updated Successfully!', response.data.status, false, 2);
                // self.dealMapperId = response.data.content.id; //as id is deal_id
                self.dealMapperId = response.data.content.dealsmapper_id;
                self.dealNo = response.data.content.deal_no;
                self.invoiceNo = response.data.content.invoice_number;
                self.hideLoader();
                if(self.print_document_flag){
                  let data = [];
                  data.deal_id = self.dealId;
                  data.vehicles = [];
                  self.assetArr.forEach(function(vehicle,index){
                    let vehicle_data = [];
                    vehicle_data['name'] = '';
                    if(vehicle.asset.model_type){
                      vehicle_data['name'] = vehicle.asset.unit + ' - '+ vehicle.asset.model_type.name;
                    }else{
                      vehicle_data['name'] = vehicle.asset.unit;
                    }
                    vehicle_data['deal_inventory_mapper_id'] = vehicle.id;
                    data.vehicles.push(vehicle_data);
                  });
                  self.print_document_flag = false;
                  EventBus.$emit("print-doc-options-popup-open",data);
                }
              }
              // self.$router.push("/deal/list");
            })
            .catch(function (err) {
                // console.log(err);
                self.hideLoader();
                self.catchHandler(err, function () {
              });
            });
          // }
        }, 200)
      // }
    },

    editTransactionSheet: function (dealId){
      self.addNewRow();
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: dealId
        }
      };

      self.showLoader();
      HTTP.get("/deal/edit", config)
        .then(function (data) {
          // console.log(data.data.content);
          self.hideLoader();
          if (data.status == 200) {
            var deals = data.data.content;
            self.salePersonId = deals.salesperson_id;
            self.locationId = deals.location_id;
            // self.customerCityId = deals.city_id;
            self.customerCountyId = deals.deal_mapper !== null ? self.customerCountyId = {
              name: deals.deal_mapper.county_code,
              value: deals.county_id
            } : '';
            self.opportunityId = deals.opportunity_id;
            self.oppNo = deals.opportunity.opp_no;
            self.customerId = deals.contact_id;

            self.loadAllData(['Contact'], true, self.loadCustomerIdAndName, "undefined", self.customerId);
            self.flagCustomerPopulate = 2;

            self.downPaymentOnOrder = deals.down_payment_on_order;
            self.downpaymentPaymentType = deals.down_payment_on_order_id;
            self.downPaymentInfo = deals.down_payment_info;
            self.cashBack = deals.cash_back;
            self.cashBackPaymentType = deals.cash_back_type_id;
            self.cashBackInfo = deals.cash_back_info;
            self.dueOnDelivery = deals.due_payment;
            self.dueOnDeliveryPaymentType = deals.due_payment_id;
            self.duePaymentInfo = deals.due_payment_info;
            self.cusTaxRate = deals.tax_rate;
            self.cusStateTaxRate = parseFloat(deals.state_tax_rate);
            self.warrantyTax = parseFloat(deals.warranty_tax);

            // self.tab = self.isTabChange ? self.tab : (deals.order_payment_method == null ? 'lease' : deals.order_payment_method);  //comment for now as default tab is cash
            if(deals.deal_mapper) {
              var dealMapper = deals.deal_mapper;
              self.dealNo = dealMapper.deal_no;
              self.invoiceNo = dealMapper.invoice_number;
              self.dealMapperId = dealMapper.id;
              self.contractNumber = dealMapper.contract_no;
              self.contractDate = moment(String(dealMapper.contract_date)).format('MM/DD/YYYY');
              self.cusPhoneNumber = dealMapper.cus_phone
              self.cusFax = dealMapper.cus_fax;
              self.cusCell = dealMapper.cus_cell;
              self.cusAddress = dealMapper.cus_address;
              self.cusCityName = dealMapper.city_name;
              self.cusStateCode = dealMapper.state_code;
              self.cusCityZipCode = dealMapper.zip_code;
              // self.cusAddress2 = dealMapper.cus_address2;
              // self.cusCity2 = dealMapper.city2;
              // self.cusAltCityName = dealMapper.alt_city
              // self.cusAltCountyName = dealMapper.alt_county;
              self.customerEmail = dealMapper.customer_email;
              self.districtOrCounty = dealMapper.district_or_county


              self.internalComments = dealMapper.internal_comments;
              self.comments = dealMapper.comments;
              self.comments2 = dealMapper.comments2
              self.add_to_forecast = dealMapper.is_forecast == 'yes' ? true : false;
              self.county = dealMapper.county
              self.cusSalesTax = dealMapper.apply_sales_tax == 'yes' ? true : false;
              self.showOnBuyersOrder = dealMapper.show_on_buyer_order == 'yes' ? true : false;
            }

            self.isEdit = true;
            self.isCountyEdit = true;
            if(deals.deal_inventory) {
              // self.assetArr = deals.deal_inventory;
              self.isEditVehicle = true;
              self.totalAmountFromVehicleWorkSheet = deals.deal_inventory.grand_total_cost_sheet;
              self.assetId = (deals.deal_inventory.asset ? deals.deal_inventory.asset.id : 0);
              self.getAssetDataFromId();
            }
            self.vehicleData = deals.deal_inventory;
            self.getPaymentOrderMethodData(dealId);
          }
        })
        .catch(function (error) {
          self.hideLoader();
          console.log('error=>'+error);
          /* console.log(error); */
          self.catchHandler(error, function () {
          });
        });
    },

    changeTab: function(type) {
      if(type == 'lease') {
        self.tab = 'lease';
      } else if(type == 'finance') {
        self.tab = 'finance';
      } else {
        self.tab = 'cash';
      }

    },

    getPaymentOrderMethodData(id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          dealId: id,
          paymentMethod: self.tab
        }
      };

      self.showLoader();
      HTTP.get("/deal/order-payment-method", config)
        .then(function (data) {
          self.hideLoader();
          if (data.status == 200) {
            var paymentData = data.data.content;
            if(paymentData !== null) {
              self.oldTab = self.tab;
              self.oldTabId = paymentData.id;
              if(self.tab == 'lease') {
                self.leaseData = paymentData;
              } else if(self.tab == 'finance') {
                self.financeData = paymentData;
              } else {
                  self.cashId = paymentData.id;
                  self.cashData = paymentData;
                  self.salesPrice = parseInt(paymentData.sales_price) > 0 ? parseInt(paymentData.sales_price) : self.salesPrice; /* the condition is applied in the case when vehicle is added but deal is not updated, todo refactor salesPrice on watch */
                  self.downpaymentAmt = parseInt(paymentData.down_payment);
                  self.tradeAllownce = parseInt(paymentData.trade_allowance_inc_gst);
                  self.totalFees = parseInt(paymentData.total_fees) > 0 ? parseInt(paymentData.total_fees) : self.totalFees; /* the condition is applied in the case when vehicle is added but deal is not updated */
                  self.totalSalesTax = parseInt(paymentData.total_sales_tax);
                  self.insurance = parseInt(paymentData.insurance);
                  self.totalPlusWarranties = parseInt(paymentData.total_plus_warranties);
                  self.cashFinanceCompanyId = paymentData.financial_company_id == null ? 0 : paymentData.financial_company_id;
              }
            }
          }
        })
        .catch(function (error) {
          self.hideLoader();
          console.log('error=>'+error);
          /* console.log(error); */
          self.catchHandler(error, function () {
          });
        });
    },

    addLeaseData(event) {
      self.leaseData = event;
    },

    addFinanceData(event) {
      self.financeData = event;
    },

    closeDeal() {
      localStorage.removeItem('dealId');
      localStorage.removeItem('opportunityId');
      localStorage.removeItem('dealInventoryMapperId');
      localStorage.removeItem('vehicleId');
      localStorage.removeItem('totalAmountFromVehicleWorkSheet');
      localStorage.removeItem('referenceNo');
      self.dealMapperId > 0 ? self.$router.push('/deal/list') : self.$router.push('/opportunity/list');
    },

    validation(event) {
      if(event.target.id == 'downpayment') {
          if(!self.numberFormateValidation(event.target.value)){
            self.downpaymentAmtError = "Only Numeric Value Allow";
          } else {
            self.downpaymentAmtError = '';
          }
      } else if(event.target.id == 'dueOnDelivery') {
          if(!self.numberFormateValidation(event.target.value)){
            self.dueOnDeliveryError = "Only Numeric Value Allow";
          } else {
            self.dueOnDeliveryError = '';
          }
      } else if(event.target.id == 'cashBack') {
          if(!self.numberFormateValidation(event.target.value)){
            self.cashBackError = "Only Numeric Value Allow";
          } else {
            self.cashBackError = '';
          }
      } else if(event.target.id == 'tradeAllownce') {
          if(!self.numberFormateValidation(event.target.value)){
            self.tradeAllownceError = "Only Numeric Value Allow";
          } else {
            self.tradeAllownceError = '';
          }
      } else if(event.target.id == 'totalFees') {
          if(!self.numberFormateValidation(event.target.value)){
            self.totalFeesError = "Only Numeric Value Allow";
          } else {
            self.totalFeesError = '';
          }
      } else if(event.target.id == 'totalSalesTax') {
          if(!self.numberFormateValidation(event.target.value)){
            self.totalSalesTaxError = "Only Numeric Value Allow";
          } else {
            self.totalSalesTaxError = '';
          }
      } else if(event.target.id == 'insurance') {
          if(!self.numberFormateValidation(event.target.value)){
            self.insuranceError = "Only Numeric Value Allow";
          } else {
            self.insuranceError = '';
          }
      } else if(event.target.id == 'ttlWarrenties') {
          if(!self.numberFormateValidation(event.target.value)){
            self.ttlWarrentiesError = "Only Numeric Value Allow";
          } else {
            self.ttlWarrentiesError = '';
          }
      } else if(event.target.id == 'cusStateTaxRate') {
          if(!self.numberFormateValidation(event.target.value)){
            self.cusStateTaxRateError = "Only Numeric Value Allow";
          } else {
            self.cusStateTaxRateError = '';
          }
      } else if(event.target.id == 'warrantyTax') {
          if(!self.numberFormateValidation(event.target.value)){
            self.warrantyTaxError = "Only Numeric Value Allow";
          } else {
            self.warrantyTaxError = '';
          }
      }
      // if (self.salesPrice && !self.numberFormateValidation(self.salesPrice)) {
      //   self.salesPriceError = "Only Numeric Value Allow";
      //   if (checked) {
      //     checked = false;
      //   }
      // }
      // self.downpaymentAmtError = "";
      // if (
      //   self.downpaymentAmt &&
      //   !self.numberFormateValidation(self.downpaymentAmt)
      // ) {
      //   self.downpaymentAmtError = "Only Numeric Value Allow";
      //   if (checked) {
      //     checked = false;
      //   }
      // }
      // self.tradeAllownceError = "";
      // if (
      //   self.tradeAllownce &&
      //   !self.numberFormateValidation(self.tradeAllownce)
      // ) {
      //   self.tradeAllownceError = "Only Numeric Value Allow";
      //   if (checked) {
      //     checked = false;
      //   }
      // }
      /* self.totalFeesError = "";
                if(self.totalFees && !self.numberFormateValidation(self.totalFees)) {
                    self.totalFeesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* self.totalSalesTaxError = "";
                if(self.totalSalesTax && !self.numberFormateValidation(self.totalSalesTax)) {
                    self.totalSalesTaxError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      // self.totalError = "";
      // if (self.total && !self.numberFormateValidation(self.total)) {
      //   self.totalError = "Only Numeric Value Allow";
      //   if (checked) {
      //     checked = false;
      //   }
      // }
      /* self.warrantiesError = "";
                if(self.warranties && !self.numberFormateValidation(self.warranties)) {
                    self.warrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* self.insuranceError = "";
                if(self.insurance && !self.numberFormateValidation(self.insurance)) {
                    self.insuranceError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
      /* self.totalWarrantiesError = "";
                if(self.totalWarranties && !self.numberFormateValidation(self.totalWarranties)) {
                    self.totalWarrantiesError = "Only Numeric Value Allow";
                    if (checked) {
                        checked = false;
                    }
                } */
    },

    deleteVehicleWorksheet : function (id) {
      // let deleteArray = [];
      // id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
      // if(id != 0) {
      //     deleteArray.push(id);
      // } else {
      //     deleteArray = self.selectedRows;
      // }
      self.$swal({
          title: '',
          text: 'Are you sure that you want to delete this Vehicle Worksheet!',
          type: 'info',
          confirmButtonText: 'OK',
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true
      }).then((result) => {
          if (result.value) {
              var config = {
                  headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                  data : {
                      id: id,
                  }
              };
              HTTP.delete("/vehicle-worksheet/delete", config)
                  .then(function (response) {
                      if (response.data.code == '200') {
                        self.salesPrice = response.data.totalSalesPrice;
                        self.tempRetailPrice = response.data.totalSalesPrice;
                        self.totalFees = response.data.calculation.total_fees;
                        self.totalSalesTax = response.data.calculation.total_sales_tax;
                        self.warranties = response.data.calculation.warranties;
                        let updateAssetArr = self.assetArr.filter((el) => el.id !== id);
                        self.showSAlert(response.data.message, 'success', false, 2);
                        self.assetArr = updateAssetArr;
                        self.calculateQuot();
                        // self.preFillTaxInfo();
                        // var salesTaxAmount = self.cusTaxRate * response.data.totalSalesPrice;
                        // var salesPriceAmount = (parseInt(response.data.totalSalesPrice) + salesTaxAmount).toFixed(2);
                        // self.salesPrice = isNaN(salesPriceAmount) ? 0 : salesPriceAmount;

                        // var salesTaxAmount = (parseFloat(self.cusTaxRate) / 100) * response.data.totalSalesPrice;
                        // var salesPriceAmount = (response.data.totalSalesPrice + salesTaxAmount).toFixed(2);
                        // self.salesPrice = isNaN(salesPriceAmount) ? 0 : salesPriceAmount;
                      }
                  })
                  .catch(function (error) {
                    console.log('error=>',error);
                      self.catchHandler(error, function () {});
                  });
          }
      });
    },

    CalculateTransaction() {
      // branch = GetListValue("ddlBranch");
      // county = GetListValue("ddlCounty");


      if(self.locationId.text == "neely coble company - nashville"  || self.locationId.text == "neely coble company - bowling green" || (self.locationId.text == "neely coble company - madison" && self.customerCountyId.text == 'tennessee'))
      {
        self.warrantyTax = 9.25;
      }

      if(self.locationId.text == "vtc - kingsport")
      {
        self.warrantyTax = 9.50;
      }

      switch (self.tab)
      {
        case "cash":
          self.CalculateCash();
          break;

        case "finance":
          // self.CalculateFinance();
          break;

        case "lease":
          // self.CalculateLease();
          break;
      }
    },

    CalculateCash()
    {
      self.cusTaxRate
      self.cusSalesTax
      self.downpaymentAmt
      self.dueOnDelivery
      // Get numbers
      // ***********
      var tax=GetValue("tbTaxView");
      var applytax = document.getElementById("cbSalesTax").checked;
        var depositOnOrder = GetValue("tbDepOnOrder");
        var depositOnDelivery = GetValue("tbDepOnDelivery");


      // Get table values
        // ****************
        var qty = 0;
        // var salesPrice = 0; //self.salesPrice
        self.salesPrice = 0;
        var fees = 0; //fees from Vehicle Information but hidden and there is no fees in our system
        var taxablefees = 0; //taxablefees from Vehicle Information but hidden and there is no fees in our system
        var discount = 0; //discount from Vehicle Information but hidden and there is no fees in our system
        var warranty = 0; //warranty from Vehicle Information but hidden and there is no fees in our system
        // var insurancesales = 0; //self.insurance
        self.insurance = 0;
      var warrantyTax = 0; //totaltaxableamount from Vehicle Information but hidden and there is no fees in our system
      var AlreadyIncludedInPrice = 0 // at NC the Bussinessis already included in teh salesPrice, so we need to deduct this amount from the fee total
      var totalTax = 0;
      if (!$find("dgVehicles"))
            return false;

      var vehicleTableRows = document.getElementById("dgVehicles").rows;

      var mastertable = $find("dgVehicles").get_masterTableView()
        var vehicleTableRows = mastertable.get_dataItems();

        for (var i = 0; i < vehicleTableRows.length; i++) {
        /*qty = vehicleTableRows[i].cells[3].innerText;
        salesPrice = salesPrice + getNumValue("",vehicleTableRows[i].cells[4].innerText);
        fees = fees + getNumValue("",vehicleTableRows[i].cells[5].innerText);
        docfee = docfee + getNumValue("",vehicleTableRows[i].cells[6].innerText);
        addpoamount = addpoamount + getNumValue("",vehicleTableRows[i].cells[7].innerText);
        dmvelectronicfiling = dmvelectronicfiling + getNumValue("",vehicleTableRows[i].cells[8].innerText);
        smogstate = smogstate + getNumValue("",vehicleTableRows[i].cells[9].innerText);
        ofs = ofs + getNumValue("",vehicleTableRows[i].cells[10].innerText);
        warranty = warranty + getNumValue("", vehicleTableRows[i].cells[11].innerText);
        AmountToExclude = AmountToExclude + getNumValue("", vehicleTableRows[i].cells[14].innerText);
        warrantyTax = warrantyTax + getNumValue("", vehicleTableRows[i].cells[16].innerText); // get the selected tax from the service contract
        NonTaxablePO = NonTaxablePO + getNumValue("", vehicleTableRows[i].cells[17].innerText);
        NumberOfUnits++;*/

        salesPrice = salesPrice + GetNumericValue(vehicleTableRows[i].get_cell("totalSalesPrice").innerHTML);
            fees = fees + GetNumericValue(vehicleTableRows[i].get_cell("fees").innerHTML);
            taxablefees = taxablefees + GetNumericValue(vehicleTableRows[i].get_cell("taxablefees").innerHTML);
            AlreadyIncludedInPrice = AlreadyIncludedInPrice + GetNumericValue(vehicleTableRows[i].get_cell("nontaxableamount").innerHTML);
            warranty = warranty + GetNumericValue(vehicleTableRows[i].get_cell("warranty").innerHTML);
            discount = discount + GetNumericValue(vehicleTableRows[i].get_cell("discount").innerHTML);
        warrantyTax = warrantyTax + GetNumericValue(vehicleTableRows[i].get_cell("totaltaxableamount").innerHTML);
      }

      var tradevalue=0;
      var tradepayoff=0;

      if (document.getElementById("dgTrade")) {
        var mastertable = $find("dgTrade").get_masterTableView()
        var tradeTableRows = mastertable.get_dataItems();

        for (var i = 0; i < tradeTableRows.length; i++) {
                tradevalue = tradevalue + GetNumericValue(tradeTableRows[i].get_cell("grossvalue").innerHTML);
                tradepayoff = tradepayoff + GetNumericValue(tradeTableRows[i].get_cell("payoff").innerHTML);
            }
        }



      if(GetListValue("ddlBranch").indexOf("neely coble company") > -1)
      {
        fees = fees; //- AlreadyIncludedInPrice;
      }

        totalDeposit = depositOnOrder + depositOnDelivery;
        totalFees = fees + taxablefees;

      //var tntaxcheck = GetValue("tbCalculatedTax");
      //if(tntaxcheck - warrantyTax == 0){
        //totalTax = GetValue("tbCalculatedTax");
      //}else{
        //totalTax = GetValue("tbCalculatedTax") - warrantyTax;
      //}
        totalTax = GetValue("tbCalculatedTax") //- warrantyTax;
        if (!applytax) {
            totalTax = 0;
        }

        SetValue("tbSalesTaxRO",totalTax);
        SetValue("tbSalesTaxTotal",totalTax);

        total = salesPrice - totalDeposit + totalFees - tradevalue + tradepayoff + totalTax + warrantyTax;

        // Print Cash Calculation Result
        // *****************************
        SetValue("tbSalesPriceRO",salesPrice);
        SetValue("tbTotDownpayment",depositOnOrder + depositOnDelivery);
        SetValue("tbTradeAllowanceRO",tradevalue - tradepayoff);
        SetValue("tbTotalFeesRO",totalFees);
        SetValue("tbSalesTaxRO",totalTax);
        SetValue("tbTotalRO",total);
        SetValue("tbSalesTaxTotal",totalTax);
        SetValue("tbTotalFinanced",total);
        SetValue("tbTotalWarranty",warranty);
        SetValue("tbTotalInsurance",insurancesales);
        SetValue("tbTotalAndWarranty",total + insurancesales + warranty);
    },
    openCustomerModal () {
      self.isClickCustomerModal = true;
      $("#custModal").modal({backdrop: 'static', keyboard: false});
    },
    onSearchDefaultCustomer() {
      const opportunityId = self.opportunityId;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: opportunityId
        }
      };
      HTTP.get("/get/default/customer", config)
        .then(function(response) {
          const data = response.data.content;
          self.populateCustomerData(data);
        })
        .catch(function(err) {
          console.log('Default Customer', err);
        });
    },
    populateCustomerData(data) {
      // Note: refactor the flagCustomerPopulate with better option
      // console.log('currentCustomerData', self.currentCustomerData, self.flagCustomerPopulate, data);
      if(self.flagCustomerPopulate == 0 && data !== null) {
        if(self.currentCustomerData == false) {
          self.customerId = data; //instead pass only text and value as an object
          if(data.county_id !== null) {
            self.customerCountyId = {
              name: data.county_name,
              value: data.county_id,
              text: data.county_code,
            };

            self.taxRateArr[data.county_code] = data.county_rate;
          } else {
            self.customerCountyId = {
              name: 'EXEMPT',
              value: 1104,
              text: 'EX',
            }
            self.taxRateArr["EX"] = 0;
          }
          self.autoPopulateCustomerAddress(data);
        } else {
          self.currentCustomerData = false;
        }
      } else {
        self.flagCustomerPopulate -= 1;
      }
      return true;
    },
    calculateSalesTaxWarranties(taxAmount, warrantyAmount, warranties) {
      self.totalSalesTax += parseFloat(taxAmount)+parseFloat(warrantyAmount);
      self.warranties = warranties;
      self.warrantyFromVehicleWorksheet = warrantyAmount;
    },
    calculateQuot() {
      if (self.dealStatusIdValue == 8) {
        self.salesPrice = self.convertToNum(parseFloat(self.salesPrice), 2);
        self.bonus_amount = self.convertToNum(parseFloat(self.bonus_amount), 2);
        self.extra_conquest = self.convertToNum(
          parseFloat(self.extra_conquest),
          2
        );
        self.gross_profit = self.convertToNum(parseFloat(self.gross_profit), 2);
        self.rego = self.convertToNum(parseFloat(self.rego), 2);
        self.ctp = self.convertToNum(parseFloat(self.ctp), 2);
        self.downpaymentAmt = self.convertToNum(
          parseFloat(!self.numberFormateValidation(self.downpaymentAmt) ? 0 : self.downpaymentAmt),
          2
        );
        self.trade_value_inc_gst = self.convertToNum(
          parseFloat(self.trade_value_inc_gst),
          2
        );
        self.over_allowance_inc_gst = self.convertToNum(
          parseFloat(self.over_allowance_inc_gst),
          2
        );

        self.trade_payout = self.convertToNum(parseFloat(self.trade_payout), 2);
        self.totalFees = self.convertToNum(parseFloat(!self.numberFormateValidation(self.totalFees) ? 0 : self.totalFees), 2);
        /* Wrong Formula on mapping Arcadium Calculation
          self.totalSalesTax = self.convertToNum(
            self.salesPrice *
            (parseFloat(!self.numberFormateValidation(self.cusStateTaxRate) ? 0 : self.cusStateTaxRate) / 100),
            2
          );
          self.warranties = self.convertToNum(
            self.salesPrice *
            (parseFloat(!self.numberFormateValidation(self.warrantyTax) ? 0 : self.warrantyTax) / 100), 2
          );
        */
        self.insurance = self.convertToNum(parseFloat(!self.numberFormateValidation(self.insurance) ? 0 : self.insurance), 2);
        self.overallowance = self.convertToNum(
          parseFloat(self.over_allowance_inc_gst) / 1.1,
          2
        );

        self.total_cq_approved = self.convertToNum(
          parseFloat(self.extra_conquest) + parseFloat(self.conquest),
          2
        );
        self.sub_total1 = self.convertToNum(
          parseFloat(self.dealer_net) -
          (parseFloat(self.bonus_amount) +
            parseFloat(self.extra_conquest) +
            parseFloat(self.conquest)),
          2
        );

        // self.$nextTick(() => {
        self.cab_chassis = self.convertToNum(
          parseFloat(self.sub_total1) +
          parseFloat(self.overallowance) +
          parseFloat(self.pre_delivery) +
          parseFloat(self.gross_profit),
          2
        );
        // });
        // console.log(JSON.stringify(self.lineItemsArr));
        var totalOfAllItems = 0;
        for (var index = 0; index < self.lineItemsArr.length; index++) {
          var lineItemObject = self.lineItemsArr[index];
          if (lineItemObject.priceGST) {
            totalOfAllItems =
              parseFloat(totalOfAllItems) + parseFloat(lineItemObject.priceGST);
          }
        }
        // console.log(totalOfAllItems);
        self.build_up_total = self.convertToNum(totalOfAllItems, 2);
        self.sub_total_ex_gst = self.convertToNum(
          parseFloat(self.build_up_total) + parseFloat(self.cab_chassis),
          2
        );
        self.total_gst = self.convertToNum(
          parseFloat(self.sub_total_ex_gst) * (10 / 100),
          2
        );

        self.sub_total2 = self.convertToNum(
          parseFloat(self.sub_total_ex_gst) + parseFloat(self.total_gst),
          2
        );
        self.gross_profit_percentage = self.convertToNum(
          (parseFloat(self.gross_profit) / parseFloat(self.sub_total2)) * 100,
          2
        );

        self.stamp_duty = 0.0;
        if (self.stampDutyType.value != "exempt") {
          self.stamp_duty = self.convertToNum(
            parseFloat(self.sub_total2) *
            (parseFloat(self.stampDutyPercentage) / 100),
            2
          );
        }

        self.tradeAllownce = self.convertToNum(
          parseFloat(!self.numberFormateValidation(self.tradeAllownce) ? 0 : self.tradeAllownce),
          2
        );
        
        self.total = self.convertToNum(
          parseFloat(self.salesPrice) -
          parseFloat(self.downpaymentAmt) +
          parseFloat(self.tradeAllownce) +
          parseFloat(self.totalSalesTax) +
          parseFloat(self.totalFees) +
          parseFloat(self.warrantyFromVehicleWorksheet),
          2
        );
        // self.tradeAllownce = self.convertToNum(
        //   parseFloat(self.trade_value_inc_gst) +
        //   parseFloat(self.over_allowance_inc_gst),
        //   2
        // );

        self.trade_equity = self.convertToNum(
          parseFloat(self.tradeAllownce) - parseFloat(self.trade_payout),
          2
        );

        self.change_over = self.convertToNum(
          parseFloat(self.total) - parseFloat(self.trade_equity),
          2
        );

        self.totalPlusWarranties = self.convertToNum(
          parseFloat(self.total) + parseFloat(self.warranties) + parseFloat(self.insurance),
          2
        );
      } 
    },
    addWarrantyTax() {
      if(!self.isMount && self.locationId.stateId == 3969) {
        console.log('Should take either countryId or code instead of stateId', self.locationId);
        self.warrantyTax = 9.25;
      } else {
        self.isMount = false;
        self.warrantyTax = 0;
      }
    }
  }
};
EventBus.$on("work-sheet-callback", function (
  callbackOpportunityId,
  callbackAssetId
) {
  if (typeof self != "undefined") {
    self.vehicleWorkSheet();
    if (callbackOpportunityId != 0 && callbackAssetId != 0) {
      self.getOpportunityInventory(callbackOpportunityId);
    }
  }
  self.isVehicleEdit = false;
});

EventBus.$on("close-add-customer",function (status){
  if (typeof self != "undefined"){
    self.isClickCustomerModal = status;
    $("#custModal").modal("hide");
  }
});

EventBus.$on("populate-customer-data",function (datas){
  if (typeof self != "undefined"){
    self.populateCustomerData(datas);
  }
});

EventBus.$on("cash-details-data",function (taxAmount, warrantyAmount, warranties){
  if (typeof self != "undefined"){
    self.calculateSalesTaxWarranties(taxAmount,warrantyAmount,warranties);
  }
});

</script>

<style>
.transaction-sheet .forms-container .form-label{
  padding: 0 !important;
  font-size: 14px;
}
</style>