<template>
    <div class="loginbg" id="login-main-hr" style="display: none;">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>{{ allStaticMessages.test_env_msg}}</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="/static/images/login-logo.png" alt="Velocity Vehicle Group">
                        </div>
                        <form @submit.prevent="emailVerify();" id="forgotPassword" name="form">
                            <div class="form-group mb-0">
                                <label for="username">{{ allLabelMessages.email }}</label>
                                <input v-model="email" type="text" name="email" class="form-control" id="email" placeholder="Enter Email" maxlength="190" @input="emailError=''">
                                <span class="help is-danger">{{ emailError }}</span>
                            </div>
                            <div>
                                <label id="errorMsg" class="help">{{ errorMsg }}</label>
                            </div>
                            <div>
                                <button :disabled="disableButtons.submitButton" id="loginbtn" type="submit" class="btn btn-primary reset-req-btn" v-on:click.prevent="submitRequest(this)">
                                    {{ buttonNames.submitButton }}
                                </button>
                                <router-link class="back-to-login" v-bind:to="{name: 'VendorLogin'}"> 
                                    {{ allLabelMessages.back_to_login_lbl }}
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
var self;
import Vue from 'vue';
import {HTTP} from '../../http-common';
import {commonMixins} from '../../mixins/commonMixins';

export default {
    name: 'VendorForgotPassword',
    mixins: [commonMixins],
    data() {
        return {
            currentEnvironment: false,
            email: "",
            emailError: "",
            buttonNames: {
                submitButton : 'Request reset link',
            },
            disableButtons: {
                submitButton : false,
            },
            errorMsg: ''
        }
    },
    beforeMount: function () {
        self = this;
    },
    mounted: function () {
        self.currentEnvironment = (process.env.VUE_APP_NODE_ENV == 'production') ? false : true;
        
        var tokenData = self.$route.query.token;
        var email = self.$route.query.email;
        if (tokenData != undefined) {
            var fields = {
                tokenValue: tokenData,
                email: email,
            };
            HTTP.post("vendor/reset/password/request/submit", fields)
                    .then(function (response) {
                        if (response.data.status == "success") {
                            self.$router.push("/vendor/resetpassword/" + response.data.content);
                        } else {
                            self.$router.push({ name: "VendorResetLinkNotFound" });
                        }
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        }
                    });
        }

        $(".loginbg").show();
        $("#wrapper").addClass("login-div-full");
    },
    methods: {
        checkForm: function() {
            var checked = true;
            self.emailError = "";
            if (!self.email || self.email.trim() == "") {
                self.emailError = "Please enter email";
                if (checked) {
                    checked = false;
                }
            } else if (self.email && !self.validEmail(self.email)) {
                self.emailError = "Please Enter Valid Email";
                if (checked) {
                    checked = false;
                }
            }
            return checked;
        },
        submitRequest: function () {
            if (self.checkForm()) {
                self.buttonNames.submitButton = "Please wait...";
                self.disableButtons.submitButton = true;

                var fields = {
                    email: self.email,
                };

                HTTP.post("vendor/reset/password/request/submit", fields)
                    .then(function (data) {
                        self.buttonNames.submitButton = "Request reset link";
                        self.disableButtons.submitButton = false;
                        
                        if (data.status == 200) {
                            self.errorMsg = data.data.message;
                            $('#errorMsg').addClass('is-success');
                        } else if (data.status == 201) {
                            if (data.data.content && Object.keys(data.data.content).length > 0) {
                                self.errorMsg = Object.values(data.data.content).flat().join("<br/>");
                                $('#errorMsg').html(self.errorMsg);
                                $('#errorMsg').addClass('is-danger');
                            }
                        }
                    })
                    .catch(function (error) {
                        self.errorMsg = "";
                        self.buttonNames.submitButton = "Request reset link";
                        self.disableButtons.submitButton = false;
                        
                        self.catchHandler(error, function () {}, 1);
                    })
            }
        }
    }
}
</script>
<style lang="css" scoped>
.loginform form .btn.btn-primary.reset-req-btn {
    margin-top: 3%;
    font-size: 16px !important;
    display: block;
    margin: 15px 0 0 0;
    width: 100%;
    height: 44px;
    font-size: 17px !important;
    border-bottom: 2px solid #076eb0 !important;
    background: #098de1;
    border-color: #098de1;
}
.loginform form .btn.btn-primary.reset-req-btn:focus {
    background: #098de1;
}
.back-to-login {
    text-decoration: underline !important; 
    margin-top: 6%;
    display: block;
    text-align: center;
}
</style>