<template>
  <div>
    <!-- Page Content Wrapper -->
    <div id="page-content-wrapper" class="admin-content-area">
      <template v-if="permissionNameArray.includes('can_add_tag_master') || permissionNameArray.includes('can_update_tag_master')">
        <div class="container-fluid xyz">
          <div class="page-header-top-container">
            <div class="page-name">{{ pageTitle }}</div>
            <button
              type="submit"
              v-on:click.prevent="buttonBack()"
              class="btn btn-primary waves-effect pull-right"
            >
              {{staticInputLabelMessages.back}}
            </button>
          </div>
          <!--page content-->
          <div class="admin-page-content">
            <!--Forms-->
            <div
              class="forms-container add_user_forms_container vtc-dealer-forms"
            >
              <div class="row no-margin">
                <!-- Tag Label -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">{{staticInputLabelMessages.label}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      id="tag-label"
                      v-model="tagLabel"
                      class="form-control"
                      type="text"
                      maxlength="70"
                      @input="tagLabelError = ''"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ tagLabelError }}</span>
                  </div>
                </div>
                <!-- Tag Value -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">{{staticInputLabelMessages.tags_value}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <input
                      id="tag-value"
                      v-model="tagValue"
                      class="form-control"
                      type="text"
                      maxlength="70"
                      :disabled="isEdit"
                      @blur="validateTagValue"
                      @input="tagValueError = ''"
                    />
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ tagValueError }}</span>
                  </div>
                </div>
                <!-- Tag Model -->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">{{staticInputLabelMessages.model}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      v-model="selectedModel"
                      @input="modelError = ''"
                      name="status"
                      id="tag-status"
                      label="text"
                      :clearable="true"
                      :searchable="true"
                      :filterable="true"
                      :options="modelList"
                      placeholder="Select Model"
                      @keydown.native="handleRemove"
                    >
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ modelError }}</span>
                  </div>
                </div>
                <!--Tag Status-->
                <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                    <div class="form-label">{{staticInputLabelMessages.status}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      v-model="status"
                      @input="statusError = ''"
                      name="status"
                      id="tag-status"
                      label="text"
                      :clearable="false"
                      :searchable="false"
                      :filterable="false"
                      :options="statusList"
                      placeholder="Select Status"
                    >
                    </v-select>
                    <div class="requiredstar">*</div>
                    <span class="help is-danger">{{ statusError }}</span>
                  </div>
                </div>
              </div>
              <div class="button-demo">
                <input
                  id="addOpportunityBtn"
                  v-if="!isEdit ? permissionNameArray.includes('can_add_tag_master') : permissionNameArray.includes('can_update_tag_master')"
                  :disabled="disableButtons.save"
                  type="submit"
                  :value="!isEdit ? buttonNames.save : buttonNames.update"
                  class="btn btn-primary"
                  v-on:click.prevent="saveUpdateTag()"
                />
                <input
                  id="cancelLocation"
                  :disabled="disableButtons.cancel"
                  type="submit"
                  :value="buttonNames.cancel"
                  class="btn btn-primary"
                  v-on:click.prevent="buttonBack"
                />
              </div>
            </div>
            <!-- Forms -->
          </div>
          <!--page content-->
        </div>
      </template>
      <template v-else>
        <no-access></no-access>
      </template>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
let self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
Vue.component("app-footer", Footer);

export default {
  name: "AddTag",
  components: {
    "app-footer": Footer,
    datePicker,
    "no-access": NoAccessPage,
  },
  mixins: [commonMixins],
  data() {
    return {
      isEdit: false,
      id: this.$route.params.id,
      buttonNames: {
        save: "Save",
        update: "Update",
        cancel: "Cancel",
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false,
      },
      pageTitle: "Add Tag",
      permissionNameArray: ["can_add_tag_master"],
      tagLabel: "",
      tagLabelError: "",
      tagValue: "",
      tagValueError: "",
      uniqueValid: true,
      status: {
        value: 1,
        text: "Active",
      },
      statusList: [
        {
          value: 1,
          text: "Active",
        },
        {
          value: 0,
          text: "In Active",
        },
      ],
      statusError: "",
      selectedModel: null,
      modelList: [
        {
          value: "AssetMaster",
          text: "Asset Master",
        },
        {
          value: "ActivityMaster",
          text: "Activity Master",
        },
        {
          value: "OpportunityMaster",
          text: "Opportunity Master",
        },
        {
          value: "DealMaster",
          text: "Deal Master",
        },
        {
          value: "ContactMaster",
          text: "Contact Master",
        }
      ],
      modelError: "",
      staticInputLabelMessages: "",
      staticMessages: ""
    };
  },
  mounted: function () {
    self = this;
    /* Redirect to login page logic start */
    const userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      this.$router.push("/");
    }
    /* Redirect to login page logic end */
    self.setActiveMenu('tag-list');
    EventBus.$emit("menu-selection-changed", "tag-list");
    this.getLoginIdPermissions();
    this.staticInputLabelMessages = this.allLabelMessages;
    const editId = this.$route.params.id;
    if (editId !== undefined) {
      this.pageTitle = "Update Tag";
      this.isEdit = true;
      this.fetchTagEditData(editId);
    }
  },
  methods: {
    buttonBack() {
      this.$router.push({ name: "ListTags" });
    },
    saveUpdateTag() {
      if (this.isValid) {
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };
        const data = {
          tag_label: this.tagLabel,
          tag_value: this.tagValue,
          model_name: this.selectedModel.value,
          status: this.status.value,
        };

        let apiRoute = "";
        if (this.isEdit) {
          apiRoute = HTTP.put("/tags/" + this.$route.params.id, data, config);
        } else {
          apiRoute = HTTP.post("/tags", data, config);
        }

        apiRoute
          .then((response) => {
            if (response.status === 200) {
              this.showSAlert(
                response.data.message,
                response.data.status,
                false,
                2
              );
              this.buttonBack();
            } else if(response.status === 201){
              self.$swal({
                title: "",
                text: response.data.message,
                type: "error",
                confirmButtonText: "OK",
                showCancelButton: false,
                showLoaderOnConfirm: true
              })
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              const errorMessages = error.response.data.errorMessage;
              this.tagLabelError = errorMessages.tag_label
                ? errorMessages.tag_label
                : "";
              this.tagValueError = errorMessages.tag_value
                ? errorMessages.tag_value
                : "";
              this.modelError = errorMessages.model_name
                ? errorMessages.model_name
                : "";
            } else {
              this.catchHandler(error, function () {});
            }
          });
      }
    },
    fetchTagEditData(id) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };

      HTTP.get("/tags/" + id, config)
        .then((response) => {
          const data = response.data.content;
          this.tagLabel = data.tag_label;
          this.tagValue = data.tag_value;
          this.status = this.statusList.find(
            (status) => status.value === data.status
          );
          this.selectedModel = this.modelList.find(
            (model) => model.value === data.model_name
          );
        })
        .catch((err) => {
          this.catchHandler(err, function () {});
        });
    },
    validateTagValue() {
      const storedTagValueList = localStorage.getItem("TagValueList");
      if (storedTagValueList.split(",").includes(this.tagValue)) {
        this.tagValueError =
          "This tag is already in use. Please choose a different one";
        this.uniqueValid = false;
      } else {
        this.uniqueValid = true;
        this.tagValueError = "";
      }
    },
    handleRemove(event) {
      if (event.key === 'Backspace' && !event.target.value) {
        this.selectedModel = null;
      }
    },
  },
  computed: {
    isValid() {
      let valid = true;

      if (!this.tagLabel) {
        this.tagLabelError = "This field is required!";
        valid = false;
      } else {
        this.tagLabelError = "";
      }

      if (!this.tagValue) {
        this.tagValueError = "This field is required!";
        valid = false;
      } else {
        if (!this.uniqueValid) {
          this.tagValueError = this.tagValueError;
          valid = false;
        } else {
          const snakeCasePattern = /^[a-z][a-z0-9_]+$/;
          this.tagValueError = "";
          if (!snakeCasePattern.test(this.tagValue)) {
            this.tagValueError =
              "Format should be in Snake_Case with lowercase letters.";
            valid = false;
          }
        }
      }

      if (!this.selectedModel) {
        this.modelError = "This field is required!";
        valid = false;
      } else {
        this.modelError = "";
      }

      return valid;
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
