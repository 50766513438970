import { render, staticRenderFns } from "./VendorResetPassword.vue?vue&type=template&id=064aeb31&scoped=true&"
import script from "./VendorResetPassword.vue?vue&type=script&lang=js&"
export * from "./VendorResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./VendorResetPassword.vue?vue&type=style&index=0&id=064aeb31&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064aeb31",
  null
  
)

export default component.exports