<template>
    <div class="no-margin">
        <div class="row">
            <div class="col-md-12 col-sm-12 forms-block">
                <div class="book-deal-customer-block text text-center">
                    Lease Details
                </div>
            </div>
        </div>

        <div class="row no-margin py-2">
            <div class="col-md-6 col-sm-6 forms-block min-margin">
                <div class="form-label deallabel text-right">
                    Finance Income:
                </div>
            </div>

            <div class="col-md-6 col-sm-6">
                <input class="form-control" type="text" style="width: 50%" v-model="financeIncome" />
            </div>
        </div>

        <div class="row no-margin py-2">
            <div class="col-md-3 col-sm-6 forms-block min-margin">
                <div class="form-label deallabel text-center">
                    First Payment Date:
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="row">
                    <div class="col-md-10">
                        <date-picker v-model="firstPaymentDate"                 
                            :config="options"           
                            class="form-control" 
                            autocomplete="off"
                            :placeholder="allStaticMessages.contract_date_place_holder" name="firstPaymentDate"
                            @input="firstPaymentDateError = null" 
                            >
                        </date-picker>
                    </div>
                    <div class="col-md-2">
                        <div class="text-danger font-weight-bold">*</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 forms-block min-margin">

                <div class="form-label deallabel text-center pb-3">
                    Rate:
                </div>

                <div class="form-label deallabel text-center">
                    Term:
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <input class="form-control" type="text" v-model="rate" />
                <input class="form-control" type="text0" v-model="term" />
            </div>
        </div>

        <hr>

        <div class="row no-margin py-2">
            <div class="col-md-3 col-sm-6 forms-block min-margin">
                <v-select v-model="companyId" name="state"
                        label="text" 
                        :filterable="false" 
                        :options="companyArr"
                        @search="onSearchFinanceCompany" 
                        :clearable="true" 
                        @input="companyError = ''"
                        :placeholder="allStaticMessages.company_custom_place_holder" 
                        class="custom-ci-auto-search"
                        >
                    <span slot="no-options" @click="$refs.select.open = false">
                        <template v-if="!isCompanyRecordNotFound">
                            {{allStaticMessages.company_custom_search}}
                        </template>
                        <template v-else>
                            {{allStaticMessages.search_record_not_found}}
                        </template>
                    </span>
                </v-select>
                <div class="requiredstar">*</div>
                <span class="help is-danger">{{companyError}}</span>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="row">
                    <div class="col-md-10">
                        <label for="">Lease Cpy:</label>
                        <input class="form-control" type="text" v-model="leaseCompany" />
                    </div>
                    <div class="col-md-2">
                        <span class="text-danger font-weight-bold">*</span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <label for="">Address:</label>
                        <input class="form-control" type="text" v-model="lineHolderAddress" />
                    </div>
                    <div class="col-md-2">
                        <span class="text-danger font-weight-bold">*</span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <label for="">City:</label>
                        <input class="form-control" type="text" v-model="lineHolderCity" />
                    </div>
                    <div class="col-md-2">
                        <span class="text-danger font-weight-bold">*</span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <label for="">State:</label>
                        <v-select v-model="state" name="state"
                            label="text" 
                            :filterable="false" 
                            :options="stateArr"
                            @search="onSearchState" 
                            :clearable="false" 
                            @input="stateError = ''"
                            :placeholder="allStaticMessages.state_place_holder" class="custom-ci-auto-search">
                            <span slot="no-options" @click="$refs.select.open = false">
                                <template v-if="!isStateRecordNotFound">
                                    {{allStaticMessages.state_search}}
                                </template>
                                <template v-else>
                                    {{allStaticMessages.search_record_not_found}}
                                </template>
                            </span>
                        </v-select>
                    </div>
                    <div class="col-md-2">
                        <span class="text-danger font-weight-bold">*</span>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <label for="">Zip:</label>
                        <input class="form-control" type="text" v-model="lineHolderZip" />
                    </div>
                    <div class="col-md-2">
                        <span class="text-danger font-weight-bold">*</span>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 forms-block min-margin">

                <div class="form-label deallabel text-center">
                    Lineholder:
                </div>

                <div class="form-label deallabel text-center">
                    (if different)
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <label for="">Name (or 'same'):</label>
                <input class="form-control" type="text" v-model="lineHolderName" /><div class="requiredstar">*</div>
                <hr>
                <label for="">Address:</label>
                <input class="form-control" type="text" v-model="lineHolderDiffAddress" />
                <hr>
                <label for="">Ciy:</label>
                <input class="form-control" type="text" v-model="lineHolderDiffCity" />
                <hr>
                <label for="">State:</label>
                <v-select v-model="diffState" name="state"
                    label="text" 
                    :filterable="false" 
                    :options="stateArr"
                    @search="onSearchState" 
                    :clearable="false" 
                    @input="stateError = ''"
                    :placeholder="allStaticMessages.state_place_holder" class="custom-ci-auto-search">
                    <span slot="no-options" @click="$refs.select.open = false">
                        <template v-if="!isStateRecordNotFound">
                            {{allStaticMessages.state_search}}
                        </template>
                        <template v-else>
                            {{allStaticMessages.search_record_not_found}}
                        </template>
                    </span>
                </v-select>  
                <hr>
                <label for="">Zip:</label>
                <input class="form-control" type="text" v-model="lineHolderDiffZip" />
                <hr>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Vehicle MSRP
                        </div>
                    </div>
                </div>
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <label for="">MSRP:</label>
                        <input class="form-control" type="text" v-model="msrp" />
                        <hr>
                        <label for="">Add to MSRP:</label>
                        <input class="form-control" type="text" v-model="addToMsrp" />
                        <hr>
                        <label for="">Adjusted MSRP:</label>
                        <input class="form-control" type="text" v-model="adjustedMsrp" />
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Residual Value
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-6">
                    <label for="">Adjusted MSRP::</label>
                    <input class="form-control" type="text" v-model="residualAdjustedMsrp" />
                    <hr>
                    <label for="">Residual % :</label>
                    <input class="form-control" type="text" v-model="residual" />
                    <hr>
                    <label for="">Subtotal:</label>
                    <input class="form-control" type="text" v-model="subTotal" />
                    <hr>
                    <label for="">Mileage Adjustment:</label>
                    <input class="form-control" type="text" v-model="mileageAdjustement" />
                    <hr>
                    <label for="">Less Other Factors Affecting Depreciation:</label>
                    <input class="form-control" type="text" v-model="lessOtherFactors" />
                    <hr>
                    <label for="">Adjusted Residual Value:</label>
                    <input class="form-control" type="text" v-model="adjustedResidualValue" />
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Capitalized Cost
                        </div>
                    </div>
                </div>
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <label for="">Capitalized cost :</label>
                        <input class="form-control" type="text" v-model="capitalizedCost" />
                        <hr>
                        <label for="">Cash Cap Reduction:</label>
                        <input class="form-control" type="text" v-model="cashCapReduction" />
                        <hr>
                        <label for="">Net Trade-In Allowance:</label>
                        <input class="form-control" type="text" v-model="netTradeInAllowance" />
                        <hr>
                        <label for="">Net Capitalized Cost:</label>
                        <input class="form-control" type="text" v-model="netCapitalizedCost" />
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Total Monthly Payment
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-6">
                    <label for="">Base Monthly Rental:</label>
                    <input class="form-control" type="text" v-model="baseMonthlyRental" />
                    <hr>
                    <label for="">Total Sales Tax:</label>
                    <input class="form-control" type="text" v-model="totalSalesTax" />
                    <hr>
                    <label for="">Total Monthly Payment:</label>
                    <input class="form-control" type="text" v-model="totalMonthlyPayment" />
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Excess Mileage Adjustment
                        </div>
                    </div>
                </div>
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <label for="">Anticipated Monthly Mileage:</label>
                        <input class="form-control" type="text" v-model="anitcipatedMonthlyMileage" />
                        <hr>
                        <label for="">Less Monthly Mileage:</label>
                        <input class="form-control" type="text" v-model="lessMonthlyMileage" />
                        <hr>
                        <label for="">Excess Mileage Per month:</label>
                        <input class="form-control" type="text" v-model="excessMileagePerMonth" />
                        <hr>
                        <label for="">Term:</label>
                        <input class="form-control" type="text" v-model="excessMileageTerm" />
                        <hr>
                        <label for="">Total Excess Mileage for Term:</label>
                        <input class="form-control" type="text" v-model="totalExcessMileageForTerm" />
                        <hr>
                        <label for="">Milaege Penalty:</label>
                        <input class="form-control" type="text" v-model="mileagePenalty" />
                        <hr>
                        <label for="">Excess Mileage Adjustment:</label>
                        <input class="form-control" type="text" v-model="excessMileageAdjustment" />
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row no-margin">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-left">
                            Payment Due at Lease Signing
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-6">
                    <label for="">Cap Cost Reduction:</label>
                    <input class="form-control" type="text" v-model="capCostReduction" />
                    <hr>
                    <label for="">Net Trade-In Allowance:</label>
                    <input class="form-control" type="text" v-model="excessMileageNetTradeInAllowance" />
                    <hr>
                    <label for="">First Monthly Payment:</label>
                    <input class="form-control" type="text" v-model="firstMonthlyPayment" />
                    <hr>
                    <label for="">Total Initial Fees:</label>
                    <input class="form-control" type="text" v-model="totalInitialFees" />
                    <hr>
                    <label for="">Amount Due At Start:</label>
                    <input class="form-control" type="text" v-model="amountDueStart" />
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 col-sm-12 forms-block">
                        <div class="book-deal-customer-block text text-center">
                            Payment Detail
                        </div>
                    </div>
                </div>
                <div class="row no-margin py-2">
                    <div class="col-md-6 col-sm-6 forms-block min-margin">
                        <label for="">Equip. Replacement Value:</label>
                        <input class="form-control" type="text" v-model="equipReplacementValue" />
                    </div>
                    <div class="col-md-6 col-sm-6 forms-block min-margin">
                        <label for="">Doc Fee:</label>
                        <input class="form-control" type="text" v-model="docFee" />
                    </div>
                </div>
                <br>
                <div class="row no-margin py-2">
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">First Payment:</label>
                        <input class="form-control" type="text" v-model="firstPayment" />
                        <hr>
                        <label for="">License Fee:</label>
                        <input class="form-control" type="text" v-model="liecenseFee" />
                    </div>
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">Payment 2-xx:</label>
                        <input class="form-control" type="text" v-model="secondPayment" />
                    </div>
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">Final Payment:</label>
                        <input class="form-control" type="text" v-model="finalPayment" />
                    </div>
                </div>
                <hr>
                <div class="row no-margin py-2">
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">Sales Tax:</label>
                        <input class="form-control" type="text" v-model="firstPaymentSalesTax" />
                        <hr>
                        <label for="">Handeling Fee:</label>
                        <input class="form-control" type="text" v-model="firstPaymentHandelingFee" />
                        <hr>
                        <label for="">Total:</label>
                        <input class="form-control" type="text" v-model="firstPaymentTotal" />
                    </div>
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">Sales Tax:</label>
                        <input class="form-control" type="text" v-model="secondPaymentSalesTax" />
                        <hr>
                        <label for="">Handeling Fee:</label>
                        <input class="form-control" type="text" v-model="secondPaymentHandelingFee" />
                        <hr>
                        <label for="">Total:</label>
                        <input class="form-control" type="text" v-model="secondPaymentTotal" />
                    </div>
                    <div class="col-md-4 col-sm-4 forms-block min-margin">
                        <label for="">Sales Tax:</label>
                        <input class="form-control" type="text" v-model="finalPaymentHandelingFee" />
                        <hr>
                        <label for="">Handeling Fee:</label>
                        <input class="form-control" type="text" v-model="finalPaymentSalesTax" />
                        <hr>
                        <label for="">Total:</label>
                        <input class="form-control" type="text" v-model="finalPaymentTotal" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { commonMixins } from "../../../mixins/commonMixins";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from 'moment';

export default {
    name: "LeaseTab",
    components: {
        datePicker
    },
    mixins: [commonMixins],
    beforeMount() {
        self = this;
    },
    props: {
        financeCompanyStateArr: [],
        selectFinanceCompanyArr: [],
        leaseData: '',
        isEdit: false,
    },
    data() {
        return {
            options: {
                format: "MM/DD/YYYY",
                useCurrent: false,
                allowInputToggle: true,
                widgetPositioning: {
                    horizontal: "auto",
                    vertical: "bottom"
                }
            },
            state: '',
            stateArr: '',
            companyArr: '',
            diffState: '',
            stateData: '',
            financeData: '',
            companyId: null,
            stateId: null,
            financeId: null,
            diffStateId: null,
            financeIncome: null,
            firstPaymentDate: null,
            rate: 0,
            term: 0,
            leaseCompany: null,
            lineHolderName: null,
            lineHolderAddress: null,
            lineHolderDiffAddress: null,
            lineHolderCity: null,
            lineHolderDiffCity: null,
            lineHolderZip: null,
            lineHolderDiffZip: null,
            msrp: 0,
            addToMsrp: 0,
            adjustedMsrp: 0,
            residualAdjustedMsrp: 0,
            residual: 0,
            subTotal: 0,
            mileageAdjustement: 0,
            lessOtherFactors: 0,
            adjustedResidualValue: 0,
            capitalizedCost: 0,
            cashCapReduction: 0,
            netTradeInAllowance: 0,
            netCapitalizedCost: 0,
            baseMonthlyRental: 0,
            totalSalesTax: 0,
            totalMonthlyPayment: 0,
            anitcipatedMonthlyMileage: 0,
            lessMonthlyMileage: 0,
            excessMileagePerMonth: 0,
            excessMileageTerm: 0,
            totalExcessMileageForTerm: 0,
            mileagePenalty: 0,
            excessMileageAdjustment: 0,
            capCostReduction: 0,
            excessMileageNetTradeInAllowance: 0,
            firstMonthlyPayment: 0,
            totalInitialFees: 0,
            amountDueStart: 0,
            equipReplacementValue: 0,
            docFee: 0,
            firstPayment: 0,
            liecenseFee: 0,
            secondPayment: 0,
            finalPayment: 0,
            firstPaymentSalesTax: 0,
            firstPaymentHandelingFee: 0,
            firstPaymentTotal: 0,
            secondPaymentSalesTax: 0,
            secondPaymentHandelingFee: 0,
            secondPaymentTotal: 0,
            finalPaymentHandelingFee: 0,
            finalPaymentSalesTax: 0,
            finalPaymentTotal: 0,

            isCompanyRecordNotFound: false,
            isMakeRecordNotFound: false,
            isModelRecordNotFound: false,
            isConditionRecordNotFound: false,
            isStateRecordNotFound: false,
            isCityRecordNotFound: false,
            companyError: '',

            id: 0,
        };
    },
    methods: {
        setData(data, type) {
            switch (type) {
                case 'state':
                    this.stateId = data.value;
                    this.emitData();
                    break;
                case 'finance':
                    // self.showLoader();
                    this.financeId = data.value;
                    var config = {
                        headers: { Authorization: "Bearer " + localStorage.getItem("api_token") },
                        params: {
                            id: data.value,
                        }
                    };
                    var self = this;
                    HTTP.get("/financegroup/get", config)
                        .then(function (data) {
                            if (data.status == 200) {
                                var holder = data.data.content;
                                self.leaseCompany = holder.name;
                                self.lineHolderAddress = holder.address;
                                self.lineHolderCity = holder.city_name;
                                self.stateData = {
                                    text: 'ts',
                                    value: 1
                                };
                                self.lineHolderZip = holder.zip_code;
                                self.emitData();
                            }
                        })
                        .catch(function (error) {
                            // self.hideLoader();
                            self.catchHandler(error, function () {
                                console.log('something wrong');
                            });
                        })
                    break;
                case 'diffState':
                    this.diffStateId = data.value;
                    this.emitData();
                    break;
            }
        },

        onSearchState(search, loading) {
            if (search.length > 1) {
                loading(true);
                self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
            }
        },
        loadStateDataCallback: function (callbackData) {
            self.isStateRecordNotFound = false;
            self.stateArr = callbackData.States;
            if(self.stateArr.length == 0) {
                self.isStateRecordNotFound = true;
            }
        },
        
        onSearchFinanceCompany(search, loading) {
            if (search.length > 2) {
                loading(true);
                self.loadAllData(['FinanceGroup'], true, self.loadFinanceCompanyDataCallback, loading, search);
            }
        },
        loadFinanceCompanyDataCallback: function (callbackData) {
            self.isCompanyRecordNotFound = false;
            self.companyArr = callbackData.FinanceGroup;
            if(self.companyArr.length == 0) {
                self.isCompanyRecordNotFound = true;
            }
        },

        emitData() {
            var formData = {
                line_holder_state_id: this.stateId,
                finance_company_master_id: this.financeId,
                line_holder_diff_state_id: this.diffStateId,
                finance_income: this.financeIncome,
                first_payment_date: moment(String(this.firstPaymentDate)).format('MM/DD/YYYY'),
                rate: this.rate,
                term: this.term,
                lease_company: this.leaseCompany,
                line_holder_name: this.lineHolderName,
                line_holder_address: this.lineHolderAddress,
                line_holder_diff_address: this.lineHolderDiffAddress,
                line_holder_city: this.lineHolderCity,
                line_holder_diff_city: this.lineHolderDiffCity,
                line_holder_zip: this.lineHolderZip,
                line_holder_diff_zip: this.lineHolderDiffZip,
                msrp: this.msrp,
                add_to_msrp: this.addToMsrp,
                adjusted_msrp: this.adjustedMsrp,
                residual_adjusted_msrp: this.residualAdjustedMsrp,
                residual: this.residual,
                sub_total: this.subTotal,
                mileage_adjustment: this.mileageAdjustement,
                less_other_factors_affecting_depreciation: this.lessOtherFactors,
                adjusted_residual_value: this.adjustedResidualValue,
                capitalized_cost: this.capitalizedCost,
                cash_cap_reduction: this.cashCapReduction,
                net_trade_in_allowance: this.netTradeInAllowance,
                net_capitalized_cost: this.netCapitalizedCost,
                base_monthly_rental: this.baseMonthlyRental,
                total_sales_tax: this.totalSalesTax,
                total_monthly_payment: this.totalMonthlyPayment,
                anitcipated_monthly_mileage: this.anitcipatedMonthlyMileage,
                less_monthly_mileage: this.lessMonthlyMileage,
                excess_mileage_per_month: this.excessMileagePerMonth,
                excess_mileage_term: this.excessMileageTerm,
                total_excess_mileage_for_term: this.totalExcessMileageForTerm,
                milaege_penalty: this.mileagePenalty,
                excess_mileage_adjustment: this.excessMileageAdjustment,
                cap_cost_reduction: this.capCostReduction,
                excess_mileage_net_trade_in_allowance: this.excessMileageNetTradeInAllowance,
                first_monthly_payment: this.firstMonthlyPayment,
                total_initial_fees: this.totalInitialFees,
                amount_due_at_start: this.amountDueStart,
                equip_replacement_value: this.equipReplacementValue,
                doc_fee: this.docFee,
                first_payment: this.firstPayment,
                license_fee: this.liecenseFee,
                second_payment: this.secondPayment,
                final_payment: this.finalPayment,
                first_payment_sales_tax: this.firstPaymentSalesTax,
                first_payment_handeling_fee: this.firstPaymentHandelingFee,
                first_payment_total: this.firstPaymentTotal,
                second_payment_sales_tax: this.secondPaymentSalesTax,
                second_payment_handeling_fee: this.secondPaymentHandelingFee,
                second_payment_total: this.secondPaymentTotal,
                final_payment_handeling_fee: this.finalPaymentHandelingFee,
                final_payment_sales_tax: this.finalPaymentSalesTax,
                final_payment_total: this.finalPaymentTotal,
                id: this.id !== 0 ? this.id : 0
            };
            this.$emit('leaseData', formData);
        },

        fillData(data) {
            this.id = data.id,
            this.stateId = data.line_holder_state_id,
            this.financeId = data.finance_company_master_id,
            this.diffStateId = data.line_holder_diff_state_id,
            this.financeIncome = data.finance_income,
            this.firstPaymentDate = data.first_payment_date,
            this.rate = data.rate
            this.term = data.term,
            this.leaseCompany = data.lease_company,
            this.lineHolderName = data.line_holder_name,
            this.lineHolderAddress = data.line_holder_address,
            this.lineHolderDiffAddress = data.line_holder_diff_address,
            this.lineHolderCity = data.line_holder_city,
            this.lineHolderDiffCity = data.line_holder_diff_city,
            this.lineHolderZip = data.line_holder_zip,
            this.lineHolderDiffZip = data.line_holder_diff_zip,
            this.msrp = data.msrp,
            this.addToMsrp = data.add_to_msrp,
            this.adjustedMsrp = data.adjusted_msrp,
            this.residualAdjustedMsrp = data.residual_adjusted_msrp,
            this.residual = data.residual,
            this.subTotal = data.sub_total,
            this.mileageAdjustement = data.mileage_adjustment,
            this.lessOtherFactors = data.less_other_factors_affecting_depreciation,
            this.adjustedResidualValue = data.adjusted_residual_value,
            this.capitalizedCost = data.capitalized_cost,
            this.cashCapReduction = data.cash_cap_reduction,
            this.netTradeInAllowance = data.net_trade_in_allowance,
            this.netCapitalizedCost = data.net_capitalized_cost,
            this.baseMonthlyRental = data.base_monthly_rental,
            this.totalSalesTax = data.total_sales_tax,
            this.totalMonthlyPayment = data.total_monthly_payment,
            this.anitcipatedMonthlyMileage = data.anitcipated_monthly_mileage,
            this.lessMonthlyMileage = data.less_monthly_mileage,
            this.excessMileagePerMonth = data.excess_mileage_per_month,
            this.excessMileageTerm = data.excess_mileage_term,
            this.totalExcessMileageForTerm = data.total_excess_mileage_for_term,
            this.mileagePenalty = data.milaege_penalty,
            this.excessMileageAdjustment = data.excess_mileage_adjustment,
            this.capCostReduction = data.cap_cost_reduction,
            this.excessMileageNetTradeInAllowance = data.excess_mileage_net_trade_in_allowance,
            this.firstMonthlyPayment = data.first_monthly_payment,
            this.totalInitialFees = data.total_initial_fees,
            this.amountDueStart = data.amount_due_at_start,
            this.equipReplacementValue = data.equip_replacement_value,
            this.docFee = data.doc_fee,
            this.firstPayment = data.first_payment,
            this.liecenseFee = data.license_fee,
            this.secondPayment = data.second_payment,
            this.finalPayment = data.final_payment,
            this.firstPaymentSalesTax = data.first_payment_sales_tax,
            this.firstPaymentHandelingFee = data.first_payment_handeling_fee,
            this.firstPaymentTotal = data.first_payment_total,
            this.secondPaymentSalesTax = data.second_payment_sales_tax,
            this.secondPaymentHandelingFee = data.second_payment_handeling_fee,
            this.secondPaymentTotal = data.second_payment_total,
            this.finalPaymentHandelingFee = data.final_payment_handeling_fee,
            this.finalPaymentSalesTax = data.final_payment_sales_tax,
            this.finalPaymentTotal = data.final_payment_total
        },
    },
    watch: {

        companyId: function(selectedFinanceCompany) {
            this.setData(selectedFinanceCompany,'finance');
        },

        state: function(selectedState) {
            this.setData(selectedState,'state');
        },
       
        diffState: function (selectedDiffState) {
            this.setData(selectedDiffState, 'diffState');
        },

        financeIncome: function () {
            this.emitData();
        },

        firstPaymentDate: function () {
            this.emitData();
        },

        rate: function () {
            this.emitData();
        },

        term: function () {
            this.emitData();
        },

        leaseCompany: function () {
            this.emitData();
        },

        lineHolderName: function () {
            this.emitData();
        },

        lineHolderAddress: function () {
            this.emitData();
        },

        lineHolderDiffAddress: function () {
            this.emitData();
        },

        lineHolderCity: function () {
            this.emitData();
        },

        lineHolderDiffCity: function () {
            this.emitData();
        },

        lineHolderZip: function () {
            this.emitData();
        },

        lineHolderDiffZip: function () {
            this.emitData();
        },

        msrp: function () {
            this.emitData();
        },

        addToMsrp: function () {
            this.emitData();
        },

        adjustedMsrp: function () {
            this.emitData();
        },

        residualAdjustedMsrp: function () {
            this.emitData();
        },

        residual: function () {
            this.emitData();
        },

        subTotal: function () {
            this.emitData();
        },

        mileageAdjustement: function () {
            this.emitData();
        },

        lessOtherFactors: function () {
            this.emitData();
        },

        adjustedResidualValue: function () {
            this.emitData();
        },

        capitalizedCost: function () {
            this.emitData();
        },

        cashCapReduction: function () {
            this.emitData();
        },

        netTradeInAllowance: function () {
            this.emitData();
        },

        netCapitalizedCost: function () {
            this.emitData();
        },

        baseMonthlyRental: function () {
            this.emitData();
        },

        totalSalesTax: function () {
            this.emitData();
        },

        totalMonthlyPayment: function () {
            this.emitData();
        },

        anitcipatedMonthlyMileage: function () {
            this.emitData();
        },

        lessMonthlyMileage: function () {
            this.emitData();
        },

        excessMileagePerMonth: function () {
            this.emitData();
        },

        excessMileageTerm: function () {
            this.emitData();
        },

        totalExcessMileageForTerm: function () {
            this.emitData();
        },

        mileagePenalty: function () {
            this.emitData();
        },

        excessMileageAdjustment: function () {
            this.emitData();
        },

        capCostReduction: function () {
            this.emitData();
        },

        excessMileageNetTradeInAllowance: function () {
            this.emitData();
        },

        firstMonthlyPayment: function () {
            this.emitData();
        },

        totalInitialFees: function () {
            this.emitData();
        },

        amountDueStart: function () {
            this.emitData();
        },

        equipReplacementValue: function () {
            this.emitData();
        },

        docFee: function () {
            this.emitData();
        },

        firstPayment: function () {
            this.emitData();
        },

        liecenseFee: function () {
            this.emitData();
        },

        secondPayment: function () {
            this.emitData();
        },

        finalPayment: function () {
            this.emitData();
        },

        firstPaymentSalesTax: function () {
            this.emitData();
        },

        firstPaymentHandelingFee: function () {
            this.emitData();
        },

        firstPaymentTotal: function () {
            this.emitData();
        },

        secondPaymentSalesTax: function () {
            this.emitData();
        },

        secondPaymentHandelingFee: function () {
            this.emitData();
        },

        secondPaymentTotal: function () {
            this.emitData();
        },

        finalPaymentHandelingFee: function () {
            this.emitData();
        },

        finalPaymentSalesTax: function () {
            this.emitData();
        },

        finalPaymentTotal: function () {
            this.emitData();
        },

        leaseData: function (newValue) {
            this.fillData(newValue);
        },

        financeCompanyStateArr: function(currentStatelist) {
            self.stateArr = currentStatelist;
            if(this.isEdit){
                currentStatelist.filter(state => {
                    if(state.value === self.leaseData.line_holder_state_id) {
                        self.state = {
                            text: state.text,
                            value: state.value
                        }
                    };

                    if(state.value === self.leaseData.line_holder_diff_state_id) {
                        self.diffState = {
                            text: state.text,
                            value: state.value
                        }
                    }
                });
            }
        },
        
        selectFinanceCompanyArr: function(currentFinanceList) {
            self.companyArr = currentFinanceList;
            if(this.isEdit){
                currentFinanceList.filter(financeCompany => {
                    if(financeCompany.value === self.financeId) {
                        self.companyId = {
                            text: financeCompany.text,
                            value: financeCompany.value
                        }
                    }
                });
            }
        }   
    }
};
</script>