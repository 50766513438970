<template>
    <div id="main-header">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>{{ allStaticMessages.test_env_msg}}</p>
                </div>
            </div>
        </div>
        <nav class=" ">
            <header class="navbar navbar-default no-margin app-sidebar-nav">
                <div class="logo-section ">
                    <a href="javascript:void(0)" v-on:click="dashboardPageRedirect()">
                        <img src="/static/images/logo.jpg" :alt="allLabelMessages.velocity_vehicle_group">
                    </a>
                </div>
                <div class="action-btn-section ">
                    <label class="dropdown" ref="dropdown">
                        <div class="dd-button">
                            <span>  
                                <img src="/static/images/avtar-img.png" :alt="allLabelMessages.vendor_account"> 
                            </span> 
                            {{ allLabelMessages.my_account}}
                        </div>
                        <input type="checkbox" class="dd-input" id="test" v-model="isMyAccClick">
                        <ul class="dd-menu">
                            <li>
                                <a class="dropdown-item" href="javascript:void(0)" v-on:click.prevent="logout()">{{ allLabelMessages.logout}}</a>
                            </li>
                        </ul>
                    </label>
                </div>
            </header>
            <a href="javascript:void(0)" id="scroll" :title="allLabelMessages.scroll_to_top" style="display: none;"><span>Top</span></a>
        </nav>
    </div>
</template>

<script>
var self;
import { commonMixins } from "../../mixins/commonMixins";

export default {
    name: 'VendorHeader',
    mixins: [commonMixins],
    data() {
        return {
            isMyAccClick: false
        }
    },
    beforeMount: function () {
        self = this;
    },
    mounted: function () {
        self = this;

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#scroll').fadeIn();
            } else {
                $('#scroll').fadeOut();
            }
        });
        $('#scroll').click(function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });
        document.addEventListener("click", self.handleClickOutside);
    },
    beforeDestroy() {
        self = this;
        document.removeEventListener("click", self.handleClickOutside);
    },
    methods: {
        dashboardPageRedirect: function() {
            self.$router.push({name:'VendorDashboard'});
        },
        logout: function () {
            let current_build = window.localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
            self.$router.push("/vendor/login");
            self.isMyAccClick = false;
        },
        handleClickOutside(event) {
            if (self.$refs.dropdown && !self.$refs.dropdown.contains(event.target)) {
                self.isMyAccClick = false;
            }
        }
    }
}
</script>
<style lang="css" scoped>
.navbar.navbar-default.no-margin.app-sidebar-nav {
    border-radius: 0;
    border: none;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    height: auto;
    padding: 20px 10px;
}
.action-btn-section {
    float: right;
    width: auto;
    text-align: right;
}
.action-btn-section .dropdown-toggle {
    height: 43px;
    text-align: left;
}
.action-btn-section label.dropdown {
    margin-bottom: 0;
}
.dd-button {
    display: inline-block;
    border: 1px solid #bababa;
    border-radius: 4px;
    padding: 12px 35px 10px 15px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    color: #656565;
    font-family: 'SanFranciscoDisplayMedium';
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}
.dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}
.dd-button:hover {
    background-color: #0069d9;
    border-color: #0069d9;
    color: #fff;
}
.dd-button:hover::after {
    border-top: 5px solid #fff;
}
.dd-button:hover img {
    filter: brightness(0) invert(1);
}
.dd-button span {
    float: left;
    padding-right: 8px;
}
.dd-input,
.dd-input + .dd-menu  {
    display: none;
}
.dd-input:checked + .dd-menu {
    display: block;
    z-index: 99;
}
.dd-menu {
    position: absolute;
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    width: 100%;
}
nav ul {
    list-style: none;
}
.dd-menu li a {
    display: block;
    margin: 0;
    padding: 10px 20px;
    max-width: 100%;
    width: 100%;
    float: left;
    text-align: left;
}
.dropdown-item {
    clear: both;
    font-weight: 400;
    color: #212529;
    white-space: nowrap;
    background-color: initial;
    border: 0;
    font-size: 16px;
}
#scroll {
    position: fixed;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #015cb7;
    text-indent: -9999px;
    display: none;
    z-index: 9999; 
}
#scroll span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -9px;
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-bottom-color: #ffffff; 
}
</style>