<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_information_report')">
      <div class="container-fluid xyz info-report-list-view">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.info_report_label }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a id="refreshList" title="Refresh" class="extraFeature btn btn-primary waves-effect pull-right ml-2" v-on:click.prevent="refreshList()">
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
            <button type="button" id="exportInfoReportData" title="Export" v-show="permissionNameArray.includes('can_export_information_report') && isExportIconShow"
                    class="btn btn-primary waves-effect pull-right"
                    v-on:click="exportInfoReportData($event)">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </button>
          </div>
          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            :isResetSorting="false"
          ></filter-component>
          <div class="col-12 paddingnone">
            <common-loader refId="commonLoaderListInfoReport" ref="commonLoaderListInfoReport"></common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div class="table-listing white-bg border-blue border-radius-top-right">
              <table class="table custom-table jsmartable">
                <tbody>
                  <template v-if="infoReporDataArr && infoReporDataArr.length > 0">
                    <tr v-for="(infoReporData, index) in infoReporDataArr" :key="index">
                      <td :class="rowClass.unit_id">{{ infoReporData.unit_id }}</td>
                      <td :class="rowClass.branch_no">{{ infoReporData.branch_no }}</td>
                      <td :class="rowClass.invoice_date">{{ infoReporData.invoice_date }}</td>
                      <td :class="rowClass.vin">{{ infoReporData.vin }}</td>
                      <td :class="rowClass.order_type">{{ infoReporData.order_type }}</td>
                      <td :class="rowClass.status">{{ infoReporData.status }}</td>
                      <td :class="rowClass.type">{{ infoReporData.type }}</td>
                      <td :class="rowClass.deal_no">{{ infoReporData.deal_no }}</td>
                      <td :class="rowClass.invoice_no">{{ infoReporData.invoice_no }}</td>
                      <td :class="rowClass.contract_no">{{ infoReporData.contract_no }}</td>
                      <td :class="rowClass.customer_id">{{ infoReporData.customer_id }}</td>
                      <td :class="rowClass.customer_name">{{ infoReporData.customer_name }}</td>
                      <td :class="rowClass.payment_information">{{ infoReporData.payment_information }}</td>
                      <td :class="rowClass.finance_company">{{ infoReporData.finance_company }}</td>
                      <td :class="rowClass.lease_company">{{ infoReporData.lease_company }}</td>
                      <td :class="rowClass.reserve">{{ infoReporData.reserve }}</td>
                      <td :class="rowClass.reserve_control">{{ infoReporData.reserve_control }}</td>
                      <td :class="rowClass.finance_reserve">{{ infoReporData.finance_reserve }}</td>
                      <td :class="rowClass.f_and_i_bonus_for_salesperson">{{ infoReporData.f_and_i_bonus_for_salesperson }}</td>
                      <td :class="rowClass.sales_rep">{{ infoReporData.sales_rep }}</td>
                      <td :class="rowClass.sales_rep_excede_id">{{ infoReporData.sales_rep_excede_id }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>

        <!-- Export Modal -->
        <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="exportModalLabel">{{ allLabelMessages.export }} {{ allLabelMessages.info_report_label }}</h4>
              </div>
              <form class="form-inline" id="exportModalForm" enctype="multipart/form-data">
                <div class="col-md-12 modal-body" id="modal-checkbox">
                  <template v-if="exportHeaders && exportHeaders.length > 0">
                    <!-- Select All -->
                    <div class="col-12 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input type="checkbox" v-model="isSelectAllExportHeader" @click="selectAllExportHeaders()" class="form-control" id="ExportList"/>
                        <strong v-if="isSelectAllExportHeader">
                          <p class="modal-check-lbl">
                            {{ allLabelMessages.deselect_all_label }}
                          </p>
                        </strong>
                        <strong v-else>
                          <p class="modal-check-lbl">
                            {{ allLabelMessages.select_all_label }}
                          </p>
                        </strong>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <template v-for="(exportOpp, exportIndex) in exportHeaders">
                      <div :key="exportIndex" class="col-md-12 paddingleft0 custom-checkbox-v">
                        <label class="container">
                          <input :value="exportOpp" class="form-control" type="checkbox" v-model="exportSelectedHeaders" @change="updateCheckedExportHeaders()"/>
                          <p class="modal-check-lbl">{{ exportOpp["name"] }}</p>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="row clearfix"></div>
                <div class="col-md-12 modal-footer">
                  <button type="button" v-on:click="exportSelectedHeadersData($event)" class="btn general-button btn-success waves-effect">
                    {{ allLabelMessages.export }}
                  </button>
                  <button type="button" class="btn general-button btn-danger waves-effect" data-bs-dismiss="modal">
                    {{ allLabelMessages.close }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";

var self;
export default {
  name: "ListInformationReport",
  mixins: [commonMixins],
  components: {
    'v-select': vSelect,
    'filter-component': FilterComponent,
    'no-access': NoAccessPage,
    'common-loader': CommonLoader,
    'app-footer': Footer
  },
  data() {
    return {
      permissionNameArray: ["can_list_information_report"],
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      infoReporDataArr: [],
      headers: [],
      filterheaders: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: 'id',
      sortType: 'asc',
      startEndRecord: "",
      jumpToHistoryPage: 1,
      filterCount: 0,
      listExecutionState: false,
      exportData: false,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      isExportIconShow: false,
      exportListInformationReportInstance: null,
    };
  },
  beforeMount() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    self = this;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("info-report-list");
    EventBus.$emit("menu-selection-changed", "info-report-list");

    var listHistoryData = self.loadFilters("listInfoReport", this);
    self.jumpToHistoryPage = listHistoryData === null || typeof listHistoryData === "undefined" || listHistoryData == "" ? 1 : listHistoryData.page;
    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listInfoReport(1);
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.exportListInformationReportInstance = new  bootstrap.Modal(
        document.getElementById("exportModal")
      );
    },
    // resetSorting: function () {
    //   self.columnName = 'id';
    //   self.sortType = 'asc';
    //   self.changepage();
    // },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.listInfoReport();
    },
    changepage: function () {
      if (!self.listExecutionState) {
        self.listInfoReport();
      }
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.listInfoReport();
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.listInfoReport();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
        //self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    listInfoReport: function (first) {
      self.listExecutionState = true;
      if (self.perPage == "All" && self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA) {
        self.showSAlert(self.allStaticMessages.too_many_data_for_all_list,"info",false);
        return false;
      }
      first = first === null || typeof first === "undefined" || first == "" ? 0 : first;

      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportData,
        export_selected_headers: self.exportSelectedHeaders
      };
      self.storeFilters("listInfoReport", fields, false);
      $("#commonLoaderListInfoReport").find(".loading-container.lodingTable").show();
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/report/information", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          $("#commonLoaderListInfoReport").find(".loading-container.lodingTable").hide();

          self.exportSelectedHeaders = self.loadExportHeaders("exportInfoReport", self);
          self.exportSelectedHeaders = self.exportSelectedHeaders === null 
                                      || typeof self.exportSelectedHeaders === "undefined" 
                                      || self.exportSelectedHeaders == ""
                                        ? [] 
                                        : self.exportSelectedHeaders;
          self.exportListInformationReportInstance.hide();
          $("#exportListData").prop("disabled", false);
          self.exportData = false;

          if (data.status == 200) {
            self.isExportIconShow = data.data.is_export_icon_show;
            if (data.data.message == "success export") {
              self.downloadFile(data.data.content.web);
            } else {
              self.headers = [];
              self.totalRecords = data.data.content.result;
              self.infoReporDataArr = data.data.content.data;
              self.headers = data.data.content.headers;
              self.filterheaders = data.data.content.filterheaders;
              self.exportHeaders = data.data.content.exportheaders;
              if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
                self.isSelectAllExportHeader = true;
              }

              self.loadFilterInputs(data.data.content.filterheaders, self.filters);

              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
          EventBus.$emit("is-open-filter-component", true);
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#exportListData").prop("disabled", false);
          self.exportData = false;
          $("#commonLoaderListInfoReport").find(".loading-container.lodingTable").hide();
          self.catchHandler(error, function () {});
          EventBus.$emit("is-open-filter-component", false);
        });
    },
    // sortTableData: function (name) {
    //   if (self.columnName == name) {
    //     self.sortType = self.sortType == "desc" ? "asc" : "desc";
    //   } else {
    //     self.sortType = "asc";
    //   }
    //   self.columnName = name;
    //   self.listInfoReport();
    // },
    exportInfoReportData: function () {
      self.exportListInformationReportInstance.show();
    },
    exportSelectedHeadersData: function () {
      if (self.exportSelectedHeaders.length == 0) {
        self.showSAlert("Please Select at least one checkbox", "info", false);
        return false;
      }

      self.exportData = true;
      if (self.exportData == true) {
        $("#exportListData").prop("disabled", true);
        self.listInfoReport();
      }
      if (
        self.exportSelectedHeaders != null &&
        self.exportSelectedHeaders != "undefined" &&
        self.exportSelectedHeaders.length > 0
      ) {
        self.storeExportHeaders("exportInfoReport", self.exportSelectedHeaders);
      } else {
        self.exportSelectedHeaders = [];
      }
    },
    selectAllExportHeaders: function () {
      self.exportSelectedHeaders = [];
      if (!self.isSelectAllExportHeader) {
        for (let key in self.exportHeaders) {
          self.exportSelectedHeaders.push(self.exportHeaders[key]);
        }
      }
    },
    updateCheckedExportHeaders: function () {
      self.isSelectAllExportHeader = (self.exportSelectedHeaders.length == self.exportHeaders.length) ? true : false;
    }
  }
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
<style scoped>
  .page_title a.btn {
    background: #007bff;
  }
  .info-report-list-view .vgt-wrap__footer.crm {
    padding: 12px;
  }
  .info-report-list-view .vgt-wrap__footer.crm .pagination {
    margin-bottom: 0;
  }
</style>

