<template>
    <div class="no-margin">
        <div class="row">
            <div class="col-md-12 col-sm-12 forms-block">
                <div class="book-deal-customer-block text text-center">
                    Finance Details
                </div>
            </div>
        </div>

        <div class="row no-margin py-2">
            <div class="col-md-6 col-sm-6 forms-block min-margin">
                <div class="form-label deallabel text-right">
                    Annuale Percentage Rate:
                </div>
            </div>

            <div class="col-md-6 col-sm-6">
                <input class="form-control" type="text" style="width: 50%" v-model="annualPercentageRate" />
            </div>
        </div>
        <hr>

        <div class="row no-margin">
            <div class="col-md-12 col-sm-12 forms-block">
                <div class="book-deal-customer-block text text-left">
                    Dealer:
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <label for="">Dealer Rate:</label>
                        <input class="form-control" type="text" v-model="dealerRate"/>
                    </div>
                </div>
            </div>
    
            <div class="col-md-6">
                <div class="col-md-12 col-sm-6">
                    <label for="">Finance Income:</label>
                    <input class="form-control" type="text" v-model="financeIncome"/>
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <label for="">Days to first payment:</label>
                        <input class="form-control" type="text" v-model="firstPaymentDays"/>
                        <hr>
                        <label for="">Term:</label>
                        <input class="form-control" type="text" v-model="term"/>
                        <hr>
                        <label for="">Sales Subtotal:</label>
                        <input class="form-control" type="text" v-model="salesSubTotal" />
                        <hr>
                        <label for="">Warranty : (not taxable)</label>
                        <input class="form-control" type="text" v-model="warranty"/>
                        <hr>
                        <label for="">Total Sales Tax:</label>
                        <input class="form-control" type="text" v-model="totalSalesTax"/>
                        <hr>
                        <label for="">Finance Charge :</label>
                        <input class="form-control" type="text" v-model="financeCharge"/>
                        <hr>
                        <label for="">Deferred Price :</label>
                        <input class="form-control" type="text" v-model="deferredPrice"/>
                    </div>
                </div>
            </div>
    
            <div class="col-md-6">
                <div class="col-md-12 col-sm-6">
                    <label for="">First payment date:</label>
                        <date-picker
                            v-model="firstPaymentDate"
                            :config="options"
                            class="form-control"
                            autocomplete="off"
                            :placeholder="allStaticMessages.contract_date_place_holder"
                            name="firstPaymentDate"
                        >
                        </date-picker>
                    <hr>
                    <label for="">Payment --></label>
                    <input class="form-control" type="text" v-model="payment"/>
                    <hr>
                    <label for="">Fees Subtotal:</label>
                    <input class="form-control" type="text" v-model="feesSubTotal"/>
                    <hr>
                    <label for="">Insurance:</label>
                    <input class="form-control" type="text" v-model="insurance"/>
                    <hr>
                    <label for="">Total Financed :</label>
                    <input class="form-control" type="text" v-model="totalFinanced"/>
                    <hr>
                    <label for="">Total of Payments :</label>
                    <input class="form-control" type="text" v-model="totalOfPayments"/>
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-md-6">
                <div class="row no-margin py-2">
                    <div class="col-md-12 col-sm-6 forms-block min-margin">
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">Select Finance Company:</label>
                                <v-select v-model="companyId" name="state"
                                label="text" 
                                :filterable="false" 
                                :options="financeCompanyArr"
                                @search="onSearchFinanceCompany" 
                                :clearable="true" 
                                @input="companyError = ''"
                                :placeholder="allStaticMessages.company_custom_place_holder" 
                                class="custom-ci-auto-search"
                                >
                                <span slot="no-options" @click="$refs.select.open = false">
                                    <template v-if="!isCompanyRecordNotFound">
                                        {{allStaticMessages.company_custom_search}}
                                    </template>
                                    <template v-else>
                                        {{allStaticMessages.search_record_not_found}}
                                    </template>
                                </span>
                                </v-select>
                                <span class="help is-danger">{{companyError}}</span>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">Finance Company:</label>
                                <input class="form-control" type="text" v-model="financeCompanyName"/>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">Address:</label>
                                <input class="form-control" type="text" v-model="financeCompanyAddress"/>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">City:</label>
                                <input class="form-control" type="text" v-model="financeCompanyCity"/>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">State:</label>
                                <v-select v-model="state" name="state"
                                    label="text" 
                                    :filterable="false" 
                                    :options="stateArr"
                                    @search="onSearchState" 
                                    :clearable="false" 
                                    @input="stateError = ''"
                                    :placeholder="allStaticMessages.state_place_holder" class="custom-ci-auto-search">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        <template v-if="!isStateRecordNotFound">
                                            {{allStaticMessages.state_search}}
                                        </template>
                                        <template v-else>
                                            {{allStaticMessages.search_record_not_found}}
                                        </template>
                                    </span>
                                </v-select>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-10">
                                <label for="">ZIP:</label>
                                <input class="form-control" type="text" v-model="financeCompanyZip"/>
                            </div>
                            <div class="col-md-2">
                                <div class="text-danger font-weight-bold">*</div>
                            </div>
                        </div>
                        <hr>
                        <label for="">Comment:</label>
                        <input class="form-control" type="text" v-model="comment"/>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="col-md-12 col-sm-6">
                    <label for="">Send docs to (if different then above):</label>
                    <input class="form-control" type="text" v-model="diffName"/>
                    <hr>
                    <label for="">Address:</label>
                    <input class="form-control" type="text" v-model="diffAddress"/>
                    <hr>
                    <label for="">City :</label>
                    <input class="form-control" type="text" v-model="diffCity"/>
                    <hr>
                    <label for="">State:</label>
                    <v-select v-model="diffState" name="state"
                        label="text" 
                        :filterable="false" 
                        :options="stateArr"
                        @search="onSearchState" 
                        :clearable="false" 
                        @input="stateError = ''"
                        :placeholder="allStaticMessages.state_place_holder" class="custom-ci-auto-search">
                        <span slot="no-options" @click="$refs.select.open = false">
                            <template v-if="!isStateRecordNotFound">
                                {{allStaticMessages.state_search}}
                            </template>
                            <template v-else>
                                {{allStaticMessages.search_record_not_found}}
                            </template>
                        </span>
                    </v-select>  
                    <hr>
                    <label for="">ZIP:</label>
                    <input class="form-control" type="text" v-model="diffZip"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { commonMixins } from "../../../mixins/commonMixins";
import { HTTP } from "../../../http-common";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from 'moment';

export default {
    name: "FinanceTab",
    components: {
        datePicker
    },
    mixins: [commonMixins],
    props: {
        financeCompanyStateArr: [],
        selectFinanceCompanyArr: [],
        financeData: '',
        isEdit: false
    },
    data() {
        return {
            options: {
                format: "MM/DD/YYYY",
                useCurrent: false,
                allowInputToggle: true,
                widgetPositioning: {
                horizontal: "auto",
                vertical: "bottom"
                }
            },

            financeCompanyArr: '',
            stateArr: '',

            companyId: null,
            state: '',
            diffState: '',
            
            stateId: null,
            financeId: null,
            diffStateId: null,
            annualPercentageRate: null,
            dealerRate: 0,
            financeIncome: 0,
            term: null,
            firstPaymentDays: null,
            firstPaymentDate: null,
            payment: 0,
            salesSubTotal: 0,
            feesSubTotal: 0,
            warranty: 0,
            insurance: 0,
            totalSalesTax: 0,
            totalFinanced: 0,
            financeCharge: 0,
            totalOfPayments: 0,
            deferredPrice: 0,
            financeCompanyName: null,
            financeCompanyAddress: null,
            financeCompanyCity: null,
            financeCompanyZip: null,
            comment: null,
            diffName: null,
            diffAddress: null,
            diffCity: null,
            diffState: null,
            diffZip: null,

            isCompanyRecordNotFound: false,
            isMakeRecordNotFound: false,
            isModelRecordNotFound: false,
            isConditionRecordNotFound: false,
            isStateRecordNotFound: false,
            isCityRecordNotFound: false,
            companyError: '',
            id: 0
        };
    },
    methods: {
        setData(data, type) {
            switch (type) {
                case 'state':
                    this.stateId = data.value;
                    this.emitData();
                    break;
                case 'finance':
                    // this.showLoader();
                    this.financeId = data.value;
                    var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                        params: {
                            id: data.value,
                        }
                    };
                    var self = this;
                    HTTP.get("/financegroup/get", config)
                        .then(function (data) { 
                            if (data.status == 200) { 
                                var finance = data.data.content;
                                self.financeCompanyName = finance.name;
                                self.financeCompanyAddress = finance.address;
                                self.financeCompanyCity = finance.city;
                                self.stateData = {
                                    text: finance.state_name,
                                    value: finance.state_id
                                };
                                self.financeCompanyZip = holder.zip_code;
                                self.emitData();
                            }
                         })
                        .catch(function (error) {
                            // self.hideLoader();
                            self.catchHandler(error, function () {
                                console.log('something wrong');
                            });
                        })
                    break;
                case 'diffState':
                    this.diffStateId = data.value;
                    this.emitData();
                    break;
            }
        },

        onSearchFinanceCompany(search, loading) {
            if (search.length > 2) {
                loading(true);
                this.loadAllData(['FinanceGroup'], true, this.loadFinanceCompanyDataCallback, loading, search);
            }
        },
        loadFinanceCompanyDataCallback: function (callbackData) {
            this.isCompanyRecordNotFound = false;
            this.financeCompanyArr = callbackData.FinanceGroup;
            if(this.financeCompanyArr.length == 0) {
                this.isCompanyRecordNotFound = true;
            }
        },

        onSearchState(search, loading) {
            if (search.length > 1) {
                loading(true);
                this.loadAllData(['States'], true, this.loadStateDataCallback, loading, search);
            }
        },
        loadStateDataCallback: function (callbackData) {
            this.isStateRecordNotFound = false;
            this.stateArr = callbackData.States;
            if(this.stateArr.length == 0) {
                this.isStateRecordNotFound = true;
            }
        },

        calculateFinance() {
            const salesSubTotal = Number(this.salesSubTotal);
            const feesSubTotal = Number(this.feesSubTotal);
            const warranty = Number(this.warranty);
            const insurance = Number(this.insurance);
            const totalSalesTax = Number(this.totalSalesTax);

            if (Number.isNaN(salesSubTotal) || Number.isNaN(feesSubTotal) || Number.isNaN(warranty) || Number.isNaN(insurance) || Number.isNaN(totalSalesTax)) return;

            this.totalFinanced = salesSubTotal+feesSubTotal+warranty+insurance+totalSalesTax;
        },

        calculatePayment() {
            const totalFinanced = Number(this.totalFinanced);
            const financeCharge = Number(this.financeCharge);

            if (Number.isNaN(totalFinanced) || Number.isNaN(financeCharge)) return;

            this.totalOfPayments = totalFinanced+financeCharge;
        },

        emitData() {
            var formData = {
                finance_company_master_id: this.financeId,
                finance_company_state_id: this.stateId,
                finance_company_diff_state_id: this.diffStateId,
                annual_percentage_rate: this.annualPercentageRate,
                finance_income: this.financeIncome,
                dealer_rate: this.dealerRate,
                term: this.term,
                first_payment_days: this.firstPaymentDays,
                first_payment_date: moment(String(this.firstPaymentDate)).format('MM/DD/YYYY'),
                payment: this.payment,
                sales_sub_total: this.salesSubTotal,
                fees_sub_total: this.feesSubTotal,
                warranty: this.warranty,
                insurance: this.insurance,
                total_sales_tax: this.totalSalesTax,
                total_financed: this.totalFinanced,
                finance_charge: this.financeCharge,
                total_of_payments: this.totalOfPayments,
                deferred_price: this.deferredPrice,
                finance_company_name: this.financeCompanyName,
                finance_company_address: this.financeCompanyAddress,
                finance_company_city: this.financeCompanyCity,
                finance_company_zip: this.financeCompanyZip,
                comment: this.comment,
                diff_name: this.diffName,
                diff_address: this.diffAddress,
                diff_city: this.diffCity,
                diff_state: this.diffState, 
                diff_zip: this.diffZip,
                id: this.id !== 0 ? this.id : 0
            };
            this.$emit('financeData', formData);
        },

        fillData(data) {
            this.id = data.id,
            this.financeId = data.finance_company_master_id,
            this.stateId = data.finance_company_state_id,
            this.diffStateId = data.finance_company_diff_state_id,
            this.annualPercentageRate = data.annual_percentage_rate,
            this.financeIncome = data.finance_income,
            this.dealerRate = data.dealer_rate,
            this.term = data.term,
            this.firstPaymentDays = data.first_payment_days,
            this.firstPaymentDate = data.first_payment_date,
            this.payment = data.payment,
            this.salesSubTotal = data.sales_sub_total ? parseInt(data.sales_sub_total) : data.sales_sub_total,
            this.feesSubTotal = data.fees_sub_total ? parseInt(data.fees_sub_total) : data.fees_sub_total,
            this.warranty = data.warranty ? parseInt(data.warranty) : data.warranty,
            this.insurance = data.insurance ? parseInt(data.insurance) : data.insurance,
            this.totalSalesTax = data.total_sales_tax ? parseInt(data.total_sales_tax) : data.total_sales_tax,
            this.totalFinanced = data.total_financed ? parseInt(data.total_financed) : data.total_financed,
            this.financeCharge = data.finance_charge ? parseInt(data.finance_charge) : data.finance_charge,
            this.totalOfPayments = data.total_of_payments ? parseInt(data.total_of_payments) : data.total_of_payments,
            this.deferredPrice = data.deferred_price ? parseInt(data.deferred_price) : data.deferred_price,
            this.financeCompanyName = data.finance_company_name,
            this.financeCompanyAddress = data.finance_company_address,
            this.financeCompanyCity = data.finance_company_city,
            this.financeCompanyZip = data.finance_company_zip,
            this.comment = data.comment,
            this.diffName = data.diff_name,
            this.diffAddress = data.diff_address,
            this.diffCity = data.diff_city,
            this.diffState = data.diff_state,
            this.diffZip = data.diff_zip
        },
    },
    watch: {
        companyId: function(selectedFinanceCompany) {
            this.setData(selectedFinanceCompany,'finance');
        },

        state: function(selectedState) {
            this.setData(selectedState,'state');
        },

        diffState: function(selectedDiffState) {
            this.setData(selectedDiffState,'diffState');
        },

        annualPercentageRate: function() {
            this.emitData();
        },

        dealerRate: function() {
            this.emitData();
        },

        financeIncome: function() {
            this.emitData();
        },

        term: function() {
            this.emitData();
        },

        firstPaymentDays: function() {
            this.emitData();
        },

        payment: function() {
            this.emitData();
        },

        salesSubTotal: function() {
            this.calculateFinance();
            this.emitData();
        },

        feesSubTotal: function() {
            this.calculateFinance();
            this.emitData();
        },

        warranty: function() {
            this.calculateFinance();
            this.emitData();
        },

        insurance: function() {
            this.calculateFinance();
            this.emitData();
        },

        totalSalesTax: function() {
            this.calculateFinance();
            this.emitData();
        },

        totalFinanced: function() {
            this.calculatePayment();
            this.emitData();
        },

        financeCharge: function() {
            this.calculatePayment();
            this.emitData();
        },

        totalOfPayments: function() {
            this.emitData();
        },

        deferredPrice: function() {
            this.emitData();
        },

        financeCompanyName: function() {
            this.emitData();
        },

        financeCompanyAddress: function() {
            this.emitData();
        },

        financeCompanyCity: function() {
            this.emitData();
        },

        financeCompanyZip: function() {
            this.emitData();
        },

        comment: function() {
            this.emitData();
        },

        diffName: function() {
            this.emitData();
        },

        diffAddress: function() {
            this.emitData();
        },

        diffCity: function() {
            this.emitData();
        },

        diffState: function() {
            this.emitData();
        },

        diffZip: function() {
            this.emitData();
        },

        financeData: function (newValue) {
            this.fillData(newValue);
        },

        financeCompanyStateArr: function(currentStatelist) {
            this.stateArr = currentStatelist;
            if(this.financeData.finance_company_state_id){
                currentStatelist.filter(state => {
                    if(state.value === this.financeData.finance_company_state_id) {
                        this.state = {
                            text: state.text,
                            value: state.value
                        }
                    };

                    if(state.value === this.financeData.finance_company_diff_state_id) {
                        this.diffState = {
                            text: state.text,
                            value: state.value
                        }
                    }
                });
            }
        },  
        
        selectFinanceCompanyArr: function(currentFinanceList) {
            this.financeCompanyArr = currentFinanceList;
            
            if(this.financeData.finance_company_master_id){
                currentFinanceList.filter(financeCompany => {
                    if(financeCompany.value === this.financeData.finance_company_master_id) {
                        this.companyId = {
                            text: financeCompany.text,
                            value: financeCompany.value
                        }
                    }
                });
            }
        }   
    }
};
</script>