<template>
    <div class="vendor-admin-footer" id="main-footer">
        <div class="page-loader-wrapper loading-container" style="display: none;">
            <div class="loading">
                <img src="/static/images/loading.gif">
            </div>
        </div>
        <div class="copyright-text"> © {{system_year}} {{ allStaticMessages.copyright_msg }}</div>
    </div>
</template>
<script>
    import {commonMixins} from '../../mixins/commonMixins';
    import moment from 'moment';
    import {HTTP} from '../../http-common';

    var self;
    export default {
        name: 'VendorFooter',
        mixins: [commonMixins],
        data() {
            return {
                system_year: '',
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            self = this;

            var date = new Date();
            self.system_year = moment(date).format('YYYY');
            self.getSystemYear();
        },
        methods: {
            getSystemYear() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("vendor_api_token")
                    }
                };
                HTTP.get("get/vendor/systemyear", config)
                    .then(function (response) {
                        if (response.data.code == 200) {
                            self.system_year = response.data.content.year;

                            let current_build = localStorage.getItem("build_version");
                            let latest_build =  response.data.content.build;
                            if(current_build != undefined && current_build == ''){
                                localStorage.setItem("build_version", response.data.content.build);
                            }else if(current_build != latest_build){
                                localStorage.setItem("build_version", response.data.content.build);
                                location.reload(true);
                            }
                        }
                    })
                    .catch(function (error) {
                        self.catchHandler(error, function () {}, 1);
                    })
            }
        }
    }
</script>
<style lang="css" scoped>
.copyright-text {
    float: left;
    width: 100%;
    text-align: center;
    color: #666666;
    font-size: 14px;
    line-height: 18px;
    font-family: 'SanFranciscoDisplayRegular';
    font-style: normal;
}
</style>
