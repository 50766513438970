<template>
  <div>
    <div
      class="modal fade"
      id="mailHistory"
      aria-hidden="true"
      aria-labelledby="mailHistoryLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mailHistoryLabel">
              {{ !isViewMailHistoryDetail ? labelMessage.mail_history  : labelMessage.mail_history_detail }}
              <span v-if="!isViewMailHistoryDetail">({{ curDealNo }})</span>
            </h5>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <!-- Back Button (only visible when viewing details) -->
              <div v-if="isViewMailHistoryDetail">
                <button
                  type="button"
                  class="btn btn-primary mx-2"
                  @click="backToHistory"
                >
                  Back
                </button>
              </div>
              <!-- Close Button -->
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>

          </div>
          <div class="modal-body mail-history-modal-body">
            <div v-if="!isViewMailHistoryDetail">
              <div class="card">
                <table class="table custom-table jsmartable">
                  <thead id="mail-history-modal">
                    <tr>
                      <th>{{ labelMessage.mail_history_date }}</th>
                      <th>{{ labelMessage.mail_history_from }}</th>
                      <th>{{ labelMessage.mail_history_to }}</th>
                      <th>{{ labelMessage.mail_history_subject }}</th>
                      <th>{{ labelMessage.mail_history_deal_status }}</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="loader">
                    <tr>
                      <td colspan="6">
                        <loader />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="mailHistory in mailHistoryList" :key="mailHistory.id">
                      <td>{{ mailHistory.mail_date }}</td>
                      <td>{{ mailHistory.mail_from }}</td>
                      <td>{{ mailHistory.mail_to }}</td>
                      <td class="mhtData">{{ mailHistory.mail_subject }}</td>
                      <td>{{ mailHistory.deal_status }}</td>
                      <td>
                        <button
                          class="btn btn-link p-0 text-primary"
                          @click="viewMailDetail(mailHistory.mail_details)"
                          title="View Mail Details"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                    <tr v-if="mailHistoryList.length === 0">
                      <td colspan="6" class="text-center">
                        {{ labelMessage.no_records_to_display_label }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            <!-- Mail Details View -->
            <div v-else>
              <div class="card p-2" v-html="mailDetails"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../../event-bus";
import { HTTP } from "../../../../../http-common";
import Loader from "../Loader.vue";

let selfThis;
export default {
  name: "MailHistoryModal",
  props: ["isClickMailHistory", "dealNo", "dealId", "labelMessage"],
  components: {
    loader: Loader,
  },
  data() {
    return {
      loader: false,
      curDealNo: "",
      mailHistoryList: [],
      mailDetails: "",
      isViewMailHistoryDetail: false,
    };
  },
  beforeMount() {
    selfThis = this;
  },
  methods: {
    getMailHistoryLog() {
      this.loader = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      HTTP.get("/mail/history/" + this.dealId, config)
        .then((response) => {
          if (response.data.status === "success") {
            this.mailHistoryList = response.data.content;
          }
        })
        .catch(function (err) {
          console.log("ERROR!!!", err);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    viewMailDetail(mailDetails) {
      this.mailDetails = mailDetails;
      this.isViewMailHistoryDetail = true;
    },
    backToHistory() {
      this.isViewMailHistoryDetail = false;
    },
  },
  watch: {
    isClickMailHistory(status) {
      if (status) {
        this.curDealNo = this.dealNo;
        this.getMailHistoryLog();
      }
    },
  },
};
</script>

<style scoped>
.mail-history-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#mail-history-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

.table.custom-table td {
  vertical-align: middle;
  white-space: unset;
}

.table.custom-table td:nth-child(3){
  max-width: 209px;
}
</style>
