<template>
  <div
    class="add-contact-popup-form add-customer-popup-form admin-content-area"
  >
    <template v-if="permissionNameArray.includes('can_add_customer_master')">
      <div
        class="modal fade bd-example-modal-lg"
        id="addContactFormPopup"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <common-loader
              refId="commonLoaderAddContactPopupForm"
              ref="commonLoaderAddContactPopupForm"
            >
            </common-loader>

            <div class="modal-header">
              <h4 class="modal-title">Add Customer</h4>
              <button
                type="submit"
                v-on:click.prevent="closeModalPopup()"
                class="btn btn-primary waves-effect pull-right"
              >
                Close
              </button>
            </div>
            <div class="modal-body" :refreshFlag="refreshFlag">
              <!--START: Customer Details Card -->
              <div class="">
                <!-- Customer Id -->
                <div class="customer-id">
                  <div class="row">
                    <div class="col-6 forms-block"></div>
                    <div class="col-6 forms-block">
                      <div class="col-3 pl-0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.customer_id_label }}
                        </div>
                      </div>
                      <div class="col-8 pl-0">
                        <input type="text" class="form-control" v-model="cus_id" disabled />
                        <div class="requiredstar">*</div>
                      </div>
                      <div class="col-1 pl-0 pr-0">
                        <a id='regenerateCusIdCode' title='Regenerate CusId' v-on:click.prevent="getCustIdUniqueCode()" class="extraFeature btn btn-primary waves-effect pull-right ml-2"> 
                          <i class="fa fa-undo" aria-hidden="true"></i>
                        </a>
                      </div>  
                    </div>
                  </div>
                </div>
                <div class="">Customer Details</div>
                <div class="section-body forms-container">
                  <!-- Is Individual Buyer -->
                  <div class="row no-margin">
                    <div class="col-6 forms-block">
                      <div class="col-5 paddingleft0">
                        <div class="form-label">
                          <label :for="pre_id + 'is_ind_buyer'">{{
                            staticInputLabelMessages.is_ind_buyer_label
                          }}</label>
                        </div>
                      </div>
                      <div class="col-7 paddingleft0 custom-checkbox-v">
                        <label class="container">
                          <input
                            :id="pre_id + 'is_ind_buyer'"
                            v-model="is_ind_buyer"
                            value="true"
                            class="form-control"
                            type="checkbox"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <!-- ABN -->
                    <div
                      v-if="production_country == 'AU'"
                      class="col-6 forms-block"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.customer_abn_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="anbNoError = ''"
                          :id="pre_id + 'abn_no'"
                          v-model="abn_no"
                          class="form-control"
                          type="text"
                          maxlength="50"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{ anbNoError }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!-- FirstName -->
                    <div class="col-6 forms-block" v-show="is_ind_buyer">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.first_name_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="firstNameError = ''"
                          :id="pre_id + 'firstName'"
                          v-model="firstName"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{ firstNameError }}</span>
                      </div>
                    </div>

                    <!-- Middle Name -->
                    <div class="col-6 forms-block" v-show="is_ind_buyer">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.middle_name_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'middleName'"
                          v-model="middleName"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row no-margin">
                    <!-- LastName -->
                    <div class="col-6 forms-block" v-show="is_ind_buyer">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.last_name_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'lastName'"
                          v-model="lastName"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div>
                    </div>

                    <div class="col-6 forms-block" v-show="!is_ind_buyer">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.name_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="contact_nameError = ''"
                          :id="pre_id + 'name'"
                          v-model="contact_name"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          contact_nameError
                        }}</span>
                      </div>
                    </div>

                    <!--Primary Phone -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.primary_phone_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="primaryPhoneError = ''"
                          :id="pre_id + 'primaryPhone'"
                          v-model="primaryPhone"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          primaryPhoneError
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Email -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.email_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="emailError = ''"
                          :id="pre_id + 'email'"
                          v-model="email"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{ emailError }}</span>
                      </div>
                    </div>

                    <!--Manage By-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.managed_by_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="manageBy"
                          @change="changeManageBy"
                          @input="manageByError = ''"
                          name="manageBy"
                          :id="pre_id + 'manageBy'"
                          label="text"
                          :clearable="false"
                          @search="onSearchManageBy"
                          :filterable="false"
                          :options="manageByArr"
                          :placeholder="staticMessages.managed_by_place_holder"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            <template v-if="!isManageByRecordNotFound">
                              {{ staticMessages.managed_by_search }}
                            </template>
                            <template v-else>
                              {{ staticMessages.search_record_not_found }}
                            </template>
                          </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{ manageByError }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- isPrimary Checkbox -->
                  <div class="col-6 forms-block hide">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        <label :for="pre_id + 'isPrimary'">{{
                          staticInputLabelMessages.is_primary_label
                        }}</label>
                      </div>
                    </div>
                    <div class="col-9 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          :id="pre_id + 'isPrimary'"
                          v-model="isPrimary"
                          value="true"
                          class="form-control"
                          type="checkbox"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <!--Tag-->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label">{{staticInputLabelMessages.tag_lable}}</div>
                    </div>
                    <div class="col-9 paddingleft0">
                            <v-select
                            v-model="tagId"
                            multiple
                            label="text"
                            id="tagId"
                            :clearable="true"
                            :searchable="true"
                            name="tagId"
                            :filterable="true"
                            :options=tagArr
                            :placeholder="staticMessages.select_tags">
                            </v-select>
                    </div> 
                  </div>
                </div>
              </div>
              <!--END: Customer Details Card -->

              <!--START: Dlr/Spec Details -->
              <template v-if="dealAppSlug == 'amp-dealer-manager'">
                <div class="">Dlr/Spec Details</div>
                <div class="section-body forms-container">
                  <div class="row no-margin">
                    <!--Distributor Type-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.distributor_type_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'distributor_type'"
                          v-model="distributorType"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  
                    <!--Funk - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.funk_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'funk_dlr_spec'"
                          v-model="funkDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Barko - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.barko_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'barko_dlr_spec'"
                          v-model="barkoDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>

                    <!--Gearmatic Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.gearmatic_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'gearmatic_spec'"
                          v-model="gearmaticSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Cat/Weiler - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.cat_weiler_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'cat_weiler_dlr_spec'"
                          v-model="catWeilerDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>

                    <!--Prentice - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.prentice_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'prentice_dlr_spec'"
                          v-model="prenticeDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Deere - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.deere_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'deere_dlr_spec'"
                          v-model="deereDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>

                    <!--Tigercat - Dlr/Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.tigercat_dlr_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'tigercat_dlr_spec'"
                          v-model="tigercatDlrSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Esco Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.esco_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'esco_spec'"
                          v-model="escoSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>

                    <!--Timberjack Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.timberjack_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'timberjack_spec'"
                          v-model="timberjackSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>

                  <div class="row no-margin">
                    <!--Franklin/TRE Spec-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.franklin_tre_spec_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'franklin_tre_spec'"
                          v-model="franklinTreSpec"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>

                    <!--Other Dlr - write in-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                          <div class="form-label">{{staticInputLabelMessages.other_dlr_write_in_lbl}}</div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'other_dlr_write_in'"
                          v-model="otherDlrWriteIn"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                      </div> 
                    </div>
                  </div>
                </div>
              </template>
              <!--END: Dlr/Spec Details -->

              <!--START: Customer Address -->
              <div class="">
                <div class="">Customer Address</div>
                <div class="section-body forms-container">
                  <!-- Country -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.country_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        v-model="countryId"
                        @change="changeConutry"
                        @input="countryError = ''"
                        :name="pre_id + 'countryId'"
                        :id="pre_id + 'countryId'"
                        label="text"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        :options="countryArr"
                        :disabled="true"
                        :placeholder="staticMessages.country_place_holder"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isCountryRecordNotFound">
                            {{ staticMessages.country_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ countryError }}</span>
                    </div>
                  </div>

                  <!-- State -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.state_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        v-model="stateId"
                        name="state"
                        :id="pre_id + 'stateId'"
                        label="text"
                        :filterable="true"
                        :options="stateArr"
                        @input="stateError = ''"
                        @change="onSearchState"
                        :placeholder="staticMessages.state_place_holder"
                        class="custom-ci-auto-search"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isStateRecordNotFound">
                            {{ staticMessages.state_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ stateError }}</span>
                    </div>
                  </div>

                  <!-- City -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.city_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        v-model="cityId"
                        name="city"
                        :id="pre_id + 'cityId'"
                        label="text"
                        :filterable="true"
                        :options="cityArr"
                        @input="cityError = ''"
                        @search="onSearchCity"
                        :clearSearchOnSelect="true"
                        @change="updateCity"
                        :placeholder="staticMessages.city_place_holder"
                        class="custom-ci-auto-search"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isCityRecordNotFound">
                            {{ staticMessages.city_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ cityError }}</span>
                    </div>
                  </div>

                  <!--Address-->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.address_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @change="changeAddress"
                        @input="addressError = ''"
                        :id="pre_id + 'address'"
                        v-model="address"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ addressError }}</span>
                    </div>
                  </div>

                  <!--Zipcode -->
                  <div class="clear"></div>
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.zip_code_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @change="changeZipCode"
                        @input="zipcodeError = ''"
                        :id="pre_id + 'zipcode'"
                        v-model="zipcode"
                        class="form-control"
                        type="text"
                        maxlength="10"
                      />
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ zipcodeError }}</span>
                    </div>
                  </div>

                  <!--Address2-->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.cus_address2_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @change="changeAddress2"
                        @input="address2Error = ''"
                        :id="pre_id + 'address2'"
                        v-model="address2"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <span class="help is-danger">{{ address2Error }}</span>
                    </div>
                  </div>
                  <div class="clearfix"></div>

                  <!-- billAddressSameAsAddress -->
                  <div class="col-6 forms-block">
                    <div class="col-6 paddingleft0">
                      <div class="form-label">
                        <label :for="pre_id + 'billAddressSameAsAddress'">{{
                          staticInputLabelMessages.is_bill_address_same_label
                        }}</label>
                      </div>
                    </div>
                    <div class="col-6 paddingleft0 custom-checkbox-v">
                      <label class="container">
                        <input
                          :id="pre_id + 'billAddressSameAsAddress'"
                          v-model="billAddressSameAsAddress"
                          value="true"
                          class="form-control"
                          type="checkbox"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!--END: Customer Address -->

              <!--START: Customer Billing Address -->
              <div class="" v-show="!billAddressSameAsAddress">
                <div class="">
                  <label :for="pre_id + 'AddBilling'" >Billing Address</label>
                </div>
                <div class="section-body forms-container">
                  <!--Bill Country -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.bill_country_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        @input="billCountryError = ''"
                        v-model="billCountry"
                        @change="changeBillCountry"
                        name="billCountry"
                        :id="billCountry"
                        label="text"
                        :clearable="false"
                        :searchable="false"
                        :filterable="false"
                        :options="billCountryArr"
                        :disabled="true"
                        :placeholder="staticMessages.country_place_holder"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isBillCountryRecordNotFound">
                            {{ staticMessages.country_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ billCountryError }}</span>
                    </div>
                  </div>

                  <!--Bill State -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.bill_state_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        @input="billStateError = ''"
                        v-model="billState"
                        name="billState"
                        label="text"
                        :filterable="true"
                        :options="billStateArr"
                        @change="onSearchBillState"
                        :id="pre_id + 'AddBilling-billState'"
                        :placeholder="staticMessages.state_place_holder"
                        class="custom-ci-auto-search"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isBillStateRecordNotFound">
                            {{ staticMessages.state_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ billStateError }}</span>
                    </div>
                  </div>

                  <!--Bill City -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.bill_city_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <v-select
                        @input="billCityError = ''"
                        v-model="billCity"
                        name="billCity"
                        label="text"
                        :filterable="true"
                        :options="billCityArr"
                        @search="onSearchBillCity"
                        :id="pre_id + 'AddBilling-billCity'"
                        :clearSearchOnSelect="true"
                        @change="updateBillCity"
                        :placeholder="staticMessages.city_place_holder"
                        class="custom-ci-auto-search"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          <template v-if="!isBillCityRecordNotFound">
                            {{ staticMessages.city_search }}
                          </template>
                          <template v-else>
                            {{ staticMessages.search_record_not_found }}
                          </template>
                        </span>
                      </v-select>
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ billCityError }}</span>
                    </div>
                  </div>

                  <!--Bill Address1-->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.bill_address1_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @input="billAddress1Error = ''"
                        :id="pre_id + 'AddBilling-billAddress1'"
                        v-model="billAddress1"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{
                        billAddress1Error
                      }}</span>
                    </div>
                  </div>

                  <div class="clear"></div>
                  <!--Bill Post -->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        <!-- Just changed label to keep it same as customer address zipcode. -->
                        {{ staticInputLabelMessages.bill_zip_code_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @input="billPostError = ''"
                        :id="pre_id + 'AddBilling-billPost'"
                        v-model="billPost"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <div class="requiredstar">*</div>
                      <span class="help is-danger">{{ billPostError }}</span>
                    </div>
                  </div>

                  <!--Bill Address2-->
                  <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                      <div class="form-label">
                        {{ staticInputLabelMessages.bill_address2_label }}
                      </div>
                    </div>
                    <div class="col-9 paddingleft0">
                      <input
                        @input="billAddress2Error = ''"
                        id="billAddress2"
                        v-model="billAddress2"
                        class="form-control"
                        type="text"
                        maxlength="190"
                      />
                      <span class="help is-danger">{{
                        billAddress2Error
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!--START: Add Contacts -->
              <div class="card mt-20 add-customer-contacts">
                <div class="card-header">
                  <div class="col-2 paddingleft0">
                    <div class="form-label">
                      <label class="mb-0">Contacts</label>
                    </div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <label class="container"> </label>
                  </div>
                </div>
                <div class="card-body section-body forms-container p-0 pt-3">
                  <template  v-for="(contactObj, contactIndex) in contacts">
                    <template>
                      <div  :key="'addUpdateCustomerContactSection_' + contactIndex"
                        class="each-contact-section mb-2"
                        
                      >
                        <div class="card">
                          <div class="card-header p-0" style="cursor: pointer">
                            <i
                              class="fa fa-trash-o pull-right p-2 mt-1"
                              @click="deleteCustomerContact(contactIndex)"
                            ></i>
                            <div
                              class="p-2 pr-5"
                              :id="pre_id + contactIndex + 'contacts'"
                              data-toggle="collapse"
                              :data-target="
                                '#addUpdateCustomerContactSection_collapse_' +
                                contactIndex
                              "
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Contact {{ contactIndex + 1 }}:
                            </div>
                          </div>
                          <div
                            :id="
                              'addUpdateCustomerContactSection_collapse_' +
                              contactIndex
                            "
                            class="collapse show card-body"
                          >
                            <customer-contact-form-fields
                              :refreshFlag="!refreshFlag"
                              :contact_index="contactIndex"
                              @callErrorMessageHandle="customerContactErrorMessageHandle"
                              :ref="'addUpdateCustomerContactRef_' + contactIndex"
                              openAt="addContactForm"
                              :form_fields="contactObj"
                            ></customer-contact-form-fields>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <input
                    type="button"
                    class="btn btn-primary mb-10 ml-10 mt-10 pull-right mr-10"
                    @click="addContact"
                    value="Add Contact"
                  />
                </div>
              </div>
              <!--END: Add Contacts -->

              <!--START: Add Opportunity -->
              <template
                v-if="
                  permissionNameArray.includes('can_add_opportunity_master')
                "
              >
                <div class="card mt-20 add-activity">
                  <div class="card-header">
                    <div class="col-2 paddingleft0">
                      <div class="form-label">
                        <label :for="pre_id + 'AddOpportunity'" class="mb-0"
                          >Add Opportunity</label
                        >
                      </div>
                    </div>
                    <div
                      class="col-9 paddingleft0 custom-checkbox-v add-cnt-fopop-checkbox"
                    >
                      <label class="container">
                        <input
                          :id="pre_id + 'AddOpportunity'"
                          v-model="add_opportunity"
                          value="true"
                          class="form-control"
                          type="checkbox"
                        />
                        <span class="checkmark" style="top: 0"></span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-show="add_opportunity"
                    class="card-body section-body forms-container"
                  >
                    <!--START: Date Open Section -->
                    <div
                      v-show="opportunity_obj.unable_fields.date_open"
                      class="col-6 forms-block"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.date_open_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <date-picker
                          v-model="opportunity_obj.dateOpen"
                          :config="opportunity_obj.dateOpenOptions"
                          class="form-control"
                          autocomplete="off"
                          :placeholder="
                            staticInputLabelMessages.date_open_place_holder
                          "
                          name="dateOpen"
                          @input="opportunity_obj.dateOpenError = ''"
                        >
                        </date-picker>
                        <span class="help is-danger">{{
                          opportunity_obj.dateOpenError
                        }}</span>
                      </div>
                    </div>

                    <!-- Decision -->
                    <div
                      v-show="opportunity_obj.unable_fields.decision_date"
                      class="col-6 forms-block"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.decision_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <date-picker
                          v-model="opportunity_obj.decisionDate"
                          :id="pre_id + 'AddOpportunity-decisionDate'"
                          :config="opportunity_obj.options"
                          class="form-control"
                          autocomplete="off"
                          :placeholder="
                            staticMessages.decision_date_place_holder
                          "
                          name="decisionDate"
                          @input="opportunity_obj.decisionDateError = ''"
                        >
                        </date-picker>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.decisionDateError
                        }}</span>
                      </div>
                    </div>

                    <!-- Location -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.location_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.locationId"
                          name="locationId"
                          :id="pre_id + 'AddOpportunity-locationId'"
                          label="text"
                          :filterable="false"
                          :options="opportunity_obj.locationArr"
                          @search="onSearchLocation"
                          :clearable="true"
                          @input="opportunity_obj.locationError = ''"
                          :placeholder="
                            allStaticMessages.location_custom_place_holder
                          "
                          class="custom-ci-auto-search"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            <template
                              v-if="!opportunity_obj.isLocationRecordNotFound"
                            >
                              {{ allStaticMessages.location_custom_search }}
                            </template>
                            <template v-else>
                              {{ allStaticMessages.search_record_not_found }}
                            </template>
                          </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.locationError
                        }}</span>
                      </div>
                    </div>

                    <!-- Salesman -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.salesperson_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.salesmanId"
                          @change="changedSalesmanValue"
                          @input="opportunity_obj.salesmanError = ''"
                          @search="onSearchSalesman"
                          @clear="onClearSalesman"
                          name="salesman"
                          :id="pre_id + 'AddOpportunity-salesman'"
                          label="text"
                          :clearable="true"
                          :searchable="true"
                          :filterable="false"
                          :options="opportunity_obj.salesmanArr"
                          :placeholder="staticMessages.acquire_by_place_holder"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            <template
                              v-if="!opportunity_obj.isSalesmanRecordNotFound"
                            >
                              {{ staticMessages.acquire_by_search }}
                            </template>
                            <template v-else>
                              {{ staticMessages.search_record_not_found }}
                            </template>
                          </span>
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.salesmanError
                        }}</span>
                      </div>
                    </div>

                    <!-- Make -->
                    <div
                      class="col-6 forms-block"
                      v-show="opportunity_obj.unable_fields.make"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.make_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.makeId"
                          @change="changedValue"
                          @input="opportunity_obj.makeError = ''"
                          name="make"
                          id="make"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="opportunity_obj.makeArr"
                          :placeholder="staticInputLabelMessages.select_make"
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.makeError
                        }}</span>
                      </div>
                    </div>

                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.likelihood_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.likelihoodId"
                          @change="changedValue"
                          @input="opportunity_obj.likelihoodError = ''"
                          name="likelihoodIdcustomPerPage"
                          :id="
                            pre_id + 'AddOpportunity-likelihoodIdcustomPerPage'
                          "
                          label="text"
                          :clearable="true"
                          :searchable="false"
                          :filterable="false"
                          :options="opportunity_obj.likelihoodArr"
                          :placeholder="
                            staticInputLabelMessages.select_likelihood_placholder_label
                          "
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.likelihoodError
                        }}</span>
                      </div>
                    </div>

                    <!-- Source -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.source_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.source"
                          @change="changedValue"
                          @input="opportunity_obj.sourceError = ''"
                          name="source"
                          :id="pre_id + 'AddOpportunity-source'"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="opportunity_obj.sourceArr"
                          :placeholder="staticMessages.select_source"
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.sourceError
                        }}</span>
                      </div>
                    </div>

                    <!-- Subject -->
                    <div
                      v-show="opportunity_obj.unable_fields.subject"
                      class="col-6 forms-block"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.subject_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          :id="pre_id + 'AddOpportunity-subject'"
                          @input="opportunity_obj.subjectError = ''"
                          v-model="opportunity_obj.subject"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          opportunity_obj.subjectError
                        }}</span>
                      </div>
                    </div>

                    <div class="col-6 forms-block"></div>
                    <!-- Status -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.status_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <b-form-radio-group
                          v-model="opportunity_obj.status"
                          :options="opportunity_obj.statusOptions"
                          name="status"
                        >
                        </b-form-radio-group>
                      </div>
                    </div>

                    <!-- Sales -->
                    <div
                      class="col-6 forms-block"
                      v-show="opportunity_obj.status == 'won'"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.sales_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          id="sales"
                          v-model="opportunity_obj.sales"
                          class="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <!-- Commission GP -->
                    <div
                      class="col-6 forms-block"
                      v-show="opportunity_obj.status == 'won'"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.commission_gp_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          id="commission_gp"
                          v-model="opportunity_obj.commission_gp"
                          class="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <!-- Lost Cause-->
                    <div
                      class="col-6 forms-block"
                      v-show="opportunity_obj.status == 'lost'"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.lost_cause_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="opportunity_obj.lost_reason"
                          @change="changedValue"
                          @input="opportunity_obj.lostReasonError = ''"
                          name="lostReasonPerPage"
                          id="lostReasonPerPage"
                          label="text"
                          :clearable="true"
                          :searchable="false"
                          :filterable="false"
                          :options="opportunity_obj.lostReasonArr"
                          :placeholder="
                            staticInputLabelMessages.select_lost_cause_placholder_label
                          "
                        >
                        </v-select>
                        <span class="help is-danger">{{
                          opportunity_obj.lostReasonError
                        }}</span>
                      </div>
                    </div>

                    <div
                      class="col-6 forms-block"
                      v-show="opportunity_obj.status == 'lost'"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{
                            staticInputLabelMessages.lost_additional_info_label
                          }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <textarea
                          id="lost_additional_info"
                          v-model="opportunity_obj.lost_additional_info"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>

                    <!-- Notes -->
                    <div class="col-12 forms-block">
                      <div class="col-12 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.notes_label }}
                        </div>
                      </div>
                      <div class="col-12 paddingleft0">
                        <textarea
                          id="notes"
                          v-model="opportunity_obj.notes"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!--END: Add Opportunity -->

              <!--START: Add Activity -->
              <template
                v-if="permissionNameArray.includes('can_add_activity_master')"
              >
                <div class="card mt-20 add-activity">
                  <div class="card-header">
                    <div class="col-2 paddingleft0">
                      <div class="form-label">
                        <label :for="pre_id + 'AddActivity'" class="mb-0"
                          >Add Activity</label
                        >
                      </div>
                    </div>
                    <div
                      class="col-9 paddingleft0 custom-checkbox-v add-cnt-fopop-checkbox"
                    >
                      <label class="container">
                        <input
                          :id="pre_id + 'AddActivity'"
                          v-model="add_activity"
                          value="true"
                          class="form-control"
                          type="checkbox"
                        />
                        <span class="checkmark" style="top: 0"></span>
                      </label>
                    </div>
                  </div>
                  <div
                    v-show="add_activity"
                    class="card-body section-body forms-container"
                  >
                    <!-- Assign User -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.assign_user_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.assignUserId"
                          name="assignUserId"
                          id="assignUserId"
                          label="text"
                          @change="changedValue"
                          @input="activity_obj.assignUserIdError = ''"
                          :filterable="false"
                          :options="activity_obj.assignUserIdArr"
                          @search="onSearchAssignUser"
                          :placeholder="staticMessages.assign_user_place_holder"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            <template
                              v-if="!activity_obj.isAssingUserRecordNotFound"
                            >
                              {{ staticMessages.assign_used_search }}
                            </template>
                            <template v-else>
                              {{ staticMessages.search_record_not_found }}
                            </template>
                          </span>
                        </v-select>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{
                          activity_obj.assignUserIdError
                        }}</span>
                      </div>
                    </div>

                    <!-- Asset -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.asset_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.assetId"
                          name="assetId"
                          id="assetId"
                          label="text"
                          @change="changedValue"
                          @input="activity_obj.assetIdError = ''"
                          :filterable="false"
                          :options="activity_obj.assetIdArr"
                          @search="onSearchAsset"
                          :placeholder="staticMessages.asset_place_holder"
                        >
                          <span
                            slot="no-options"
                            @click="$refs.select.open = false"
                          >
                            <template
                              v-if="!activity_obj.isAssetRecordNotFound"
                            >
                              {{ staticMessages.asset_search }}
                            </template>
                            <template v-else>
                              {{ staticMessages.search_record_not_found }}
                            </template>
                          </span>
                        </v-select>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{
                          activity_obj.assetIdError
                        }}</span>
                      </div>
                    </div>

                    <!-- Activity Date Time -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.activity_time_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <date-picker
                          v-model="activity_obj.activityDateTime"
                          :config="activity_obj.options"
                          class="form-control"
                          autocomplete="off"
                          :placeholder="
                            staticMessages.activity_datetime_place_holder
                          "
                          name="activityDateTime"
                          @input="changeActivityDateTime()"
                        >
                        </date-picker>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.activityDateTimeError
                        }}</span>
                      </div>
                    </div>

                    <!--type-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.activity_type_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.type"
                          @change="changedValue"
                          @input="activity_obj.typeError = ''"
                          name="type"
                          id="type"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="activity_obj.typeArr"
                          :placeholder="
                            staticMessages.activity_type_place_holder
                          "
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.typeError
                        }}</span>
                      </div>
                    </div>

                    <!-- Other Type -->
                    <div
                      v-show="activity_obj.isActivityTypeOther"
                      class="col-6 forms-block"
                    >
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.other_type_text_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          id="otherActivityTypeText"
                          v-model="activity_obj.otherActivityTypeText"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.otherActivityTypeTextError
                        }}</span>
                      </div>
                    </div>

                    <!-- Priority -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.priority_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.priority"
                          name="priority"
                          @input="activity_obj.priorityError = ''"
                          label="text"
                          :filterable="false"
                          :options="activity_obj.priorityArr"
                          :clearable="false"
                          :placeholder="staticMessages.select_priority"
                        >
                        </v-select>
                        <!-- <div class="requiredstar">*</div> -->
                        <span class="help is-danger">{{
                          activity_obj.priorityError
                        }}</span>
                      </div>
                    </div>
                    <!-- Direction -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.direction_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.direction"
                          name="direction"
                          @input="activity_obj.directionError = ''"
                          label="text"
                          :filterable="false"
                          :options="activity_obj.directionArr"
                          :clearable="false"
                          :placeholder="staticMessages.direction_place_holder"
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.directionError
                        }}</span>
                      </div>
                    </div>

                    <!-- Subject -->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.subject_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <input
                          @input="activity_obj.subjectError = ''"
                          :id="pre_id + 'AddActivity-subject'"
                          v-model="activity_obj.subject"
                          class="form-control"
                          type="text"
                          maxlength="190"
                        />
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.subjectError
                        }}</span>
                      </div>
                    </div>

                    <!--Status-->
                    <div class="col-6 forms-block">
                      <div class="col-3 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.status_label }}
                        </div>
                      </div>
                      <div class="col-9 paddingleft0">
                        <v-select
                          v-model="activity_obj.status"
                          @change="changedValue"
                          @input="activity_obj.statusError = ''"
                          name="status"
                          id="status"
                          label="text"
                          :clearable="false"
                          :searchable="false"
                          :filterable="false"
                          :options="activity_obj.statusArr"
                          :placeholder="staticMessages.status_place_holder"
                        >
                        </v-select>
                        <div class="requiredstar">*</div>
                        <span class="help is-danger">{{
                          activity_obj.statusError
                        }}</span>
                      </div>
                    </div>

                    <!-- Notes -->
                    <div class="col-12 forms-block">
                      <div class="col-12 paddingleft0">
                        <div class="form-label">
                          {{ staticInputLabelMessages.notes_label }}
                        </div>
                      </div>
                      <div class="col-12 paddingleft0">
                        <textarea
                          id="notes"
                          v-model="activity_obj.notes"
                          class="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- End Card ACTIVITY -->
                </div>
              </template>
              <!--END: Add Activity -->
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
              <div class="button-demo ml-3">
                <input
                  type="submit"
                  @click="saveContact"
                  class="btn btn-primary"
                  value="Save"
                />
                <input
                  type="submit"
                  @click="closeModalPopup"
                  class="btn btn-primary"
                  value="Close"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
var self;
import EventBus from ".././event-bus";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { HTTP } from "../../http-common";

import { commonMixins } from "../../mixins/commonMixins";
import CustomerContactFields from "../../json/customer_contact_form_fields";
import CustomerContactFormFields from "./CustomerContactFormFields.vue";
import { checkCustomerContactForm, customerContactReqData } from "./customer";
import moment from "moment";
import CommonLoader from "@/components/partials/CommonLoader.vue";

export default {
  name: "AddContactFormPopup",
  mixins: [commonMixins],
  components: {
    datePicker,
    CustomerContactFormFields,
    "common-loader": CommonLoader,
  },
  data() {
    return {
      production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
      permissionNameArray: [],
      forceSaveContact:false,
      forceSaveContactFor:[],
      add_opportunity: false,
      add_activity: false,
      headers: [],
      staticInputLabelMessages: "",
      staticMessages: "",
      pre_id: "add_contact_form_popup_",
      abn_no: "",
      anbNoError: "",
      contact_name: "",
      contact_nameError: "",
      firstNameError: "",
      firstName: "",
      middleName: "",
      lastName: "",
      primaryPhoneError: "",
      primaryPhone: "",
      emailError: "",
      email: "",
      manageBy: "",
      manageByArr: [],
      isManageByRecordNotFound: false,
      manageByError: "",
      isPrimary: false,
      is_ind_buyer: false,
      countryId: "",
      countryArr: [],
      isCountryRecordNotFound: false,
      countryError: "",
      stateId: "",
      stateArr: [],
      isStateRecordNotFound: false,
      stateError: "",
      cityId: "",
      cityArr: [],
      isCityRecordNotFound: false,
      cityError: "",
      address: "",
      addressError: "",
      zipcode: "",
      zipcodeError: "",
      address2: "",
      address2Error: "",
      billAddressSameAsAddress: true,
      billAddress1: "",
      billAddress2: "",
      billCountry: "",
      billCountryArr: [],
      isBillCountryRecordNotFound: false,
      billState: "",
      billStateArr: [],
      isBillStateRecordNotFound: false,
      billCity: "",
      billCityArr: [],
      isBillCityRecordNotFound: false,
      billPost: "",
      contact_opportunities: [],
      contact_activities: [],

      opportunity_obj: {
        subject: "",
        subjectError: "",
        depositDate: "",
        depositDateError: "",
        decisionDate: "",
        decisionDateError: "",
        options: {
          format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
          useCurrent: false,
          allowInputToggle: true,
          widgetPositioning: {
            horizontal: "auto",
            vertical: "bottom",
          },
          minDate: new Date(),
        },
        salesmanId: "",
        salesmanArr: [],
        salesmanError: "",
        isSalesmanRecordNotFound: false,
        source: "",
        sourceArr: [],
        sourceError: "",
        locationId: "",
        locationArr: [],
        locationError: "",
        isLocationRecordNotFound: false,
        status: "pending",
        statusDefaultValue: "pending",
        statusOptions: [
          { text: "Lost", value: "lost" },
          { text: "Pending", value: "pending" },
          { text: "Won", value: "won" },
        ],
        sales: "",
        budget: "",
        chanceId: "",
        chanceArr: [
          { text: "0%", value: "0" },
          { text: "100%", value: "100" },
          { text: "20%", value: "20" },
          { text: "40%", value: "40" },
          { text: "60%", value: "60" },
          { text: "80%", value: "80" },
        ],
        profit: "",
        stockOrder: "",
        stockOrderDefaultValue: "stock",
        stockOrderOptions: [
          { text: "Stock", value: "stock" },
          { text: "Order", value: "order" },
        ],
        delivered: "",
        commission_gp: "",
        conclusion: "",
        conclusionArr: [],
        isConclusionRecordNotFound: false,
        unable_fields: {
          date_open: process.env.VUE_APP_PRODUCTION_COUNTRY == "AU" ? 1 : 0,
          decision_date: process.env.VUE_APP_PRODUCTION_COUNTRY == "AU" ? 0 : 1,
          make: process.env.VUE_APP_PRODUCTION_COUNTRY == "AU" ? 1 : 0,
          subject: process.env.VUE_APP_PRODUCTION_COUNTRY == "AU" ? 0 : 1,
        },
        dateOpen: new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        dateOpenError: "",
        dateOpenOptions: {
          format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
          useCurrent: false,
          allowInputToggle: true,
          widgetPositioning: {
            horizontal: "auto",
            vertical: "bottom",
          },
        },
        makeError: "",
        makeId: "",
        makeArr: [],
        likelihoodId: "",
        likelihoodArr: [
          { text: "High", value: "High" },
          { text: "Medium", value: "Medium" },
          { text: "Low", value: "Low" },
        ],
        likelihoodError: "",
        lost_reason: "",
        lostReasonArr: [
          { text: "Availability", value: "Availability" },
          { text: "Price", value: "Price" },
          { text: "Finance", value: "Finance" },
          { text: "Other", value: "Other" },
        ],
        lostReasonError: "",
        lost_additional_info: "",
        notes: "",
      },
      activity_obj: {
        notes: "",
        assignUserId: "",
        assignUserIdArr: [],
        assignUserIdError: "",
        isAssingUserRecordNotFound: false,
        type: { text: "Call", value: "call" },
        typeArr: [
          {
            text: "Call",
            value: "call",
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Incident",
            value: "incident",
          },
          {
            text: "Other",
            value: "other",
          },
        ],
        isActivityTypeOther: false,
        otherActivityTypeText: "",
        otherActivityTypeTextError: "",
        assetId: "",
        assetIdArr: [],
        isAssetRecordNotFound: false,
        activityDateTime: moment().format("MM/DD/YYYY hh:mm a"),
        priority: { value: "medium", text: "Medium" },
        priorityArr: [
          {
            value: "high",
            text: "High",
          },
          {
            value: "medium",
            text: "Medium",
          },
          {
            value: "low",
            text: "Low",
          },
        ],
        priorityError: "",
        direction: { value: "outgoing", text: "Outgoing" },
        directionArr: [
          {
            value: "incoming",
            text: "Incoming",
          },
          {
            value: "outgoing",
            text: "Outgoing",
          },
        ],
        directionError: "",
        subject: "",
        subjectError: "",
        status: { value: "in progress", text: "In Progress" },
        statusArr: [
          {
            value: "in progress",
            text: "In Progress",
          },
          {
            value: "completed",
            text: "Completed",
          },
        ],
        statusError: "",
        moduleData: {
          text: "Inventory",
          value: "inventory",
        },
        moduleDataArr: [
          {
            text: "Inventory",
            value: "inventory",
          },
          {
            text: "Expenses",
            value: "expenses",
          },
          {
            text: "Invoice",
            value: "invoice",
          },
        ],
      },
      contacts: [],
      refreshFlag: true,
      billCountryError: "",
      billStateError: "",
      billCityError: "",
      billPostError: "",
      billAddress1Error: "",
      billAddress2Error: "",
      isModalClosing: false,
      contactModalInstance: null,
      cus_id: '',
      tagId: [],
      tagArr: [],
      distributorType: "",
      funkDlrSpec: "",
      barkoDlrSpec: "",
      gearmaticSpec: "",
      catWeilerDlrSpec: "",
      prenticeDlrSpec: "",
      deereDlrSpec: "",
      tigercatDlrSpec: "",
      escoSpec: "",
      timberjackSpec: "",
      franklinTreSpec: "",
      otherDlrWriteIn: "",
      dealAppSlug: ""
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    self.dealAppSlug = process.env.VUE_APP_APP_SLUG;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    if (userObj != null) {
      var userJson = JSON.parse(userObj);
      self.activity_obj.assignUserId = {
        text: userJson.first_name + " " + userJson.last_name,
        value: userJson.id,
      };

      self.opportunity_obj.salesmanId = {
        text: userJson.first_name + " " + userJson.last_name,
        value: userJson.id,
      };
    }
    self.staticMessages = self.allStaticMessages;
    self.staticInputLabelMessages = self.allLabelMessages;
    self.loadAllData(
      [
        "Location",
        "ReferralSource" /* , 'LeadQuality', 'SaleType', 'FundingSource', 'FundingMethod', 'FundingStatus' */,
        "Conclusion",
        "User",
        "Contact",
      ],
      true,
      self.loadMasterDataCallback,
      "undefined",
      ""
    );
    self.countryId = self.prodCountryObj();
    self.billCountry = self.prodCountryObj();

    self.loadAllMasterData(["Make"], self.loadMakeMasterDataCallback, "", {
      req_for: "OpportunityForm",
    });
    self.loadAllData(["Tag"], false, self.loadTagsDataCallback, "undefined", "", "", "", "", "", "", "", "ContactMaster");
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      if(document.getElementById("addContactFormPopup")) {
        self.contactModalInstance = new bootstrap.Modal(
        document.getElementById("addContactFormPopup"),
          {
            backdrop: "static",
            keyboard: false,
          }
        );
      }
    },
    getCustIdUniqueCode() {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
      };
      HTTP.get("getCustIdUniqueCode", config).then((response) => {
        self.cus_id = response.data.content;
      });
    },
    loadTagsDataCallback(callbackData) {
      self.tagArr = callbackData.Tag;
    },
    openContactFormPopupModal() {
      self.initializeModals();
      self.getCustIdUniqueCode();
      self.contactModalInstance.show();
    },
    loadMakeMasterDataCallback: function (callbackData) {
      self.opportunity_obj.makeArr = callbackData.Make;
    },
    addContact: function () {
      let contactFields = JSON.parse(JSON.stringify(CustomerContactFields));
      contactFields.company_name.display = false;
      contactFields.country.display = false;
      contactFields.abn.display = false;
      self.contacts.push(contactFields);
    },
    deleteCustomerContact: function (index) {
      //ToDo: In edit mode need to delete from DB
      self.contacts.splice(index, 1);
    },
    checkForm() {
      let scrollTo = "";
      let checked = true;
      if (!self.contact_name && !self.is_ind_buyer) {
        self.contact_nameError = "Please enter name";
        if (checked) {
          scrollTo = self.pre_id + "name";
          checked = false;
        }
      }

      if (!self.firstName && self.is_ind_buyer) {
        self.firstNameError = "Please enter first name";
        if (checked) {
          scrollTo = self.pre_id + "firstName";
          checked = false;
        }
      }

      const abnRegex = /^\d{11}$/;
      if (self.production_country === "AU") {
        if (!self.abn_no) {
          self.anbNoError = "Please enter ABN";
          if (checked) {
            scrollTo = self.pre_id + "abn_no";
            checked = false;
          }
        } else if (!abnRegex.test(self.abn_no)) {
          self.anbNoError = "ABN must be a 11-digit number";
          if (checked) {
            scrollTo = self.pre_id + "abn_no";
            checked = false;
          }
        }
      }

      let phoneRegex = /^(\+\d{10,15}|\d{10,15})$/;
      if (!self.primaryPhone) {
        self.primaryPhoneError = "Please enter primary phone";
        if (checked) {
          scrollTo = self.pre_id + "primaryPhone";
          checked = false;
        }
      } else if (!phoneRegex.test(self.primaryPhone)) {
        self.primaryPhoneError =
          "Invalid phone number. Please enter a valid number.";
        scrollTo = self.pre_id + "primaryPhone";
        checked = false;
      }

      if (!self.email) {
        self.emailError = "Please enter email";
        if (checked) {
          scrollTo = self.pre_id + "email";
          checked = false;
        }
      } else if (self.email && !self.validEmail(self.email)) {
        self.emailError = "Please enter valid email";
        if (checked) {
          scrollTo = self.pre_id + "email";
          checked = false;
        }
      }

      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }

      if (self.stateId == null || !self.stateId.value) {
        self.stateError = "Please select state";
        if (checked) {
          scrollTo = self.pre_id + "stateId";
          checked = false;
        }
      }

      if (self.cityId == null || !self.cityId.value) {
        self.cityError = "Please select city";
        if (checked) {
          scrollTo = self.pre_id + "cityId";
          checked = false;
        }
      }

      if (self.manageBy == null || !self.manageBy.value) {
        self.manageByError = "Please select user";
        if (checked) {
          scrollTo = self.pre_id + "manageBy";
          checked = false;
        }
      }

      self.amtInvoiceError = "";
      if (self.amtInvoice && !self.numberFormateValidation(self.amtInvoice)) {
        self.amtInvoiceError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.amtPaymentError = "";
      if (self.amtPayment && !self.numberFormateValidation(self.amtPayment)) {
        self.amtPaymentError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }

      if (self.address == "" || self.address == null) {
        self.addressError = "Please enter address";
        if (checked) {
          scrollTo = self.pre_id + "address";
          checked = false;
        }
      }

      if (self.zipcode == "" || self.zipcode == null) {
        self.zipcodeError = "Please enter zip code";
        if (checked) {
          scrollTo = self.pre_id + "zipcode";
          checked = false;
        }
      }

      // Billing Address
      if (!self.billAddressSameAsAddress) {
        if (self.billCountry == null || !self.billCountry.value) {
          self.billCountryError = "Please select bill country";
          if (checked) {
            checked = false;
          }
        }

        if (self.billState == null || !self.billState.value) {
          self.billStateError = "Please select bill state";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billState";
            checked = false;
          }
        }

        if (self.billCity == null || !self.billCity.value) {
          self.billCityError = "Please select bill city";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billCity";
            checked = false;
          }
        }

        if (self.billPost == "" || self.billPost == null) {
          // Just changed label to keep it same as customer address zipcode.
          self.billPostError = "Please enter bill Zip code";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billPost";
            checked = false;
          }
        }

        if (self.billAddress1 == "" || self.billAddress1 == null) {
          self.billAddress1Error = "Please enter bill address 1";
          if (checked) {
            scrollTo = self.pre_id + "AddBilling-billAddress1";
            checked = false;
          }
        }
      }

      //Customer Contact Validations
      if (self.contacts.length) {
        $.each(self.contacts, function (key, contactData) {
          let checkedContact = checkCustomerContactForm(
            JSON.parse(JSON.stringify(contactData))
          );
          self.contacts[key] = JSON.parse(
            JSON.stringify(checkedContact.form_fields)
          );
          if (!checkedContact.is_valid && scrollTo == "") {
            scrollTo = self.pre_id + key + "contacts";
            checked = false;
          }
        });
      }

      //Opportunity fields validations
      if (self.add_opportunity) {
        if (
          self.opportunity_obj.source == null ||
          !self.opportunity_obj.source.value
        ) {
          self.opportunity_obj.sourceError = "Please select source";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-source";
            checked = false;
          }
        }
        if (
          self.opportunity_obj.salesmanId == null ||
          !self.opportunity_obj.salesmanId.value
        ) {
          self.opportunity_obj.salesmanError = "Please select salesperson";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-salesman";
            checked = false;
          }
        }

        if (
          self.opportunity_obj.locationId == null ||
          !self.opportunity_obj.locationId.value
        ) {
          self.opportunity_obj.locationError = "Please select location";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-locationId";
            checked = false;
          }
        }

        if (
          self.opportunity_obj.decisionDate == "" &&
          self.opportunity_obj.unable_fields.decision_date
        ) {
          self.opportunity_obj.decisionDateError =
            "Please select decision date";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-decisionDate";
            checked = false;
          }
        }

        if (
          self.opportunity_obj.likelihoodId == null ||
          !self.opportunity_obj.likelihoodId.value
        ) {
          self.opportunity_obj.likelihoodError = "Please select likelihood";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-likelihoodIdcustomPerPage";
            checked = false;
          }
        }

        if (
          (self.opportunity_obj.makeId == null ||
            !self.opportunity_obj.makeId.value) &&
          self.opportunity_obj.unable_fields.make
        ) {
          self.opportunity_obj.makeError = "Please select Make";
          if (checked) {
            checked = false;
          }
        }
        if (
          !self.opportunity_obj.subject &&
          self.opportunity_obj.unable_fields.subject
        ) {
          self.opportunity_obj.subjectError = "Please enter subject";
          if (checked) {
            scrollTo = self.pre_id + "AddOpportunity-subject";
            checked = false;
          }
        }

        if (
          self.opportunity_obj.salesmanId == null ||
          !self.opportunity_obj.salesmanId.value
        ) {
          self.opportunity_obj.salesmanError = "Please select salesman";
          if (checked) {
            checked = false;
          }
        }
      }

      //Activity fields validations
      if (self.add_activity) {
        /* if (self.activity_obj.assignUserId == null || !self.activity_obj.assignUserId.value) {
                    self.activity_obj.assignUserIdError = "Please select assign user";
                    if (checked) {
                        checked = false;
                    }
                } */
        if (self.activity_obj.type == null || !self.activity_obj.type.value) {
          self.activity_obj.typeError = "Please select type";
          if (checked) {
            checked = false;
          }
        }
        self.activity_obj.otherActivityTypeTextError = "";
        if (self.activity_obj.type.value == "other") {
          if (!self.activity_obj.otherActivityTypeText) {
            self.activity_obj.otherActivityTypeTextError =
              "Please enter Other Activity type";
            if (checked) {
              checked = false;
            }
          } else if (
            self.activity_obj.otherActivityTypeText &&
            self.activity_obj.otherActivityTypeText.length >= 191
          ) {
            self.activity_obj.otherActivityTypeTextError =
              "Please enter Other Activity type up to 190 characters only";
            if (checked) {
              checked = false;
            }
          }
        }
        if (!self.activity_obj.activityDateTime) {
          self.activity_obj.activityDateTimeError =
            "Please select activity Date Time";
          if (checked) {
            checked = false;
          }
        }

        if (!self.activity_obj.subject) {
          self.activity_obj.subjectError = "Please enter subject";
          if (checked) {
            scrollTo = self.pre_id + "AddActivity-subject";
            checked = false;
          }
        } else if (
          self.activity_obj.subject &&
          self.activity_obj.subject.length >= 191
        ) {
          self.activity_obj.subjectError =
            "Please enter subject up to 190 characters only";
          if (checked) {
            scrollTo = self.pre_id + "AddActivity-subject";
            checked = false;
          }
        }
        if (
          self.activity_obj.status == null ||
          !self.activity_obj.status.value
        ) {
          self.activity_obj.statusError = "Please select status";
          if (checked) {
            checked = false;
          }
        }
      }

      if (scrollTo && !checked) {
        document
          .getElementById(scrollTo)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }
      self.refreshFlag = !self.refreshFlag;
      return checked;
    },
    customerContactErrorMessageHandle: function (contactIndex, fieldIndex) {
      let contactData = JSON.parse(JSON.stringify(self.contacts[contactIndex]));
      if(contactData && contactData[fieldIndex]) {
        contactData[fieldIndex].error = false;
      }
      self.contacts[contactIndex] = JSON.parse(JSON.stringify(contactData));
      self.refreshFlag = !self.refreshFlag;
    },
    changeActivityDateTime: function () {
      self.activity_obj.activityDateTimeError = "";
    },
    closeModalPopup() {
      self.isModalClosing = true;
      self.resetFormData();
      self.contactModalInstance.hide();
      this.$emit("call-close-add-contact-popup");
    },
    resetFormData() {
      self.billAddressSameAsAddress = true;
      self.add_opportunity = false;
      self.add_activity = false;
      self.abn_no = "";
      self.anbNoError = "";
      self.contact_name = "";
      self.contact_nameError = "";
      self.firstNameError = "";
      self.firstName = "";
      self.middleName = "";
      self.lastName = "";
      self.primaryPhoneError = "";
      self.primaryPhone = "";
      self.emailError = "";
      self.email = "";
      self.manageBy = "";
      self.isManageByRecordNotFound = false;
      self.manageByError = "";
      self.isPrimary = false;
      self.is_ind_buyer = false;
      self.isCountryRecordNotFound = false;
      self.countryError = "";
      self.stateId = "";
      self.isStateRecordNotFound = false;
      self.stateError = "";
      self.cityId = "";
      self.isCityRecordNotFound = false;
      self.cityError = "";
      self.address = "";
      self.addressError = "";
      self.zipcode = "";
      self.zipcodeError = "";
      self.address2 = "";
      self.address2Error = "";
      self.billAddress2Error = "";
      self.billAddress1 = "";
      self.billAddress2 = "";
      self.forceSaveContact = false;
      self.forceSaveContactFor = [];
      //billCountry is not need to reset becuase we keep by default system country
      // self.billCountry = "";
      self.isBillCountryRecordNotFound = false;
      self.billState = "";
      self.isBillStateRecordNotFound = false;
      self.billCity = "";
      self.isBillCityRecordNotFound = false;
      self.billPost = "";

      //Opportunity fields reset
      self.opportunity_obj.subject = "";
      self.opportunity_obj.subjectError = "";
      self.opportunity_obj.depositDate = "";
      self.opportunity_obj.depositDateError = "";
      self.opportunity_obj.decisionDate = "";
      self.opportunity_obj.decisionDateError = "";
      self.opportunity_obj.salesmanId = "";
      self.opportunity_obj.salesmanError = "";
      self.opportunity_obj.isSalesmanRecordNotFound = false;
      self.opportunity_obj.source = "";
      self.opportunity_obj.sourceError = "";
      self.opportunity_obj.locationId = "";
      self.opportunity_obj.locationError = "";
      self.opportunity_obj.isLocationRecordNotFound = false;
      self.opportunity_obj.status = "pending";
      self.opportunity_obj.statusDefaultValue = "pending";
      self.opportunity_obj.sales = "";
      self.opportunity_obj.budget = "";
      self.opportunity_obj.chanceId = "";
      self.opportunity_obj.profit = "";
      self.opportunity_obj.stockOrder = "";
      self.opportunity_obj.stockOrderDefaultValue = "stock";
      self.opportunity_obj.delivered = "";
      self.opportunity_obj.commission_gp = "";
      self.opportunity_obj.conclusion = "";
      self.opportunity_obj.isConclusionRecordNotFound = false;

      self.opportunity_obj.dateOpen = new Date().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      self.opportunity_obj.likelihoodId = "";
      self.opportunity_obj.makeId = "";
      self.opportunity_obj.lost_additional_info = "";
      self.opportunity_obj.notes = "";

      //Activity field reset
      self.activity_obj.activityDateTimeError = "";
      self.activity_obj.assignUserId = "";
      self.activity_obj.assignUserIdError = "";
      self.activity_obj.isAssingUserRecordNotFound = false;
      self.activity_obj.type = { text: "Call", value: "call" };
      self.activity_obj.isActivityTypeOther = false;
      self.activity_obj.otherActivityTypeText = "";
      self.activity_obj.otherActivityTypeTextError = "";
      self.activity_obj.assetId = "";
      self.activity_obj.isAssetRecordNotFound = false;
      self.activity_obj.activityDateTime =
        moment().format("MM/DD/YYYY hh:mm a");
      self.activity_obj.priority = { value: "medium", text: "Medium" };
      self.activity_obj.priorityError = "";
      self.activity_obj.direction = { value: "outgoing", text: "Outgoing" };
      self.activity_obj.directionError = "";
      self.activity_obj.subject = "";
      self.activity_obj.subjectError = "";
      self.activity_obj.status = { value: "in progress", text: "In Progress" };
      self.activity_obj.statusError = "";
      self.activity_obj.moduleData = { text: "Inventory", value: "inventory" };
      self.activity_obj.notes = "";

      self.contacts = [];
      self.cus_id = '';
      self.tagId = [];
      self.tagArr = [];

      self.distributorType = "";
      self.funkDlrSpec = "";
      self.barkoDlrSpec = "";
      self.gearmaticSpec = "";
      self.catWeilerDlrSpec = "";
      self.prenticeDlrSpec = "";
      self.deereDlrSpec = "";
      self.tigercatDlrSpec = "";
      self.escoSpec = "";
      self.timberjackSpec = "";
      self.franklinTreSpec = "";
      self.otherDlrWriteIn = "";
    },
    handleUspsValidationErrors: function(receivedData,address_type){
      let scrollTo = '';
      if(address_type == 'customerAddress'){
        if(receivedData.address){
          self.addressError = receivedData.address;
          scrollTo = self.pre_id + "address";
        }
        self.address2Error = receivedData.address_2;
        if(receivedData.zip_code){
          self.zipcodeError = receivedData.zip_code;
          scrollTo = self.pre_id + "zipcode";
        }
        if(receivedData.city_name){
          self.cityError = receivedData.city_name;
          scrollTo = self.pre_id + "cityId";
        }
        if(receivedData.state_code){
          self.stateError = receivedData.state_code;
          scrollTo = self.pre_id + "stateId";
        }
      }else if(address_type == 'billAddress'){
        if(receivedData.address){
          self.billAddress1Error = receivedData.address;
          scrollTo = self.pre_id + "AddBilling-billAddress1";
        }
        self.billAddress2Error = receivedData.address_2;
        if(receivedData.city_name){
          self.billCityError = receivedData.city_name;
          scrollTo = self.pre_id + "AddBilling-billCity";
        }
        if(receivedData.state_code){
          self.billStateError = receivedData.state_code;
          scrollTo = self.pre_id + "AddBilling-billState";
        }
        if(receivedData.zip_code){
          self.billPostError = receivedData.zip_code;
          scrollTo = self.pre_id + "AddBilling-billPost";
        }
      }
      return scrollTo;
    },
    saveContact() {
      if (self.checkForm()) {
        var config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("api_token"),
          },
        };

        var data = {};
        data.browser_timezone = localStorage.getItem("browserTimeZone");
        data.abn_no = self.abn_no;
        data.contact_name = self.contact_name;
        data.first_name = self.firstName;
        data.middle_name = self.middleName;
        data.last_name = self.lastName;
        data.primary_phone = self.primaryPhone;
        data.email = self.email;
        data.is_ind_buyer = self.is_ind_buyer ? 1 : 0;
        data.is_primary = self.isPrimary ? "yes" : "no";
        data.manage_by_id = self.manageBy.value;

        data.country_id =
          self.countryId && self.countryId.value ? self.countryId.value : "";
        data.state_id =
          self.stateId && self.stateId.value ? self.stateId.value : "";
        data.city_id =
          self.cityId && self.cityId.value ? self.cityId.value : "";
        data.address = self.address;
        data.zip_code = self.zipcode;
        data.address_2 = self.address2;
        data.state_code =
          self.stateId && self.stateId.state_code ? self.stateId.state_code : "";
        data.city_name =
          self.cityId && self.cityId.text ? self.cityId.text : "";
        data.forceSaveContactFor = self.forceSaveContactFor;
        data.billAddressSameAsAddress = self.billAddressSameAsAddress;

        if (self.billAddressSameAsAddress) {
          data.bill_address1 = self.address;
          data.bill_address2 = self.address2;
          data.bill_country =
            self.countryId && self.countryId.value ? self.countryId.value : "";
          data.bill_state =
            self.stateId && self.stateId.value ? self.stateId.value : "";
          data.bill_city =
            self.cityId && self.cityId.value ? self.cityId.value : "";
          data.bill_post = self.zipcode;
        } else {
          data.bill_address1 = self.billAddress1;
          data.bill_address2 = self.billAddress2;
          data.bill_country =
            self.billCountry && self.billCountry.value
              ? self.billCountry.value
              : "";
          data.bill_state =
            self.billState && self.billState.value ? self.billState.value : "";
          data.bill_city =
            self.billCity && self.billCity.value ? self.billCity.value : "";
          data.bill_state_code =
            self.billState && self.billState.state_code ? self.billState.state_code : "";
          data.bill_city_name =
            self.billCity && self.billCity.text ? self.billCity.text : "";
          data.bill_post = self.billPost;
        }

        data.add_opportunity = self.add_opportunity;
        if (self.add_opportunity) {
          let opportunityData = {};
          var chanceId =
            self.opportunity_obj.chanceId && self.opportunity_obj.chanceId.value
              ? self.opportunity_obj.chanceId.value
              : "";

          let decisionDate = self.apiRequestDateFormat(self.opportunity_obj.decisionDate);

          opportunityData.salesman_id =
            self.opportunity_obj.salesmanId &&
            self.opportunity_obj.salesmanId.value
              ? self.opportunity_obj.salesmanId.value
              : "";
          opportunityData.source =
            self.opportunity_obj.source && self.opportunity_obj.source.value
              ? self.opportunity_obj.source.value
              : "";
          opportunityData.location_id =
            self.opportunity_obj.locationId &&
            self.opportunity_obj.locationId.value
              ? self.opportunity_obj.locationId.value
              : "";

          // opportunityData.budget = self.opportunity_obj.budget ? self.opportunity_obj.budget : "";
          opportunityData.sales = self.opportunity_obj.sales
            ? self.opportunity_obj.sales
            : "";
          // opportunityData.profit = self.opportunity_obj.profit ? self.opportunity_obj.profit : "";
          opportunityData.commission_gp = self.opportunity_obj.commission_gp
            ? self.opportunity_obj.commission_gp
            : "";

          opportunityData.chance =
            self.opportunity_obj.chanceId && self.opportunity_obj.chanceId.value
              ? self.opportunity_obj.chanceId.value
              : "";
          opportunityData.status = self.opportunity_obj.status
            ? self.opportunity_obj.status
            : "pending";
          opportunityData.stock_order = self.opportunity_obj.stockOrder
            ? self.opportunity_obj.stockOrder
            : "";
          opportunityData.conclusion =
            self.opportunity_obj.conclusion &&
            self.opportunity_obj.conclusion.value
              ? self.opportunity_obj.conclusion.value
              : "";
          opportunityData.subject = self.opportunity_obj.subject;
          opportunityData.decision_date = decisionDate;

          let openDate = "";
          if (
            self.dateOpen != null &&
            typeof self.dateOpen != "undefined" &&
            self.dateOpen != ""
          ) {
            openDate = self.dateOpen.split("/");
            openDate = openDate[2] + "-" + openDate[0] + "-" + openDate[1];
          }

          opportunityData.open_date = openDate;
          opportunityData.likelihood =
            self.opportunity_obj.likelihoodId == null ||
            !self.opportunity_obj.likelihoodId.value
              ? ""
              : self.opportunity_obj.likelihoodId.value;
          opportunityData.make_master_id =
            self.opportunity_obj.makeId == null ||
            !self.opportunity_obj.makeId.value
              ? ""
              : self.opportunity_obj.makeId.value;
          opportunityData.lost_reason =
            opportunityData.status == "lost" &&
            self.opportunity_obj.lost_reason != "" &&
            self.opportunity_obj.lost_reason.value
              ? self.opportunity_obj.lost_reason.value
              : "";
          opportunityData.lost_additional_info =
            opportunityData.status == "lost" &&
            self.opportunity_obj.lost_additional_info != "" &&
            self.opportunity_obj.lost_additional_info
              ? self.opportunity_obj.lost_additional_info
              : "";
          opportunityData.notes = self.opportunity_obj.notes;

          data.opportunity_data = opportunityData;
        }

        data.add_activity = self.add_activity;
        if (self.add_activity) {
          let activityData = {};
          activityData.module =
            self.activity_obj.moduleData && self.activity_obj.moduleData.value
              ? self.activity_obj.moduleData.value
              : "";
          activityData.assign_user_id =
            self.activity_obj.assignUserId &&
            self.activity_obj.assignUserId.value
              ? self.activity_obj.assignUserId.value
              : "";
          activityData.asset_id =
            self.activity_obj.assetId && self.activity_obj.assetId.value
              ? self.activity_obj.assetId.value
              : "";

          activityData.company_id =
            self.activity_obj.assetId && self.activity_obj.assetId.company_id
              ? self.activity_obj.assetId.company_id
              : "";
          activityData.location_id =
            self.activity_obj.assetId && self.activity_obj.assetId.location_id
              ? self.activity_obj.assetId.location_id
              : "";

          activityData.type =
            self.activity_obj.type && self.activity_obj.type.value
              ? self.activity_obj.type.value
              : "";

          activityData.other_activity_type_text =
            self.activity_obj.otherActivityTypeText;
          activityData.activity_date_time =
            self.activity_obj && self.activity_obj.activityDateTime
              ? moment(self.activity_obj.activityDateTime).format()
              : "";
          activityData.notes = self.activity_obj.notes
            ? self.activity_obj.notes
            : "";
          activityData.direction =
            self.activity_obj.direction && self.activity_obj.direction.value
              ? self.activity_obj.direction.value
              : "";
          activityData.priority =
            self.activity_obj.priority && self.activity_obj.priority.value
              ? self.activity_obj.priority.value
              : "";
          activityData.subject = self.activity_obj.subject
            ? self.activity_obj.subject
            : "";
          activityData.status =
            self.activity_obj.status && self.activity_obj.status.value
              ? self.activity_obj.status.value
              : "";
          // activityData.browser_timezone= localStorage.getItem("browserTimeZone");
          // activityData.opportunity_master_id= opportunityMasterId;
          data.activity_data = activityData;
        }

        //Customer Contact Data
        data.contacts = [];
        if (self.contacts.length) {
          $.each(self.contacts, function (key, contactData) {
            let reqContactData = customerContactReqData(
              JSON.parse(JSON.stringify(contactData))
            );
            data.contacts.push(reqContactData);
          });
        }

        data.cus_id = self.cus_id;
        if (Array.isArray(self.tagId)) {
          const serializedTagId = JSON.stringify(self.tagId);
          data.tag_id = serializedTagId;
        }

        data.dealAppSlug = self.dealAppSlug;
        const allDlrSpecFields = [
              'distributorType', 'funkDlrSpec', 'barkoDlrSpec', 'gearmaticSpec',
              'catWeilerDlrSpec', 'prenticeDlrSpec', 'deereDlrSpec', 'tigercatDlrSpec',
              'escoSpec', 'timberjackSpec', 'franklinTreSpec', 'otherDlrWriteIn'
          ];

        allDlrSpecFields.forEach(field => {
            data[field] = (self.dealAppSlug == 'amp-dealer-manager') ? self[field] : '';
        });
       
        $("#commonLoaderAddContactPopupForm")
          .find(".loading-container.lodingTable")
          .show();
        self.forceSaveContact = false;
        HTTP.post("/contact/addwithopportunityandactivity", data, config)
          .then(function (response) {
            if (response.data && response.data.status == "success") {
              self.forceSaveContactFor = [];
              self.showSAlert(response.data.message, "success", false);
              self.closeModalPopup();
            } else {
              if(response.data && response.data.address_validation){
                let scrollTo = '';
                let errorMessage = '';
                if(response.data.address_type == 'customerAddress'){
                  scrollTo = self.handleUspsValidationErrors(response.data.content,'customerAddress');
                  errorMessage = "Customer Address: " + response.data.message;
                }else if(response.data.address_type == 'billAddress'){
                  scrollTo = self.handleUspsValidationErrors(response.data.content,'billAddress');
                  errorMessage = "Bill Address: " + response.data.message;
                }

                if(response.data.address_validation == "Fail"){
                  self.showSAlert(errorMessage, "error", false);
                  if(scrollTo){
                    setTimeout(function () {
                      document
                      .getElementById(scrollTo)
                      .scrollIntoView({ behavior: "smooth", block: "start" });
                    }, 2000);
                  }
                }
                else if(response.data.address_validation == "Warning"){
                  self.$swal({
                      title: "",
                      text: errorMessage,
                      type: "info",
                      confirmButtonText: "Proceed",
                      cancelButtonText: "Update",
                      showCancelButton: true,
                      showLoaderOnConfirm: true,
                  })
                  .then((result) => {
                      if (result.value) {
                        self.forceSaveContact = true;
                        if(response.data.address_type){
                          self.forceSaveContactFor.push(response.data.address_type);
                        }
                      }else{
                        if(scrollTo){
                          setTimeout(function () {
                            document
                            .getElementById(scrollTo)
                            .scrollIntoView({ behavior: "smooth", block: "start" });
                          }, 2000);
                        }
                      }
                  });
                }else{
                  // display received error
                  self.showSAlert(errorMessage, "error", false);
                }
              }else{
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            }
            $("#commonLoaderAddContactPopupForm")
              .find(".loading-container.lodingTable")
              .hide();
          })
          .catch(function (err) {
            $("#commonLoaderAddContactPopupForm")
              .find(".loading-container.lodingTable")
              .hide();
          });
      }
    },
    clearDataOnSaveAndContinue() {
      self.contactName = "";
    },
    onSearchManageBy(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadManageByCallBack,
          loading,
          search
        );
      }
    },
    loadManageByCallBack: function (callbackData) {
      self.isManageByRecordNotFound = false;
      self.manageByArr = callbackData.User;
      if (self.manageByArr) {
        self.isManageByRecordNotFound = true;
      }
    },
    changeManageBy: function (value) {},
    changeConutry: function (value) {
      self.forceSaveContactFor = [];
    },
    //need to clear forceSaveContactFor for change in customer address 
    // as on basis os this we are calling USPS api for customer address validtion
    changeAddress: function (value) {
      self.forceSaveContactFor = [];
    },
    changeAddress2: function (value) {
      self.forceSaveContactFor = [];
    },
    changeZipCode: function (value) {
      self.forceSaveContactFor = [];
    },
    changeStatus: function (value) {},
    changeBillCountry: function (value) {},
    onSearchCity(search, loading) {
      if (self.stateId == null || !self.stateId.value) {
        self.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadCityDataCallback,
            loading,
            search,
            self.stateId.value
          );
        }
      }
    },
    onSearchState(search, loading) {
      self.forceSaveContactFor = [];
      if (search.length > 2) {
        if (self.countryId == null || !self.countryId.value) {
          self.showSAlert("Please Select Country First!", "info", false);
        } else {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadStateDataCallback,
            loading,
            search,
            self.countryId.value
          );
        }
      }
    },
    loadStateDataCallback: function (callbackData) {
      self.isStateRecordNotFound = false;
      self.stateArr = callbackData.States;
      if (self.stateArr.length == 0) {
        self.isStateRecordNotFound = true;
      }
    },
    loadCityDataCallback: function (callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr) {
        self.isCityRecordNotFound = true;
      }
    },
    updateCity() {
      self.forceSaveContactFor = [];
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
    updateBillCity() {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    },
    onSearchBillCity(search, loading) {
      if (self.billState == null || !self.billState.value) {
        self.showSAlert("Please Select Bill State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadBillCityDataCallback,
            loading,
            search,
            self.billState.value
          );
        }
      }
    },
    loadBillCityDataCallback: function (callbackData) {
      self.isBillCityRecordNotFound = false;
      self.billCityArr = callbackData.Cities;
      if (self.billCityArr) {
        self.isBillCityRecordNotFound = true;
      }
    },
    onSearchBillState(search, loading) {
      if (search.length > 2) {
        if (self.billCountry == null || !self.billCountry.value) {
          self.showSAlert("Please Select Bill Country First!", "info", false);
        } else {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadBillStateDataCallback,
            loading,
            search,
            self.billCountry.value
          );
        }
      }
    },
    loadBillStateDataCallback: function (callbackData) {
      self.isBillStateRecordNotFound = false;
      self.billStateArr = callbackData.States;
      if (self.billStateArr.length == 0) {
        self.isBillStateRecordNotFound = true;
      }
    },
    onSearchSalesman(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadSalesmanCallback,
          loading,
          search
        );
      }
    },
    loadSalesmanCallback: function (callbackData) {
      self.opportunity_obj.isSalesmanRecordNotFound = false;
      self.opportunity_obj.salesmanArr = callbackData.User;
      if (self.opportunity_obj.salesmanArr.length == 0) {
        self.opportunity_obj.isSalesmanRecordNotFound = true;
      }
    },
    onClearSalesman: function () {
      self.opportunity_obj.salesmanError = "";
    },
    changedSalesmanValue: function (value) {
      /*console.log(value);*/
    },

    loadMasterDataCallback: function (callbackData) {
      self.opportunity_obj.isSalesmanRecordNotFound =
        self.opportunity_obj.isConclusionRecordNotFound =
        self.opportunity_obj.isCustomerRecordNotFound =
          false;
      self.opportunity_obj.salesmanArr = callbackData.User;
      if (self.opportunity_obj.salesmanArr.length == 0) {
        self.opportunity_obj.isSalesmanRecordNotFound = true;
      }
      self.opportunity_obj.customerArr = callbackData.Contact;
      if (self.opportunity_obj.customerArr.length == 0) {
        self.opportunity_obj.isCustomerRecordNotFound = true;
      }
      self.opportunity_obj.isLocationRecordNotFound = false;
      self.opportunity_obj.locationArr = callbackData.Location;
      if (self.opportunity_obj.locationArr.length == 0) {
        self.opportunity_obj.isLocationRecordNotFound = true;
      }
      self.opportunity_obj.sourceArr = callbackData.ReferralSource;
      /* self.leadQualityArr = callbackData.LeadQuality;
            self.saleTypeArr = callbackData.SaleType;
            self.fundingSourceArr = callbackData.FundingSource;
            self.fundingMethodArr = callbackData.FundingMethod;
            self.fundingStatusArr = callbackData.FundingStatus; */
      self.opportunity_obj.conclusionArr = callbackData.Conclusion;
      if (self.opportunity_obj.conclusionArr.length == 0) {
        self.opportunity_obj.isConclusionRecordNotFound = true;
      }
    },
    onSearchLocation(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Location"],
          true,
          self.loadLocationDataCallback,
          loading,
          search
        );
      }
    },
    loadLocationDataCallback: function (callbackData) {
      self.opportunity_obj.isLocationRecordNotFound = false;
      self.opportunity_obj.locationArr = callbackData.Location;
      if (self.opportunity_obj.locationArr.length == 0) {
        self.opportunity_obj.isLocationRecordNotFound = true;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    onSearchConclusion(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["Conclusion"],
          true,
          self.loadConclusionCallback,
          loading,
          search
        );
      }
    },
    loadConclusionCallback: function (callbackData) {
      self.opportunity_obj.isConclusionRecordNotFound = false;
      self.opportunity_obj.conclusionArr = callbackData.Conclusion;
      if (self.opportunity_obj.conclusionArr.length == 0) {
        self.opportunity_obj.isConclusionRecordNotFound = true;
      }
    },
    onSearchAssignUser(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadAssignUserCallback,
          loading,
          search
        );
      }
    },
    loadAssignUserCallback: function (callbackData) {
      self.activity_obj.isAssingUserRecordNotFound = false;
      self.activity_obj.assignUserIdArr = callbackData.User;
      if (self.activity_obj.assignUserIdArr.length == 0) {
        self.activity_obj.isAssingUserRecordNotFound = true;
      }
    },
    onSearchAsset(search, loading) {
      if (search.length > 1) {
        loading(true);
        self.loadAllData(
          ["Asset"],
          true,
          self.loadAssetCallback,
          loading,
          search
        );
      }
    },
    loadAssetCallback: function (callbackData) {
      self.activity_obj.isAssetRecordNotFound = false;
      self.activity_obj.assetIdArr = callbackData.Asset;
      if (self.activity_obj.assetIdArr.length == 0) {
        self.activity_obj.isAssetRecordNotFound = true;
      }
    },
  },
  watch: {
    "activity_obj.type": function (type) {
      self.activity_obj.isActivityTypeOther = false;
      if (type.value == "other") {
        self.activity_obj.isActivityTypeOther = true;
      }
    },
    forceSaveContact: function (forceSaveContact) {
      if(self.forceSaveContact){
        console.log("calling again saveContact");
        self.saveContact();
      }
    },
    countryId: function (countryId) {
      self.loadAllData(
        ["States"],
        true,
        self.loadStateDataCallback,
        "undefined",
        "",
        self.countryId.value
      );
    },
    stateId: function (stateId) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
    cityId: function () {
      if (self.stateId == null || !self.stateId.value) {
        if (!self.isModalClosing) {
          setTimeout(function () {
            self.cityId = "";
          }, 0.1);
          self.showSAlert("Please Select State First!", "info", false);
        }
      }
    },
    billCountry: function (billCountry) {
      self.loadAllData(
        ["States"],
        true,
        self.loadBillStateDataCallback,
        "undefined",
        "",
        self.billCountry.value
      );
    },
    billState: function (billState) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    },
  },
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
    if (
      !self.permissionNameArray.includes("can_add_customer_master") &&
      !self.permissionNameArray.includes("can_delete_customer_master") &&
      !self.permissionNameArray.includes("can_active_inactive_customer_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
      }, 50);
    }
  }
});
</script>

<style scoped>
.add-cnt-fopop-checkbox:hover input ~ .checkmark {
  background-color: #fff !important;
}

.add-cnt-fopop-checkbox label input ~ .checkmark:hover {
  background-color: #ccc !important;
}

.add-cnt-fopop-checkbox:hover input:checked ~ .checkmark,
.add-cnt-fopop-checkbox label input:checked ~ .checkmark:hover {
  background-color: #2196f3 !important;
}
.add-customer-popup-form .checkbox-label {
  text-align: left;
}
.customer-id .col-9{
  max-width: 70%;
}
.customer-id .form-label {
  font-family: 'SanFranciscoDisplayMedium';
  font-size: 15px;
}
.customer-id #regenerateCusIdCode {
  color: #fff;
}
.modal-xl {
  max-width: 1400px !important;
}
</style>
