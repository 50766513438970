<template>
    <div class="loginbg" style="display: none;" id="login-main-hr">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>{{ allStaticMessages.test_env_msg}}</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="/static/images/login-logo.png" alt="Velocity Vehicle Group">
                        </div>
                        <form @submit.prevent="attemptResetPassword();" id="ResetPasswordVendorForm" name="form">
                            <h2 class="reset-pwd-lbl"> {{ allLabelMessages.reset_password_lbl}} </h2>
                            <div class="form-group">
                                <label for="username">{{ allLabelMessages.password_lbl }}<span class="text-danger">*</span></label>
                                <input v-model="password" type="password" name="password" class="form-control" id="password" maxlength="190" @input="frontendErrors.password = ''">
                                <span class="help is-danger">{{frontendErrors.password}}</span>
                            </div>
                            <div class="form-group">
                                <label for="username">{{ allLabelMessages.confirm_password_lbl }}<span class="text-danger">*</span></label>
                                <input v-model="cpassword" type="password" name="cpassword" class="form-control" id="cpassword" maxlength="190" @input="frontendErrors.cpassword = ''">
                                <span class="help is-danger">{{frontendErrors.cpassword}}</span>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-offset-0 col-sm-offset-3 col-md-offset-3 col-lg-offset-3 pl-0">
                                <font class="help is-danger"> <label id="errorMsg" class="error text-danger"></label><br> </font>
                            </div>
                            <div class="reset-action-controls">
                                <button :disabled="disableButtons.submitButton" class="btn btn-primary" id="sendEmail">{{buttonNames.submitButton}}</button>
                                <button type="button" :disabled="disableButtons.backButton" class="btn btn-primary" v-on:click.prevent="backBtn()">{{buttonNames.backButton}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    var self;
    import Vue from 'vue';
    import {HTTP} from '../../http-common';
    import {commonMixins} from '../../mixins/commonMixins';

    export default {
        name: 'VendorResetPassword',
        mixins: [commonMixins],
        data() {
            return {
                currentEnvironment: false,
                vendorToken: '',
                password: '',
                cpassword: '',
                frontendErrors: {
                    password: '',
                    cpassword: '',
                },
                buttonNames: {
                    submitButton: 'Submit',
                    backButton: 'Back',
                },
                disableButtons: {
                    submitButton: false,
                    backButton: false,
                }
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            self.currentEnvironment = (process.env.VUE_APP_NODE_ENV == 'production') ? false : true;

            self.vendorToken = this.$route.params.token;
            if (self.vendorToken != undefined) {
                var fields = {
                    token: self.vendorToken
                };
                HTTP.post("/vendor/token/check", fields)
                    .then(function (response) {
                        if (response.data.code == 201) {
                            self.$router.push({ name: "VendorResetLinkNotFound" });
                        }
                    })
                    .catch(function (err) {
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        }
                    });
            }

            $("#wrapper").addClass("login-div-full");
            $(".loginbg").show();
        },
        methods: {
            backBtn: function () {
                self.$router.push('/vendor/login');
            },
            checkForm: function () {
                var checked = true;
                self.frontendErrors.password = "";
                self.frontendErrors.cpassword = "";
                if (!self.password || self.password.trim() == '') {
                    self.frontendErrors.password = 'Enter the password';
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.cpassword || self.cpassword.trim() == '') {
                    self.frontendErrors.cpassword = 'Enter the confirm password';
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.password != self.cpassword) {
                    self.frontendErrors.cpassword = 'Password Does Not Match';
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },
            attemptResetPassword: function() {
                self.checkForm();
                if (self.checkForm()) {
                    self.buttonNames.submitButton = "Submiting...";
                    self.disableButtons.submitButton = true;
                    var fields = {
                        token: self.vendorToken,
                        password: self.password,
                    };
                     HTTP.post("/vendor/reset/password", fields)
                        .then(function (response) {
                            self.buttonNames.submitButton = "Submit";
                            self.disableButtons.submitButton = false;
                            if (response.data.code == 200) {
                                self.$swal({
                                    title: '',
                                    text: response.data.message,
                                    type: 'success',
                                    confirmButtonText: 'Ok',
                                    showCancelButton: false,
                                    allowOutsideClick: false,
                                    showLoaderOnConfirm: true
                                }).then((result) => {
                                    self.backBtn();
                                });
                            }
                        })
                        .catch(function (err) {
                            self.buttonNames.submitButton = "Submit";
                            self.disableButtons.submitButton = false;
                            if (err.response.status == 0) {
                                self.error = "Remote server can not be reachable";
                            } else {
                                $('#errorMsg').html(err.response.data.message);
                            }
                        });
                }
            },
        },
    }
</script>  
<style lang="css" scoped>
h2.reset-pwd-lbl{
    font-size: 22px;
    text-align: center;
}
.reset-action-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.reset-action-controls .btn.btn-primary,
.reset-action-controls .btn.btn-primary:hover,
.reset-action-controls .btn.btn-primary:focus{
    margin: 0;
}
</style>