<template>
  <div class="payment-section">
    <div class="payment-section-secondary-row">
      <div>
        <div>
          <div class="select-container">
            <label for="branch" class="select-label"
              >Select Lease Company</label
            >
            <v-select
              v-model="leaseFinanceCompany"
              name="finance"
              label="text"
              :filterable="true"
              :options="leaseFinanceCompanyArr"
              :clearable="false"
              @input="selectLeaseFinanceCompany()"
              placeholder="SELECT LEASE"
              class="custom-ci-auto-search"
              id="select-finance-company"
            >
            </v-select>
          </div>
        </div>
        <div id="add-fnc-cmp" v-if="permissionNameArray.includes('can_add_finance_company_master')">
          <i
            class="fa fa-plus-circle"
            role="button"
            aria-hidden="true"
            title="Add Finance Company"
            @click="addLeaseFinanceCompany()"
          ></i>
        </div>
      </div>
    </div>

    <div class="payment-section-secondary-row" v-if="showAllFields">
      <div>
        <div class="fm-group">
          <label for="finance-company">Lease Company</label>
          <input
            type="text"
            class="form-input"
            id="finance-company"
            v-model="leaseCompany"
            @blur="emitLeaseData()"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="address">Address</label>
          <input
            type="text"
            class="form-input"
            id="finance-address"
            v-model="lineHolderAddress"
            @blur="emitLeaseData()"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="finance-state">City</label>
          <input
            type="text"
            class="form-input"
            id="finance-state"
            v-model="lineHolderCity"
            @blur="emitLeaseData()"
          />
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="state">State</label>
          <v-select
            v-model="lineHolderState"
            name="state"
            label="text"
            :filterable="false"
            :options="leaseStateArr"
            @search="onSearchState"
            :clearable="false"
            @input="emitLeaseData()"
            placeholder="Search & Select State"
            class="custom-ci-auto-search"
            id="select-finance-state"
          >
            <span slot="no-options" @click="$refs.select.open = false">
              <template v-if="!isStateRecordNotFound">
                Search State...
              </template>
              <template v-else>
                Sorry, no matching record found!
              </template>
            </span>
          </v-select>
        </div>
      </div>

      <div>
        <div class="fm-group">
          <label for="zip">Zip</label>
          <input
            type="text"
            class="form-input"
            id="finance-zip"
            v-model="lineHolderZip"
            @blur="emitLeaseData()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
import NoAccessPage from "../../../NoAccessPage/NoAccessPage";
import EventBus from "../../../event-bus";
import { commonMixins } from "../../../../mixins/commonMixins";
import { HTTP } from "../../../../http-common";
var selfThis;

export default {
  name: "Finance",
  components: {
    "no-access": NoAccessPage,
    Money,
  },
  mixins: [commonMixins],
  props: ['financeCompanyList', 'leaseData', 'isEdit', 'triggerPayment', 'paymentCat', 'permissionNameArray'],
  data() {
    return {
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
      },
      leaseCompany: "",
      leaseFinanceCompany: "",
      lineHolderName: "",
      lineHolderAddress: "",
      lineHolderDiffAddress: "",
      lineHolderCity: "",
      lineHolderDiffCity: "",
      lineHolderZip: "",
      lineHolderDiffZip: "",
      lineHolderState: "",
      lineHolderDiffState: "",
      leaseStateArr: [],
      leaseFinanceCompanyArr: [],
      leaseId: null,
      showAllFields: false,
      isStateRecordNotFound: false,
    };
  },
  mounted() {
    selfThis = this;
  },
  methods: {
    addLeaseFinanceCompany() {
      EventBus.$emit("add-financial-company");
    },

    populateLeaseFinanceData(data) {
      this.leaseCompany = data.text;
      this.lineHolderAddress = data.address;
      this.lineHolderCity = data.city_id;
      this.lineHolderState = data.state_id;
      this.lineHolderZip = data.zip_code;
      this.lineHolderName = "";
      this.lineHolderDiffAddress = "";
      this.lineHolderDiffCity = "";
      this.lineHolderDiffState = "";
      this.lineHolderDiffZip = "";
      this.getStateCity(data.value);
      EventBus.$emit("close-add-financial-company", false);
      setTimeout(() => {
        this.emitLeaseData();
      }, 1000);
    },

    onSearchState(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.loadAllData(
          ["States"],
          true,
          this.loadStateDataCallback,
          loading,
          search
        );
      }
    },
    loadStateDataCallback: function (callbackData) {
      this.isStateRecordNotFound = false;
      this.leaseStateArr = callbackData.States;
      if(this.leaseStateArr.length == 0) {
          this.isStateRecordNotFound = true;
      }
    },
    onSearchCity(search, loading) {
      if (this.leaseState == null || !this.leaseState.value) {
        this.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          this.loadAllData(
            ["Cities"],
            true,
            this.loadCityDataCallback,
            loading,
            search,
            this.leaseState.value
          );
        }
      }
    },
    loadCityDataCallback: function (callbackData) {
      this.leaseCityArr = callbackData.Cities;
    },
    selectLeaseFinanceCompany() {
      this.showAllFields = true;
      this.leaseStateArr = [];
      this.populateLeaseFinanceData(this.leaseFinanceCompany);
    },

    getStateCity(financeCompanyId) {
      var config = {
          headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
      };

      HTTP.get("/get/transactionsheet-finance-state/"+financeCompanyId, config)
      .then(function (response) {
        const data = response.data.content;
        if(data.state !== null) {
          selfThis.lineHolderState = {
            text: data.state.name,
            value: data.state.id
          };
        } else {
          selfThis.lineHolderState = "";
        }

        if(data.city !== null) {
          selfThis.lineHolderCity = data.city.name
        }
      })
      .catch(function (err) {
          console.log('ERROR!!', err);
      });
    },

    emitLeaseData() {
      // console.log('lineHolderDiffState',this.lineHolderDiffState == null, this.lineHolderDiffState == "", this.lineHolderDiffState == undefined);
      let leaseData = {
        id: this.leaseId,
        lease_company: this.leaseCompany,
        finance_company_master_id: (this.leaseFinanceCompany == null || this.leaseFinanceCompany == "") ? null : this.leaseFinanceCompany.value,
        line_holder_state_id: (this.lineHolderState == null || this.lineHolderState == "") ? null : this.lineHolderState.value,
        line_holder_diff_state_id: (this.lineHolderDiffState == null || this.lineHolderDiffState == "") ? null : this.lineHolderDiffState.value,
        line_holder_name: this.lineHolderName,
        line_holder_address: this.lineHolderAddress,
        line_holder_diff_address: this.lineHolderDiffAddress,
        line_holder_city: this.lineHolderCity,
        line_holder_diff_city: this.lineHolderDiffCity,
        line_holder_zip: this.lineHolderZip,
        line_holder_diff_zip: this.lineHolderDiffZip,
        tempLineHolderState: this.lineHolderState,
        tempLeaseFinanceCompany: this.leaseFinanceCompany,
        tempLineHolderDiffState: this.lineHolderDiffState,
        showAllFields: this.showAllFields,
      };
      EventBus.$emit('emit-lease-data', leaseData);
    },

    populateLeaseData() {
      let data = this.leaseData;
      if(Object.keys(data).length > 0) {
        this.showAllFields = true;
        if(Object.keys(data).includes('finance_company') && data.finance_company !== null) {
          this.leaseFinanceCompany = {
            text: data.finance_company.name,
            value: data.finance_company.id
          }
        } else {
          this.leaseFinanceCompany = data.tempLeaseFinanceCompany;
        }

        if(Object.keys(data).includes('state') && data.state !== null) {
          this.lineHolderState = (data.state !== null) ? {
            text: data.state.name,
            value: data.state.id
          } : "";
        } else {
          this.lineHolderState = data.tempLineHolderState;
        }

        if(Object.keys(data).includes('diff_state') && data.diff_state !== null) {
          this.lineHolderDiffState = (data.diff_state !== null) ? {
            text: data.diff_state.name,
            value: data.diff_state.id
          } : "";
        } else {
          this.lineHolderDiffState = data.tempLineHolderDiffState;
        }

        this.lineHolderAddress = data.line_holder_address;
        this.leaseCompany = data.lease_company;
        this.lineHolderCity = data.line_holder_city;
        this.lineHolderZip = data.line_holder_zip;
        this.leaseId = data.id;
        this.lineHolderDiffAddress = data.line_holder_diff_address;
        this.lineHolderDiffCity = data.line_holder_diff_city;
        this.lineHolderDiffZip = data.line_holder_diff_zip;
        this.lineHolderName = data.line_holder_name;
      }
    }
  },
  watch: {
    financeCompanyList:{
      handler(list){
        this.leaseFinanceCompanyArr = list;
      },
      immediate:true,
    },

    isEdit(status) {
      if(status) {
        setTimeout(() => {
          this.populateLeaseData();
        }, 1000);
      }
    },

    triggerPayment:{
      handler(isTrigger){
        // console.log('isTriggerPayment>>', isTrigger);
        this.populateLeaseData();
      },
      immediate:true,
    }
  }
};

EventBus.$on("finance-company-added-data", function (data) {
  if (typeof self != "undefined") {
    if(selfThis.paymentCat == "lease") {
      selfThis.leaseFinanceCompany = {
        text: data.text,
        value: data.value
      }
      selfThis.showAllFields = true;
      selfThis.leaseFinanceCompanyArr.push(data);
      selfThis.populateLeaseFinanceData(data);
    }
  }
});
</script>

<style
  lang="css"
  scoped
  src="../../../../../public/static/css/trnsPayment.css"
></style>

<style>
#select-finance-company .dropdown-toggle,
#select-finance-state .dropdown-toggle {
  height: 32px !important;
  background: #eff5ff !important;
}

#select-finance-company .dropdown-menu,
#select-finance-state .dropdown-menu {
  max-height: 100px !important;
}
</style>
