<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_list_expense_master')">
      <div class="container-fluid xyz">
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>{{ allLabelMessages.expense }}</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} {{ allLabelMessages.results_found }}</p>
            </div>
            <router-link
              v-show="permissionNameArray.includes('can_add_expense_master')"
              class="btn btn-primary waves-effect pull-right ml-2"
              :title="allLabelMessages.add_expense"
              v-bind:to="{ name: 'AddUpdateAssetExpenses' }"
            >
              <span>{{ allLabelMessages.add_expense }}</span>
            </router-link>

            <a
              id="refreshList"
              :title="allLabelMessages.refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <!-- Export Modal Start -->
            <a
              id="exportAssetExpense"
              :title="allLabelMessages.export"
              v-show="permissionNameArray.includes('can_export_expense_master')"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Export Expense', //Modal Title
                'export', //Action
                'exportExpenseRequest', //Module Name 
                exportHeaders //Master Headers List
              )"
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>  
            <!-- Export Modal End -->

            <!-- List Header Modal Start -->
            <button
              id="expenseHeaderList"
              :title="allLabelMessages.expense_header_list"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="openReorderHeadersListModal(
                'Select Expense Headers', //Modal Title
                'list', //Action
                'listExpense', //Module Name 
                expenseHeadersList //Master Headers List
              )"
            >
              {{ allLabelMessages.expense_header_list }}
            </button>
            <!-- List Header Modal End -->
          </div>

          <div class="col-12" v-if="batchProcess">
            <div class="alert alert-success">
              Export file is in process. batch no. is {{batchNum}}. <router-link class="color-blue" v-bind:to="{name: 'ListBatch'}" target="_blank" @click.native="batchProcess = 0"> Click Here</router-link> to check file status!
            </div>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            @resetSorting="resetSorting"
          ></filter-component>
          <div
            class="col-12 paddingnone list-common-actions"
            v-if="selectedRows.length >= 1"
          >
            <span class="color-cc9636"
              >{{ selectedRows.length }} {{ allLabelMessages.rows_selected }}
              <a class="color-098de1" v-on:click.prevent="clearSelection()"
                >X</a
              ></span
            >
            <button
              v-show="
                permissionNameArray.includes('can_delete_expense_master') &&
                selectedDeleteRows.length >= 1
              "
              type="button"
              v-on:click.prevent="deleteAssetExpenses()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              {{ allLabelMessages.delete }} {{ selectedDeleteRows.length }} {{ allLabelMessages.records }}
            </button>
            <button
              v-show="
                permissionNameArray.includes(
                  'can_expense_include_expense_master'
                )
              "
              type="button"
              v-on:click.prevent="addAmountStatus()"
              class="btn btn-primary waves-effect pull-right ml-2"
            >
              {{ allLabelMessages.expense_include_status_for }} {{ selectedRows.length }} {{ allLabelMessages.records }}
            </button>
          </div>
          <div class="col-12 paddingnone">
            <common-loader
              refId="commonLoaderListAssetExpenses"
              ref="commonLoaderListAssetExpenses"
            >
            </common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    :placeholder="allStaticMessages.select_per_page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel"> {{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    :placeholder="allStaticMessages.page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right table-scrollable"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template
                    v-if="assetexpensesArr && assetexpensesArr.length > 0"
                  >
                    <template
                      v-for="(assetexpenses, index) in assetexpensesArr"
                    >
                      <tr class="mainDetails" :key="index">
                        <td class="">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              v-model="selectedRows"
                              :value="assetexpenses.id"
                              @change="inputCheckbox($event)"
                              class="custom-control-input"
                              :id="'customCheck' + assetexpenses.id"
                              name="case[]"
                            />
                            <label
                              class="custom-control-label"
                              :for="'customCheck' + assetexpenses.id"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <div class="actions" style="width: 102px">
                            <a
                              :id="'accordion-' + assetexpenses.id"
                              v-on:click="toggleIcon(assetexpenses.id)"
                              v-b-toggle="'accordion-' + assetexpenses.id"
                              href="javascript:void(0)"
                              :title="allLabelMessages.show_extra_details"
                              class="mar-right-0"
                            >
                              <i
                                v-if="accordianIds.includes(assetexpenses.id)"
                                class="exceptEdit fa fa-minus"
                                aria-hidden="true"
                              ></i>
                              <i
                                v-else
                                class="exceptEdit fa fa-plus"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <template v-if="assetexpenses.from_excede == '0'">
                              <router-link
                                v-show="
                                  permissionNameArray.includes(
                                    'can_add_expense_master'
                                  )
                                "
                                class="mar-right-0"
                                :title="allLabelMessages.edit_expense"
                                v-bind:to="{
                                  name: 'UpdateAssetExpenses',
                                  params: { id: assetexpenses.id },
                                }"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa fa-angle-right forward_icon"
                                ></i>
                              </router-link>
                            </template>
                            <template v-if="assetexpenses.from_excede == '0'">
                              <a
                                v-show="
                                  permissionNameArray.includes(
                                    'can_delete_expense_master'
                                  )
                                "
                                href="javascript:void(0)"
                                :title="allLabelMessages.delete_expense"
                                class="mar-right-0"
                                v-on:click.prevent="
                                  deleteAssetExpenses(assetexpenses.id)
                                "
                              >
                                <i
                                  class="exceptEdit fa fa-trash-o"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </template>
                            <!--<a v-show="permissionNameArray.includes('can_delete_expense_master')" href="javascript:void(0)" title='Delete Asset Expenses' class="mar-right-0" v-on:click.prevent="deleteAssetWistExpenses(assetexpenses.id)">
                                                        <i class="exceptEdit fa fa-trash-o" aria-hidden="true"></i>
                                                    </a>-->
                            <a
                              :title="allLabelMessages.excede_po"
                              v-if="assetexpenses.from_excede === 1"
                              href="javascript:void(0)"
                              class="mar-right-0"
                            >
                              <i
                                class="exceptEdit fa fa-etsy color-71aa3f"
                                aria-hidden="true"
                              ></i>
                            </a>
                            <template>
                              <template
                                v-if="
                                  permissionNameArray.includes(
                                    'can_expense_include_expense_master'
                                  )
                                "
                              >
                                <template
                                  v-if="assetexpenses.exclude_from_total === 1"
                                >
                                  <a
                                    :title="allLabelMessages.exclude_from_total"
                                    href="javascript:void(0)"
                                    class="mar-right-0"
                                    v-on:click.prevent="
                                      addAmountStatus(assetexpenses.id)
                                    "
                                  >
                                    <i
                                      class="exceptEdit fa fa-calculator color-c3202b"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </template>
                                <template v-else>
                                  <a
                                    :title="allLabelMessages.include_in_total"
                                    href="javascript:void(0)"
                                    class="mar-right-0"
                                    v-on:click.prevent="
                                      addAmountStatus(assetexpenses.id)
                                    "
                                  >
                                    <i
                                      class="exceptEdit fa fa-calculator color-71aa3f"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </template>
                              </template>
                            </template>
                          </div>
                        </td>
                        <template
                          v-if="expenseDataArr && expenseDataArr.length > 0"
                        >
                          <template
                            v-for="(
                              expenseData, expenseDataIndex
                            ) in expenseDataArr"
                          >
                            <template v-if="index == expenseDataIndex">
                              <template
                                v-for="(data, dataIndex) in expenseData"
                              >
                                <template
                                  v-for="(headerData, headerIndex) in headers"
                                >
                                  <template
                                    v-if="headerData.column_name == dataIndex"
                                  >
                                    <td
                                      :class="headerData.column_class"
                                      :key="dataIndex"
                                    >
                                      {{ data }}
                                    </td>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </template>
                        </template>
                        <!-- <td :class="rowClass.stock">{{assetexpenses.stock}}</td>
                                            <td :class="rowClass.vinNo">{{assetexpenses.vinNo}}</td>
                                            <td :class="rowClass.department">{{assetexpenses.department}}</td>
                                            <td :class="rowClass.vendor">{{assetexpenses.vendor}}</td>
                                            <td :class="rowClass.excede_po">{{assetexpenses.excede_po}}</td>
                                            <td :class="rowClass.invoice_id">{{assetexpenses.invoice_id}}</td>
                                            <td :class="rowClass.expense_category">{{assetexpenses.expense_category}}</td>
                                            <td :class="rowClass.reference_no">{{assetexpenses.reference_no}}</td>
                                            <td :class="rowClass.estimated_cost">{{assetexpenses.estimated_cost}}</td>
                                            <td :class="rowClass.actual_cost">{{assetexpenses.actual_cost}}</td>
                                            <td :class="rowClass.entered">{{assetexpenses.entered}}</td>
                                            <td :class="rowClass.paid_date">{{assetexpenses.paid_date}}</td> -->
                        <!--<td :class="rowClass.rebill_contact">{{assetexpenses.rebill_contact}}</td>
                                            <td :class="rowClass.rebill_paid_date">{{assetexpenses.rebill_paid_date}}</td>-->
                      </tr>
                      <!--<tr v-show="accordianIds.includes(assetexpenses.id)">-->
                      <tr class="extraDetails">
                        <td colspan="2"></td>
                        <td
                          :colspan="headers.length - 1"
                          class="text-alignment-left"
                        >
                          <list-accordian
                            :accordianData="assetexpenses"
                            :displayFields="returnAccordianDisplayDataKeys()"
                          ></list-accordian>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <th class="">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="selectAll"
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAllCheckBox"
                          name="example1"
                          @change="clickHeaderCheckBox()"
                        />
                        <label
                          class="custom-control-label"
                          for="selectAllCheckBox"
                        ></label>
                      </div>
                    </th>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        class=""
                        :key="headerIndex"
                        v-if="data.name == 'Action'"
                        data-breakpoint="xs"
                      >
                        {{ data.name }}
                      </th>
                    </template>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        :key="headerIndex"
                        v-if="data.name != 'Action'"
                        v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        "
                      >
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    :title="allLabelMessages.select_per_page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    :placeholder="allStaticMessages.select_per_page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel"> {{ allLabelMessages.page }}</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    :placeholder="allStaticMessages.page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>
        <!-- Export and List Headers Modal Start -->
         <reorder-headers-list-modal
            @call-is-export="exportExpenseRequest = true"
            @call-reload-list="loadAssetExpenses"
            ref="reorderHeadersListModalRef"
          >
          </reorder-headers-list-modal>
          <!-- Export and List Headers Modal End -->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
var self;
var filterColumn = "id";
var filterType = "desc";
import Vue from "vue";
import vSelect from "vue-select";
import Footer from "@/components/partials/Footer";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import ListAccordianComponent from "../ListAccrodianComponent/ListAccordianComponent";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";

Vue.component("app-footer", Footer);
Vue.component("v-select", vSelect);

export default {
  name: "ListAssetExpenses",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "list-accordian": ListAccordianComponent,
    "common-loader": CommonLoader,
    "reorder-headers-list-modal": ReorderHeadersListModal,
  },
  data() {
    return {
      text: "Anim pariatur cliche reprehenderit, ",
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      assetexpensesArr: [],
      headers: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      columnName: filterColumn,
      sortType: filterType,
      selectedRows: [],
      selectedDeleteRows: [],
      startEndRecord: "",
      selectAll: false,
      permissionNameArray: ["can_list_expense_master"],
      actionDisplay: true,
      accordianIds: [],
      exportExpenseRequest: false,
      exportAction: '',
      filterheaders: [],
      jumpToHistoryPage: 1,
      filterCount: 0,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      expenseHeadersList: [],
      selectedExpenseHeadersList: [],
      isSelectAllExpenseHeaderList: false,
      expenseDataArr: [],
      batchProcess: 0,
      batchNum: "",
    };
  },
  beforeMount() {
    self = this;
  },
  created: function () {
    self = this;
    self.getLoginIdPermissions();
  },
  watch: {
    /*selectAll: function (selectAll) {
             self.clickHeaderCheckBox();
             },*/
    /*perPage: function () {
                self.currentPage = 1;
                if (self.perPage !== "All") {
                    self.perPageValue = self.perPage;
                }
                self.loadAssetExpenses();
            },
            currentPage: function () {
                self.loadAssetExpenses();
            }*/
  },
  mounted: function () {
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("assetexpenses-list");
    EventBus.$emit("menu-selection-changed", "assetexpenses-list");
    var listHistoryData = self.loadFilters("listExpenses", self);
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;
    let headersNames = [];
    headersNames["filters"] = self.filters;
    /* self.listExecutionState = new Date($.now()); */
    self.listExecutionState = false;
    self.loadAssetExpenses(1);
    self.initializeModals();
  },
  methods: {
    initializeModals() {
      self.exportAssetExpenseInstance = new bootstrap.Modal(
        document.getElementById("exportModal")
      );
      self.assetExpenseHeaderListModalInstance = new bootstrap.Modal(
        document.getElementById("exportHeaderListModal")
      );
    },  
    resetSorting: function () {
      self.columnName = filterColumn;
      self.sortType = filterType;
      self.changepage();
    },
    changePerPage: function () {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.changepage();
    },
    changepage: function () {
      /* var diff = ((new Date($.now()) - self.listExecutionState)/1000);
                // console.log("hi" + diff);
                if (diff > 1) { */
      if (!self.listExecutionState) {
        self.loadAssetExpenses();
      }
      /* };
                self.listExecutionState = new Date($.now()); */
      // console.log("hi" + self.listExecutionState);
    },
    returnAccordianDisplayDataKeys: function () {
      let dataArray = [
        {
          field: "id",
          label: self.allLabelMessages.id_label,
          alt: "",
        },
        {
          field: "rebill_contact",
          label: self.allLabelMessages.rebill_contact_label,
          alt: "",
        },
        {
          field: "chargeback",
          label: self.allLabelMessages.chargeback_label,
          alt: "",
        },
        {
          field: "summary",
          label: self.allLabelMessages.summary_label,
          alt: "",
        },
        {
          field: "notes",
          label: self.allLabelMessages.notes_label,
          alt: "",
        },
      ];

      return dataArray;
    },
    refreshList: function () {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.loadAssetExpenses();
    },
    toggleIcon: function (id) {
      self.accordianIds = self.toggelExpandCollespeIcon(id, self.accordianIds);
    },

    clearSelection: function () {
      self.selectAll = false;
      self.selectedRows = [];
      self.selectedDeleteRows = [];
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    clickHeaderCheckBox: function () {
      setTimeout(function () {
        self.selectedRows = [];
        self.selectedDeleteRows = [];
        if (self.selectAll) {
          for (let i in self.assetexpensesArr) {
            self.selectedRows.push(self.assetexpensesArr[i].id);
            if (self.assetexpensesArr[i].from_excede != 1) {
              self.selectedDeleteRows.push(self.assetexpensesArr[i].id);
            }
          }
        }
      }, 100);
    },
    inputCheckbox: function (e) {
      setTimeout(function () {
        self.selectedDeleteRows = [];
        self.selectAll = false;
        if (self.selectedRows.length == self.assetexpensesArr.length) {
          self.selectAll = true;
        }
        $.each(self.selectedRows, function (i) {
          $.each(self.assetexpensesArr, function (j) {
            if (self.assetexpensesArr[j].id == self.selectedRows[i]) {
              if (self.assetexpensesArr[j].from_excede != 1) {
                self.selectedDeleteRows.push(self.assetexpensesArr[j].id);
              }
            }
          });
        });
      }, 100);
    },
    addAssetExpenses: function () {
      // self.$router.push('/add/expense');
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.loadAssetExpenses();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
        // self.headers = self.appliedHeaders;
      }
    },
    changedValue: function (value) {
      /*console.log(value);*/
    },
    loadAssetExpenses: function (first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        self.perPage = 500;
        self.loadAssetExpenses();
      }
      self.exportSelectedHeaders = self.loadExportHeaders(
            "exportExpenseRequest",
            self
          );
          self.exportSelectedHeaders =
            self.exportSelectedHeaders === null ||
            typeof self.exportSelectedHeaders === "undefined" ||
            self.exportSelectedHeaders == ""
              ? []
              : self.exportSelectedHeaders;
      
      self.selectedExpenseHeadersList = self.loadListHeaders(
        "listExpense",
        self
      );
      self.selectedExpenseHeadersList =
        self.selectedExpenseHeadersList === null ||
        typeof self.selectedExpenseHeadersList === "undefined" ||
        self.selectedExpenseHeadersList == ""
          ? []
          : self.selectedExpenseHeadersList;
      
      // Export Records Check
      if (self.exportExpenseRequest == true) {
        self.exportRecords();
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportExpenseRequest,
        export_action: self.exportAction,
        export_selected_headers: self.exportSelectedHeaders,
        selected_expense_header: self.selectedExpenseHeadersList,
      };
      this.storeFilters("listExpenses", fields, false);
      if (self.exportExpenseRequest) {
        self.$refs.reorderHeadersListModalRef.exportLoaderShow(); 
      } else {
        $("#commonLoaderListAssetExpenses")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/assetexpenses/list", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (data.status == 200) {
            if (data.data.message == "success export queue") {
              self.exportAction = '';
                
              self.exportExpenseRequest = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.loadAssetExpenses();
              self.batchProcess = 1;
              self.batchNum = data.data.content;
            } else if (data.data.message == "success export") {
              self.exportAction = '';
              self.exportExpenseRequest = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.downloadFile(data.data.content.web);
              self.loadAssetExpenses();
            } else {
              $("#commonLoaderListAssetExpenses")
              .find(".loading-container.lodingTable")
              .hide();
              self.clearSelection();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              if (
                self.perPage == "All" &&
                self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
              ) {
                let seconds = 5000;
                self.$swal({
                  type: "error",
                  html: self.allStaticMessages
                    .too_many_data_to_display_browser_can_not_load,
                  showConfirmButton: false,
                  timer: seconds,
                });
                setTimeout(() => {
                  self.perPage = 500;
                  self.loadAssetExpenses();
                }, seconds);
              }
              self.assetexpensesArr = data.data.content.data;
              self.expenseDataArr = data.data.content.expenseData;
              self.headers = data.data.content.headers;
              self.expenseHeadersList = data.data.content.expenseHeadersList;
              if (
                self.selectedExpenseHeadersList.length ==
                self.expenseHeadersList.length
              ) {
                self.isSelectAllExpenseHeaderList = true;
              }
              self.loadFilterInputs(
                data.data.content.filterheaders,
                self.filters
              );
              self.filterheaders = data.data.content.filterheaders;
              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }
              if (first) {
                self.currentPage = self.jumpToHistoryPage;
              }
              self.addColumnClass(self.headers);

              let testArray = [
                {
                  text: 1,
                  value: "2020-02-01",
                },
                {
                  text: 2,
                  value: "2020-02-02",
                },
                {
                  text: 3,
                  value: "2020-02-03",
                },
              ];
              for (let i = 0; i < self.headers.length; i++) {
                if (self.headers[i]["type"] == "drop_down") {
                  self.headers[i]["options_array"] = testArray;
                }
              }
              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
        })
        .catch(function (error) {
          self.listExecutionState = false;
          $("#exportAssetExpense").prop("disabled", false);
          self.exportExpenseRequest = false;
          if (self.exportExpenseRequest) {
            self.$refs.reorderHeadersListModalRef.exportLoaderHide(); 
          } else {
            $("#commonLoaderListAssetExpenses")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(error, function () {});
        });
    },
    sortTableData: function (name, isSortAllowed) {
      if (isSortAllowed == "nosort") {
        if (self.columnName == name) {
          self.sortType = self.sortType == "desc" ? "asc" : "desc";
        } else {
          self.sortType = "asc";
        }
        self.columnName = name;
        self.loadAssetExpenses();
      }
    },
    deleteAssetExpenses: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedDeleteRows;
      }
      if (deleteArray.length >= 1) {
        self
          .$swal({
            title: "",
            text: "Are you sure that you want to delete this Expense(s)!",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            // showCloseButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data: {
                  deleteIdArr: deleteArray,
                },
              };
              HTTP.delete("/assetexpenses/delete", config)
                .then(function (response) {
                  if (response.data.code == "200") {
                    self.loadAssetExpenses();
                  }
                })
                .catch(function (error) {
                  self.catchHandler(error, function () {});
                });
            }
          });
      }
    },
    addAmountStatus: function (id) {
      let deleteArray = [];
      id =
        id === null || typeof id === "undefined" || id == "" || id == 0
          ? 0
          : id;
      if (id != 0) {
        deleteArray.push(id);
      } else {
        deleteArray = self.selectedRows;
      }
      if (deleteArray.length >= 1) {
        self
          .$swal({
            title: "",
            text: "Are you sure that you want to change Expense(s) status!",
            type: "info",
            confirmButtonText: "OK",
            showCancelButton: true,
            // showCloseButton: true,
            showLoaderOnConfirm: true,
          })
          .then((result) => {
            if (result.value) {
              var config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("api_token"),
                },
                data: {
                  deleteIdArr: deleteArray,
                },
              };
              HTTP.delete("/assetexpenses/changestatus", config)
                .then(function (response) {
                  if (response.data.code == "200") {
                    self.loadAssetExpenses();
                  }
                })
                .catch(function (error) {
                  self.catchHandler(error, function () {});
                });
            }
          });
      }
    },
  }
  
};
EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }

    if (
      !self.permissionNameArray.includes("can_add_expense_master") &&
      !self.permissionNameArray.includes("can_delete_expense_master") &&
      !self.permissionNameArray.includes("can_view_inventory_master") &&
      !self.permissionNameArray.includes("can_expense_include_expense_master")
    ) {
      setTimeout(function () {
        self.headers.pop();
        self.actionDisplay = false;
      }, 50);
    }
  }
});
</script>
