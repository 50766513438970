<template>
    <div class="loginbg" id="login-main-hr">
        <div v-if="currentEnvironment" class="notification-block notification-block-top">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary alert-dismissible">
                <div class="scroll-left">
                    <p>This is the test environment!</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="loginform">
                        <div class="loginlogo">
                            <img src="/static/images/login-logo.png" alt="Velocity Vehicle Group">
                        </div>
                        <form>
                            <!-- Username -->
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input 
                                    v-model="username" 
                                    type="text" 
                                    name="username" 
                                    id="username" 
                                    class="form-control" 
                                    placeholder="Enter Username" 
                                    maxlength="190" 
                                    @input="username_error=''; error_msg_login=''"
                                >
                                <span class="help is-danger">{{username_error}}</span>
                            </div>

                            <!-- Password -->
                            <div class="form-group">
                                <label for="password">Password</label>
                                <div class="password-container" style="position:relative">
                                    <!-- Password input field with conditional type -->
                                    <input 
                                        v-model="password" 
                                        :type="isPasswordHide ? 'password' : 'text'" 
                                        name="password" 
                                        id="password" 
                                        class="form-control" 
                                        placeholder="Enter Password" 
                                        maxlength="190" 
                                        aria-label="Password"
                                        @input="password_error=''; error_msg_login=''"
                                    >
                                    <!-- Password visibility toggle -->
                                    <button 
                                        type="button" 
                                        v-on:click.prevent="hideShowPassword()" 
                                        class="password-toggle" 
                                        aria-label="Toggle password visibility" 
                                        style="position:absolute; top:10px; right:12px; background:none; border:none; cursor:pointer;">
                                        <i :class="isPasswordHide ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                    </button>
                                </div>
                                <span class="help is-danger">{{ password_error }}</span>
                            </div>
                            <span class="help is-danger">{{error_msg_login}}</span>

                            <!-- Submit/Forget Password Button -->
                            <div>
                                <button :disabled="disableButtons.loginButton" id="loginbtn" type="submit" class="btn btn-primary" v-on:click.prevent="vendorLogin(this)">{{buttonNames.loginButton}}</button>
                                <router-link class="pull-right" v-bind:to="{name: 'VendorForgotPassword'}"> Forgot/Reset Password?</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var self;
import {HTTP} from '../../http-common';

export default {
    name: 'VendorLogin',
    data() {
        return {
            username: "",
            username_error: "",
            
            password: "",
            password_error: "",
            isPasswordHide: true,

            not_registered_url: "",
            forgot_pass_url: "",

            buttonNames : {
                loginButton : 'Login',
            },

            disableButtons : {
                loginButton : false,
            },
            error_msg_login: "",
            currentEnvironment: false,
        }
    },
    created: function () {
    },
    beforeMount: function () {
        self = this;
    },
    mounted: function () {
        self.currentEnvironment = (process.env.VUE_APP_NODE_ENV == 'production') ? false : true;
        self.checkLocalStorage();
    },
    methods: {
        checkLocalStorage : function () {
            var vendorAPIToken = localStorage.getItem('vendor_api_token');
            if(vendorAPIToken) {
                var vendor_redirect_url = localStorage.getItem('vendor_redirect_url');
                if (vendor_redirect_url && vendor_redirect_url != null && vendor_redirect_url !== "") {
                    self.$router.push(localStorage.getItem("vendor_redirect_url"));
                } else {
                    self.resetBuildVersion();
                    self.$router.push('/vendor/login');
                }
            }  else {
                self.resetBuildVersion();
                self.$router.push('/vendor/login');
            }
        },
        resetBuildVersion: function() {
            let current_build = localStorage.getItem("build_version");
            localStorage.clear();
            localStorage.setItem("build_version", current_build);
        },
        hideShowPassword: function() {
            self.isPasswordHide = !self.isPasswordHide;
        },
        checkForm: function() {
            var checked = true;
            self.username_error = '';
            self.password_error = '';

            const usernameError = self.validateInput(self.username, "Username");
            if(usernameError) {
                self.username_error = usernameError;
                if (checked) {
                    checked = false;
                }
            }

            const passwordError = self.validateInput(self.password, "Password");
            if(passwordError) {
                self.password_error = passwordError;
                if (checked) {
                    checked = false;
                }
            }

            return checked;
        },
        validateInput: function(input, fieldName) {
            // Reset error message for the field
            let error = '';

            // Check if input is null, undefined, empty string, or the string 'null'
            if (input === null || input === undefined || input === '') {
                error = `Please enter ${fieldName}`;
            } else if (input === 'null') {
                error = `${fieldName} cannot be the string 'null'.`;
            } else if (input === 'undefined') {
                error = `${fieldName} cannot be the string 'undefined'.`;
            }

            return error;
        },
        vendorLogin: function() {
            if(self.checkForm()) {

                self.buttonNames.loginButton = "Login...";
                self.disableButtons.loginButton = true;

                var fields = {
                    username: self.username,
                    password: self.password,
                };

                HTTP.post('/vendor/login', fields)
                    .then(function (response) {
                        self.buttonNames.loginButton = "Login";
                        self.disableButtons.loginButton = false;

                        if (response.status == 200) {
                            localStorage.setItem("login_role", "vendor");
                            localStorage.setItem("vendor_api_token", response.data.api_token);
                            localStorage.setItem("vendor_access_token", response.data.api_token);
                            localStorage.setItem("vendor_obj", JSON.stringify(response.data.vendor_obj));
                            localStorage.setItem('vendor_redirect_url', '/vendor/dashboard');

                            window.location.href = localStorage.getItem('vendor_redirect_url');
                        }
                    })
                    .catch(function (error) {
                        self.error_msg_login = "";
                        self.buttonNames.loginButton = "Login";
                        self.disableButtons.loginButton = false;
                        /*$('#loginbtn').text("Login");
                        $("#loginbtn").attr("disabled", false);*/
                        if (error.response.status == 0) {
                            self.error_msg_login = "Remote server can not be reachable";
                        }else if (error.response.status == 422) {
                            console.log(error.response);
                            self.username_error = error.response.data.email[0];
                            self.password_error = error.response.data.password[0];
                        } else {
                            if (error.response.data.message) {
                                self.error_msg_login = error.response.data.message;
                            }
                        }
                    })

            }
        }
    }
}
</script>
