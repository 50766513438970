<template xmlns:title="http://www.w3.org/1999/xhtml">
    <!-- Sidebar -->
    <div id="sidebar-wrapper" ref="sidebar">
        <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
            <template v-for="(menu, m_index) in menuArray">
                <li v-if="arrayContainsArray(permissionNameArray, menu.permissionName) || menu.permissionName == ''" v-bind:class="{'active': menu.isActive}"  :id="menu.id" :ref="menu.ref" v-bind:key="'menu-'+m_index">
                    <template v-if="!menu.SubMenu">
                        <template v-if="menu.isPage">
                            <router-link :title=menu.name v-bind:to="{name: menu.routerlink.name}">
                                <span class="fa-stack fa-lg pull-left">
                                    <i :class="menu.icon"></i>
                                </span>
                                {{ menu.name }}
                            </router-link>
                        </template>
                        <template v-else>
                            <a v-if="menu.routerlink.name == '/download-ut-ref-guide'" :title=menu.name href="javascript:void(0)" @click="loadGuidePdf()">
                                <span class="fa-stack fa-lg pull-left">
                                    <i :class="menu.icon"></i>
                                </span>
                                {{ menu.name }}
                            </a>
                            <a v-else :title=menu.name :href="menu.routerlink.name" target="_blanke" download>
                                <span class="fa-stack fa-lg pull-left">
                                    <i :class="menu.icon"></i>
                                </span>
                                {{ menu.name }}
                            </a>
                        </template>
                    </template>
                    <template v-if="menu.SubMenu && menu.SubMenu.length > 0">
                        <label :title=menu.name class="tree-toggle header-menu nav-header" @click="toggleMenu(menu.menuClass, m_index)">
                            <span class="fa-stack fa-lg pull-left">
                                <i :class="menu.icon"></i>
                            </span>
                            {{menu.name}}
                            <i :class="'fa '+menu.menuClass" aria-hidden="true"></i>
                        </label>
                        <Transition name="slideToggle">
                            <ul v-show="activeMenuIndex.includes(m_index)" class="nav nav-list tree sub-menu-list">
                                <template v-for="(submenu, sub_index) in menu.SubMenu">
                                    <template v-if="submenu.isPage">
                                        <template v-if="currentEnvironment">
                                            <li v-if="arrayContainsArray(permissionNameArray, submenu.permissionName)" :id="submenu.id" :ref="submenu.ref" v-bind:key="'submenu-'+sub_index">
                                            <router-link :title=submenu.name v-bind:to="{name: submenu.routerlink.name}" v-bind:class="{'active': submenu.isActive}">
                                                {{ submenu.name }}
                                            </router-link>
                                            </li>
                                        </template>
                                        <template v-else-if="submenu.name != 'Mail'">
                                            <li v-if="arrayContainsArray(permissionNameArray, submenu.permissionName)" :id="submenu.id" :ref="submenu.ref" v-bind:key="'submenu-'+sub_index">
                                            <router-link title:submenu.name v-bind:to="{name: submenu.routerlink.name}" v-bind:class="{'active': submenu.isActive}">
                                                {{ submenu.name }}
                                            </router-link>
                                            </li>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <li v-if="arrayContainsArray(permissionNameArray, submenu.permissionName)" :id="submenu.id" :ref="submenu.ref" v-bind:key="'submenu-'+sub_index">
                                            <a title:submenu.name :href="submenu.routerlink.name" target="_blanke" v-bind:class="{'active': submenu.isActive}" download>
                                            {{ submenu.name }}
                                            </a>
                                        </li>
                                    </template>
                                </template>
                            </ul>
                        </Transition>
                    </template>
            </li>
        </template>
    </ul>
</div>
<!-- Sidebar -->
</template>

<script>
    import Vue from 'vue';
    import EventBus from '.././event-bus';
    import {commonMixins} from '../../mixins/commonMixins';
    import { HTTP } from "../../http-common";
    var self;

    export default {
        name: 'Sidebar',
        mixins: [commonMixins],
        components: {
        },
        data() {
            return {
                permissionNameArray: [],
                menuArray: [
                    /* {
                        name: "Dashboard",
                        isActive: true,
                        isPage: true,
                        // ref: "dashboard",
                        // id: "dashboard",
                        routerlink: {
                            name: "ListOnOrderAsset"
                        },
                        icon: "fa fa-tachometer",
                        permissionName: [
                            "can_list_inventory_master"
                        ],
                        SubMenu: [
                            {
                                name: "Deal Search",
                                isActive: true,
                                isPage: true,
                                ref: "dtna-ordered-and-cancelled",
                                id: "dtna-ordered-and-cancelled",
                                routerlink: {
                                    name: "ListOnOrderAsset"
                                },
                                icon: "icon-vtccompany",
                                permissionName: ["can_list_inventory_master"]
                            },
                            {
                                name: "DTNA Invoiced Units",
                                isActive: true,
                                isPage: true,
                                ref: "dtna-ordered-and-cancelled",
                                id: "dtna-ordered-and-cancelled",
                                routerlink: {
                                    name: "ListOnOrderAsset"
                                },
                                icon: "icon-vtccompany",
                                permissionName: ["can_list_inventory_master"]
                            },
                            {
                                name: "DTNA Ordered and Cancelled",
                                isActive: true,
                                isPage: true,
                                ref: "dtna-ordered-and-cancelled",
                                id: "dtna-ordered-and-cancelled",
                                routerlink: {
                                    name: "ListOnOrderAsset"
                                },
                                icon: "icon-vtccompany",
                                permissionName: ["can_list_inventory_master"]
                            },
                        ],
                    }, */
                    {
                        name: "Calendar",
                        isActive: true,
                        isPage: true,
                        ref: "calendar",
                        id: "calendar-list",
                        routerlink: {
                            name: "Calendar"
                        },
                        icon: "fa fa-calendar fa-stack-1x",
                        permissionName: ["can_list_calendar_master"]
                    },
                    {
                        name: "Email",
                        isActive: true,
                        isPage: true,
                        ref: "email",
                        id: "email-list",
                        routerlink: {
                            name: "ListMail"
                        },
                        icon: "fa fa-envelope-o fa-stack-1x",
                        permissionName: ["can_list_email_master"]
                    },
                    {
                        name: "Inventory",
                        isActive: true,
                        isPage: true,
                        ref: "asset-list",
                        id: "asset-list",
                        routerlink: {
                            name: "ListAsset"
                        },
                        icon: "icon-vtcasset",
                        permissionName: ["can_list_inventory_master"]
                    },
                    {
                        name: "Batch",
                        isActive: true,
                        isPage: true,
                        ref: "batch-list",
                        id: "batch-list",
                        routerlink: {
                            name: "ListBatch"
                        },
                        icon: "fa fa-tasks",
                        permissionName: ["can_list_batch_master"]
                    },
                    {
                        name: "F&I",
                        isActive: true,
                        isPage: true,
                        ref: "fandi-list",
                        id: "fandi-list",
                        routerlink: {
                            name: "Fandi"
                        },
                        icon: "icon-vtcfinance",
                        permissionName: ["can_list_f&i_master"]
                    },
                    {
                        name: "Expense",
                        isActive: true,
                        isPage: true,
                        ref: "assetexpenses-list",
                        id: "assetexpenses-list",
                        routerlink: {
                            name: "ListAssetExpenses"
                        },
                        icon: "icon-vtcasset-expense",
                        permissionName: ["can_list_expense_master"]
                    },
                    {
                        name: "Invoice",
                        isActive: true,
                        isPage: true,
                        ref: "invoice-list",
                        id: "invoice-list",
                        routerlink: {
                            name: "ListInvoice"
                        },
                        icon: "icon-vtcinvoice",
                        permissionName: ["can_list_invoice_master"]
                    },
                    {
                        name: "User Management",
                        icon: "icon-vtcuser",
                        menuClass: "fa-angle-down",
                        permissionName: [
                            "can_list_company_master"
                            , "can_list_department_master"
                            , "can_list_user"
                            , "can_list_role"
                            , "can_list_teams_master"
                        ],
                        SubMenu: [
                            {
                                name: "Companies",
                                isActive: true,
                                isPage: true,
                                ref: "company-list",
                                id: "company-list",
                                routerlink: {
                                    name: "ListCompany"
                                },
                                icon: "icon-vtccompany",
                                permissionName: ["can_list_company_master"]
                            },
                            {
                                name: "Departments",
                                isActive: true,
                                isPage: true,
                                ref: "department-list",
                                id: "department-list",
                                routerlink: {
                                    name: "ListDepartment"
                                },
                                icon: "icon-vtcdepartment",
                                permissionName: ["can_list_department_master"]
                            },
                            {
                                name: "Roles",
                                isActive: true,
                                isPage: true,
                                ref: "role-list",
                                id: "role-list",
                                routerlink: {
                                    name: "ListRole"
                                },
                                icon: "fa fa-tasks",
                                permissionName: ["can_list_role"]
                            },
                            {
                                name: "Users",
                                isActive: true,
                                isPage: true,
                                ref: "user-list",
                                id: "user-list",
                                routerlink: {
                                    name: "ListUser"
                                },
                                icon: "icon-vtcuser",
                                permissionName: ["can_list_user"]
                            },
                            {
                                name: "Notification Manager",
                                isActive: true,
                                isPage: true,
                                ref: "notification-manager",
                                id: "notification-manager",
                                routerlink: {
                                    name: "NotificationManager"
                                },
                                icon: "icon-vtcuser",
                                permissionName: ["can_notification_manager_user"]
                            },
                            {
                                name: "Teams",
                                isActive: true,
                                isPage: true,
                                ref: "teams-list",
                                id: "teams-list",
                                routerlink: {
                                    name: "ListTeams"
                                },
                                icon: "icon-vtcuser",
                                permissionName: ["can_list_teams_master"]
                            },
                            {    
                                name: "Tags",
                                isActive: true,
                                isPage: true,
                                ref: "tag-list",
                                id: "tag-list",
                                routerlink: {
                                    name: "ListTags"
                                },
                                icon: "icon-vtcuser",
                                permissionName: ["can_list_tag_master"]
                            }
                        ]
                    },
                    {
                        name: "CRM",
                        icon: "icon-vtccontact",
                        menuClass: "fa-angle-down",
                        permissionName: [
                            "can_list_customer_company_master"
                            , "can_list_customer_lead_master"
                            , "can_list_customer_master"
                            , "can_list_opportunity_master"
                            , "can_list_activity_master"
                            , "can_email_customer_master"
                            , "can_list_vendor_master"
                            , "can_list_vendor_company_master"
                            , "can_list_deals"
                            , "can_list_finance_company_master"
                            , "can_list_calls_and_emails"
                            , "can_list_case_master"
                            , "can_list_task_master"
                            , "can_list_finance_contract_master"
                            , "can_list_finance_service_master"
                        ],
                        SubMenu: [
                            { 
                                name: "Customers",
                                isActive: true,
                                isPage: true,
                                ref: "contact-list",
                                id: "contact-list",
                                routerlink: {
                                    name: "ListContact"
                                },
                                icon: "icon-vtccontact",
                                permissionName: ["can_list_customer_master"]
                            },
                            {
                                name: "Customer Notification",
                                isActive: true,
                                isPage: true,
                                ref: "contactemail-list",
                                id: "contactemail-list",
                                routerlink: {
                                    name: "ListContactEmail"
                                },
                                icon: "icon-vtccontact",
                                permissionName: ["can_email_customer_master"]
                            },
                            {
                                name: "Opportunity",
                                isActive: true,
                                isPage: true,
                                ref: "opportunity-list",
                                id: "opportunity-list",
                                routerlink: {
                                    name: "ListOpportunity"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_opportunity_master"]
                            },
                            {
                                name: "Deals",
                                isActive: true,
                                isPage: true,
                                ref: "deals-list",
                                id: "deals-list",
                                routerlink: {
                                    name: process.env.VUE_APP_PRODUCTION_COUNTRY=="US" ? "ListDealsWithVehicles" : "ListDeals"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_deals"]
                            },
                            {
                                name: "Deals Review",
                                isActive: true,
                                isPage: true,
                                ref: "deals-list",
                                id: "deals-list",
                                routerlink: {
                                    name: "DealsReview"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_deals_review"]
                            },
                            {
                                name: "Activity",
                                isActive: true,
                                isPage: true,
                                ref: "activity-list",
                                id: "activity-list",
                                routerlink: {
                                    name: "ListActivity"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_activity_master"]
                            },
                            {
                                name: "Task",
                                isActive: true,
                                isPage: true,
                                ref: "task-list",
                                id: "task-list",
                                routerlink: {
                                    name: "ListTaskMaster"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_task_master"]
                            },
                            {
                                name: "Case",
                                isActive: true,
                                isPage: true,
                                ref: "case-list",
                                id: "case-list",
                                routerlink: {
                                    name: "ListCaseMaster"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_case_master"]
                            },
                            {
                                name: "Calls/Email",
                                isActive: true,
                                isPage: true,
                                ref: "calls-email-list",
                                id: "calls-email-lis",
                                routerlink: {
                                    name: "ListCallsAndEmails"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_calls_and_emails"]
                            },
                            {
                                name: "Vendor",
                                isActive: true,
                                isPage: true,
                                ref: "vendor-list",
                                id: "vendor-list",
                                routerlink: {
                                    name: "ListVendor"
                                },
                                icon: "icon-vtccontact",
                                permissionName: ["can_list_vendor_master"]
                            },
                            {
                                name: "Vendor Company",
                                isActive: true,
                                isPage: true,
                                ref: "vendorgroup-list",
                                id: "vendorgroup-list",
                                routerlink: {
                                    name: "ListVendorGroup"
                                },
                                icon: "icon-vtccontact-group",
                                permissionName: ["can_list_vendor_company_master"]
                            },
                            {
                                name: "Finance Company",
                                isActive: true,
                                isPage: true,
                                ref: "financecompany-list",
                                id: "financecompany-list",
                                routerlink: {
                                    name: "ListFinanceGroup"
                                },
                                icon: "icon-vtcfinance-group",
                                permissionName: ["can_list_finance_company_master"]
                            },
                            {
                                name: "Finance Contract",
                                isActive: true,
                                isPage: true,
                                ref: "financecontract-list",
                                id: "financecontract-list",
                                routerlink: {
                                    name: "ListFinanceContract"
                                },
                                icon: "icon-vtcfinance-group",
                                permissionName: ["can_list_finance_contract_master"]
                            },
                            {
                                name: "Finance Service",
                                isActive: true,
                                isPage: true,
                                ref: "financeservice-list",
                                id: "financeservice-list",
                                routerlink: {
                                    name: "ListFinanceService"
                                },
                                icon: "icon-vtcfinance-group",
                                permissionName: ["can_list_finance_service_master"]
                            }
                        ]
                    },
                    {
                        name: "Forms",
                        icon: "fa fa-wpforms fa-stack-1x",
                        menuClass: "fa-angle-down",
                        permissionName: [
                            "can_list_form_email_manager"
                            , "can_list_po_request"
                            , "can_add_po_request"
                            , "can_list_vehicle_check_in"
                            , "can_add_vehicle_check_in"
                            , "can_list_used_truck_holds_submission_form"
                            , "can_list_used_truck_moved_submission_form"
                            , "can_add_used_truck_holds_submission_form"
                            , "can_add_used_truck_moved_submission_form"
                            , "can_list_truck_trailer_search_made_easy"
                            , "can_add_truck_trailer_search_made_easy"
                            , "can_list_offer_submission_form"
                            , "can_add_offer_submission_form"
                            , "can_list_quotation_master"
                            , "can_add_quotation_master"
                            , "can_list_buy_truck_submission_form"
                            , "can_add_buy_truck_submission_form"
                            , "can_list_cso_request"
                        ],
                        SubMenu: [
                            {
                                name: "Offer Submission Form",
                                isActive: true,
                                isPage: true,
                                ref: "offer_submission_form",
                                id: "offer_submission_form",
                                routerlink: {
                                    name: "ListOfferSubmission"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_offer_submission_form", "can_add_offer_submission_form"]
                            },
                            {
                                name: "PO Request",
                                isActive: true,
                                isPage: true,
                                ref: "po-request",
                                id: "po-request",
                                routerlink: {
                                    name: "ListPORequest"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_po_request", "can_add_po_request"]
                            },
                            {
                                name: "Truck & Trailer Search Made Easy",
                                isActive: true,
                                isPage: true,
                                ref: "truck_trailer_search_made_easy",
                                id: "truck_trailer_search_made_easy",
                                routerlink: {
                                    name: "ListTruckTrailerSearchMadeEasy"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_truck_trailer_search_made_easy", "can_add_truck_trailer_search_made_easy"]
                            },
                            {
                                name: "Used Truck Holds Submission Form",
                                isActive: true,
                                isPage: true,
                                ref: "used_truck_holds_submission_form",
                                id: "used_truck_holds_submission_form",
                                routerlink: {
                                    name: "ListUsedTruckHoldsSubmissionForm"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_used_truck_holds_submission_form", "can_add_used_truck_holds_submission_form"]
                            },
                            {
                                name: "Used Truck Move Submission Form",
                                isActive: true,
                                isPage: true,
                                ref: "used_truck_move_submission_form",
                                id: "used_truck_move_submission_form",
                                routerlink: {
                                    name: "ListUsedTruckMovedSubmission"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_used_truck_moved_submission_form", "can_add_used_truck_moved_submission_form"]
                            },
                            {
                                name: "Vehicle Check In",
                                isActive: true,
                                isPage: true,
                                ref: "vehicle_check_in",
                                id: "vehicle_check_in",
                                routerlink: {
                                    name: "ListVehicleCheckIn"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_vehicle_check_in", "can_add_vehicle_check_in"]
                            },
                            {
                                name: "Quotation",
                                isActive: true,
                                isPage: true,
                                ref: "quotation-list",
                                id: "quotation-list",
                                routerlink: {
                                    name: "ListQuotation"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_quotation_master", "can_add_quotation_master"]
                            },
                            {
                                name: "Buys - Trucks Submission Form",
                                isActive: true,
                                isPage: true,
                                ref: "buy_truck_submission_form",
                                id: "buy_truck_submission_form",
                                routerlink: {
                                    name: "ListBuyTruckSubmissionForm"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_buy_truck_submission_form", "can_add_buy_truck_submission_form"]
                            },
                            {
                                name: "Form Email Manager",
                                isActive: true,
                                isPage: true,
                                ref: "form-email-manager",
                                id: "form-email-manager",
                                routerlink: {
                                    name: "ListFormEmailManager"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_form_email_manager"]
                            },
                            {
                                name: "CSO Request",
                                isActive: true,
                                isPage: true,
                                ref: "cso-request",
                                id: "cso-request",
                                routerlink: {
                                    name: "ListCSORequest"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_cso_request"]
                            }
                        ]
                    },
                    {
                      name: "Reports",
                      icon: "icon-vtccontact",
                      menuClass: "fa-angle-down",
                      permissionName: [
                        "can_list_information_report",
                        "can_list_sold_unit_by_branch_report",
                        "can_list_flooring_report",
                        "can_list_branch_location_report",
                        "can_list_finance_reserve_report",
                        "can_list_location_inventory_report",
                        "can_list_all_inventory_for_accounting_report",
                        "can_list_vehicle_warranty_report",
                        "can_list_gp_report",
                        "can_list_brand_value_report",
                      ],
                      SubMenu: [
                        {
                          name: "Information Report",
                          isActive: true,
                          isPage: true,
                          ref: "info-report-list",
                          id: "info-report-list",
                          routerlink: {
                              name: "ListInformationReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_information_report"]
                        },
                        {  
                          name: "Sold Unit By Branch Report",
                          isActive: true,
                          isPage: true,
                          ref: "sold-unit-by-branch-report-list",
                          id: "sold-unit-by-branch-report-list",
                          routerlink: {
                              name: "SoldUnitByBranchReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_sold_unit_by_branch_report"]
                        },
                        {
                          name: "Flooring Report",
                          isActive: true,
                          isPage: true,
                          ref: "flooring-report-list",
                          id: "flooring-report-list",
                          routerlink: {
                              name: "FlooringReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_flooring_report"]
                        },
                        {
                          name: "Customer Info Report",
                          isActive: true,
                          isPage: true,
                          ref: "customer-info-report-list",
                          id: "customer-info-report-list",
                          routerlink: {
                              name: "CustomerInfoReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_customer_info_report"]
                        },
                        {
                          name: "Branch Location Report",
                          isActive: true,
                          isPage: true,
                          ref: "branch-location-report-list",
                          id: "branch-location-report-list",
                          routerlink: {
                              name: "BranchLocationReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_branch_location_report"]
                        },
                        {
                          name: "Finance Reserve Report",
                          isActive: true,
                          isPage: true,
                          ref: "finance-reserve-report-list",
                          id: "finance-reserve-report-list",
                          routerlink: {
                              name: "FinanceReserveReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_finance_reserve_report"]
                        },
                        {
                          name: "Location Inventory Report",
                          isActive: true,
                          isPage: true,
                          ref: "location-inventory-report-list",
                          id: "location-inventory-report-list",
                          routerlink: {
                              name: "LocationInventoryReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_location_inventory_report"]
                        },
                        {
                          name: "All Inventory For Accounting Report",
                          isActive: true,
                          isPage: true,
                          ref: "all-inventory-for-accounting-report-list",
                          id: "all-inventory-for-accounting-report-list",
                          routerlink: {
                              name: "AllInventoryForAccountingReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_all_inventory_for_accounting_report"]
                        },
                        {
                          name: "Vehicle Warranty Report",
                          isActive: true,
                          isPage: true,
                          ref: "vehicle-warranty-report",
                          id: "vehicle-warranty-report",
                          routerlink: {
                              name: "VehicleWarrantyReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_vehicle_warranty_report"]
                        },
                        {
                            name: "GP Report",
                            isActive: true,
                            isPage: true,
                            ref: "gp-report-list",
                            id: "gp-report-list",
                            routerlink: {
                                name: "GPReport"
                            },
                            icon: "icon-vtccontact",
                            permissionName: ["can_list_gp_report"]
                        },
                        {
                          name: "Brand Value Report",
                          isActive: true,
                          isPage: true,
                          ref: "brand-value-report-list",
                          id: "brand-value-report-list",
                          routerlink: {
                              name: "BrandValueReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_brand_value_report"]
                        },
                        {
                          name: "Accounting and Doc Request Deals",
                          isActive: true,
                          isPage: true,
                          ref: "deal-level-report-list",
                          id: "deal-level-report-list",
                          routerlink: {
                              name: "DealLevelReport"
                          },
                          icon: "icon-vtccontact",
                          permissionName: ["can_list_deal_level_report"]
                        },
                      ]
                    },
                    /*{
                        name: "Used Truck Forms",
                        icon: "fa fa-paper-plane",
                        permissionName: [
                             "can_add_used_truck_holds_submission_form"
                            , "can_add_vehicle_check_in"
                            , "can_add_po_request"
                            , "can_add_offer_submission_form"
                            , "can_add_truck_trailer_search_made_easy"
                        ],
                        SubMenu: [
                            {
                                name: "Offer Submission",
                                isActive: true,
                                isPage: true,
                                ref: "offer_submission_form_add",
                                id: "offer_submission_form_add",
                                routerlink: {
                                    name: "AddUpdateOfferSubmission"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_add_offer_submission_form"]
                            },
                            {
                                name: "PO Request",
                                isActive: true,
                                isPage: true,
                                ref: "po_request_submission_form_add",
                                id: "po_request_submission_form_add",
                                routerlink: {
                                    name: "AddUpdatePORequestSubmission"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_add_po_request"]
                            },
                            {
                                name: "Truck and Trailer Search Made Easy",
                                isActive: true,
                                isPage: true,
                                ref: "truck_trailer_search_made_easy_submission_form_add",
                                id: "truck_trailer_search_made_easy_submission_form_add",
                                routerlink: {
                                    name: "AddUpdateTruckAndTrailerSearchMadeEasy"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_add_truck_trailer_search_made_easy"]
                            },
                            {
                                name: "Used Truck Holds Submission Form",
                                isActive: true,
                                isPage: true,
                                ref: "used_truck_holds_submission_form_add",
                                id: "used_truck_holds_submission_form_add",
                                routerlink: {
                                    name: "AddUpdateUsedTruckHoldSubmission"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_add_used_truck_holds_submission_form"]
                            },
                            {
                                name: "Vehical Check-In",
                                isActive: true,
                                isPage: true,
                                ref: "vehical_checkin_submission_from_add",
                                id: "vehical_checkin_submission_from_add",
                                routerlink: {
                                    name: "AddUpdateVehicalCheckIn"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_add_vehicle_check_in"]
                            },
                        ]
                    },*/
                    {
                        name: "Location",
                        isActive: true,
                        isPage: true,
                        ref: "location-list",
                        id: "location-list",
                        routerlink: {
                            name: "ListLocation"
                        },
                        icon: "icon-vtclocation",
                        permissionName: ["can_list_location_master"]
                    },
                    {
                        name: "City",
                        isActive: true,
                        isPage: true,
                        ref: "city-list",
                        id: "city-list",
                        routerlink: {
                            name: "ListCity"
                        },
                        icon: "icon-vtccity1",
                        permissionName: ["can_list_cities"]
                    },
                    {
                        name: "State",
                        isActive: true,
                        isPage: true,
                        ref: "state-list",
                        id: "state-list",
                        routerlink: {
                            name: "ListState"
                        },
                        icon:  "icon-vtcstate",
                        permissionName: ["can_list_states"]
                    },
                    {
                          name: "Tax Rates",
                          isActive: true,
                          isPage: true,
                          ref: "tax-rates",
                          id: "tax-rates",
                          routerlink: {
                            name: "ViewTaxRates"
                          },
                          icon: "icon-vtcinvoice",
                          permissionName: ["can_list_tax_rates"] //todo: need to update permission
                    },
                    {
                        name: "Inquiry Notification Settings",
                        isActive: true,
                        isPage: true,
                        ref: "inquiry-notification-list",
                        id: "inquiry-notification-list",
                        routerlink: {
                            name: "ListInquiryNotificationSettings"
                        },
                        icon: "fa fa-question-circle",
                        permissionName: ["can_list_inquiry_notification_master"]
                    },
                    /* {
                        name: "Document Management",
                        icon: "fa fa-envelope",
                        permissionName: [
                            "can_list_documents",
                            "can_list_document_group_master"
                        ],
                        SubMenu: [
                            {
                                name: "Documents",
                                isActive: true,
                                isPage: true,
                                ref: "document-list",
                                id: "document-list",
                                routerlink: {
                                    name: "ListDocument"
                                },
                                icon: "icon-vtcinvoice",
                                permissionName: ["can_list_documents"]
                            },
                            {
                                name: "Document Groups",
                                isActive: true,
                                isPage: true,
                                ref: "document-group-list",
                                id: "document-group-list",
                                routerlink: {
                                    name: "ListDocumentGroup"
                                },
                                icon: "icon-vtcinvoice",
                                permissionName: ["can_list_document_group_master"]
                            }
                        ]
                    }, */
                    {
                        name: "Repair Order",
                        isActive: true,
                        isPage: true,
                        ref: "repairorder-list",
                        id: "repairorder-list",
                        routerlink: {
                            name: "ListRepairOrder"
                        },
                        icon: "fa fa-gavel",
                        permissionName: ["can_list_repair_order_master"]
                    },
                    /* {
                        name: "Notification Email Manager",
                        isActive: true,
                        isPage: true,
                        ref: "notification-email-manager-list",
                        id: "notification-email-manager-list",
                        routerlink: {
                            name: "ListNotificationEmailManager"
                        },
                        icon: "icon-cog",
                        icon: "fa fa-podcast",
                        permissionName: ["can_list_notification_email_manager"]
                    },
                    {
                        name: "Branch",
                        isActive: true,
                        isPage: true,
                        ref: "branch-list",
                        id: "branch-list",
                        routerlink: {
                            name: "ListBranch"
                        },
                        icon: "fa fa-building-o",
                        permissionName: ["can_list_branch_master"]
                    },*/
                    {
                        name: "Inventory Master",
                        icon: "fa fa-cog",
                        menuClass: "fa-angle-down",
                        permissionName: [
                            "can_list_apu_make_master"
                            , "can_list_axle_type_master"
                            , "can_list_body_style_master"
                            , "can_list_body_type_master"
                            , "can_list_brake_type_master"
                            , "can_list_breathers_master"
                            , "can_list_cab_style_master"
                            , "can_list_cab_type_master"
                            , "can_list_conclusion_master"
                            , "can_list_condition_master"
                            , "can_list_drop_axle_config_master"
                            , "can_list_dtr_trade_terms"
                            , "can_list_dump_make_master"
                            , "can_list_dump_type_master"
                            , "can_list_engine_cylinder_master"
                            , "can_list_engine_make"
                            , "can_list_engine_model"
                            , "can_list_exhaust_master"
                            , "can_list_expense_category_master"
                            , "can_list_fairing_master"
                            , "can_list_fleet"
                            , "can_list_floor_type_master"
                            , "can_list_frame_master"
                            , "can_list_fuel_type_master"
                            , "can_list_interior_master"
                            , "can_list_make_master"
                            , "can_list_model_master"
                            , "can_list_rear_door_type_master"
                            , "can_list_reefer_make_master"
                            , "can_list_roof_type_master"
                            , "can_list_sale_type_master"
                            , "can_list_side_door_type_master"
                            , "can_list_suspension_master"
                            , "can_list_tire_master"
                            , "can_list_trailer_type_master"
                            , "can_list_trans_make_master"
                            , "can_list_trans_model_master"
                            , "can_list_trans_speed_master"
                            , "can_list_trans_type_master"
                            , "can_list_warranty_type_master"
                            , 'can_list_commission_type_master'
                            , "can_list_funding_method_master"
                            , "can_list_funding_source_master"
                            , "can_list_lead_quality_master"
                            , "can_list_referral_source_master"
                            , "can_list_funding_status_master"
                            , "can_list_hold_status_master"
                            , "can_list_inventory_setting_master"
                            , "can_list_apu_master"
                            , "can_list_body_color_master"
                            , "can_list_body_notes_master"
                            , "can_list_liftgate_master"
                            , "can_list_price_book_master"
                            , "can_list_pto_master"
                            , "can_list_body_length_master"
                            , "can_list_liftgate_capacity_master"
                            , "can_list_body_make_master"
                            , "can_list_apu_model_master"
                            , "can_list_body_model_master"
                            , "can_list_liftgate_model_master"
                            , "can_list_deal_status_master"
                            , "can_list_brake_make_master"
                            , "can_list_front_axle_make_master"
                            , "can_list_front_tire_make_master"
                            , "can_list_front_tire_model_master"
                            , "can_list_front_wheels_model_master"
                            , "can_list_rear_wheels_model_master"
                            , "can_list_rear_tire_make_master"
                            , "can_list_rear_tire_model_master"
                            , "can_list_sleeper_type_master"
                            , "can_list_suspension_model_master"
                            , "can_list_suspension_type_master"
                            , "can_list_front_wheels_master"
                            , "can_list_rear_wheels_master"
                            , "can_list_inventory_type_master"
                            , "can_list_specification_master"
                            , "can_list_close_type_master"
                            , "can_list_location_expense_type_master"
                            , "can_list_website_master",
                            , "can_list_gvw_fee_master"
                        ],
                        SubMenu: [
                            {
                                name: "Apu",
                                isActive: true,
                                isPage: true,
                                ref: "apu-list",
                                id: "apu-list",
                                routerlink: {
                                    name: "ListApu"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_apu_master"]
                            },
                            {
                                name: "Apu Make",
                                isActive: true,
                                isPage: true,
                                ref: "apumake-list",
                                id: "apumake-list",
                                routerlink: {
                                    name: "ListApuMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_apu_make_master"]
                            },
                            {
                                name: "Apu Model",
                                isActive: true,
                                isPage: true,
                                ref: "apumodel-list",
                                id: "apumodel-list",
                                routerlink: {
                                    name: "ListApuModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_apu_model_master"]
                            },
                            {
                                name: "Axle Type",
                                isActive: true,
                                isPage: true,
                                ref: "axletype-list",
                                id: "axletype-list",
                                routerlink: {
                                    name: "ListAxleType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_axle_type_master"]
                            },
                            {
                                name: "Body Color",
                                isActive: true,
                                isPage: true,
                                ref: "bodycolor-list",
                                id: "bodycolor-list",
                                routerlink: {
                                    name: "ListBodyColor"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_color_master"]
                            },
                            {
                                name: "Body Length",
                                isActive: true,
                                isPage: true,
                                ref: "bodylength-list",
                                id: "bodylength-list",
                                routerlink: {
                                    name: "ListBodyLength"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_length_master"]
                            },
                            {
                                name: "Body Make",
                                isActive: true,
                                isPage: true,
                                ref: "bodymake-list",
                                id: "bodymake-list",
                                routerlink: {
                                    name: "ListBodyMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_make_master"]
                            },
                            {
                                name: "Body Model",
                                isActive: true,
                                isPage: true,
                                ref: "bodymodel-list",
                                id: "bodymodel-list",
                                routerlink: {
                                    name: "ListBodyModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_model_master"]
                            },
                            {
                                name: "Body Note",
                                isActive: true,
                                isPage: true,
                                ref: "bodynotes-list",
                                id: "bodynotes-list",
                                routerlink: {
                                    name: "ListBodyNotes"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_notes_master"]
                            },
                            {
                                name: "Body Style",
                                isActive: true,
                                isPage: true,
                                ref: "bodystyle-list",
                                id: "bodystyle-list",
                                routerlink: {
                                    name: "ListBodyStyle"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_style_master"]
                            },
                            {
                                name: "Body Type",
                                isActive: true,
                                isPage: true,
                                ref: "bodytype-list",
                                id: "bodytype-list",
                                routerlink: {
                                    name: "ListBodyType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_body_type_master"]
                            },
                            {
                                name: "Brake Make",
                                isActive: true,
                                isPage: true,
                                ref: "brakemake-list",
                                id: "brakemake-list",
                                routerlink: {
                                    name: "ListBrakeMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_brake_make_master"]
                            },
                            {
                                name: "Brake Type",
                                isActive: true,
                                isPage: true,
                                ref: "braketype-list",
                                id: "braketype-list",
                                routerlink: {
                                    name: "ListBrakeType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_brake_type_master"]
                            },
                            {
                                name: "Breathers",
                                isActive: true,
                                isPage: true,
                                ref: "breathers-list",
                                id: "breathers-list",
                                routerlink: {
                                    name: "ListBreathers"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_breathers_master"]
                            },
                            {
                                name: "Cab Style",
                                isActive: true,
                                isPage: true,
                                ref: "cabstyle-list",
                                id: "cabstyle-list",
                                routerlink: {
                                    name: "ListCabStyle"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_cab_style_master"]
                            },
                            {
                                name: "Cab Type",
                                isActive: true,
                                isPage: true,
                                ref: "cabtype-list",
                                id: "cabtype-list",
                                routerlink: {
                                    name: "ListCabType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_cab_type_master"]
                            },
                            /*{
                                name: "Close Type",
                                isActive: true,
                                isPage: true,
                                ref: "close-type-list",
                                id: "close-type-list",
                                routerlink: {
                                    name: "ListCloseType"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_close_type_master"]
                            },*/
                            {
                                name: "Commission Type",
                                isActive: true,
                                isPage: true,
                                ref: "commission-type-list",
                                id: "commission-type-list",
                                routerlink: {
                                    name: "ListCommissionType"
                                },
                                icon: "icon-vtcopportunity",
                                permissionName: ["can_list_commission_type_master"]
                            },
                            {
                                name: "Conclusion",
                                isActive: true,
                                isPage: true,
                                ref: "conclusion-list",
                                id: "conclusion-list",
                                routerlink: {
                                    name: "ListConclusion"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_conclusion_master"]
                            },
                            {
                                name: "Condition",
                                isActive: true,
                                isPage: true,
                                ref: "condition-list",
                                id: "condition-list",
                                routerlink: {
                                    name: "ListCondition"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_condition_master"]
                            },
                            {
                                name: "Deal Status",
                                isActive: true,
                                isPage: true,
                                ref: "deal-status-list",
                                id: "deal-status-list",
                                routerlink: {
                                    name: "ListDealStatus"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_deal_status_master"]
                            },
                            {
                                name: "Drop Axle Config",
                                isActive: true,
                                isPage: true,
                                ref: "dropaxleconfig-list",
                                id: "dropaxleconfig-list",
                                routerlink: {
                                    name: "ListDropAxleConfig"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_drop_axle_config_master"]
                            },
                            {
                                name: "DTR Trade Terms",
                                isActive: true,
                                isPage: true,
                                ref: "dtrtradeterms-list",
                                id: "dtrtradeterms-list",
                                routerlink: {
                                    name: "ListDtrTradeTerms"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_dtr_trade_terms"]
                            },
                            {
                                name: "Dump Make",
                                isActive: true,
                                isPage: true,
                                ref: "dumpmake-list",
                                id: "dumpmake-list",
                                routerlink: {
                                    name: "ListDumpMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_dump_make_master"]
                            },
                            {
                                name: "Dump Type",
                                isActive: true,
                                isPage: true,
                                ref: "dumptype-list",
                                id: "dumptype-list",
                                routerlink: {
                                    name: "ListDumpType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_dump_type_master"]
                            },
                            {
                                name: "Engine Cylinder",
                                isActive: true,
                                isPage: true,
                                ref: "enginecylinder-list",
                                id: "enginecylinder-list",
                                routerlink: {
                                    name: "ListEngineCylinder"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_engine_cylinder_master"]
                            },
                            {
                                name: "Engine Make",
                                isActive: true,
                                isPage: true,
                                ref: "enginemake-list",
                                id: "enginemake-list",
                                routerlink: {
                                    name: "ListEngineMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_engine_make"]
                            },
                            {
                                name: "Engine Model",
                                isActive: true,
                                isPage: true,
                                ref: "enginemodel-list",
                                id: "enginemodel-list",
                                routerlink: {
                                    name: "ListEngineModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_engine_model"]
                            },
                            {
                                name: "Exhaust",
                                isActive: true,
                                isPage: true,
                                ref: "exhaust-list",
                                id: "exhaust-list",
                                routerlink: {
                                    name: "ListExhaust"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_exhaust_master"]
                            },
                            {
                                name: "Expense Category",
                                isActive: true,
                                isPage: true,
                                ref: "expense-category-list",
                                id: "expense-category-list",
                                routerlink: {
                                    name: "ListExpenseCategory"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_expense_category_master"]
                            },
                            {
                                name: "Fairings",
                                isActive: true,
                                isPage: true,
                                ref: "fairings-list",
                                id: "fairings-list",
                                routerlink: {
                                    name: "ListFairings"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_fairing_master"]
                            },
                            {
                                name: "Fleet",
                                isActive: true,
                                isPage: true,
                                ref: "fleet-list",
                                id: "fleet-list",
                                routerlink: {
                                    name: "ListFleet"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_fleet"]
                            },
                            {
                                name: "Floor Type",
                                isActive: true,
                                isPage: true,
                                ref: "floortype-list",
                                id: "floortype-list",
                                routerlink: {
                                    name: "ListFloorType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_floor_type_master"]
                            },
                            {
                                name: "Frame",
                                isActive: true,
                                isPage: true,
                                ref: "frame-list",
                                id: "frame-list",
                                routerlink: {
                                    name: "ListFrame"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_frame_master"]
                            },
                            {
                                name: "Front Axle Make",
                                isActive: true,
                                isPage: true,
                                ref: "frontaxlemake-list",
                                id: "frontaxlemake-list",
                                routerlink: {
                                    name: "ListFrontAxleMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_front_axle_make_master"]
                            },
                            {
                                name: "Front Tire Make",
                                isActive: true,
                                isPage: true,
                                ref: "fronttiremake-list",
                                id: "fronttiremake-list",
                                routerlink: {
                                    name: "ListFrontTireMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_front_tire_make_master"]
                            },
                            {
                                name: "Front Tire Model",
                                isActive: true,
                                isPage: true,
                                ref: "fronttiremodel-list",
                                id: "fronttiremodel-list",
                                routerlink: {
                                    name: "ListFrontTireModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_front_tire_model_master"]
                            },
                            {
                                name: "Front Wheels",
                                isActive: true,
                                isPage: true,
                                ref: "frontwheels-list",
                                id: "frontwheels-list",
                                routerlink: {
                                    name: "ListFrontWheels"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_front_wheels_master"]
                            },
                            {
                                name: "Front Wheels Model",
                                isActive: true,
                                isPage: true,
                                ref: "frontwheelsmodel-list",
                                id: "frontwheelsmodel-list",
                                routerlink: {
                                    name: "ListFrontWheelsModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_front_wheels_model_master"]
                            },
                            {
                                name: "Fuel Type",
                                isActive: true,
                                isPage: true,
                                ref: "fueltype-list",
                                id: "fueltype-list",
                                routerlink: {
                                    name: "ListFuelType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_fuel_type_master"]
                            },
                            {
                                name: "Funding Method",
                                isActive: true,
                                isPage: true,
                                ref: "fundingmethod-list",
                                id: "fundingmethod-list",
                                routerlink: {
                                    name: "ListFundingMethod"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_funding_method_master"]
                            },
                            {
                                name: "Funding Source",
                                isActive: true,
                                isPage: true,
                                ref: "fundingsource-list",
                                id: "fundingsource-list",
                                routerlink: {
                                    name: "ListFundingSource"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_funding_source_master"]
                            },
                            {
                                name: "Funding Status",
                                isActive: true,
                                isPage: true,
                                ref: "fundingstatus-list",
                                id: "fundingstatus-list",
                                routerlink: {
                                    name: "ListFundingStatus"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_funding_status_master"]
                            },
                            {
                                name: "GVW Fee",
                                isActive: true,
                                isPage: true,
                                ref: "gvwfee-list",
                                id: "gvwfee-list",
                                routerlink: {
                                    name: "ListGvwFee"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_gvw_fee_master"]
                            },
                            {
                                name: "Hold Status",
                                isActive: true,
                                isPage: true,
                                ref: "hold-status-list",
                                id: "hold-status-list",
                                routerlink: {
                                    name: "ListHoldStatus"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_hold_status_master"]
                            },
                            {
                                name: "Interior",
                                isActive: true,
                                isPage: true,
                                ref: "interior-list",
                                id: "interior-list",
                                routerlink: {
                                    name: "ListInterior"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_interior_master"]
                            },
                            {
                                name: "Inventory Setting",
                                isActive: true,
                                isPage: true,
                                ref: "inventory-setting-list",
                                id: "inventory-setting-list",
                                routerlink: {
                                    name: "ListInventorySetting"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_inventory_setting_master"]
                            },
                            {
                                name: "Inventory Type",
                                isActive: true,
                                isPage: true,
                                ref: "inventory-type-list",
                                id: "inventory-type-list",
                                routerlink: {
                                    name: "ListInventoryType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_inventory_type_master"]
                            },
                            {
                                name: "Lead Quality",
                                isActive: true,
                                isPage: true,
                                ref: "leadquality-list",
                                id: "leadquality-list",
                                routerlink: {
                                    name: "ListLeadQuality"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_lead_quality_master"]
                            },
                            {
                                name: "Liftgate",
                                isActive: true,
                                isPage: true,
                                ref: "liftgate-list",
                                id: "liftgate-list",
                                routerlink: {
                                    name: "ListLiftgate"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_liftgate_master"]
                            },
                            {
                                name: "Liftgate Capacity",
                                isActive: true,
                                isPage: true,
                                ref: "liftgatecapacity-list",
                                id: "liftgatecapacity-list",
                                routerlink: {
                                    name: "ListLiftgateCapacity"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_liftgate_capacity_master"]
                            },
                            {
                                name: "Liftgate Model",
                                isActive: true,
                                isPage: true,
                                ref: "liftgatemodel-list",
                                id: "liftgatemodel-list",
                                routerlink: {
                                    name: "ListLiftgateModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_liftgate_model_master"]
                            },
                            {
                                name: "Location Expense Type",
                                isActive: true,
                                isPage: true,
                                ref: "locationexpensetype-list",
                                id: "locationexpensetype-list",
                                routerlink: {
                                    name: "ListLocationExpenseType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_location_expense_type_master"]
                            },
                            {
                                name: "Mail",
                                isActive: true,
                                isPage: true,
                                ref: "mail-list",
                                id: "mail-list",
                                routerlink: {
                                    name: "ListMail"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_axle_type_master"]
                            },
                            {
                                name: "Make",
                                isActive: true,
                                isPage: true,
                                ref: "make-list",
                                id: "make-list",
                                routerlink: {
                                    name: "ListMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_make_master"]
                            },
                            {
                                name: "Model",
                                isActive: true,
                                isPage: true,
                                ref: "model-list",
                                id: "model-list",
                                routerlink: {
                                    name: "ListModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_model_master"]
                            },
                            {
                                name: "Price Book",
                                isActive: true,
                                isPage: true,
                                ref: "price-book-list",
                                id: "price-book-list",
                                routerlink: {
                                    name: "ListPriceBook"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_price_book_master"]
                            },
                            {
                                name: "PTO",
                                isActive: true,
                                isPage: true,
                                ref: "pto-list",
                                id: "pto-list",
                                routerlink: {
                                    name: "ListPTO"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_pto_master"]
                            },
                            {
                                name: "Rear Door Type",
                                isActive: true,
                                isPage: true,
                                ref: "reardoortype-list",
                                id: "reardoortype-list",
                                routerlink: {
                                    name: "ListRearDoorType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_rear_door_type_master"]
                            },
                            {
                                name: "Rear Tire Make",
                                isActive: true,
                                isPage: true,
                                ref: "reartiremake-list",
                                id: "reartiremake-list",
                                routerlink: {
                                    name: "ListRearTireMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_rear_tire_make_master"]
                            },
                            {
                                name: "Rear Tire Model",
                                isActive: true,
                                isPage: true,
                                ref: "reartiremodel-list",
                                id: "reartiremodel-list",
                                routerlink: {
                                    name: "ListRearTireModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_rear_tire_model_master"]
                            },
                            {
                                name: "Rear Wheels",
                                isActive: true,
                                isPage: true,
                                ref: "rearwheels-list",
                                id: "rearwheels-list",
                                routerlink: {
                                    name: "ListRearWheels"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_rear_wheels_master"]
                            },
                            {
                                name: "Rear Wheels Model",
                                isActive: true,
                                isPage: true,
                                ref: "rearwheelsmodel-list",
                                id: "rearwheelsmodel-list",
                                routerlink: {
                                    name: "ListRearWheelsModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_rear_wheels_model_master"]
                            },
                            {
                                name: "Reefer Make",
                                isActive: true,
                                isPage: true,
                                ref: "reefermake-list",
                                id: "reefermake-list",
                                routerlink: {
                                    name: "ListReeferMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_reefer_make_master"]
                            },
                            {
                                name: "Referral Source",
                                isActive: true,
                                isPage: true,
                                ref: "referralsource-list",
                                id: "referralsource-list",
                                routerlink: {
                                    name: "ListReferralSource"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_referral_source_master"]
                            },
                            {
                                name: "Roof Type",
                                isActive: true,
                                isPage: true,
                                ref: "rooftype-list",
                                id: "rooftype-list",
                                routerlink: {
                                    name: "ListRoofType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_roof_type_master"]
                            },
                            {
                                name: "Sale Type",
                                isActive: true,
                                isPage: true,
                                ref: "saletype-list",
                                id: "saletype-list",
                                routerlink: {
                                    name: "ListSaleType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_sale_type_master"]
                            },
                            {
                                name: "Side Door Type",
                                isActive: true,
                                isPage: true,
                                ref: "sidedoortype-list",
                                id: "sidedoortype-list",
                                routerlink: {
                                    name: "ListSideDoorType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_side_door_type_master"]
                            },
                            {
                                name: "Sleeper Type",
                                isActive: true,
                                isPage: true,
                                ref: "sleepertype-list",
                                id: "sleepertype-list",
                                routerlink: {
                                    name: "ListSleeperType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_sleeper_type_master"]
                            },
                            /* {
                             name: "Status",
                             isActive: true,
                             isPage: true,
                             ref: "status-list",
                             id: "status-list",
                             routerlink: {
                             name: "ListStatus"
                             },
                             icon: "fa fa-users fa-stack-1x"
                             }, */
                            {
                                name: "Specification",
                                isActive: true,
                                isPage: true,
                                ref: "specification-list",
                                id: "specification-list",
                                routerlink: {
                                    name: "ListSpecification"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_specification_master"]
                            },
                            {
                                name: "Suspension",
                                isActive: true,
                                isPage: true,
                                ref: "suspension-list",
                                id: "suspension-list",
                                routerlink: {
                                    name: "ListSuspension"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_suspension_master"]
                            },
                            {
                                name: "Suspension Model",
                                isActive: true,
                                isPage: true,
                                ref: "suspensionmodel-list",
                                id: "suspensionmodel-list",
                                routerlink: {
                                    name: "ListSuspensionModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_suspension_model_master"]
                            },
                            {
                                name: "Suspension Type",
                                isActive: true,
                                isPage: true,
                                ref: "suspensiontype-list",
                                id: "suspensiontype-list",
                                routerlink: {
                                    name: "ListSuspensionType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_suspension_type_master"]
                            },
                            {
                                name: "Tire",
                                isActive: true,
                                isPage: true,
                                ref: "tire-list",
                                id: "tire-list",
                                routerlink: {
                                    name: "ListTire"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_tire_master"]
                            },
                            {
                                name: "Trailer Type",
                                isActive: true,
                                isPage: true,
                                ref: "trailertype-list",
                                id: "trailertype-list",
                                routerlink: {
                                    name: "ListTrailerType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_trailer_type_master"]
                            },
                            {
                                name: "Transmission Make",
                                isActive: true,
                                isPage: true,
                                ref: "transmake-list",
                                id: "transmake-list",
                                routerlink: {
                                    name: "ListTransMake"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_trans_make_master"]
                            },
                            {
                                name: "Transmission Model",
                                isActive: true,
                                isPage: true,
                                ref: "transmodel-list",
                                id: "transmodel-list",
                                routerlink: {
                                    name: "ListTransModel"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_trans_model_master"]
                            },
                            {
                                name: "Transmission Speed",
                                isActive: true,
                                isPage: true,
                                ref: "transspeed-list",
                                id: "transspeed-list",
                                routerlink: {
                                    name: "ListTransSpeed"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_trans_speed_master"]
                            },
                            {
                                name: "Transmission Type",
                                isActive: true,
                                isPage: true,
                                ref: "transtype-list",
                                id: "transtype-list",
                                routerlink: {
                                    name: "ListTransType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_trans_type_master"]
                            },
                            {
                                name: "Warranty Type",
                                isActive: true,
                                isPage: true,
                                ref: "warrantytype-list",
                                id: "warrantytype-list",
                                routerlink: {
                                    name: "ListWarrantyType"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_warranty_type_master"]
                            },
                            {
                                name: "Website",
                                isActive: true,
                                isPage: true,
                                ref: "website-list",
                                id: "website-list",
                                routerlink: {
                                    name: "ListWebsite"
                                },
                                icon: "fa fa-users fa-stack-1x",
                                permissionName: ["can_list_website_master"]
                            },
                        ]
                    },
                    {
                        name: "Instruction Guide",
                        isActive: true,
                        isPage: false,
                        ref: "instruction-guide",
                        id: "instruction-guide",
                        routerlink: {
                            name: "/static/file/Documentation.pdf"
                        },
                        icon: "fa fa-file-pdf-o",
                        permissionName: ["can_export_instruction_guide"]
                    },
                    {
                        name: "UT Reference Guide",
                        isActive: true,
                        isPage: false,
                        ref: "ut-ref-guide",
                        id: "ut-ref-guide",
                        routerlink: {
                            name: "/download-ut-ref-guide"
                        },
                        icon: "fa fa-file-pdf-o",
                        permissionName: []
                    },
                    {
                        name: "Upload Guide Document",
                        isActive: true,
                        isPage: true,
                        ref: "upload-guide-document",
                        id: "upload-guide-document",
                        routerlink: {
                            name: "UploadGuideDocument"
                        },
                        icon: "fa fa-upload",
                        permissionName: ["can_upload_guide_documents"]
                    },
                ],
                activeMenuIndex: [],
            }
        },
        beforeMount: function () {
            self = this;
        },
        mounted: function () {
            $(document).ready(function () {
                var menu_display = window.localStorage.getItem("menu_display");
                if (menu_display != undefined && menu_display == '') {
                    menu_display = "small";
                }
                self.$nextTick(function () {
                    if (menu_display == "small") {
                        var viewportWidth = $(window).width();
                        $(".app-sidebar-right").removeClass("toggled");
                        $(".app-sidebar-right").removeClass("toggled-2");
                        if (viewportWidth <= 667) {
                            $(".app-sidebar-right").removeClass('toggled');
                            $(".app-sidebar-right").removeClass('toggled-2');
                        } else {
                            $(".app-sidebar-right").removeClass('toggled-2');
                            $(".app-sidebar-right").addClass('toggled-2');
                            $(".app-sidebar-right").removeClass('toggled');
                        }
                        localStorage.setItem("menu_display", "small");
                    } else {
                        localStorage.setItem("menu_display", "big");
                    }
                });

                /*#### Set Menu Active On Page Hard Refresh If Its Submenu Is Active ####*/
                const activeMenuIndex = self.menuArray.findIndex(({ SubMenu }) => 
                    SubMenu && SubMenu.some(({ isActive }) => isActive)
                );
                if(activeMenuIndex > 0) {
                    self.toggleMenu('fa-angle-down', activeMenuIndex);
                }
            });
            $("#menu-toggle").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled");
                if ($(".app-sidebar-right").hasClass("toggled")) {
                    localStorage.setItem("menu_display", "small");
                } else {
                    localStorage.setItem("menu_display", "big");
                }
            });
            $("#menu-toggle-2").click(function (e) {
                e.preventDefault();
                $(".app-sidebar-right").toggleClass("toggled-2");
                if ($(".app-sidebar-right").hasClass("toggled-2")) {
                    localStorage.setItem("menu_display", "small");
                    /* $(".vgt-wrap__footer").removeClass("sticky_pagination_manuopen"); */
                } else {
                    /* $(".vgt-wrap__footer").addClass("sticky_pagination_manuopen"); */
                    localStorage.setItem("menu_display", "big");
                }
            });
            //disabled to reduce multi call from all pages
            //self.getLoginIdPermissions();
        },
        methods: {
            getActiveMenu: function () {
                //console.log("called");
            },
            initMenu: function () {
                $('#menu ul').hide();
                $('#menu ul').children('.current').parent().show();
                //$('#menu ul:first').show();
                $('#menu li a').click(
                        function () {
                            var checkElement = $(this).next();
                            if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
                                return false;
                            }
                            if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
                                $('#menu ul:visible').slideUp('normal');
                                checkElement.slideDown('normal');
                                return false;
                            }
                        }
                );
            },
            toggleMenu: function(menuClass, menuIndex) {
                self.menuArray[menuIndex].menuClass = menuClass === "fa-angle-down" ? "fa-angle-up" : "fa-angle-down"; // Change the font angle type
                /*#### Add & Remove Menu Index to expand and collapse menu ####*/
                if(menuClass === "fa-angle-down") {
                    self.activeMenuIndex.push(menuIndex);
                } else {
                    self.activeMenuIndex = self.activeMenuIndex.filter(index => index !== menuIndex);
                }
            },
            loadGuidePdf: function () {
                var config = {
                    headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
                    params: {}
                };
                HTTP.get("/download-ut-ref-guide", config)
                        .then(function (response) {
                            self.downloadFile(response.data.content);
                        })
                        .catch(function (error) {
                            self.catchHandler(error, function () {});
                        })
            }
        }
    }

    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });

    EventBus.$on("menu-selection-changed", function (menuref) {
        if (typeof self != "undefined") {
            for (var i = 0; i < self.menuArray.length; i++) {
                var menuObject = self.menuArray[i];
                if (menuref == menuObject.ref) {
                    menuObject.isActive = true;
                } else {
                    menuObject.isActive = false;
                    if (menuObject.SubMenu && menuObject.SubMenu.length > 0) {
                        for (var j = 0; j < menuObject.SubMenu.length; j++) {
                            var subMenuObject = menuObject.SubMenu[j];
                            if (menuref == subMenuObject.ref) {
                                subMenuObject.isActive = true;
                                menuObject.isActive = true;
                            } else {
                                subMenuObject.isActive = false;
                            }
                        }
                    }
                }
            }
        }
    });
</script>

