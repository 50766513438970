<template>
  <div id="page-content-wrapper" class="admin-page-content">
    <div class="error-template text-center">
      <!-- Cross Icon and Expired Link Message -->
      <div class="error-message">
        <span class="error-icon"><i class="fa fa-close"></i></span>
        <h1>Password Reset Link Not Valid</h1>
        <p>The password reset link you clicked is no longer valid. <br/> To reset your password, please return to the login page and click on the "Forgot/Reset Password" option to request a new email with a valid reset link.</p>
      </div>

      <!-- Button to Go to Login Page -->
      <button
        class="extraFeature btn btn-primary waves-effect ml-2"
        @click.prevent="goToLoginPage"
      >
        Go To Login Page
      </button>
    </div>
  </div>
</template>


<script>
export default {
  name: "VendorResetLinkNotFound",
  methods: {
    goToLoginPage() {
      this.$router.push({ name: "VendorLogin" });
    }
  },
};
</script>
<style lang="css" scoped>
  #page-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }

  .error-detail-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .error-template {
    padding: 60px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 100%;
  }

  .error-message {
    margin-bottom: 20px;
  }

  .error-icon {
    font-size: 60px;
    color: #d9534f;
  }

  .error-template h1 {
    font-size: 28px;
    font-weight: bold;
    color: #d9534f;
  }

  .error-template p {
    font-size: 20px;
    margin-top: 10px;
    color: #6c757d;
  }

  .extraFeature {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .extraFeature:hover {
    background-color: #0056b3;
  }
</style>

