<template>
  <div>
    <div
      class="modal"
      id="bookDealDetail"
      tabindex="-1"
      role="dialog"
      v-bind:style="{ zIndex: parentModalShowHide }"
    >
      <div
        class="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <div>{{ labelMessage.vehicle_order }} ({{ curDealNo }})</div>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal(false)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body book-deal-detail-modal-body">
            <div v-if="loader">
              <loader />
            </div>
            <div v-else>
              <div class="card mb-10">
              <table class="table custom-table jsmartable mb-0">
                <tr>
                  <td class="customer-detail"> {{ labelMessage.customer_id }} </td>
                  <td> {{dealDetail.customer_id}}</td>
                </tr>
                <tr>
                  <td class="customer-detail"> {{ labelMessage.customer_name_label }} </td>
                  <td> {{dealDetail.customer_name}}</td>
                </tr>
                <tr>
                  <td class="customer-detail"> {{ labelMessage.sales_rep }} : </td>
                  <td> {{dealDetail.saleperson_name}} - {{dealDetail.saleperson_emp_id}}</td>
                </tr>
                <tr>
                  <td class="customer-detail"> {{ labelMessage.sales_tax }} </td>
                  <td> {{dealDetail.sales_tax}}</td>
                </tr>
              </table>
              </div>
              <template v-for="vehicle in dealDetail.vehicles">
                <div class="card mb-10">
                <table class="table custom-table jsmartable mb-0">
                  <tr>
                    <td class="text-alignment-center bold"> {{ vehicle.vehicle_detail }}</td>
                  </tr>
                  <tr>
                    <td class="text-alignment-center"> {{ labelMessage.fet_label }} {{ vehicle.fet }}</td>
                  </tr>
                </table>
                <table class="table custom-table jsmartable mb-0">
                  <thead id="book-deal-detail-modal">
                    <th>{{ labelMessage.vehicle_order_item }}</th>
                    <th>{{ labelMessage.vehicle_order_description }}</th>
                    <th style="text-align: right;">{{ labelMessage.vehicle_order_price }}</th>
                    <th style="text-align: right;">{{ labelMessage.vehicle_order_cost }}</th>
                    <th>{{ labelMessage.vehicle_order_tax_id }}</th>
                  </thead>
                  <tbody>
                      <tr
                          v-for="item in vehicle.itemList"
                          :key="item.id"
                      >
                          <td>{{ item.name }}</td>
                          <td>{{ item.description }}</td>
                          <td style="text-align: right;">{{ item.price }}</td>
                          <td style="text-align: right;">{{ item.cost }}</td>
                          <td>{{ item.tax_id }}</td>
                      </tr>
                  </tbody>
                </table>
                </div>
              </template>
            </div>
            <div class="modal-footer">
              
              <button v-if="dealDetail.sls_id && isUpdateExOrderFlag" style="width: auto;" type="button" class="trns-mail-save-button" @click="storeOrder()" :disabled="loader">
                Update Order
              </button>
              <button v-if="!dealDetail.sls_id" style="width: auto;" type="button" class="trns-mail-save-button" @click="storeOrder()" :disabled="loader">
                Create Order
              </button>
              
              <!--
              <button type="button" class="trns-mail-close-button" @click="closeModal(false)">Print</button>
              -->
              <button type="button" class="trns-mail-close-button" @click="closeModal(false)">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../../../event-bus";
import { HTTP } from "../../../../../http-common";
import Loader from "../Loader.vue";
import { commonMixins } from "../../../../../mixins/commonMixins";

let self;
export default {
  name: "BookDealDetailModal",
  props: ["isClickBookDealDetail", "dealNo", "dealId", "bookModalFlag", "labelMessage"],
  components: {
    loader: Loader,
  },
  mixins: [commonMixins],
  data() {
    return {
      loader: false,
      curDealNo: "",
      dealDetail: [],
      parentModalShowHide: 1042,
      isUpdateExOrderFlag: false,
    };
  },
  beforeMount() {
    self = this;
  },
  methods: {
    storeOrder() {
      this.loader = true;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        deal_detail : this.dealDetail
      };
      HTTP.post("/storeVehicleOrderInExcede", fields, config)
        .then(function (response) {
          if (response.data.status === "success") {
            self.closeModal(response.data.is_refresh);
            self.showSAlert(response.data.message,'success',false,3);
          }
        })
        .catch(function (e) {
          if(e.response.data.code == "500") {
            self.$swal({
              title: e.response.data.message,
              html: self.formatKeyValue(JSON.parse(e.response.data.content)),
              type: "info",
              confirmButtonText: "OK",
              showCancelButton: false,
              showLoaderOnConfirm: false
            });
          }else{
            self.catchHandler(e, function () {});
          }
        })
        .finally(() => {
          this.loader = false;
        });
    },
    closeModal(isRefresh) {
      eventBus.$emit("close-book-deal-detail", isRefresh);
      this.parentModalShowHide = 1042;
    },
    getBookDealDetail() {
      this.loader = true;
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        deal_id: this.dealId,
        flag: this.bookModalFlag,
      };
      HTTP.post("/book-deal-detail/", fields, config)
        .then((response) => {
          if (response.data.status === "success") {
            this.dealDetail = response.data.content;
            this.isUpdateExOrderFlag = response.data.content.is_update_ex_order;
          }
        })
        .catch(function (error) {
          this.loader = false;
          self.catchHandler(error, function () {});
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  watch: {
    isClickBookDealDetail(status) {
      if (status) {
        this.curDealNo = this.dealNo;
        this.getBookDealDetail();
      }
    },
  },
};

</script>

<style scoped>
.book-deal-detail-modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

#book-deal-detail-modal {
  background-color: #fff;
  position: sticky;
  top: -16px;
}

.table.custom-table td {
  vertical-align: middle;
  white-space: unset;
}

.table.custom-table td:nth-child(3){
  max-width: 209px;
}

.customer-detail{
  width:30%;
  font-weight: 400;
  border-right: 2px solid #f0f1f1 !important;
}
</style>
