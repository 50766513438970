<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <template
      v-if="
        permissionNameArray.includes(
          'can_list_all_inventory_for_accounting_report'
        )
      "
    >
      <div
        class="container-fluid xyz all-inventory-for-accounting-report-list-view"
      >
        <div class="spaced-container">
          <div class="col-12 page_title paddingnone">
            <div class="page_title_left">
              <span>All Inventory For Accounting Report</span>
              <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
            </div>
            <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="refreshList()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>

            <a
              id="exportAllInventoryForAccountingId"
              title="Export"
              v-show="
                permissionNameArray.includes(
                  'can_export_all_inventory_for_accounting_report'
                )
              "
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="
                openReorderHeadersListModal(
                  'Export All Inventory For Accounting', //Modal Title
                  'export', //Action
                  'exportAllInventoryForAccountingReport', //Module Name
                  exportHeaders //Master Headers List
                )
              "
            >
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
            <button
              id="allInventoryForAccountingHeaderList"
              title="All Inventory For Accounting Header List"
              class="btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="
                openReorderHeadersListModal(
                  'Select All Inventory For Accounting Header List', //Modal Title
                  'list', //Action
                  'listAllInventoryForAccountingReport', //Module Name
                  allInventoryForAccountingHeadersList //Master Headers List
                )
              "
            >
              Headers List
            </button>
          </div>

          <!-- Export file status -->
          <div class="col-12" v-if="batchProcess">
            <div class="alert alert-success">
              Export file is in process. batch no. is {{ batchNum }}.
              <router-link
                class="color-blue"
                v-bind:to="{ name: 'ListBatch' }"
                target="_blank"
                @click.native="batchProcess = 0"
              >
                Click Here</router-link
              >
              to check file status!
            </div>
          </div>

          <filter-component
            :key="filterCount"
            :filterCounts="filterCount"
            :parentHeaders="filterheaders"
            @applyFilterParentComponent="applyFilterParentComponent"
            :isResetSorting="false"
          ></filter-component>

          <div class="col-12 paddingnone">
            <common-loader
              refId="commonAllInventoryForAccountingReport"
              ref="commonAllInventoryForAccountingReport"
            ></common-loader>
            <!-- Pagination Start -->
            <div class="top_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <!-- v-if="totalRecords >= perPage" -->
                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
            <div
              class="table-listing white-bg border-blue border-radius-top-right"
            >
              <table class="table custom-table jsmartable">
                <tbody>
                  <template
                    v-if="
                      allInventoryForAccountingDataArr &&
                      allInventoryForAccountingDataArr.length > 0
                    "
                  >
                    <tr
                      v-for="(row, index) in allInventoryForAccountingDataArr"
                      :key="index"
                    >
                      <template v-for="headerData in headers">
                        <td
                          v-if="headerData.column_name in row"
                          :class="headerData.column_class"
                          :key="headerData.column_name"
                        >
                          {{ row[headerData.column_name] }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        :colspan="headers.length + 1"
                        class="text-alignment-center"
                      >
                        {{ allStaticMessages.data_not_available }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <thead>
                  <tr>
                    <template v-for="(data, headerIndex) in headers">
                      <th
                        :class="data.column_full_class + ' column-header'"
                        v-if="data.name != 'Action'"
                        :key="headerIndex"
                      >
                      <!-- v-on:click.prevent="
                          sortTableData(data.column_name, data.sort)
                        " -->
                        {{ data.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- Pagination Start -->
            <div class="bottom_pagination vgt-wrap__footer crm">
              <div class="row">
                <div class="col-sm-12 col-md-2 PerPage">
                  <v-select
                    title="Select Per Page"
                    v-model="perPage"
                    @input="changePerPage"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="paginationDropDownArr"
                    placeholder="Select Per Page"
                  >
                  </v-select>
                </div>
                <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                  <p class="pageRowCount">
                    {{ startEndRecord }} of {{ totalRecords }}
                  </p>
                </div>

                <div class="col-sm-12 col-md-6 Pagination">
                  <b-pagination
                    v-if="totalRecords > 0"
                    align="center"
                    :limit="5"
                    prev-text="‹ Previous"
                    next-text="Next ›"
                    :total-rows="totalRecords"
                    v-model="currentPage"
                    @input="changepage()"
                    :per-page="perPageValue"
                  ></b-pagination>
                </div>

                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <p class="pageJumpLabel">Page :</p>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                  <input
                    v-model="jumpToPage"
                    type="text"
                    class="form-control form-control-sm"
                    name="jumpToPage"
                    id="jumpToPage"
                    v-on:keyup.enter="keyMonitor()"
                    placeholder="Page"
                  />
                </div>
              </div>
            </div>
            <!-- Pagination End -->
          </div>
        </div>

        <!-- Export and List Headers Modal -->
        <reorder-headers-list-modal
          @call-is-export="exportData = true"
          @call-reload-list="listAllInventoryForAccountingReport(1)"
          ref="reorderHeadersListModalRef"
        >
        </reorder-headers-list-modal>
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Vue from "vue";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from "../event-bus";
import { HTTP } from "../../http-common";
import FilterComponent from "../FilterComponent/FilterComponent";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import CommonLoader from "@/components/partials/CommonLoader.vue";
import Footer from "@/components/partials/Footer";
import ReorderHeadersListModal from "../ReorderHeadersListModal/ReorderHeadersListModal";

var self;
export default {
  name: "AllInventoryForAccountingReport",
  mixins: [commonMixins],
  components: {
    "filter-component": FilterComponent,
    "no-access": NoAccessPage,
    "common-loader": CommonLoader,
    "app-footer": Footer,
    "reorder-headers-list-modal": ReorderHeadersListModal,
  },
  data() {
    return {
      perPageValue: 25,
      perPage: 25,
      currentPage: 1,
      totalRecords: 0,
      paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
      headers: [],
      filterheaders: [],
      filters: {},
      appliedHeaders: [],
      jumpToPage: 1,
      umpToPage: 1,
      columnName: "id",
      sortType: "asc",
      startEndRecord: "",
      jumpToHistoryPage: 1,
      filterCount: 0,
      listExecutionState: false,
      exportHeaders: [],
      exportSelectedHeaders: [],
      isSelectAllExportHeader: false,
      allInventoryForAccountingHeadersList: [],
      exportAction: "",
      selectAllInventoryForAccountingHeaderList: [],
      allInventoryForAccountingDataArr: [],
      exportData: false,
      isSelectAllInventoryForAccountingHeaderList: false,
      permissionNameArray: ["can_list_all_inventory_for_accounting_report"],
      batchProcess: 0,
      batchNum: "",
    };
  },
  beforeMount() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function () {
    self = this;
    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */

    self.setActiveMenu("all-inventory-for-accounting-report-list");
    EventBus.$emit(
      "menu-selection-changed",
      "all-inventory-for-accounting-report-list"
    );

    var listHistoryData = self.loadFilters(
      "listAllInventoryForAccountingReport",
      this
    );
    self.jumpToHistoryPage =
      listHistoryData === null ||
      typeof listHistoryData === "undefined" ||
      listHistoryData == ""
        ? 1
        : listHistoryData.page;
    let headersNames = [];
    headersNames["filters"] = self.filters;
    self.listAllInventoryForAccountingReport(1);
  },
  methods: {
    // resetSorting() {
    //   self.columnName = "id";
    //   self.sortType = "asc";
    //   self.changepage();
    // },
    changePerPage() {
      self.currentPage = 1;
      if (self.perPage !== "All") {
        self.perPageValue = self.perPage;
      }
      self.listAllInventoryForAccountingReport();
    },
    changepage() {
      if (!self.listExecutionState) {
        self.listAllInventoryForAccountingReport();
      }
    },
    refreshList() {
      self.jumpToPage = 1;
      self.keyMonitor();
      self.listAllInventoryForAccountingReport();
    },
    keyMonitor: function () {
      var tempNumber = self.jumpToInputPage(
        self.jumpToPage,
        self.totalRecords,
        self.perPage
      );
      self.currentPage = self.jumpToPage = parseInt(tempNumber);
    },
    applyFilterParentComponent: function (data) {
      let key;
      let value;
      for (let i = 0; i < data.length; i++) {
        if (data[i]["column_name"] != "null") {
          key = data[i]["column_name"];
          value = data[i]["field"];
          self.filters[key] = value;
        }
      }
      self.appliedHeaders = data;
      self.listAllInventoryForAccountingReport();
    },
    setAlreadyAppliedFilters: function () {
      if (self.appliedHeaders.length > 0) {
        self.filterheaders = self.appliedHeaders;
        //self.headers = self.appliedHeaders;
      }
    },
    listAllInventoryForAccountingReport(first) {
      self.listExecutionState = true;
      if (
        self.perPage == "All" &&
        self.totalRecords > process.env.VUE_APP_MAX_SINGLEPAGE_DATA
      ) {
        self.showSAlert(
          self.allStaticMessages.too_many_data_for_all_list,
          "info",
          false
        );
        return false;
      }
      first =
        first === null || typeof first === "undefined" || first == ""
          ? 0
          : first;

      self.exportSelectedHeaders = self.loadExportHeaders(
        "exportAllInventoryForAccountingReport",
        self
      );
      self.exportSelectedHeaders =
        self.exportSelectedHeaders === null ||
        typeof self.exportSelectedHeaders === "undefined" ||
        self.exportSelectedHeaders == ""
          ? []
          : self.exportSelectedHeaders;
      self.selectAllInventoryForAccountingHeaderList = self.loadListHeaders(
        "listAllInventoryForAccountingReport",
        self
      );
      self.selectAllInventoryForAccountingHeaderList =
        self.selectAllInventoryForAccountingHeaderList === null ||
        typeof self.selectAllInventoryForAccountingHeaderList === "undefined" ||
        self.selectAllInventoryForAccountingHeaderList == ""
          ? []
          : self.selectAllInventoryForAccountingHeaderList;

      if (self.exportData == true) {
        self.exportRecords();
      }

      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token"),
        },
      };
      var fields = {
        filters: self.filters,
        per_page: self.perPage,
        page: self.currentPage,
        sortColumnName: self.columnName,
        sortType: self.sortType,
        is_export: self.exportData,
        export_action: self.exportAction,
        export_selected_headers: self.exportSelectedHeaders,
        selected_all_inventory_for_accounting_header:
          self.selectAllInventoryForAccountingHeaderList,
      };
      self.storeFilters("listAllInventoryForAccountingReport", fields, false);
      if (self.exportData) {
        self.$refs.reorderHeadersListModalRef.exportLoaderShow();
      } else {
        $("#commonAllInventoryForAccountingReport")
          .find(".loading-container.lodingTable")
          .show();
      }
      self.filterCount = self.filterCountFunction(self.filters);
      HTTP.post("/report/all-inventory-for-accounting", fields, config)
        .then(function (data) {
          self.listExecutionState = false;
          if (first) {
            self.currentPage = self.jumpToHistoryPage;
          }
          if (data.status == 200) {
            if (data.data.message == "success export queue") {
              self.exportAction = '';
              self.exportData = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.listAllInventoryForAccountingReport();
              self.batchProcess = 1;
              self.batchNum = data.data.content;
            } else if (data.data.message == "success export") {
              self.exportAction = "";
              self.exportData = false;
              self.$refs.reorderHeadersListModalRef.exportLoaderHide();
              self.$refs.reorderHeadersListModalRef.closeModal();
              self.downloadFile(data.data.content.web);
              self.listAllInventoryForAccountingReport();
            } else {
              $("#commonAllInventoryForAccountingReport")
                .find(".loading-container.lodingTable")
                .hide();
              self.headers = [];
              self.totalRecords = data.data.content.result;
              self.allInventoryForAccountingDataArr = data.data.content.data;
              self.headers = data.data.content.headers;
              self.filterheaders = data.data.content.filterheaders;

              self.allInventoryForAccountingHeadersList =
                data.data.content.allInventoryForAccountingHeadersList;
              if (
                self.selectAllInventoryForAccountingHeaderList.length ==
                self.allInventoryForAccountingHeadersList.length
              ) {
                self.isSelectAllInventoryForAccountingHeaderList = true;
              }

              self.exportHeaders = data.data.content.exportheaders;
              if (
                self.exportSelectedHeaders.length == self.exportHeaders.length
              ) {
                self.isSelectAllExportHeader = true;
              }

              self.loadFilterInputs(
                data.data.content.filterheaders,
                self.filters
              );

              self.addColumnClass(self.headers);

              if (data.data.content.per_page !== self.totalRecords) {
                self.perPage = parseInt(data.data.content.per_page);
                self.jumpToPage = parseInt(data.data.content.current_page);
              } else {
                if (self.perPage == "All") {
                  self.jumpToPage = 1;
                }
                self.perPageValue = self.totalRecords;
                self.currentPage = 1;
              }
              self.setAlreadyAppliedFilters();
              self.startEndRecord = self.calculateStartRecordEndRecord(
                self.currentPage,
                self.perPage,
                self.totalRecords
              );
              self.sortFieldDisplay(self.columnName, self.sortType);
            }
          }
          EventBus.$emit("is-open-filter-component", true);
        })
        .catch(function (error) {
          self.listExecutionState = false;
          if (self.exportData) {
            self.$refs.reorderHeadersListModalRef.exportLoaderHide();
          } else {
            $("#commonAllInventoryForAccountingReport")
              .find(".loading-container.lodingTable")
              .hide();
          }
          self.catchHandler(error, function () {});
          EventBus.$emit("is-open-filter-component", true);
        });
    },
    // sortTableData: function (name) {
    //   if (self.columnName == name) {
    //     self.sortType = self.sortType == "desc" ? "asc" : "desc";
    //   } else {
    //     self.sortType = "asc";
    //   }
    //   self.columnName = name;
    //   self.listAllInventoryForAccountingReport();
    // },
  },
};

EventBus.$on("login-permissions", function (permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
