<template>
  <!-- <div class="spaced-container"> -->
  <!-- Page Content Wrapper -->
  <div id="page-content-wrapper" class="admin-content-area">
    <template v-if="permissionNameArray.includes('can_add_customer_master')">
      <div class="container-fluid xyz">
        <div class="page-header-top-container">
          <div class="page-name">{{ pageTitle }}</div>
          <button
            type="submit"
            v-on:click.prevent="buttonBack()"
            class="btn btn-primary waves-effect pull-right"
          >
            Back
          </button>
        </div>
        <!--page content-->
        <div class="admin-page-content">
          <!--Forms-->
          <div :class="alertClass" id="success-alert">
            <strong> {{ alertMessage }} </strong>
          </div>
          <div
            class="forms-container add_user_forms_container vtc-dealer-forms"
          >
            <!--<span class="help is-danger" v-if="id > 0 && linkCountAsset > 0">
                            Are you sure that you want to update this Model! It is link with <strong> {{linkCountAsset}} </strong> Inventory(s).
                        </span>-->
            <!--
                        In Edit Customer we want to display TabView
                        -->
            <div class="row no-margin">
              <!--Customer Id-->
              <div v-if="isEdit" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0 input-with-icon">
                  <input
                    disabled
                    id="cusId"
                    v-model="cusId"
                    class="form-control"
                    type="text"
                  />
                  <a href="javascript:void(0)" v-b-tooltip.hover title="Copy" class="mar-right-0 " v-clipboard:copy="cusId" v-clipboard:success="onCopy" v-clipboard:error="onError" id="contact-specification-cust-id"><i aria-hidden="true" class="fa fa-clone"></i></a>  
                </div>
              </div>

              <!-- isIndBuyer Checkbox -->
              <div class="col-6 forms-block">
                    <div class="col-3 paddingleft0">
                        <div class="form-label"><label for="is_ind_buyer">{{staticInputLabelMessages.is_ind_buyer_label}}</label></div>
                    </div>
                    <div class="col-9 paddingleft0 custom-checkbox-v">
                        <label class="container">
                            <input id="is_ind_buyer" v-model="is_ind_buyer" value="true" class="form-control" type="checkbox"/>
                            <span class="checkmark"></span>
                        </label>
                    </div>
              </div>
              <div v-if="production_country == 'AU'" class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.customer_abn_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0 input-with-icon">
                  <input
                    id="cusId"
                    v-model="abn_no"
                    class="form-control"
                    type="text"
                  />
                  <a href="javascript:void(0)" v-b-tooltip.hover title="Copy" class="mar-right-0 " v-clipboard:copy="abn_no" v-clipboard:success="onCopy" v-clipboard:error="onError" id="contact-specification-abn_no"><i aria-hidden="true" class="fa fa-clone"></i></a>  
                </div>
                
              </div>

              <!-- Contact Group -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.contact_group_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="contactId"
                    @change="changeContactGroup"
                    @input="contactGroupError = ''"
                    name="customPerPage"
                    id="customPerPage"
                    label="text"
                    :clearable="false"
                    @search="onSearchContactGroup"
                    :filterable="false"
                    :options="contactGroupArr"
                    :placeholder="staticMessages.contact_group_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isContactGroupRecordNotFound">
                        {{ staticMessages.contact_group_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <!--<div class="requiredstar">*</div>
                                    <span class="help is-danger">{{contactGroupError}}</span>-->
                </div>
              </div>

              <!--Code-->
              <div v-if="isEdit" class="col-6 forms-block hide">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.code_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    disabled
                    @input="codeError = ''"
                    id="code"
                    v-model="code"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ codeError }}</span>
                </div>
              </div>

              

              <!-- ContactName -->
              <div class="col-6 forms-block" v-show="!is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="nameError = ''"
                    id="contactName"
                    v-model="contact_name"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ nameError }}</span>
                </div>
              </div>

              <!-- FirstName -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.first_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="firstNameError = ''"
                    id="firstName"
                    v-model="firstName"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ firstNameError }}</span>
                </div>
              </div>

              <!-- Middle Name -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.middle_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="middleName"
                    v-model="middleName"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- LastName -->
              <div class="col-6 forms-block" v-show="is_ind_buyer">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.last_name_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="lastName"
                    v-model="lastName"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyNameError}}</span> -->
                </div>
              </div>

              <!-- Jobtitle -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.job_title_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="jobTitle"
                    v-model="jobTitle"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{companyNameError}}</span> -->
                </div>
              </div>

              <!--Company Name-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.company_name_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="companyNameError = ''" id="companyName" v-model="companyName" class="form-control" type="text"/> -->
              <!-- <div class="requiredstar">*</div> -->
              <!-- <span class="help is-danger">{{companyNameError}}</span> -->
              <!-- </div>
                            </div> -->

              <!--Primary Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.primary_phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="primaryPhoneError = ''"
                    id="primaryPhone"
                    v-model="primaryPhone"
                    class="form-control"
                    type="text"
                  />
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ primaryPhoneError }}</span>
                </div>
              </div>

              <!--Alternate Phone -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.alternate_phone_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="alternatePhoneError = ''"
                    id="alternatePhone"
                    v-model="alternatePhone"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ alternatePhoneError }}</span>
                </div>
              </div>

              <!-- Fax -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.fax_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="faxError = ''"
                    id="fax"
                    v-model="fax"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ faxError }}</span>
                </div>
              </div>

              <!--Email -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.email_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="emailError = ''"
                    id="email"
                    v-model="email"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div class="requiredstar">*</div> -->
                  <span class="help is-danger">{{ emailError }}</span>
                </div>
              </div>

              <!-- Country -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.country_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="countryId"
                    @change="changeConutry"
                    @input="countryError = ''"
                    :name="pre_id + 'countryId'"
                    :id="pre_id + 'countryId'"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="countryArr"
                    :disabled="true"
                    :placeholder="staticMessages.country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ countryError }}</span>
                </div>
              </div>

              <!-- State -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.state_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="stateId"
                    name="state"
                    label="text"
                    :filterable="false"
                    :options="stateArr"
                    @input="stateError = ''"
                    @search="onSearchState"
                    :placeholder="staticMessages.state_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ stateError }}</span>
                </div>
              </div>

              <!-- City -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.city_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="cityId"
                    name="state"
                    label="text"
                    :filterable="false"
                    :options="cityArr"
                    @input="cityError = ''"
                    @search="onSearchCity"
                    :placeholder="staticMessages.city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ cityError }}</span>
                </div>
              </div>

              <!--Address-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.address_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="addressError = ''"
                    id="address"
                    v-model="address"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div class="requiredstar">*</div> -->
                  <!-- <span class="help is-danger">{{addressError}}</span> -->
                </div>
              </div>

              <!--Address2-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.cus_address2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="address2"
                    v-model="address2"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Zipcode -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.zip_code_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="zipcodeError = ''"
                    id="zipcode"
                    v-model="zipcode"
                    class="form-control"
                    type="text"
                  />
                  <!-- <div class="requiredstar">*</div> -->
                  <!-- <span class="help is-danger">{{zipcodeError}}</span> -->
                </div>
              </div>

              <!-- isPrimary Checkbox -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    <label for="isPrimary">{{
                      staticInputLabelMessages.is_primary_label
                    }}</label>
                  </div>
                </div>
                <div class="col-9 paddingleft0 custom-checkbox-v">
                  <label class="container">
                    <input
                      id="isPrimary"
                      v-model="isPrimary"
                      value="true"
                      class="form-control"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <!-- <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{descriptionError}}</span> -->
                </div>
              </div>

              <!--Manage By-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.managed_by_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <v-select
                    v-model="manageBy"
                    @change="changeManageBy"
                    @input="manageByError = ''"
                    name="manageBy"
                    id="manageBy"
                    label="text"
                    :clearable="false"
                    @search="onSearchManageBy"
                    :filterable="false"
                    :options="manageByArr"
                    :placeholder="staticMessages.managed_by_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isManageByRecordNotFound">
                        {{ staticMessages.managed_by_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                  <div class="requiredstar">*</div>
                  <span class="help is-danger">{{ manageByError }}</span>
                </div>
              </div>

              <!-- Status -->
              <div
                v-show="
                  permissionNameArray.includes(
                    'can_active_inactive_customer_master'
                  )
                "
                class="col-6 forms-block"
              >
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.status_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!-- <v-select 
                                              v-model="statusId"
                                              @change="changeStatus"
                                              @input="statusError = ''"
                                              name="customPerPage"
                                              id="customPerPage"
                                              label="text"
                                              :clearable="false"
                                              :searchable="false"
                                              :filterable="false"
                                              :options="statusArr"
                                              :placeholder="staticMessages.status_place_holder">
                                    </v-select>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{statusError}}</span> -->
                  <b-form-radio-group
                    v-model="statusId"
                    :options="statusIdOptions"
                    name="statusId"
                  >
                  </b-form-radio-group>
                </div>
              </div>

              <!-- Contact Type -->
              <!-- <div v-show="permissionNameArray.includes('can_active_inactive_`customer`_master')" class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.contact_type_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <b-form-radio-group v-model="contactTypeId"
                                                        :options="contactTypeIdOptions"
                                                        name="contactTypeId">
                                    </b-form-radio-group>
                                </div>
                            </div> -->

              <!--Name-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Name :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="nameError = ''" id="name" v-model="name" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{nameError}}</span>
                                </div>
                            </div> -->

              <!--Share Type-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Share Type :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="shareTypeError = ''" id="shareType" v-model="shareType" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{shareTypeError}}</span>
                                </div>
                            </div> -->

              <!--Title-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Title :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="titleError = ''" id="title" v-model="title" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{titleError}}</span>
                                </div>
                            </div> -->

              

              <!--Excede Country-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_country_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="excedeCountry" v-model="excedeCountry" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->

              <!--Excede State-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_state_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="excedeState" v-model="excedeState" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->

              <!--Excede City-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_city_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input id="excedeCity" v-model="excedeCity" class="form-control" type="text" maxlength="190"/>
                                </div>
                            </div> -->

              <!--Bill Address1-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_address1_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billAddress1"
                    v-model="billAddress1"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Address2-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_address2_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billAddress2"
                    v-model="billAddress2"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Bill Country -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_country_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!-- <input id="billCountry" v-model="billCountry" class="form-control" type="text" maxlength="190"/> -->
                  <v-select
                    v-model="billCountry"
                    @change="changeBillCountry"
                    name="billCountry"
                    id="billCountry"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :filterable="false"
                    :options="billCountryArr"
                    :placeholder="staticMessages.country_place_holder"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillCountryRecordNotFound">
                        {{ staticMessages.country_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill State -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_state_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!--  <input id="billState" v-model="billState" class="form-control" type="text" maxlength="190"/> -->
                  <v-select
                    v-model="billState"
                    name="billState"
                    label="text"
                    :filterable="false"
                    :options="billStateArr"
                    @search="onSearchBillState"
                    :placeholder="staticMessages.state_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillStateRecordNotFound">
                        {{ staticMessages.state_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill City -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_city_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!-- <input id="billCity" v-model="billCity" class="form-control" type="text" maxlength="190"/> -->
                  <v-select
                    v-model="billCity"
                    name="billCity"
                    label="text"
                    :filterable="false"
                    :options="billCityArr"
                    @search="onSearchBillCity"
                    :placeholder="staticMessages.city_place_holder"
                    class="custom-ci-auto-search"
                  >
                    <span slot="no-options" @click="$refs.select.open = false">
                      <template v-if="!isBillCityRecordNotFound">
                        {{ staticMessages.city_search }}
                      </template>
                      <template v-else>
                        {{ staticMessages.search_record_not_found }}
                      </template>
                    </span>
                  </v-select>
                </div>
              </div>

              <!--Bill Post -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.bill_post_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="billPost"
                    v-model="billPost"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Typ -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.typ_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="typ"
                    v-model="typ"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Trm Id -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.trm_id_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="trmId"
                    v-model="trmId"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!--Excede Date Create -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_date_create_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="excedeDateCreate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.excede_date_created_place_holder"
                                            name="excedeDateCreate"
                                    >
                                    </date-picker>
                                </div>
                            </div> -->

              <!--Excede Date Update-->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">{{staticInputLabelMessages.excede_date_update_label}}</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <date-picker
                                            v-model="excedeDateUpdate"
                                            :config="options"
                                            class="form-control"
                                            autocomplete="off"
                                            :placeholder="staticMessages.excede_date_updated_place_holder"
                                            name="excedeDateUpdate"
                                    >
                                    </date-picker>
                                </div>
                            </div> -->

              <!--Excede Date Invoice-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.excede_date_invoice_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!-- <input id="excedeDateInvoice" v-model="excedeDateInvoice" class="form-control" type="text"/> -->
                  <date-picker
                    v-model="excedeDateInvoice"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    :placeholder="
                      staticMessages.excede_date_invoice_place_holder
                    "
                    name="excedeDateInvoice"
                  >
                  </date-picker>
                </div>
              </div>

              <!--Amt Invoice -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.amt_invoice_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="amtInvoiceError = ''"
                    id="amtInvoice"
                    v-model="amtInvoice"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ amtInvoiceError }}</span>
                </div>
              </div>

              <!--Excede Date Payment-->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.excede_date_payment_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <!-- <input id="excedeDatePayment" v-model="excedeDatePayment" class="form-control" type="text"/> -->
                  <date-picker
                    v-model="excedeDatePayment"
                    :config="options"
                    class="form-control"
                    autocomplete="off"
                    :placeholder="
                      staticMessages.excede_date_payment_place_holder
                    "
                    name="excedeDatePayment"
                  >
                  </date-picker>
                </div>
              </div>

              <!-- Amt Payment -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.amt_payment_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    @input="amtPaymentError = ''"
                    id="amtPayment"
                    v-model="amtPayment"
                    class="form-control"
                    type="text"
                  />
                  <span class="help is-danger">{{ amtPaymentError }}</span>
                </div>
              </div>

              <!-- Cus Misc1 -->
              <div class="col-6 forms-block">
                <div class="col-3 paddingleft0">
                  <div class="form-label">
                    {{ staticInputLabelMessages.cus_misc1_label }}
                  </div>
                </div>
                <div class="col-9 paddingleft0">
                  <input
                    id="cusMisc1"
                    v-model="cusMisc1"
                    class="form-control"
                    type="text"
                    maxlength="190"
                  />
                </div>
              </div>

              <!-- County -->
              <div class="col-6 forms-block">
                  <div class="col-3 paddingleft0">
                      <div class="form-label">{{allLabelMessages.county_label}}</div>
                  </div>
                  <div class="col-9 paddingleft0">
                    <v-select
                      v-model="customerCountyId"
                      name="customerCountyId"
                      label="name"
                      :filterable="false"
                      :options="countyCodeArr"
                      @search="onSearchCountyCode"
                      :clearable="false"
                      @input="customerCountyError = ''"
                      id="customerCountyId"
                      :placeholder="allStaticMessages.county_place_holder"
                      class="custom-ci-auto-search"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        <template v-if="!isCustomerCountyRecordNotFound">
                          {{ allStaticMessages.county_search }}
                        </template>
                        <template v-else>
                          {{ allStaticMessages.search_record_not_found }}
                        </template>
                      </span>
                    </v-select>
                  </div>
              </div>

              
              <!--Extention -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Extention :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="extentionError = ''" id="extention" v-model="extention" class="form-control" type="text"/>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{extentionError}}</span>
                                </div>
                            </div> -->

              <!--Cell Phone -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Cell Phone :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="cellPhoneError = ''" id="cellPhone" v-model="cellPhone" class="form-control" type="text"/>
                                    <span class="help is-danger">{{cellPhoneError}}</span>
                                </div>
                            </div> -->

              <!--International Phone -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">International Phone :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <input @input="internationalPhoneError = ''" id="internationalPhone" v-model="internationalPhone" class="form-control" type="text"/>
                                    <span class="help is-danger">{{internationalPhoneError}}</span>
                                </div>
                            </div> -->

              <!--Birth Date -->
              <!-- <div class="col-6 forms-block">
                                <div class="col-3 paddingleft0">
                                    <div class="form-label">Birth Date  :</div>
                                </div>
                                <div class="col-9 paddingleft0">
                                    <VueCtkDateTimePicker v-model="datePickerDates"
                                                          :range="datePickerOptions.range"
                                                          :only-date="datePickerOptions.onlyDate"
                                                          :only-time="false"
                                                          :output-format="datePickerOptions.outputFormat"
                                                          :format="datePickerOptions.inputFormat"
                                                          :formatted="datePickerOptions.formatted"
                                                          :noClearButton="datePickerOptions.noClearButton"
                                                          :custom-shortcuts="datePickerOptions.customShortcuts"
                                                          @input="changeDatePickerDate()"
                                                          :maxDate="datePickerOptions.maxDate"
                                    ></VueCtkDateTimePicker>
                                    <div class="requiredstar">*</div>
                                    <span class="help is-danger">{{birthDateError}}</span>
                                </div>
                            </div> -->
            </div>

            <!--Error Alert-->
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="alert alert-danger" id="alertError">
                  <!--<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
                  <strong id="errorMsg" class="error"></strong>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

            <!--Buttons-->
            <div class="row clearfix"></div>
            <div class="button-demo">
              <input
                id="addModelBtn"
                :disabled="disableButtons.save"
                type="submit"
                :value="buttonNames.save"
                class="btn btn-primary"
                v-on:click.prevent="addModel()"
              />
              <input
                id="addAndContinueModelBtn"
                :disabled="disableButtons.saveAndContinue"
                type="submit"
                :value="buttonNames.saveAndContinue"
                class="btn btn-primary"
                v-on:click.prevent="addModel((flag = 'Save and Continue'))"
              />
              <input
                id="cancelModel"
                :disabled="disableButtons.cancel"
                type="submit"
                :value="buttonNames.cancel"
                class="btn btn-primary"
                v-on:click.prevent="buttonBack"
              />
            </div>
          </div>
          <!-- Forms -->
        </div>
        <!-- <template v-if="isEdit">
          <div class="spaced-container">
            <contact-email :contactId="id"></contact-email>
          </div>
          <asset-crm></asset-crm>
        </template> -->
        <!--page content-->
      </div>
    </template>
    <template v-else>
      <no-access></no-access>
    </template>
    <app-footer></app-footer>
  </div>
  <!-- Page Content Wrapper -->
  <!-- </div> -->
</template>

<script>
var self;
import Vue from "vue";
import Footer from "@/components/partials/Footer";
import ListContactWiseEmail from "@/components/ContactMaster/ListContactWiseEmail";
import ListActivity from "@/components/Activity/ListActivity";
import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import NoAccessPage from "../NoAccessPage/NoAccessPage";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.component("app-footer", Footer);

export default {
  destroyed() {
    localStorage.removeItem("CopyContact");
    localStorage.removeItem("CopyContactId");
  },
  props: ["CopyContact", "CopyId"],
  name: "ContactSpecification",
  mixins: [commonMixins],
  components: {
    datetime: Datetime,
    datePicker,
    "contact-email": ListContactWiseEmail,
    "asset-crm": ListActivity
  },
  data() {
    return {
      production_country: process.env.VUE_APP_PRODUCTION_COUNTRY,
      staticInputLabelMessages: "",
      staticMessages: "",
      isEdit: false,
      contactId: "",
      contactGroupArr: [],
      isContactGroupRecordNotFound: false,
      contactGroupError: "",
      code: "",
      codeError: "",
      cusId: "",
      firstName: "",
      firstNameError: "",
      lastName: "",
      jobTitle: "",
      companyName: "",
      companyNameError: "",
      primaryPhone: "",
      primaryPhoneError: "",
      alternatePhone: "",
      alternatePhoneError: "",
      fax: "",
      faxError: "",
      email: "",
      emailError: "",
      countryId: "",
      countryArr: [],
      isCountryRecordNotFound: false,
      countryError: "",
      stateId: "",
      stateArr: [],
      isStateRecordNotFound: false,
      stateError: "",
      cityId: "",
      cityArr: [],
      isCityRecordNotFound: false,
      cityError: "",
      address: "",
      addressError: "",
      zipcode: "",
      zipcodeError: "",
      isPrimary: false,
      manageBy: "",
      manageByArr: [],
      isManageByRecordNotFound: false,
      manageByError: "",
      // statusArr : [ { text : "Active", value : 'active'}, { text : "Inactive",  value : 'inactive' } ],
      // statusId : { text : "Active", value : 'active'},
      statusId: "active",
      statusIdOptions: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" }
      ],
      statusError: "",
      /* contactTypeId: 'yes',
                contactTypeIdOptions:[
                    { text : "Customer", value : 'yes'}, { text : "Provider",  value : 'no' }
                ], */

      buttonNames: {
        save: "Save",
        saveAndContinue: "Save And Continue",
        cancel: "Cancel"
      },
      disableButtons: {
        save: false,
        saveAndContinue: false,
        cancel: false
      },
      alertClass: "",
      alertMessage: "",
      pageTitle: "Add Customer",
      id: 0,

      /* linkCountAsset: 0,
                datePickerOptions: {
                    range: false,
                    onlyDate: true,
                    outputFormat: 'YYYY-MM-DD',
                    inputFormat: 'YYYY-MM-DD',
                    formatted: 'MMM DD, YYYY',//'YYYY-MM-DD',
                    noClearButton: true,
                    maxDate : '',
                },
                birthDateError : "",
                datePickerDates : "",
                name : "",
                nameError : "",
                shareType : "",
                shareTypeError : "",
                title : "",
                titleError : "",*/
      address2: "",
      /*address2Error : "",
                extention : "",
                extentionError : "",
                cellPhone : "",
                cellPhoneError : "",
                internationalPhone : "",
                internationalPhoneError : "", */

      middleName: "",
      excedeCountry: "",
      excedeState: "",
      excedeCity: "",
      billAddress1: "",
      billAddress2: "",
      billCountry: "",
      billCountryArr: [],
      isBillCountryRecordNotFound: false,
      billState: "",
      billStateArr: [],
      isBillStateRecordNotFound: false,
      billCity: "",
      billCityArr: [],
      isBillCityRecordNotFound: false,
      billPost: "",
      typ: "",
      trmId: "",
      excedeDateCreate: "",
      excedeDateUpdate: "",
      excedeDateInvoice: "",
      amtInvoice: "",
      amtInvoiceError: "",
      excedeDatePayment: "",
      amtPayment: "",
      amtPaymentError: "",
      cusMisc1: "",
      abn_no: "",
      anbNoError: "",
      options: {
        format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
        useCurrent: false,
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: "auto",
          vertical: "bottom"
        }
      },

      // county part
      customerCountyId: "",
      countyCodeArr: [],
      isCountyCodeNotFound: false,
      customerCountyError: "",
      isCustomerCountyRecordNotFound: false,

      // county part
      customerCountyId: "",
      countyCodeArr: [],
      isCountyCodeNotFound: false,
      customerCountyError: "",
      isCustomerCountyRecordNotFound: false,

      permissionNameArray: ["can_add_customer_master"],
      is_excede: 0,
      contact_name: "",
      nameError: "",
      is_ind_buyer: false
    };
  },
  beforeMount() {
    self = this;
  },
  created: function() {
    self = this;
    self.getLoginIdPermissions();
  },
  mounted: function() {

    // throw new Error('Test error by NITIN RAJPUROHIT');

    /* Redirect to login page logic start */
    var userObj = localStorage.getItem("user_obj");
    if (userObj == null) {
      self.$router.push("/");
    }
    /* Redirect to login page logic end */
    if (localStorage.getItem("CopyContact") === "true") {
      self.CopyContact = "CopyContact";
      self.CopyId = localStorage.getItem("CopyContactId");
    }
    if (
      typeof self.CopyContact !== "undefined" &&
      self.CopyContact === "CopyContact" &&
      typeof self.CopyId !== "undefined" &&
      self.CopyId
    ) {
      $("#addAndContinueModelBtn").hide();
      self.getModelDetailsById(self.CopyId);
      localStorage.setItem("CopyContact", true);
      localStorage.setItem("CopyContactId", self.CopyId);
    }

    self.staticInputLabelMessages = self.allLabelMessages;
    self.staticMessages = self.allStaticMessages;
    self.setActiveMenu("contact-list");
    EventBus.$emit("menu-selection-changed", "contact-list");

    $("#alertError").hide();
    self.isEdit = false;
    if (
      typeof self.$route.params.id != "undefined" &&
      self.$route.params.id != "" &&
      self.$route.params.id != 0
    ) {
      self.id = self.$route.params.id;
      self.isEdit = true;
      self.getModelDetailsById(self.$route.params.id);
      self.buttonNames.save = "Update";
      $("#addAndContinueModelBtn").hide();
      self.pageTitle = "Customer";
    } else {
      self.countryId /* = self.billCountry */ = {
        text: self.staticInputLabelMessages.US_country_label,
        value: 231
      };
    }
    self.loadAllData(
      ["Countries"],
      false,
      self.loadCountryCallBack,
      "undefined",
      ""
    );
    self.setDatePickerDateToCurrentDate();
  },
  watch: {
    countryId: function(countryId) {
      self.loadAllData(
        ["States"],
        true,
        self.loadStateDataCallback,
        "undefined",
        "",
        self.countryId.value
      );
    },
    stateId: function(stateId) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadCityDataCallback,
        "undefined",
        "",
        self.stateId.value
      );
    },
    billCountry: function(billCountry) {
      self.loadAllData(
        ["States"],
        true,
        self.loadBillStateDataCallback,
        "undefined",
        "",
        self.billCountry.value
      );
    },
    billState: function(billState) {
      self.loadAllData(
        ["Cities"],
        true,
        self.loadBillCityDataCallback,
        "undefined",
        "",
        self.billState.value
      );
    }
  },
  methods: {
    changeDatePickerDate: function() {
      self.birthDateError = "";
    },
    setDatePickerDateToCurrentDate: function() {
      var date = new Date();
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      self.today = today;
      // self.datePickerDates = today
      // self.datePickerOptions.maxDate = today;
    },
    onSearchCity(search, loading) {
      if (self.stateId == null || !self.stateId.value) {
        self.showSAlert("Please Select State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadCityDataCallback,
            loading,
            search,
            self.stateId.value
          );
        }
      }
    },
    onSearchState(search, loading) {
      if (self.countryId == null || !self.countryId.value) {
        self.showSAlert("Please Select Country First!", "info", false);
      } else {
        if (search.length > 1) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadStateDataCallback,
            loading,
            search,
            self.countryId.value
          );
        }
      }
    },
    loadStateDataCallback: function(callbackData) {
      self.isStateRecordNotFound = false;
      self.stateArr = callbackData.States;
      if (self.stateArr.length == 0) {
        self.isStateRecordNotFound = true;
      }
    },
    onSearchBillCity(search, loading) {
      if (self.billState == null || !self.billState.value) {
        self.showSAlert("Please Select Bill State First!", "info", false);
      } else {
        if (search.length > 2) {
          loading(true);
          self.loadAllData(
            ["Cities"],
            true,
            self.loadBillCityDataCallback,
            loading,
            search,
            self.billState.value
          );
        }
      }
    },
    loadBillCityDataCallback: function(callbackData) {
      self.isBillCityRecordNotFound = false;
      self.billCityArr = callbackData.Cities;
      if (self.billCityArr) {
        self.isBillCityRecordNotFound = true;
      }
    },
    onSearchBillState(search, loading) {
      if (self.billCountry == null || !self.billCountry.value) {
        self.showSAlert("Please Select Bill Country First!", "info", false);
      } else {
        if (search.length > 1) {
          loading(true);
          self.loadAllData(
            ["States"],
            true,
            self.loadBillStateDataCallback,
            loading,
            search,
            self.billCountry.value
          );
        }
      }
    },
    loadBillStateDataCallback: function(callbackData) {
      self.isBillStateRecordNotFound = false;
      self.billStateArr = callbackData.States;
      if (self.billStateArr.length == 0) {
        self.isBillStateRecordNotFound = true;
      }
    },
    onSearchContactGroup(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["CustomerCompany"],
          true,
          self.loadContactGroupCallBack,
          loading,
          search
        );
      }
    },
    loadContactGroupCallBack: function(callbackData) {
      self.isContactGroupRecordNotFound = false;
      self.contactGroupArr = callbackData.CustomerCompany;
      // self.contactGroupArr = callbackData.ContactGroup;
      if (self.contactGroupArr.length == 0) {
        self.isContactGroupRecordNotFound = true;
      }
    },
    /* onSearchCountry(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Countries'], true, self.loadCountryCallBack, loading, search);
                }
            }, */
    loadCountryCallBack: function(callbackData) {
      self.isCountryRecordNotFound = self.isBillCountryRecordNotFound = false;
      self.countryArr = self.billCountryArr = callbackData.Countries;
      if (self.countryArr.length == 0) {
        self.isCountryRecordNotFound = true;
      }
      if (self.billCountryArr.length == 0) {
        self.isBillCountryRecordNotFound = true;
      }
    },
    loadCityDataCallback: function(callbackData) {
      self.isCityRecordNotFound = false;
      self.cityArr = callbackData.Cities;
      if (self.cityArr) {
        self.isCityRecordNotFound = true;
      }
    },
    onSearchManageBy(search, loading) {
      if (search.length > 2) {
        loading(true);
        self.loadAllData(
          ["User"],
          true,
          self.loadManageByCallBack,
          loading,
          search
        );
      }
    },
    loadManageByCallBack: function(callbackData) {
      self.isManageByRecordNotFound = false;
      self.manageByArr = callbackData.User;
      if (self.manageByArr) {
        self.isManageByRecordNotFound = true;
      }
    },
    buttonBack() {
      self.$router.push({ name: "ListContact" });
    },
    changeContactGroup: function(value) {
      /*console.log(value);*/
    },
    changeManageBy: function(value) {},
    changeConutry: function(value) {},
    changeBillCountry: function(value) {},
    changeStatus: function(value) {},
    clearDataOnSaveAndContinue: function() {
      self.contactId = "";
      self.contactGroupArr = [];
      self.isContactGroupRecordNotFound = false;
      self.code = "";
      self.cusId = "";
      
      self.contact_name = "";
      self.is_ind_buyer = false;

      self.firstName = "";
      self.lastName = "";
      self.jobTitle = "";
      self.companyName = "";
      self.primaryPhone = "";
      self.alternatePhone = "";
      self.fax = "";
      self.email = "";
      self.countryId = "";
      //self.countryArr = [];
      self.isCountryRecordNotFound = false;
      self.stateId = "";
      self.stateArr = [];
      self.isStateRecordNotFound = false;
      self.cityId = "";
      self.cityArr = [];
      self.isCityRecordNotFound = false;
      self.address = "";
      self.zipcode = "";
      self.manageBy = "";
      self.manageByArr = [];
      self.isManageByRecordNotFound = false;
      self.statusId = "active";
      // self.contactTypeId = "yes";
      self.middleName = "";
      self.excedeCountry = "";
      self.excedeState = "";
      self.excedeCity = "";
      self.billAddress1 = "";
      self.billAddress2 = "";
      self.billCountry = "";
      // self.billCountryArr = [],
      (self.isBillCountryRecordNotFound = false), (self.billState = "");
      self.billStateArr = [];
      self.isBillStateRecordNotFound = false;
      self.billCity = "";
      self.billCityArr = [];
      self.isBillCityRecordNotFound = false;
      self.billPost = "";
      self.typ = "";
      self.trmId = "";
      self.excedeDateCreate = "";
      self.excedeDateUpdate = "";
      self.excedeDateInvoice = "";
      self.amtInvoice = "";
      self.amtInvoiceError = "";
      self.excedeDatePayment = "";
      self.amtPayment = "";
      (self.amtPaymentError = ""), (self.cusMisc1 = "");
      self.address2 = "";
      self.abn_no = "";
      self.anbNoError = "";
    },
    showAlertOnSave: function(alertMessage, alertType) {
      if (alertType === "success") {
        self.alertClass = "alert alert-success";
      } else {
        self.alertClass = "alert alert-danger";
      }
      self.alertMessage = alertMessage;
      $("#success-alert")
        .fadeTo(3000, 500)
        .slideUp(500, function() {
          $("#success-alert").slideUp(500);
        });
    },
    getModelDetailsById: function(id) {
      var config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("api_token")
        },
        params: {
          id: id
        }
      };
      HTTP.get("/get/contact", config)
        .then(response => {
          self.cusId = response.data.content.customer_id || response.data.content.id;
          self.contactId = {
            value: response.data.content.vvg_bill_cus_id,
            text: (response.data.content.vvg_bill_cus_id && response.data.content.company_name) ? response.data.content.company_name : ""
          };
          self.code = response.data.content.code;

          self.full_name = response.data.content.full_name;
          self.contact_name = response.data.content.contact_name && response.data.content.contact_name != "" ? response.data.content.contact_name : self.full_name;
          self.is_ind_buyer = response.data.content.is_ind_buyer ? true : false;
          self.firstName = response.data.content.first_name;
          self.lastName = response.data.content.last_name;
          self.jobTitle = response.data.content.job_title;
          self.companyName = response.data.content.company_name;
          self.primaryPhone = response.data.content.primary_phone;
          self.alternatePhone = response.data.content.alternate_phone;
          self.fax = response.data.content.fax;
          self.email = response.data.content.email;
          self.countryId = {
            value: response.data.content.country_id,
            text: response.data.content.countryName
          };
          self.stateId = {
            value: response.data.content.state_id,
            text: response.data.content.stateName
          };
          self.cityId = {
            value: response.data.content.city_id,
            text: response.data.content.cityName
          };
          self.address = response.data.content.address;
          self.zipcode = response.data.content.zip_code;
          self.isPrimary =
            response.data.content.is_primary == "no" ? false : true;
          if (response.data.content.manage_by_id == 0) {
            self.manageBy = "";
          } else if (
            !self.numberFormateValidation(response.data.content.manage_by_id)
          ) {
            self.manageBy = "";
          } else {
            self.manageBy = {
              value: response.data.content.manage_by_id,
              text: response.data.content.manage_by_name
            };
          }
          self.statusId = response.data.content.status;
          /* self.contactTypeId = response.data.content.is_customer; */
          /* self.name = response.data.content.name;
                        self.shareType = response.data.content.share_type;
                        self.title = response.data.content.title;*/
          if (
            response.data.content.address_2 != null &&
            response.data.content.address_2 != ""
          ) {
            self.address2 = response.data.content.address_2;
          }else if(response.data.content.address2 != null &&
            response.data.content.address2 != ""){
              self.address2 = response.data.content.address2;
          }
          /*self.extention = response.data.content.extention;
                        self.cellPhone = response.data.content.cell_phone;
                        self.internationalPhone = response.data.content.international_phone;
                        self.datePickerDates = response.data.content.birth_date;
                        self.datePickerDates = response.data.content.birth_date; */
          if (
            response.data.content.middle_name != null &&
            response.data.content.middle_name != ""
          ) {
            self.middleName = response.data.content.middle_name;
          }
          if (
            response.data.content.country != null &&
            response.data.content.country != ""
          ) {
            self.excedeCountry = response.data.content.country;
          }
          if (
            response.data.content.state != null &&
            response.data.content.state != ""
          ) {
            self.excedeState = response.data.content.state;
          }
          if (
            response.data.content.city != null &&
            response.data.content.city != ""
          ) {
            self.excedeCity = response.data.content.city;
          }
          if (
            response.data.content.bill_addr1 != null &&
            response.data.content.bill_addr1 != ""
          ) {
            self.billAddress1 = response.data.content.bill_addr1;
          }
          if (
            response.data.content.bill_addr2 != null &&
            response.data.content.bill_addr2 != ""
          ) {
            self.billAddress2 = response.data.content.bill_addr2;
          }
          if (
            response.data.content.bill_country_id != null &&
            response.data.content.bill_country_id != ""
          ) {
            self.billCountry = {
              value: response.data.content.bill_country_id,
              text: response.data.content.countryName
            };
          }
          if (
            response.data.content.bill_state_id != null &&
            response.data.content.bill_state_id != ""
          ) {
            self.billState = {
              value: response.data.content.bill_state_id,
              text: response.data.content.stateName
            };
          }
          if (
            response.data.content.bill_city_id != null &&
            response.data.content.bill_city_id != ""
          ) {
            self.billCity = {
              value: response.data.content.bill_city_id,
              text: response.data.content.cityName
            };
          }
          if (
            response.data.content.bill_post != null &&
            response.data.content.bill_post != ""
          ) {
            self.billPost = response.data.content.bill_post;
          }
          if (
            response.data.content.typ != null &&
            response.data.content.typ != ""
          ) {
            self.typ = response.data.content.typ;
          }
          if (
            response.data.content.trm_id != null &&
            response.data.content.trm_id != ""
          ) {
            self.trmId = response.data.content.trm_id;
          }
          if (
            response.data.content.excede_date_created != null &&
            response.data.content.excede_date_created != ""
          ) {
            self.excedeDateCreate = self.datePickerDateFormat(response.data.content.excede_date_created);
          }
          if (
            response.data.content.excede_date_update != null &&
            response.data.content.excede_date_update != ""
          ) {
            self.excedeDateUpdate = self.datePickerDateFormat(response.data.content.excede_date_update);
          }
          if (
            response.data.content.excede_date_invoice != null &&
            response.data.content.excede_date_invoice != ""
          ) {
            self.excedeDateInvoice = self.datePickerDateFormat(response.data.content.excede_date_invoice);
          }
          if (
            response.data.content.amt_invoice != null &&
            response.data.content.amt_invoice != ""
          ) {
            self.amtInvoice = response.data.content.amt_invoice;
          }
          if (
            response.data.content.excede_date_payment != null &&
            response.data.content.excede_date_payment != ""
          ) {
            self.excedeDatePayment = self.datePickerDateFormat(response.data.content.excede_date_payment);
          }
          if (
            response.data.content.amt_payment != null &&
            response.data.content.amt_payment != ""
          ) {
            self.amtPayment = response.data.content.amt_payment;
          }
          if (
            response.data.content.ven_misc1 != null &&
            response.data.content.ven_misc1 != ""
          ) {
            self.cusMisc1 = response.data.content.ven_misc1;
          }
          if (
            response.data.content.county != null &&
            response.data.content.county_id != null
          ) {
            self.customerCountyId = {
              name: response.data.content.county,
              value: response.data.content.county_id
            }
          }

          if (
            response.data.content.abn_no != null &&
            response.data.content.abn_no != ""
          ) {
            self.abn_no = response.data.content.abn_no;
          }

          self.is_excede = response.data.content.is_excede;
        })
        .catch(function(err) {
          self.catchHandler(err, function() {});
        });
    },
    checkForm: function() {
      var checked = true;
      /*if (self.contactId == null || !self.contactId.value) {
                    self.contactGroupError = "Please select contact group";
                    if (checked) {
                        checked = false;
                    }
                }*/
      /* if (!self.code) {
                    self.codeError = "Please enter code";
                    if (checked) {
                        checked = false;
                    }
                } */
      if (!self.abn_no && self.production_country == 'AU' && !self.is_excede) {
        self.anbNoError = "Please enter ABN";
        if (checked) {
          checked = false;
        }
      }
      if (!self.contact_name && !self.is_ind_buyer) {
          self.nameError = "Please enter name";
          if (checked) {
            checked = false;
          }
      }

      if (!self.firstName && self.is_ind_buyer) {
        self.firstNameError = "Please enter first name";
        if (checked) {
          checked = false;
        }
      }
      if (!self.primaryPhone) {
        self.primaryPhoneError = "Please enter primary phone";
        if (checked) {
          checked = false;
        }
      }
      /* if (!self.email) {
                    self.emailError = "Please enter email";
                    if (checked) {
                        checked = false;
                    }
                } else */ if (
        self.email &&
        !self.validEmail(self.email)
      ) {
        self.emailError = "Please enter valid email";
        if (checked) {
          checked = false;
        }
      }
      if (self.countryId == null || !self.countryId.value) {
        self.countryError = "Please select country";
        if (checked) {
          checked = false;
        }
      }
      if (self.stateId == null || !self.stateId.value) {
        self.stateError = "Please select state";
        if (checked) {
          checked = false;
        }
      }
      if (self.cityId == null || !self.cityId.value) {
        self.cityError = "Please select city";
        if (checked) {
          checked = false;
        }
      }
      if (self.manageBy == null || !self.manageBy.value) {
        self.manageByError = "Please enter managed by";
        if (checked) {
          checked = false;
        }
      }
      /* if (!self.name) {
                    self.nameError = "Please enter name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.shareType) {
                    self.shareTypeError = "Please enter share type";
                    if (checked) {
                        checked = false;
                    }
                }
                if (self.statusId == null || !self.statusId.value) {
                    self.statusError = "Please select status";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.title) {
                    self.titleError = "Please enter title";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.companyName) {
                    self.companyNameError = "Please enter company name";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.address) {
                    self.addressError = "Please enter address";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.zipcode) {
                    self.zipcodeError = "Please enter zipcode";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.extention) {
                    self.extentionError = "Please enter extention";
                    if (checked) {
                        checked = false;
                    }
                }
                if (!self.datePickerDates) {
                    self.birthDateError = "Please enter birth date";
                    if (checked) {
                        checked = false;
                    }
                } */
      self.amtInvoiceError = "";
      if (self.amtInvoice && !self.numberFormateValidation(self.amtInvoice)) {
        self.amtInvoiceError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      self.amtPaymentError = "";
      if (self.amtPayment && !self.numberFormateValidation(self.amtPayment)) {
        self.amtPaymentError = "Only Numeric Value Allow";
        if (checked) {
          checked = false;
        }
      }
      return checked;
    },
    addModel(flag) {
      setTimeout(() => {
        if (self.checkForm()) {
          var config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("api_token")
            }
          };
          var data = new FormData();
          var contactId = "";
          if (self.contactId == null || !self.contactId.value) {
            contactId = "";
          } else {
            contactId = self.contactId.value;
          }

         //Bill Customer Is Company, So Company Name set by Bill Customer Name
         if (self.contactId != null && self.contactId.text) {
            self.companyName = self.contactId.text;
          }
          
          // data.append("group_id", contactId);
          data.append("vvg_bill_cus_id", contactId);
          // data.append('code', self.code);
          
          data.append("contact_name", self.contact_name);
          data.append("is_ind_buyer", self.is_ind_buyer ? 1 : 0);

          data.append("first_name", self.firstName);
          data.append("last_name", self.lastName);
          data.append("job_title", self.jobTitle);
          data.append("company_name", self.companyName);
          data.append("primary_phone", self.primaryPhone);
          data.append("alternate_phone", self.alternatePhone);
          data.append("email", self.email);
          data.append("fax", self.fax);
          data.append("country_id", self.countryId.value);
          data.append("state_id", self.stateId.value);
          data.append("city_id", self.cityId.value);
          data.append("address", self.address);
          data.append("zip_code", self.zipcode);
          data.append("is_primary", self.isPrimary ? "yes" : "no");
          data.append("manage_by_id", self.manageBy.value);
          // data.append('status', self.statusId.value);
          data.append("status", self.statusId);
          /* data.append('contact_type', self.contactTypeId); */
          /* data.append('name', self.name);
                        data.append('share_type', self.shareType);
                        data.append('title', self.title);
                        data.append('region_id', ''); */
          data.append("address_2", self.address2);
          /* data.append('extention', self.extention);
                        data.append('cell_phone', self.cellPhone);
                        data.append('international_phone', self.internationalPhone);
                        data.append('birth_date', self.datePickerDates);
                        data.append('client_key', ''); */
          data.append("middle_name", self.middleName);
          data.append("excede_country", self.excedeCountry);
          data.append("excede_state", self.excedeState);
          data.append("excede_city", self.excedeCity);
          data.append("bill_address1", self.billAddress1);
          data.append("bill_address2", self.billAddress2);
          data.append("bill_country", self.billCountry.value);
          data.append("bill_state", self.billState.value);
          data.append("bill_city", self.billCity.value);
          data.append("bill_post", self.billPost);
          data.append("typ", self.typ);
          data.append("trm_id", self.trmId);
          data.append("excede_date_create", self.apiRequestDateFormat(self.excedeDateCreate));
          data.append("excede_date_update", self.apiRequestDateFormat(self.excedeDateUpdate));
          data.append("excede_date_invoice", self.apiRequestDateFormat(self.excedeDateInvoice));
          data.append("amt_invoice", self.amtInvoice);
          data.append("excede_date_payment", self.apiRequestDateFormat(self.excedeDatePayment));
          data.append("amt_payment", self.amtPayment);
          data.append("cus_misc1", self.cusMisc1);
          data.append("county_id", self.customerCountyId.value);
          data.append('county', self.customerCountyId.name);
          data.append("abn_no", self.abn_no);
          if (
            typeof self.$route.params.id != "undefined" &&
            self.$route.params.id != 0 &&
            self.$route.params.id != ""
          ) {
            data.append("id", self.$route.params.id);
            data.append("code", self.code);
            self.buttonNames.save = "Updating ...";
            self.disableButtons.save = true;
            self.disableButtons.cancel = true;
          } else {
            if (flag == "Save and Continue") {
              self.buttonNames.saveAndContinue = "Saving ...";
            } else {
              self.buttonNames.save = "Saving ...";
            }
            self.disableButtons.save = true;
            self.disableButtons.saveAndContinue = true;
            self.disableButtons.cancel = true;
          }
          HTTP.post("/contact/add/update", data, config)
            .then(function(response) {
              if (
                typeof self.$route.params.id != "undefined" &&
                self.$route.params.id != 0 &&
                self.$route.params.id != ""
              ) {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                if (self.$route.params.id != 0 && self.$route.params.id != "") {
                  self.buttonNames.save = "Update";
                } else {
                  if (flag == "Save and Continue") {
                    self.buttonNames.saveAndContinue = "Save and Continue";
                  } else {
                    self.buttonNames.save = "Save";
                  }
                }
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                } else {
                  self.buttonNames.save = "Save";
                }
              }
              if (response.data.status == "success") {
                self.disableButtons.save = true;
                self.disableButtons.saveAndContinue = true;
                self.disableButtons.cancel = true;
                self.showSAlert(response.data.message, "success", false, 3);
                if (flag == "Save and Continue") {
                  self.clearDataOnSaveAndContinue();
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                } else {
                  setTimeout(function() {
                    self.disableButtons.save = false;
                    self.disableButtons.saveAndContinue = false;
                    self.disableButtons.cancel = false;
                    self.buttonBack();
                  }, 3000);
                }
              } else {
                self.disableButtons.save = false;
                self.disableButtons.saveAndContinue = false;
                self.disableButtons.cancel = false;
                if (response.data.content.length > 0) {
                  var err = "";
                  for (var i = 0; i < response.data.content.length; i++) {
                    err += response.data.content[i] + "<br/>";
                  }
                  self.showSAlert(err, "error", false);
                }
              }
            })
            .catch(function(err) {
              if (self.$route.params.id != 0 && self.$route.params.id != "") {
                self.buttonNames.save = "Update";
                self.disableButtons.save = false;
                self.disableButtons.cancel = false;
              } else {
                if (flag == "Save and Continue") {
                  self.buttonNames.saveAndContinue = "Save and Continue";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                  self.clearDataOnSaveAndContinue();
                } else {
                  self.buttonNames.save = "Save";
                  self.disableButtons.save = false;
                  self.disableButtons.saveAndContinue = false;
                  self.disableButtons.cancel = false;
                }
              }
              self.catchHandler(err, function() {});
            });
        }
      }, 200);
    },

    //Search For county
    onSearchCountyCode(search, loading){
      if (search.length > 1){
        loading(true);
        self.loadAllData(
          ["TaxRate"],
          true,
          self.loadCountyCode,
          loading,
          search
        );
      }
    },
    loadCountyCode : function(callbackData){
      self.isCountyCodeNotFound = false;
      var taxRates = callbackData.TaxRate;
      self.countyCodeArr = [];
      taxRates.forEach(function(taxRate){
        self.countyCodeArr.push({
          name: taxRate.name,
          value: taxRate.id,
          text: taxRate.code,
        });
      });
      if  (self.countyCodeArr.length === 0){
        self.isCountyCodeNotFound = true;
      }
    },
  }
};
EventBus.$on("login-permissions", function(permissions) {
  if (typeof self != "undefined") {
    self.permissionNameArray = [];
    for (let i = 0; i < permissions.length; i++) {
      self.permissionNameArray.push(permissions[i].permission_name);
    }
  }
});
</script>
<style scoped>
.input-with-icon {
  position: relative;
}
.input-with-icon a {
    position: absolute;
    right: -6px;
    top: 8px;
    color: #aaaaaa;
}
</style>