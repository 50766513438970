<template>
    <!-- Page Content Wrapper -->
    <div >
        <template v-if="permissionNameArray.includes('can_add_inventory_master')">
            <!--Forms-->
            <div :class="alertClass" id="success-alert">
                <strong> {{alertMessage}} </strong>
            </div>
            <div class="col-12 paddingnone">
                <div class="forms-container add_user_forms_container vtc-dealer-forms">
                    <div class="table-listing white-bg border-radius-top-right">
                        <table class="table custom-table jsmartable">
                            <thead>
                                <tr>
                                    <!-- <th class="">{{staticInputLabelMessages.id_label}}</th> -->
                                    <th class="">{{staticInputLabelMessages.vin_no_label}}</th>
                                    <th class="">{{staticInputLabelMessages.condition_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('conditionIdInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.is_truck_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('typeInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.make_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('makeIdInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.model_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('modelIdInput')"></i></th>
                                    <!-- <th class="">{{staticInputLabelMessages.classification_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('classificationInput')"></i></th> -->
                                    <th class="">{{staticInputLabelMessages.fleet_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('fleetInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.visibility_type_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('visibilityTypeIdInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.company_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('companyIdInput')"></i></th>
                                    <th class="">{{staticInputLabelMessages.location_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('locationIdInput')"></i></th>
                                    <!-- <th class="">{{staticInputLabelMessages.status_label}}<i class="copy_to_all_icon fa fa-arrow-down" :title="staticMessages.copy_to_all" aria-hidden="true" v-on:click.prevent="copyToAll('statusIdInput')"></i></th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(asset, index) in asset.content" :key="index">
                                    <!-- <td class="">
                                        <input id="assetIdInput" v-model="assetIdInput[asset.asset_id]" class="form-control" type="hidden"/>
                                        <span class="form-control">{{asset.asset_id}}</span>
                                    </td> -->
                                    <td class="">
                                        <span class="form-control" id="vinNoInput">{{vinNoInput[asset.asset_id]}}</span>
                                        <!-- <input style="min-width: 190px;" id="vinNoInput" @input="vinNoErrorMsg[asset.asset_id] = ''" v-model="vinNoInput[asset.asset_id]" class="form-control" type="text"/> -->
                                        <span class="help is-danger">{{vinNoErrorMsg[asset.asset_id]}}</span>
                                        <!--{{asset.vin_no}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 165px;" v-model="conditionIdInput[asset.asset_id]"
                                                  @input="conditionIdError[asset.asset_id] = ''"
                                                  name="conditionIdInput"
                                                  id="conditionIdInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="conditionArr"
                                                  :placeholder="staticMessages.condition_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{conditionIdError[asset.asset_id]}}</span>
                                        <!--{{asset.condition_id}} {{asset.condition_name}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 165px;" v-model="typeInput[asset.asset_id]"
                                                  @input="typeInputError[asset.asset_id] = ''"
                                                  name="typeInput"
                                                  id="typeInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="typeArr"
                                                  :placeholder="staticMessages.condition_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{typeInputError[asset.asset_id]}}</span>
                                        <!--{{asset.condition_id}} {{asset.condition_name}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 190px;" v-model="makeIdInput[asset.asset_id]"
                                                  @input="makeChangeInventory(asset.asset_id, makeIdInput[asset.asset_id].value, index)"
                                                  name="makeIdInput"
                                                  id="makeIdInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="makeArr"
                                                  :placeholder="staticMessages.make_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{makeIdError[asset.asset_id]}}</span>
                                        <!--{{asset.make_id}} {{asset.make_name}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 165px;" 
                                                  :key="'AssetId ' + modelIdInput[asset.asset_id]"
                                                  v-model="modelIdInput[asset.asset_id]"
                                                  @input="modelIdError[asset.asset_id] = ''"
                                                  name="modelIdInput"
                                                  id="modelIdInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="multiEditModelArr[asset.asset_id]"
                                                  :placeholder="staticMessages.model_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{modelIdError[asset.asset_id]}}</span>
                                        <!--{{asset.mode_id}} {{asset.model_name}}-->
                                    </td>
                                    <!-- <td class="">
                                        <input style="min-width: 165px;" id="classificationInput" @input="classificationErrorMsg[asset.asset_id] = ''" v-model="classificationInput[asset.asset_id]" class="form-control" type="text"/>
                                        <span class="help is-danger">{{classificationErrorMsg[asset.asset_id]}}</span>
                                    </td> -->
                                    <td class="">
                                        <v-select style="min-width: 120px;" v-model="fleetInput[asset.asset_id]"
                                                  @input="fleetInputError[asset.asset_id] = ''"
                                                  name="fleetInput"
                                                  id="fleetInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="fleetArr"
                                                  :placeholder="staticMessages.visibility_type_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{fleetInputError[asset.asset_id]}}</span>
                                        <!--{{asset.visibility_type}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 120px;" v-model="visibilityTypeIdInput[asset.asset_id]"
                                                  @input="visibilityTypeIdError[asset.asset_id] = ''"
                                                  name="visibilityTypeIdInput"
                                                  id="visibilityTypeIdInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="visibilityTypeArr"
                                                  :placeholder="staticMessages.visibility_type_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{visibilityTypeIdError[asset.asset_id]}}</span>
                                        <!--{{asset.visibility_type}}-->
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 120px;"
                                            v-model="companyIdInput[asset.asset_id]"
                                            @input="companyChangeInventory(asset.asset_id, companyIdInput[asset.asset_id].value, index)"
                                            name="companyIdInput"
                                            id="companyIdInput"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="companyArr"
                                            :placeholder="staticMessages.department_place_holder">
                                        </v-select>
                                        <span class="help is-danger">{{companyIdError[asset.asset_id]}}</span>
                                    </td>
                                    <td class="">
                                        <v-select style="min-width: 120px;"
                                            :key="'AssetId ' + locationIdInput[asset.asset_id]"
                                            v-model="locationIdInput[asset.asset_id]"
                                            @input="locationIdError[asset.asset_id] = ''"
                                            name="locationIdInput"
                                            id="locationIdInput"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            :filterable="false"
                                            :options="multiEditLocationArr[asset.asset_id]"
                                            :placeholder="staticMessages.location_place_holder">
                                    </v-select>
                                        <span class="help is-danger">{{locationIdError[asset.asset_id]}}</span>
                                    </td>
                                    <!-- <td class="">
                                        <v-select style="min-width: 120px;" v-model="statusIdInput[asset.asset_id]"
                                                  @input="statusIdError[asset.asset_id] = ''"
                                                  name="statusIdInput"
                                                  id="statusIdInput"
                                                  label="text"
                                                  :clearable="false"
                                                  :searchable="false"
                                                  :filterable="false"
                                                  :options="statusArr"
                                                  :placeholder="staticMessages.status_place_holder"
                                                  >
                                        </v-select>
                                        <span class="help is-danger">{{statusIdError[asset.asset_id]}}</span>
                                        <!--{{asset.status_id}} {{asset.status_name}}-->
                                    <!-- </td> -->
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="button-demo">
                        <input id="addAssetBtn" :disabled="disableButtons.save" type="submit" :value="buttonNames.save" class="btn btn-primary" v-on:click.prevent="addAsset()">
                        <input id="cancelAsset" :disabled="disableButtons.cancel" type="submit" :value="buttonNames.cancel" class="btn btn-primary" v-on:click.prevent="buttonBack()">
                    </div>
                </div>
            </div>
            <!-- Forms -->
        </template>
        <template v-else>
            <no-access></no-access>
        </template>
    </div>
    <!-- Page Content Wrapper -->
</template>

<script>
    var self;
    import Vue from "vue";
    import Footer from "@/components/partials/Footer";
    import {commonMixins} from '../../../mixins/commonMixins';
    import EventBus from "../../event-bus";
    import {HTTP} from '../../../http-common';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NoAccessPage from "../../NoAccessPage/NoAccessPage";
    Vue.component("app-footer", Footer);

    export default {
        name: 'AddUpdateAsset',
        components: {
            datePicker,
            'vueDropzone': vue2Dropzone,
            'no-access': NoAccessPage,
        },
        mixins: [commonMixins],
        data() {
            return {
                staticMessages: "",
                staticInputLabelMessages: "",
                assetIdInput: [],
                vinNoInput: [],
                vinNoErrorMsg: [],
                conditionIdInput: [],
                conditionIdError: [],
                typeInput: [],
                typeInputError: [],
                makeIdInput: [],
                makeIdError: [],
                modelIdInput: [],
                multiEditModelArr: [],
                modelIdError: [],
                classificationInput: [],
                classificationErrorMsg: [],
                fleetInput: [],
                fleetInputError: [],
                fleetArr: [],
                visibilityTypeIdInput: [],
                visibilityTypeIdError: [],
                locationArr: [],
                locationIdInput: [],
                multiEditLocationArr: [],
                locationIdError: [],
                companyIdInput: [],
                companyIdError: [],
                statusIdInput: [],
                statusIdError: [],
                cityArr: [],
                cityError: "",
                state: "",
                stateArr: [],
                stateError: "",
                companyArr: [],
                companyId: "",
                companyError: "",
                quantity: "",
                quantityError: "",
                lotLocation: "",
                lotLocationError: "",
                statusArr: [],
                statusId: "",
                statusError: "",
                acquireOnDate: "",
                orderDateError: "",
                acquireOnDateError: "",
                visibilityTypeId: "",
                visibilityTypeArr: [{text: "Private", value: "private"}, {text: "Public", value: "public"}],
                visibilityTypeError: "",
                saleTypeId: "",
                saleTypeArr: [],
                saleTypeError: "",
                isFeaturedId: "",
                isFeaturedArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                isFeaturedError: "",
                orderNo: "",
                orderNoError: "",
                orderDate: "",
                orderDateError: "",
                pricePaid: "",
                pricePaidError: "",
                location: "",
                locationArr: [],
                locationError: "",
                descrition: "",
                descriptionError: "",
                website: "",
                websiteError: "",
                titleInId: "",
                titleInArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                titleInError: "",
                privateComment: "",
                privateCommentError: "",
                titleOutDate: "",
                titleOutDateError: "",

                availableDate: "",
                availableDateError: "",
                featuredText: "",
                featuredTextError: "",
                specialFeature: "",
                specialFeatureError: "",
                specialExpirationDate: "",
                specialExpirationDateError: "",
                year: "",
                yearError: "",
                makeId: "",
                makeArr: [],
                makeError: "",
                modelId: "",
                modelArr: [],
                modelError: "",
                yupuTubeVideoUrl: "",
                yupuTubeVideoUrlError: "",
                warrantyTypeId: "",
                warrantyTypeArr: [],
                warrantyTypeError: "",
                vinNo: "",
                vinNoError: "", classification: "",
                classificationError: "",
                conditionId: "",
                conditionArr: [],
                typeArr: [{text: "Bus", value: "bus"}, {text: "Truck", value: "truck"}, {text: "Trailer", value: "trailer"}],
                conditionError: '',
                askingRetailUsd: "",
                askingRetailUsdError: "",
                minRetailUsd: "",
                minRetailUsdError: "",
                highRetailUsd: "",
                highRetailUsdError: "",
                showPriceOnlineId: "",
                showPriceOnlineArr: [{text: "Yes", value: "yes"}, {text: "No", value: "no"}],
                showPriceOnlineError: "",
                wholeShaleUsd: "",
                wholeShaleUsdError: "",

                options: {
                    format: process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT,
                    useCurrent: false,
                    allowInputToggle: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                },
                buttonNames: {
                    save: 'Save',
                    saveAndContinue: 'Save And Continue',
                    cancel: 'Cancel'
                },
                disableButtons: {
                    save: false,
                    cancel: false
                },
                alertClass: '',
                alertMessage: '',
                pageTitle: "Add Inventory",

                activetab: 1,

                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 250, //MB
                    dictFileTooBig: "File is too big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                loadDocArr: [],
                isAddFrom: false,
                permissionNameArray: ['can_add_inventory_master'],
                isMountedCallEnd: false,
            }
        },
        beforeMount() {
            self = this;
        },
        props: {
            asset: {
                type: Object,
                required: false,
                default: undefined
            }
        },
        mounted: function () {
            /* Redirect to login page logic start */
            var userObj = localStorage.getItem("user_obj");
            if (userObj == null) {
                self.$router.push('/');
            }
            /* Redirect to login page logic end */
            self.staticMessages = self.allStaticMessages;
            self.staticInputLabelMessages = self.allLabelMessages;
            self.setActiveMenu('asset-list');
            EventBus.$emit("menu-selection-changed", "asset-list");
            
            $("#alertError").hide();
            self.getLoginIdPermissions();

            self.isAddFrom = false;
            self.buttonNames.save = "Update";
            $("#addAndContinueAssetBtn").hide();
            self.pageTitle = "Edit Inventory";


                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        self.assetIdInput[self.asset.content[i].asset_id] = self.asset.content[i].asset_id;
                        // self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin_no;
                        self.vinNoInput[self.asset.content[i].asset_id] = self.asset.content[i].vin;
                        self.conditionIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].condition_id != null && typeof self.asset.content[i].condition_id != "undefined" && self.asset.content[i].condition_id != '') {
                            self.conditionIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].condition_name), value: self.asset.content[i].condition_id};
                        }
                        self.companyIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].company_id != null && typeof self.asset.content[i].company_id != "undefined" && self.asset.content[i].company_id != '') {
                            self.companyIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].company_name), value: self.asset.content[i].company_id};
                        }
                        self.fleetInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].fleet_id != null && typeof self.asset.content[i].fleet_id != "undefined" && self.asset.content[i].fleet_id != '') {
                            self.fleetInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].fleet_name, value: self.asset.content[i].fleet_id};
                        }
                        self.locationIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].location_id != null && typeof self.asset.content[i].location_id != "undefined" && self.asset.content[i].location_id != '') {
                            self.locationIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].location_name), value: self.asset.content[i].location_id};
                        }
                        self.makeIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].make_id != null && typeof self.asset.content[i].make_id != "undefined" && self.asset.content[i].make_id != '') {
                            self.makeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].make_name + " " + self.asset.content[i].is_truck), value: self.asset.content[i].make_id};
                        }
                        self.modelIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].mode_id != null && typeof self.asset.content[i].mode_id != "undefined" && self.asset.content[i].mode_id != '') {
                            self.modelIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].model_name + " " + self.asset.content[i].is_truck + " ("  + self.asset.content[i].make_name + ")"), value: self.asset.content[i].mode_id};
                        }
                        self.classificationInput[self.asset.content[i].asset_id] = self.asset.content[i].classification;
                        if (self.asset.content[i].visibility_type == "public") {
                            self.visibilityTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].visibility_type), value: self.asset.content[i].visibility_type};
                        } else {
                            self.visibilityTypeIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].visibility_type), value: self.asset.content[i].visibility_type};
                        }
                        self.statusIdInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].status_id != null && typeof self.asset.content[i].status_id != "undefined" && self.asset.content[i].status_id != '') {
                            self.statusIdInput[self.asset.content[i].asset_id] = {text: self.capitalizeFirstLetter(self.asset.content[i].status_name), value: self.asset.content[i].status_id};
                        }
                        self.typeInput[self.asset.content[i].asset_id] = {};
                        if (self.asset.content[i].is_truck != null && typeof self.asset.content[i].is_truck != "undefined" && self.asset.content[i].is_truck != '') {
                            self.typeInput[self.asset.content[i].asset_id] = {text: self.asset.content[i].is_truck, value: self.asset.content[i].is_truck.toLowerCase()};
                        }
                    }
                });
            /* $(".form-control").on('focus', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": $(".table-listing").height() + $(".dropdown.v-select .dropdown-menu").height() + 4 + ".px"});
                }, 200);
            });
            $(".form-control").on('focusout', function(event){
                setTimeout(function () {
                    $(".table-listing").css({"height": 'auto'});
                }, 200);
            }); */
            self.loadAllData(['Location'], false, self.loadLocationDataCallback, "undefined", '', 0, '', 1);
            self.loadAllData(['Model'], false, self.loadModelDataCallback, "undefined", '', 0, '', 1);
            self.loadAllData(['Make', 'Condition', 'SaleType', 'WarrantyType', 'Company', 'Status','Fleet'], false, self.loadMasterDataCallback, "undefined", '');
            /* self.loadAllData('Make', false, self.loadMakeDataCallback, "undefined", '');
            self.loadAllData('Condition', false, self.loadConditionCallback, "undefined", '');
            self.loadAllData('SaleType', false, self.loadSaleTypeCallback, "undefined", '');
            self.loadAllData('WarrantyType', false, self.loadWarrantyTypeCallback, "undefined", '');
            self.loadAllData('Company', false, self.loadCompanyCallback, "undefined", ''); */
            /* self.loadAllData('Status', false, self.loadStatusCallback, "undefined", ''); */
            self.$nextTick(function () {
                self.isMountedCallEnd = true;
                $(".table-listing").height(($(".table-listing").height() + 124));
            });

        },
        methods: {
            makeChangeInventory: function(inventoryId, makeId, assetIndex) {
                self.makeIdError[inventoryId] = '';
                self.modelIdInput[inventoryId] = {};

                self.multiEditModelArr[inventoryId] = [];
                // self.loadAllData('Model', false, self.loadModelDataCallback, "undefined", '', makeId);
                var tempModelId = self.asset.content[assetIndex].mode_id;
                self.$nextTick(function () {
                    // setTimeout(function() { //UN-COMMENT WHILE USING API BASED LOGIC
                        if(self.modelArr && self.modelArr.length > 0) {
                            for (let i in self.modelArr) {
                                var obj = self.modelArr[i];
                                if(makeId == obj.makeId) {
                                    self.multiEditModelArr[inventoryId].push(obj);
                                }
                                /* if(obj.value == tempModelId) {
                                    // self.modelIdInput[inventoryId] = {text: self.capitalizeFirstLetter(self.asset.content[assetIndex].model_name + " " + self.asset.content[assetIndex].is_truck), value: self.asset.content[assetIndex].mode_id};
                                    // return false;
                                } */
                            }
                        }
                    // }, 500);
                });
            },
            companyChangeInventory: function(inventoryId, companyId, assetIndex) {
                self.companyIdError[inventoryId] = '';
                self.locationIdInput[inventoryId] = {};

                self.multiEditLocationArr[inventoryId] = [];
                // self.loadAllData('Location', false, self.loadLocationDataCallback, "undefined", '', companyId);
                var tempLocationId = self.asset.content[assetIndex].location_id;
                self.$nextTick(function () {
                    // setTimeout(function() { //UN-COMMENT WHILE USING API BASED LOGIC
                        if(self.locationArr && self.locationArr.length > 0) {
                            for (let i in self.locationArr) {
                                var obj = self.locationArr[i];
                                if(companyId == obj.companyId) {
                                    self.multiEditLocationArr[inventoryId].push(obj);
                                }
                                /* if(obj.value == tempLocationId) {
                                    // self.locationIdInput[inventoryId] = {text: self.capitalizeFirstLetter(self.asset.content[assetIndex].location_name), value: self.asset.content[assetIndex].location_id};
                                    // return false;
                                } */
                            }
                        }
                    // }, 500);
                });
            },
            returnData: function () {
                var data = [];
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        var temp = {
                            'id' : self.assetIdInput[self.asset.content[i].asset_id],
                            'vin' : self.vinNoInput[self.asset.content[i].asset_id],
                            'condition_id' : self.conditionIdInput[self.asset.content[i].asset_id].value,
                            'inv_type' : self.typeInput[self.asset.content[i].asset_id].value,
                            'make_id' : self.makeIdInput[self.asset.content[i].asset_id].value,
                            'model_id' : self.modelIdInput[self.asset.content[i].asset_id].value,
                            'classification' : self.classificationInput[self.asset.content[i].asset_id],
                            'visibility_type' : self.visibilityTypeIdInput[self.asset.content[i].asset_id].value,
                            'status_id' : self.statusIdInput[self.asset.content[i].asset_id].value,
                            'location_id' : self.locationIdInput[self.asset.content[i].asset_id].value,
                            'company_id' : self.companyIdInput[self.asset.content[i].asset_id].value,
                            'fleet_id' : self.fleetInput[self.asset.content[i].asset_id].value,

                        };
                        data.push(temp);
                    }
                });
                var result = {
                    'data':data
                };
                /*console.log(result);*/
                return result;
            },

            getFileType: function (file_name) {

                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {

                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];

                self.imagesPaths.push(String(orgPath + "/" + file_name));

                return file_name;
            },
            removeAllFiles: function () {
                this.$refs.dropzone.removeAllFiles();
            },
            changeSpecialExpirationDate: function () {
                self.specialExpirationError = "";
            },
            changedValue: function (value) {
                /*console.log(value);*/
            },
            loadLocationDataCallback: function (callbackData) {
                self.locationArr = callbackData.Location;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].location_id != null && typeof self.asset.content[i].location_id != "undefined" && self.asset.content[i].location_id != '') {
                            self.multiEditLocationArr[self.asset.content[i].asset_id] = [];
                            $.each(self.locationArr, function (j) {
                                if(self.asset.content[i].company_id == self.locationArr[j].companyId) {
                                    self.multiEditLocationArr[self.asset.content[i].asset_id].push(self.locationArr[j]);
                                }
                                // self.multiEditLocationArr[self.asset.content[i].asset_id] = self.locationArr;
                            });
                        }
                    }
                });
            },
            loadModelDataCallback: function (callbackData) {
                self.modelArr = callbackData.Model;
                $.each(self.asset.content, function (i) {
                    if (self.asset.content[i].asset_id != null) {
                        if (self.asset.content[i].mode_id != null && typeof self.asset.content[i].mode_id != "undefined" && self.asset.content[i].mode_id != '') {
                            self.multiEditModelArr[self.asset.content[i].asset_id] = [];
                            $.each(self.modelArr, function (j) {
                                if(self.asset.content[i].make_id == self.modelArr[j].makeId) {
                                    self.multiEditModelArr[self.asset.content[i].asset_id].push(self.modelArr[j]);
                                }
                                // self.multiEditModelArr[self.asset.content[i].asset_id] = self.modelArr;
                            });
                        }
                    }
                });
            },
            loadMasterDataCallback: function (callbackData) {
                self.makeArr = callbackData.Make;
                self.conditionArr = callbackData.Condition;
                self.saleTypeArr = callbackData.SaleType;
                self.warrantyTypeArr = callbackData.WarrantyType;
                self.companyArr = callbackData.Company;
                self.fleetArr = callbackData.Fleet;
                self.statusArr = callbackData.Status;
            },
            /* loadMakeDataCallback: function (callbackData) {
                self.makeArr = callbackData.Make;
            },
            loadConditionCallback: function (callbackData) {
                self.conditionArr = callbackData.Condition;
            },
            loadSaleTypeCallback: function (callbackData) {
                self.saleTypeArr = callbackData.SaleType;
            },
            loadWarrantyTypeCallback: function (callbackData) {
                self.warrantyTypeArr = callbackData.WarrantyType;
            },
            loadCompanyCallback: function (callbackData) {
                self.companyArr = callbackData.Company;
            },
            loadStatusCallback: function (callbackData) {
                self.statusArr = callbackData.Status;
            }, */
            changeAvailableDate: function () {
                self.availableDateError = "";
            },
            changeTitleOutDate: function () {
                self.titleOutDateError = "";
            },
            changeAvailableDateDate: function () {
                self.availableDateError = "";
            },
            changeTitleIn: function () {

            },
            changeAcuireDate: function () {
                self.acquireOnDateError = "";
            },
            chageOrderDate: function () {
                self.orderDateError = "";
            },
            changedValueLocation: function () {

            },
            changeIsTruck: function () {

            },
            onSearchState(search, loading) {
                if (search.length > 1) {
                    loading(true);
                    self.loadAllData(['States'], true, self.loadStateDataCallback, loading, search);
                }
            },
            loadStateDataCallback: function (callbackData) {
                self.stateArr = callbackData.States;
            },
            loadCityDataCallback: function (callbackData) {
                self.cityArr = callbackData.Cities;
            },
            onSearchCity(search, loading) {
                if (self.state == null || !self.state.value) {
                    self.showSAlert('Please Select State First!', 'info', false);
                } else {
                    if (search.length > 2) {
                        loading(true);
                        self.loadAllData(['Cities'], true, self.loadCityDataCallback, loading, search, self.state.value);
                    }
                }
            },
            onSearchCompany(search, loading) {
                if (search.length > 2) {
                    loading(true);
                    self.loadAllData(['Company'], true, self.loadCompanyDataCallback, loading, search);
                }
            },
            loadCompanyDataCallback: function (callbackData) {
                self.companyArr = callbackData.Company;
            },
            buttonBack() {
                self.$router.push({name: "ListAsset"});
            },
            copyToAll(copyfor){
                var j = 0;
                var firstvalue = "";
                switch (copyfor) {
                    case 'conditionIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.conditionIdInput[self.asset.content[i].asset_id];
                                    self.conditionIdInput = [];
                                }
                                self.conditionIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'typeInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.typeInput[self.asset.content[i].asset_id];
                                    self.typeInput = [];
                                }
                                self.typeInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'makeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.makeIdInput[self.asset.content[i].asset_id];
                                    self.makeIdInput = [];
                                }
                                self.makeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'modelIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.modelIdInput[self.asset.content[i].asset_id];
                                    self.modelIdInput = [];
                                }
                                self.modelIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'classificationInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.classificationInput[self.asset.content[i].asset_id];
                                    self.classificationInput = [];
                                }
                                self.classificationInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'fleetInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.fleetInput[self.asset.content[i].asset_id];
                                    self.fleetInput = [];
                                }
                                self.fleetInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'visibilityTypeIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.visibilityTypeIdInput[self.asset.content[i].asset_id];
                                    self.visibilityTypeIdInput = [];
                                }
                                self.visibilityTypeIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'companyIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.companyIdInput[self.asset.content[i].asset_id];
                                    self.companyIdInput = [];
                                }
                                self.companyIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'locationIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.locationIdInput[self.asset.content[i].asset_id];
                                    self.locationIdInput = [];
                                }
                                self.locationIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                    case 'statusIdInput':
                        $.each(self.asset.content, function (i) {
                            if (self.asset.content[i].asset_id != null) {
                                if(j == 0){
                                    firstvalue = self.statusIdInput[self.asset.content[i].asset_id];
                                    self.statusIdInput = [];
                                }
                                self.statusIdInput[self.asset.content[i].asset_id] = firstvalue;
                                j++;
                            }
                        });
                        break;
                }
            },
            clearDataOnSaveAndContinue: function () {
                self.name = '';
            },
            showAlertOnSave: function (alertMessage, alertType) {
                if (alertType === "success") {
                    self.alertClass = "alert alert-success";
                } else {
                    self.alertClass = "alert alert-danger";
                }
                self.alertMessage = alertMessage;
                $("#success-alert").fadeTo(3000, 500).slideUp(500, function () {
                    $("#success-alert").slideUp(500);
                });
            },

            checkForm: function () {
                var checked = true;
                $.each(self.assetIdInput, function (i) {
                    if (typeof(self.assetIdInput[i])  == 'undefined' || self.assetIdInput[i] == null || self.assetIdInput[i] == '') {
                    } else {
                        if(self.assetIdInput[i] != null){
                            /*console.log(self.assetIdInput[i]);*/
                            /*console.log(self.vinNoInput[i]);*/
                            if (!self.vinNoInput[i]) {
                                Vue.set(self.vinNoErrorMsg, i, "Please select vin no.");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            /*console.log(self.conditionIdInput[i]);*/
                            /*if (typeof(self.conditionIdInput[i].value)  == 'undefined' || self.conditionIdInput[i] == null || self.conditionIdInput[i].value == '') {
                                Vue.set(self.conditionIdError, i, "Please select condition");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*console.log(self.makeIdInput[i]);*/
                            if (typeof(self.makeIdInput[i].value)  == 'undefined' || self.makeIdInput[i] == null || self.makeIdInput[i].value == '') {
                                Vue.set(self.makeIdError, i, "Please select make");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            if (typeof(self.locationIdInput[i].value)  == 'undefined' || self.locationIdInput[i] == null || self.locationIdInput[i].value == '') {
                                Vue.set(self.locationIdError, i, "Please select location");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            /*console.log(self.modelIdInput[i]);*/
                            if (typeof(self.modelIdInput[i].value)  == 'undefined' || self.modelIdInput[i] == null || self.modelIdInput[i].value == '') {
                                Vue.set(self.modelIdError, i, "Please select model");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            /*console.log(self.classificationInput[i]);*/
                            /*if (!self.classificationInput[i]) {
                                Vue.set(self.classificationErrorMsg, i, "Please select classification");
                                if (checked) {
                                    checked = false;
                                }
                            }*/
                            /*console.log(self.visibilityTypeIdInput[i]);*/
                            if (typeof(self.visibilityTypeIdInput[i].value)  == 'undefined' || self.visibilityTypeIdInput[i] == null || self.visibilityTypeIdInput[i].value == '') {
                                Vue.set(self.visibilityTypeIdError, i, "Please select visibility type");
                                if (checked) {
                                    checked = false;
                                }
                            }
                            /*console.log(self.statusIdInput[i]);*/
                            if (typeof(self.statusIdInput[i].value)  == 'undefined' || self.statusIdInput[i] == null || self.statusIdInput[i].value == '') {
                                Vue.set(self.statusIdError, i, "Please select status");
                                if (checked) {
                                    checked = false;
                                }
                            }
                        }
                    }
                });
                return checked;
            },
            addAsset(flag) {
                setTimeout(() => {
                    if (self.checkForm()) {
                        var config = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("api_token")
                            }
                        };
                        var data = self.returnData();
                        self.buttonNames.save = "Updating ...";
                        self.disableButtons.save = true;
                        self.disableButtons.cancel = true;
                        HTTP.post("/asset/edit/core", data, config)
                                .then(function (response) {
                                    self.disableButtons.save = true;
                                    self.disableButtons.cancel = true;
                                    self.buttonNames.save = "Update";
                                    if (response.data.status == "success") {
                                        EventBus.$emit("update-multi-edit-tab-view-data", 1);
                                        self.disableButtons.save = true;
                                        self.disableButtons.cancel = true;
                                        self.showSAlert(response.data.message, 'success', false, 3);
                                        if (flag == "Save and Continue") {
                                            self.clearDataOnSaveAndContinue();
                                            self.disableButtons.save = false;
                                            self.disableButtons.cancel = false;
                                        } else {
                                            setTimeout(function () {
                                                self.disableButtons.save = false;
                                                self.disableButtons.cancel = false;
                                                // self.buttonBack();
                                            }, 3000);
                                        }
                                    } else {
                                        self.disableButtons.save = false;
                                        self.disableButtons.cancel = false;
                                        if (response.data.content.length > 0) {
                                            var err = "";
                                            for (var i = 0; i < response.data.content.length; i++) {
                                                err += response.data.content[i] + "<br/>";
                                            }
                                            self.showSAlert(err, 'error', false);
                                        }
                                    }
                                })
                                .catch(function (err) {
                                    self.buttonNames.save = "Update";
                                    self.disableButtons.save = false;
                                    self.disableButtons.cancel = false;

                                    self.catchHandler(err, function () {});
                                });
                    }
                }, 200);

            },
        }
    }
    EventBus.$on("login-permissions", function (permissions) {
        if (typeof self != "undefined") {
            self.permissionNameArray = [];
            for (let i = 0; i < permissions.length; i++) {
                self.permissionNameArray.push(permissions[i].permission_name)
            }
        }
    });
</script>