<template>
    <div id="page-content-wrapper" class="admin-content-area">
      <template v-if="permissionNameArray.includes('can_list_opportunity_master')">
        <div class="container-fluid xyz">
          <div class="spaced-container">
            <div class="col-12 page_title paddingnone">
              <div class="page_title_left">
                <span class="pl-0">Opportunity</span>
                <p v-if="totalRecords > 0">{{ totalRecords }} Results found</p>
              </div>
              <a v-show="permissionNameArray.includes('can_add_opportunity_master')"
                 class="btn btn-primary waves-effect pull-right ml-2" v-on:click="openAddOpportunityPopup()">
                <span>Add Opportunity</span>
              </a>
  
              <a
                id='refreshList'
                title='Refresh'
                class="extraFeature btn btn-primary waves-effect pull-right ml-2"
                v-on:click.prevent="refreshList()">
                <i class="fa fa-undo" aria-hidden="true"></i>
              </a>
            </div>
            <filter-component :key="filterCount" :filterCounts="filterCount" :parentHeaders="headers"
                              @applyFilterParentComponent="applyFilterParentComponent"
                              @resetSorting="resetSorting"></filter-component>
            <div class="col-12 paddingnone list-common-actions" v-if="selectedRows.length >= 1">
              <span class="color-cc9636">{{ selectedRows.length }} rows selected <a class="color-098de1"
                                                                                    v-on:click.prevent="clearSelection()">X</a></span>
            </div>
            <div class="col-12 paddingnone">
              <common-loader
              refId="commonLoaderListOpportunity"
              ref="commonLoaderListOpportunity"
              >
              </common-loader>
              <!-- Pagination Start -->
              <div class="top_pagination vgt-wrap__footer crm">
                <div class="row">
                  <div class="col-sm-12 col-md-2 PerPage">
                    <v-select title="Select Per Page"
                              v-model="perPage"
                              @input="changePerPage"
                              name="customPerPage"
                              id="customPerPage"
                              label="text"
                              :clearable="false"
                              :searchable="false"
                              :filterable="false"
                              :options="paginationDropDownArr"
                              placeholder="Select Per Page">
                    </v-select>
                  </div>
                  <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                    <p class="pageRowCount">
                      {{ startEndRecord }} of {{ totalRecords }}
                    </p>
                  </div>
  
                  <div class="col-sm-12 col-md-6 Pagination">
                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous"
                                  next-text="Next ›" :total-rows="totalRecords" v-model="currentPage"
                                  @input="changepage()" :per-page="perPageValue"></b-pagination>
                  </div>
  
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <p class="pageJumpLabel">Page : </p>
                  </div>
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage"
                           id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                  </div>
                </div>
              </div>
              <!-- Pagination End -->
              <div class="table-listing white-bg border-blue border-radius-top-right table-scrollable">
                  <table class="table custom-table jsmartable">
                      <tbody>
                      <template v-if="opportunityArr && opportunityArr.length > 0">
                          <template  v-for="(opportunity, index) in opportunityArr">
                              <tr :key="index">
                                  <td class="">
                                      <div class="custom-control custom-checkbox">
                                          <input type="checkbox" v-model="selectedRows" :value="opportunity.id" @change="inputCheckbox($event)" class="custom-control-input" :id="'customCheck' + opportunity.id" name="case[]">
                                          <label class="custom-control-label" :for="'customCheck' + opportunity.id"></label>
                                      </div>
                                  </td>
                                  <td v-show="actionDisplay">
                                      <div class="actions">
                                        <router-link v-show="permissionNameArray.includes('can_add_opportunity_master')"
                                                    class="mar-right-0" title='Edit Opportunity'
                                                    v-bind:to="{name: 'OpportunityTab', params:{id: opportunity.id, type: 'UpdateContact', customer_id: customerId}}">
                                          <i aria-hidden="true" class="fa fa-angle-right forward_icon"></i>
                                        </router-link>
                                      </div>
                                  </td>
                                  <td :class="rowClass.organization">{{opportunity.organization}}</td>
                                  <td :class="rowClass.status">{{opportunity.status}}</td>
                                  <td :class="rowClass.tags">
                                    <template v-if="opportunity.tags && opportunity.tags.length > 0">
                                      <span
                                        v-for="(tag, tagIndex) in opportunity.tags"
                                        :key="tagIndex"
                                        class="badge badge-primary"
                                        style="margin-right: 5px;"
                                      >
                                        {{ tag.tag.tag_label }}
                                      </span>
                                    </template>
                                  </td>
                                  <td :class="rowClass.vins">
                                    <template v-if="opportunity.vins && opportunity.vins.length > 0">
                                      <span
                                        v-for="(vin, vinsIndex) in opportunity.vins"
                                        :key="vinsIndex"
                                        class="badge badge-info"
                                        style="margin-right: 5px;"
                                      >
                                        {{ vin.vin_num.vin_no }}
                                      </span>
                                    </template>
                                  </td>
                                  <td :class="rowClass.subject">{{ opportunity.subject }}</td>
                                  <td :class="rowClass.opportunity_number">{{opportunity.opportunity_number}}</td>
                                  <td :class="rowClass.person"></td>
                                  <td :class="rowClass.cust_ref"></td>
                                  <td :class="rowClass.owner">{{opportunity.owner}}</td>
                                  <td :class="rowClass.creation_date">{{opportunity.creation_date}}</td>
                              </tr>
                          </template>
                      </template>
                      <template v-else>
                          <tr>
                              <td :colspan="headers.length + 1" class="text-alignment-center">
                                  {{allStaticMessages.data_not_available}}
                              </td>
                          </tr>
                      </template>
                      </tbody>
                      <thead>
                      <tr>
                          <th class="">
                              <div class="custom-control custom-checkbox">
                                  <input v-model="selectAll" type="checkbox" class="custom-control-input" id="selectAllCheckBox" name="example1" @change="clickHeaderCheckBox()">
                                  <label class="custom-control-label" for="selectAllCheckBox"></label>
                              </div>
                          </th>
                          <template v-for="(data, headerIndex) in headers">
                              <th class="" :key="headerIndex" v-if="data.name == 'Action'" data-breakpoint="xs">{{data.name}}</th>
                          </template>
                          <template v-for="(data, headerIndex) in headers">
                              <th :class="data.column_full_class + ' column-header'" v-if="data.name != 'Action'" :key="headerIndex" v-on:click.prevent="sortTableData(data.column_name, data.sort)">{{data.name}}</th>
                          </template>
                      </tr>
                      </thead>
                  </table>
              </div>
              <!-- Pagination Start -->
              <div class="bottom_pagination vgt-wrap__footer crm">
                <div class="row">
                  <div class="col-sm-12 col-md-2 PerPage">
                    <v-select title="Select Per Page"
                              v-model="perPage"
                              @input="changePerPage"
                              name="customPerPage"
                              id="customPerPage"
                              label="text"
                              :clearable="false"
                              :searchable="false"
                              :filterable="false"
                              :options="paginationDropDownArr"
                              placeholder="Select Per Page">
                    </v-select>
                  </div>
                  <div class="mt-2 col-sm-12 col-md-2 RowsDisplay">
                    <p class="pageRowCount">
                      {{ startEndRecord }} of {{ totalRecords }}
                    </p>
                  </div>
  
                  <div class="col-sm-12 col-md-6 Pagination">
                    <b-pagination v-if="totalRecords > 0" align="center" :limit="5" prev-text="‹ Previous"
                                  next-text="Next ›" :total-rows="totalRecords" v-model="currentPage"
                                  @input="changepage()" :per-page="perPageValue"></b-pagination>
                  </div>
  
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <p class="pageJumpLabel">Page : </p>
                  </div>
                  <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6">
                    <input v-model="jumpToPage" type="text" class="form-control form-control-sm" name="jumpToPage"
                           id="jumpToPage" v-on:keyup.enter="keyMonitor()" placeholder="Page">
                  </div>
                </div>
              </div>
              <!-- Pagination End -->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <no-access></no-access>
      </template>
      <app-footer></app-footer>
    </div>
  </template>
  
  <script>
  var self;
  var filterColumn = "id";
  var filterType = "desc";
  import Vue from "vue";
  import vSelect from 'vue-select';
  import Footer from "@/components/partials/Footer";
  import {commonMixins} from '../../mixins/commonMixins';
  import EventBus from ".././event-bus";
  import {HTTP} from '../../http-common';
  import FilterComponent from "../FilterComponent/FilterComponent";
  import NoAccessPage from "../NoAccessPage/NoAccessPage";
  import AddUpdateOpportunityPopup from './AddUpdateOpportunityPopup.vue';
  import CommonLoader from "@/components/partials/CommonLoader.vue";  
  
  Vue.component("app-footer", Footer);
  Vue.component('v-select', vSelect)
  
  export default {
    name: 'ListOpportunity',
    mixins: [commonMixins],
    props: ['isAddOpportunityPopUpClose'],
    components: {
      'filter-component': FilterComponent,
      'no-access': NoAccessPage,
      'add-update-opportunity-popup': AddUpdateOpportunityPopup,
      "common-loader": CommonLoader,
    },
    data() {
      return {
        perPageValue: 25,
        perPage: 25,
        currentPage: 1,
        totalRecords: 0,
        paginationDropDownArr: [5, 10, 15, 25, 50, 100, 200, 500, "All"],
        opportunityArr: [],
        headers: [],
        filterheaders: [],
        filters: {},
        appliedHeaders: [],
        jumpToPage: 1,
        columnName: filterColumn,
        sortType: filterType,
        selectedRows: [],
        startEndRecord: "",
        selectAll: false,
        permissionNameArray: ['can_list_opportunity_master'],
        actionDisplay: true,
        jumpToHistoryPage: 1,
        filterCount: 0,
        staticMessages: "",
        staticInputLabelMessages: "",
        contactData: {},
        customerId: "",
      }
    },
    beforeMount() {
      self = this;
      self.getLoginIdPermissions();
    },
    mounted: function () {
      /* Redirect to login page logic start */
      var userObj = localStorage.getItem("user_obj");
      if (userObj == null) {
        self.$router.push('/');
      }
      /* Redirect to login page logic end */
      self.staticMessages = self.allStaticMessages;
      self.staticInputLabelMessages = self.allLabelMessages;

      /* Set the left sidebar opportunity menu active only when it is opened from the left sidebar */
      /* Otherwise it is mounted from the customer tab so there is no need to set the opportunity menu as active */
      if (this.$route.params.id == undefined){
        self.setActiveMenu('opportunity-list');
        EventBus.$emit("menu-selection-changed", "opportunity-list");
      }

      var listHistoryData = self.loadFilters('listCustomerOpportunity', self);
      self.jumpToHistoryPage = (listHistoryData === null || typeof (listHistoryData) === "undefined" || listHistoryData == '') ? 1 : listHistoryData.page;
  
      let headersNames = [];
      headersNames['filters'] = self.filters;
      self.listExecutionState = false;
      self.loadOpportunity(1);
    },
    methods: {
      resetSorting: function () {
        self.columnName = filterColumn;
        self.sortType = filterType;
        self.changepage();
      },
      changePerPage: function () {
        self.currentPage = 1;
        if (self.perPage !== "All") {
          self.perPageValue = self.perPage;
        }
        self.loadOpportunity();
      },
      changepage: function () {
        if (!self.listExecutionState) {
          self.loadOpportunity();
        }
      },
      selectAllExportHeaders: function () {
        self.exportSelectedHeaders = [];
        if (!self.isSelectAllExportHeader) {
          for (let key in self.exportHeaders) {
            self.exportSelectedHeaders.push(self.exportHeaders[key]);
          }
        }
      },
      updateCheckedExportHeaders: function () {
        if (self.exportSelectedHeaders.length == self.exportHeaders.length) {
          self.isSelectAllExportHeader = true;
        } else {
          self.isSelectAllExportHeader = false;
        }
      },
      refreshList: function () {
        self.jumpToPage = 1;
        self.keyMonitor();
        self.loadOpportunity();
      },
      clearSelection: function () {
        self.selectAll = false;
        self.selectedRows = [];
      },
      deleteOpportunity: function (id) {
        let deleteArray = [];
        id = (id === null || typeof (id) === "undefined" || id == '' || id == 0) ? 0 : id;
        if (id != 0) {
          deleteArray.push(id);
        } else {
          deleteArray = self.selectedRows;
        }
        self.$swal({
          title: '',
          text: 'Are you sure that you want to delete this Opportunity!',
          type: 'info',
          confirmButtonText: 'OK',
          showCancelButton: true,
          // showCloseButton: true,
          showLoaderOnConfirm: true
        }).then((result) => {
          if (result.value) {
            var config = {
              headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
              data: {
                deleteArr: deleteArray,
              }
            };
  
            HTTP.delete("/opportunity/delete", config)
              .then(function (response) {
                if (response.data.code == '200') {
                  self.loadOpportunity();
                }
              })
              .catch(function (error) {
                self.catchHandler(error, function () {
                });
              });
          }
        });
      },
      keyMonitor: function () {
        var tempNumber = self.jumpToInputPage(self.jumpToPage, self.totalRecords, self.perPage);
        self.currentPage = self.jumpToPage = parseInt(tempNumber);
      },
      clickHeaderCheckBox: function () {
        setTimeout(function () {
          self.selectedRows = [];
          if (self.selectAll) {
            for (let i in self.opportunityArr) {
              self.selectedRows.push(self.opportunityArr[i].id);
            }
          }
        }, 100);
      },
      inputCheckbox: function (e) {
        setTimeout(function () {
          self.selectAll = false;
          if (self.selectedRows.length == self.opportunityArr.length) {
            self.selectAll = true;
          }
        }, 100);
      },
      openAddOpportunityPopup: function () {
        this.$emit('call-list-wise-add-opportunity',this.contactData);
      },
      applyFilterParentComponent: function (data) {
        let key;
        let value;
        for (let i = 0; i < data.length; i++) {
          if (data[i]['column_name'] != "null") {
            key = data[i]['column_name'];
            value = data[i]['field'];
            self.filters[key] = value;
          }
        }
        self.appliedHeaders = data;
        self.loadOpportunity();
      },
      setAlreadyAppliedFilters: function () {
        if (self.appliedHeaders.length > 0) {
          self.filterheaders = self.appliedHeaders;
        }
      },
      loadOpportunity: function (first) {
        self.listExecutionState = true;
        if (self.perPage == 'All' && self.totalRecords > process.env.MAX_SINGLEPAGE_DATA) {
          self.showSAlert(self.allStaticMessages.too_many_data_for_all_list, 'info', false);
          self.perPage = 500;
          self.loadOpportunity();
        }
        self.selectedOpportunityHeadersList = self.loadListHeaders('listOpportunity', self);
        self.selectedOpportunityHeadersList = (self.selectedOpportunityHeadersList === null || typeof (self.selectedOpportunityHeadersList) === "undefined" || self.selectedOpportunityHeadersList == '') ? [] : self.selectedOpportunityHeadersList;
        first = (first === null || typeof (first) === "undefined" || first == '') ? 0 : first;
        var fields = {
          filters: self.filters,
          per_page: self.perPage,
          page: self.currentPage,
          sortColumnName: self.columnName,
          sortType: self.sortType,
        };

        var config = {
          headers: {Authorization: "Bearer " + localStorage.getItem("api_token")},
          params: fields
        };
        
        $("#commonLoaderListOpportunity").find(".loading-container.lodingTable").show();
        self.filterCount = self.filterCountFunction(self.filters);
        this.storeFilters('listCustomerOpportunity', fields, false);
        let customerId = this.$route.params.id;
        self.customerId = customerId;
        HTTP.get("/list/opportunity/"+customerId, config)
          .then(function (data) {
            self.listExecutionState = false;
            if (first) {
              self.currentPage = self.jumpToHistoryPage;
            }
            $("#commonLoaderListOpportunity").find(".loading-container.lodingTable").hide();
            if (data.status == 200) {
                self.clearSelection();
                self.headers = [];
                self.totalRecords = data.data.content.result;
                if (self.perPage == 'All' && self.totalRecords > process.env.MAX_SINGLEPAGE_DATA) {
                  let seconds = 5000;
                  self.$swal({
                    type: 'error',
                    html: self.allStaticMessages.too_many_data_to_display_browser_can_not_load,
                    showConfirmButton: false,
                    timer: seconds,
                  });
                  setTimeout(() => {
                    self.perPage = 500;
                    self.loadOpportunity();
                  }, seconds);
                }
                self.opportunityArr = data.data.content.data;
                self.headers = data.data.content.headers;                
                self.loadFilterInputs(data.data.content.headers, self.filters);
                self.contactData = data.data.content.contactData;
  
                self.addColumnClass(self.headers);
  
                let testArray = [
                  {
                    'text': 1,
                    'value': '2020-02-01'
                  },
                  {
                    'text': 2,
                    'value': '2020-02-02'
                  },
                  {
                    'text': 3,
                    'value': '2020-02-03'
                  }
                ]
                for (let i = 0; i < self.headers.length; i++) {
                  if (self.headers[i]['type'] == 'drop_down') {
                    self.headers[i]['options_array'] = testArray;
                  }
                }
                if (data.data.content.per_page !== self.totalRecords) {
                  self.perPage = parseInt(data.data.content.per_page);
                  self.jumpToPage = parseInt(data.data.content.current_page);
                } else {
                  if (self.perPage == 'All') {
                    self.jumpToPage = 1;
                  }
                  self.perPageValue = self.totalRecords;
                  self.currentPage = 1;
                }
                self.setAlreadyAppliedFilters();
                self.startEndRecord = self.calculateStartRecordEndRecord(self.currentPage, self.perPage, self.totalRecords);
                self.sortFieldDisplay(self.columnName, self.sortType);
            }
          })
          .catch(function (error) {
            self.listExecutionState = false;
            $('#exportOpportunityId').prop("disabled", false);
            self.exportOpportunity = false;
            $("#commonLoaderListOpportunity").find(".loading-container.lodingTable").hide();
            self.catchHandler(error, function () {
            });
          })
      },
      sortTableData: function (name, isSortAllowed) {
        if (isSortAllowed == "nosort") {
          if (self.columnName == name) {
            self.sortType = (self.sortType == 'desc') ? 'asc' : 'desc';
          } else {
            self.sortType = "asc";
          }
          self.columnName = name;
          self.loadOpportunity();
        }
      },
    },
    watch: {
      isAddOpportunityPopUpClose(status) {
        if(status) {
          this.refreshList();
        }
      }
    }
  }
  EventBus.$on("login-permissions", function (permissions) {
    if (typeof self != "undefined") {
      self.permissionNameArray = [];
      for (let i = 0; i < permissions.length; i++) {
        self.permissionNameArray.push(permissions[i].permission_name)
      }
      if (!self.permissionNameArray.includes('can_add_opportunity_master') && !self.permissionNameArray.includes('can_delete_opportunity_master')) {
        setTimeout(function () {
          self.headers.pop();
          self.actionDisplay = false;
        }, 50);
      }
    }
  });
  </script>
  